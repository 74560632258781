import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import {Box, Tooltip} from "@mui/material";

const TooltipProps = {
    tooltip: {
        sx: {
            color: "var(--black)",
            backgroundColor: "var(--white)",
            borderRadius: 0,
            maxWidth: 120
        }
    }
}

export default function RiskCategoryButton(props) {

    const {value, tooltip, className} = props

    return (
        <Box className="risk-category-container">
            <Tooltip
                title={tooltip}
                componentsProps={TooltipProps}
            >
                <ToggleButton className={className} {...props}>{value}</ToggleButton>
            </Tooltip>
        </Box>
    );
}