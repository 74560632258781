import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const ModalSimpleNotification = ({
  open,
  handleClose,
  title,
  content,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} className="modal" maxWidth="md">
      <DialogTitle className="modal-title-between">
        <Typography className="modal-title">{title}</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0 24px 40px",
          ".modal-text": {
            fontSize: "18px", fontWeight: "400",
          },
        }}>
        <Typography className="modal-text">{content}</Typography>
      </DialogContent>
    </Dialog>
  );
};
