import React from "react";

const EquipmentIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00011 20V14C4.00011 11.8783 4.84296 9.84344 6.34325 8.34315C7.84354 6.84285 9.87837 6 12.0001 6C14.1218 6 16.1567 6.84285 17.657 8.34315C19.1573 9.84344 20.0001 11.8783 20.0001 14V20H21.0001V22H3.00011V20H4.00011ZM6.00011 20H18.0001V14C18.0001 12.4087 17.368 10.8826 16.2427 9.75736C15.1175 8.63214 13.5914 8 12.0001 8C10.4088 8 8.88268 8.63214 7.75746 9.75736C6.63225 10.8826 6.00011 12.4087 6.00011 14V20ZM11.0001 2H13.0001V5H11.0001V2ZM19.7781 4.808L21.1921 6.222L19.0721 8.343L17.6571 6.929L19.7781 4.808ZM2.80811 6.222L4.22211 4.808L6.34311 6.928L4.93011 8.344L2.80811 6.222ZM7.00011 14C7.00011 12.6739 7.52689 11.4021 8.46457 10.4645C9.40225 9.52678 10.674 9 12.0001 9V11C11.2045 11 10.4414 11.3161 9.87878 11.8787C9.31618 12.4413 9.00011 13.2044 9.00011 14H7.00011Z"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default EquipmentIcon;
