import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export const LimitedTypography = ({ fullText }) => {
  const [expanded, setExpanded] = useState(false);
  const textRef = React.useRef(null);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Typography
        ref={textRef}
        variant="body1"
        component="div"
        style={{
          marginTop:"10px",
          overflow: 'hidden',
          fontFamily: "var(--INTER)",
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: expanded ? 'unset' : 5,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {fullText}
      </Typography>
      {fullText.length>600 && ( // Check if text overflows
        <Button sx={{color:"var(--main)",fontFamily: "var(--INTER)",fontWeight:"bold",fontSize:"12px"}} onClick={handleToggleExpand}>
          {expanded ? 'Скрыть' : 'Показать полностью'}
        </Button>
      )}
    </div>
  );
};
