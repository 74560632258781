import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HorizontalBar = ({ data, labels }) => {
  const { t } = useTranslation();

  function findMaxCountValue(systems) {
    let maxCount = -Infinity;
    for (let i = 0; i < systems?.length; i++) {
      if (systems[i].data > maxCount) {
        maxCount = systems[i].data;
      }
    }
    return maxCount;
  }

  const maxCountValue = findMaxCountValue(data);

  const options = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
        max: maxCountValue + 1,
        min: 0,
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "#212121",
        align: "end",
        anchor: "end",
        offset: 10,
      },
    },
    elements: {
      bar: {},
    },
  };

  const dataCfg = useMemo(
    () => ({
      labels: labels?.length ? labels?.map((label) => t(label)) : [""],
      datasets: [
        {
          data: data?.map((item) => item.data),
          backgroundColor: data?.map((item) => item.backgroundColor),
          barPercentage: 0.3,
          borderWidth: 0,
          borderRadius: 4,
          borderSkipped: false,
        },
      ],
    }),
    [t, data, labels]
  );

  return <Bar plugins={[ChartDataLabels]} options={options} data={dataCfg} />;
};

export default HorizontalBar;
