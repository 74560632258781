import { createSlice } from "@reduxjs/toolkit";
import { messageCodeApi } from "../../middleware/messageCodeApi";

const initialState = {

  messageCodeTypes: [],
  messageCodeElems: [],
};

export const messageCodeSlice = createSlice({
  name: "messageCode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    
    builder.addMatcher(
      messageCodeApi.endpoints.getMessageCodeTypes.matchFulfilled,
      (state, { payload }) => {
        state.messageCodeTypes = payload;
      }
    );
    builder.addMatcher(
      messageCodeApi.endpoints.getMessageCodeElements.matchFulfilled,
      (state, { payload }) => {
        state.messageCodeElems = payload;
      }
    );
  },
});

export const messageCodeActions = messageCodeSlice.actions;
export const messageCodeReducer = messageCodeSlice.reducer;
