import React from "react";
import { t } from "i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { fileSizeCheck, fileTypeCheck } from "../../../../validation";

import InputText from "../../../components/InputText";
import InputFileSingle from "../../../components/InputFile/InputFileSingle";
import CheckboxSimple from "../../../components/CheckboxSimple";

export const formFields = {
  workRequirements: "workRequirements",
  workscopeName: "workscopeName",
  isActive: "isActive",
  description: "description",
};

export const templatejob = {
  [formFields.workscopeName]: null,
  [formFields.description]: "",
  [formFields.isActive]: true,
};
export const maxFloorAmount = 163;

export const worksConfig = [
  {
    name: formFields.workscopeName,

    type: "text",
    rules: {
      required: true,
    },
    flexBasis: "40%",
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.description,
    type: "text",
    rules: {
      required: true,
    },
    flexBasis: "40%",
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.isActive,
    type: "checkbox",
    rules: {},
    flexBasis: "20%",
    sx: {marginLeft: '12px'},
    input: (props) => <CheckboxSimple {...props} />,
  },
];
