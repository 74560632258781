import React from "react";
import { useGetFacilityOnMapInfoQuery } from "../../../middleware/facilitiesApi";
import ObjectDetails from "../../pages/ObjectsList/ObjectDetails";
import {Box} from "@mui/material";

const MapPopUp = ({
  setIsMapPopUpOpen,
  selectedMarkerID,
  setSelectedMarkerId,
}) => {

  const onCloseHandler = () => {
    setIsMapPopUpOpen(false);
    setSelectedMarkerId(null);
  };

  const {data: object} = useGetFacilityOnMapInfoQuery(selectedMarkerID, {
    skip: !selectedMarkerID,
  });

  return (
    <Box className="map_popup">
      <ObjectDetails data={object} onClose={onCloseHandler}/>
    </Box>
  );
};

export default MapPopUp;
