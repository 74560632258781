import { createSlice } from "@reduxjs/toolkit";
// import { executiveApi } from "../../middleware/executiveApi";

const initialState = {};

export const executiveSlice = createSlice({
  name: "executive",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const executiveActions = executiveSlice.actions;
export const executiveReducer = executiveSlice.reducer;