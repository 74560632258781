

import { t } from "i18next";
import { formFields } from "./options";
import DatePickerField from "../../../components/DatePickerField";
import InputTextarea from "../../../components/InputTextarea";
import InputText from "../../../components/InputText";
import { InputFileMultiple } from "../../../components/InputFile/InputFileMultiple";
import InputFileSingle from "../../../components/InputFile/InputFileSingle";
import { fileTypeCheck, fileSizeCheck } from "../../../../validation";
import { FilesForm } from "./FilesForm";
import { Box, Typography } from "@mui/material";
import WorkShortFields from "../../FireCompartments/WorkShortFields";
import {
  EquipmentTypeIdSelectGrouping, SelectPeriodRegulation, EquipmentNameGroup,EquipmentRegulationNameGroup
} from "../Components";

import {
  priorityOptions,

} from "./options";
import { SelectSimpleObject, EquipmentKindSelect } from "../Components";
import CheckboxSimple from '../../../components/CheckboxSimple'

export const formConfigSection = (
  setValue,
  isCustomRegulation
) => [
    {
      name: formFields.isActive,
      label: t("label:unbanned"),
      type: "checkbox",
      rules: {},
      flexBasis: "100%",
      sx: {marginLeft: '12px'},
      input: (props) => <CheckboxSimple {...props} />,
    },
    {
      name: formFields.name,
      label: t("label:nameReg"),
      type: "text",
      flexBasis: "49%",
      rules: {},
      input: (props) =>
          <InputText {...props} />
    },
    {

      name: formFields.protectionObjectId,
      label: `label:protectionObjectId`,
      type: "select-text",
      rules: {
        required: true,
      },
      flexBasis: "49%",

      isAllExist: false,
      isCanDisabled: false,
      input: (props) => <SelectSimpleObject setValue={setValue} {...props} />,
    },
    {
      id: 4,
      name: formFields.equipmentKindsIds,
      label: "label:equipmentKindEployee",
      type: "select-multi",
      flexBasis: "49%",

      rules: {
        required: true,
      },
      input: (props) => <EquipmentTypeIdSelectGrouping setValue={setValue} {...props} />,
    },
    {
      id: 4,
      name: formFields.equipmentGroupId,
      label: "label:group_equipment",
      type: "select-multi",
      flexBasis: "49%",

      rules: {
        required: false,
      },
      input: (props) => <EquipmentNameGroup setValue={setValue} {...props} />,
    },
    {
      id: 5,
      name: formFields.regulationId,
      label: "label:regulationName",
      type: "select-multi",
      flexBasis: "49%",
      rules: {
        required: true,
      },
      input: (props) => <EquipmentRegulationNameGroup disabled={isCustomRegulation} setValue={setValue} {...props} />,
    },
    {
      id: 4,
      name: formFields.periodId,
      label: "label:periodReglament",
      type: "select-multi",
      flexBasis: "49%",

      rules: {
        required: true,
      },
      input: (props) => <SelectPeriodRegulation {...props} />,
    },
    {
      id: 10,
      flexBasis: "49%",
      name: `workRequirements`,
      CustomFormItem: WorkShortFields,
    },
    {
      name: formFields.description,
      label: t("label:description"),
      type: "text",
      rows: 10,
      flexBasis: "100%",
      sx: { marginTop: "8px", alignSelf: "start !important" },
      rules: {},
      input: (props) =>
        <Box>
          <Typography sx={{ flexBasis: "100%", marginBottom: "20px", fontWeight: "bold !important", fontSize: "20px !important", textAlign: "center", lineHeight: '24px !important' }}>Описание регламента</Typography>
          <InputTextarea {...props} />
        </Box>
    },
    {
      id: 3,
      name: formFields.techcardFiles,
      type: "file",
      flexBasis: "22%",
      sx: { marginTop: "20px" },
      rules: {
        validate: {
          type: (files) => {
            const availableFileTypes = [
              "image/jpeg",
              "image/png",
              "application/pdf",
            ];
            return fileTypeCheck(
              files.filesToAdd,
              formFields.techcardFiles,
              availableFileTypes
            );
          },
          size: (files) => {
            const maxAvailableFileSize = 10; // MBs
            return fileSizeCheck(
              files.filesToAdd,
              formFields.techcardFiles,
              maxAvailableFileSize
            );
          },
        },
      },
      canDeleteFile: false,
      input: (props) => (
        <Box sx={{ display: "flex", paddingBottom: "20px", alignItems: 'baseline' }}>
          <Typography sx={{ alignContent: "center", fontWeight: "bold !important", fontSize: "20px !important", mr: '10px' }}>Технологическая карта :</Typography>
          <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} />
        </Box>

      ),
    },

  ];
export const equipmentsConfig = ({ control }) => ([

  {
    name: formFields.jobEquipments,
    label: t("label:jobEquipments"),
    type: "select-text",

    rules: {

    },
    input: (props) => (
      <EquipmentTypeIdSelectGrouping {...props} />
    ),
  },

]);
