import React from "react";
import { Box } from "@mui/material";
import FormItem from "../../../components/FormItem";

export const FilesForm = ({ config, control, errors }) => {
  return (
    <Box className="files_form">
      {config.map(({ id, name, input, ...rest }) => (
        <FormItem
          key={id}
          name={name}
          control={control}
          errors={errors}
          input={input}
          {...rest}
        />
      ))}
    </Box>
  );
};
