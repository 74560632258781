import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/material";

import { useNavigate } from "react-router";
import {
  useUpdateRegulationsMutation,
} from "../../../../middleware/regulationsApi";
import { formFields } from "./options";
import { useAction } from "../../../../hooks/actions";
import { PATHS } from "../../../../constants";
import { getConfigByRole, getRequestData, getRequestDataAdd, getRequestDataFilesForAdd } from "./helpers";
import { useAppSelector } from "../../../../hooks/redux";

import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";
import FormItem from "../../../components/FormItem";

import {
  useGetFacilitySelectElementsQuery,
  useLazyGetFloorOptionsSelectByFacilityQuery,
} from "../../../../middleware/facilitiesApi";
import BasicForm from "../BasicForm";
import dayjs from "dayjs";

const AddReglament = () => {
  const [ranOnce, setRanOnce] = useState(false);

 
  const { errorFace } = useGetFacilitySelectElementsQuery();
  
  const isErrorRequest = errorFace ;

  const isActive = true; // mock data. Wait from backend
  const { info } = useAppSelector((state) => state.users);
  const { roleId} = info || {};
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [objectId, setObjectId] = useState("");
  const [regulations, setRegulations] = useState([]);
  const [manipulateFiles, { error: errorFile, isSuccess: isSuccessFile }] =
  useManipulateFilesMutation();
  const [addRegulation, { error: errorAdd, isSuccess: isSuccessAdd }] =
  useUpdateRegulationsMutation();
  
  

  const errorSubmit = errorAdd || errorFile;
  const isSuccess = isSuccessAdd || isSuccessFile;
  const handleCancel = () => navigate(PATHS.EQUIPMENT_REGLAMENTS);

  
  useEffect(() => {
    setIsErrorSubmit(errorSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSubmit]);
  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  

  // Обработка события отправки формы
  const onSubmit = async (data) => {
    const { entities,techcardFiles } = getRequestData({data:data,id:null});
    addRegulation(entities).then((result) => {
      if ("error" in result) {
        return;
      }
      const id = result?.data?.id;
      const requestDataFiles = getRequestDataFilesForAdd(id, techcardFiles);

      manipulateFiles(requestDataFiles).then((response) => {
        if ("error" in response) {
          return;
        }
        
      });
      navigate(PATHS.EQUIPMENT_REGLAMENTS);
    })
  };

  return (
    <Box className="template_page employee">
      <Box className="template_page-header">
        <Typography className="template_page-header-title">
          {t("title_page:add_equip_reglaments")}
        </Typography>
        <Box className="template_page-header-buttons">
          <Button className="btn btn-outlined-grey" onClick={handleCancel}>
            {t("button:comeback")}
          </Button>
          <Button
            form="editRegulationForm"
            className="btn btn-primary-fill"
            type="submit"
            disabled={isSuccess}
          >
            {t("button:save")}
          </Button>
        </Box>
      </Box>
      <BasicForm onSubmit={onSubmit} isErrorRequest={isErrorRequest} />
    </Box>
  );
};

export default AddReglament;
