import React from "react";
import { t } from "i18next";

import { formFields } from "./form-config";
import { EquipmentKindSelectGrouping } from "../Equipments/Components";
import {
  SelectSimpleObject,
  ClickableEquipmentCell,
  SimpleTextWithTooltipCellDetailes,
} from "./Components";
import {
  SimpleTextCell,
} from "../TasksList/Components";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import FormItem from "../../components/FormItem";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import { Box } from "@mui/material";

export const isCustomOptions = [
  { value: "все", name: "все" },
  { value: "true", name: "Пользователь" },
  { value: "false", name: "Стандарт" },
];

export const getColumns = ({
  control,
  columnsFromStorage,
  searchequipmentCount,
  setsearchequipmentCount,
  objectId,
  searchStandardName,
  setsearchStandardName,
  searchPeriodName,
  setsearchPeriodName,
  searchEquipmentName,
  setsearchEquipmentName,
  searchRegulationName,
  setsearchRegulationName,
  searchIsCustom,
  setSearchIsCustom,
  searchWorkDetailes,
  setsearchWorkDetailes,
  searchWorkscopeName,
  setsearchWorkscopeName,
}) =>
  [
    {
      id: formFields.protectionObjectName,
      numeric: false,
      enableSorting: false,
      clickable: true,
      isSticky: true,
      borderHeader: true,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.protectionObjectName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          label={t("label:object")}
          name={`${formFields.searchParams}.${formFields.protectionObjectId}`}
          sx={{
            padding: "13px 0 13px 16px",
          }}
          width="200px"
          input={(props) => (
            <SelectSimpleObject
              {...props}
              isTableheader={true}
              isAllExist={true}
              className="select_in_built small-light"
            />
          )}
        />
      ),
    },
    {
      id: formFields.equipmentKindName,
      numeric: false,
      enableSorting: false,
      size: '210px',
      clickable: false,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.equipmentKindName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: (props) => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.equipmentKindId}`}
          sx={{
            padding: "13px 0 13px 16px",
            fontSize: '16px',
            width: '100%'
          }}
          label={t("label:equipKinds")}
          input={(props) => (
            <EquipmentKindSelectGrouping
              itemoption={{
                id: "все",
                name: t("options_name:everything"),
              }}
              useShortTitle={true}
              {...props}
              isTableheader={true}
              className="select_in_built small-light"
            />
          )}
        />
      ),
    },
    {
      id: formFields.equipmentName,
      numeric: false,
      enableSorting: false,
      clickable: true,
      isSticky: true,
      size: '200px',
      borderHeader: true,
      isVisible: true,
      RowCell: (props) => <ClickableEquipmentCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.EquipmentName`}
          sx={{ padding: "9px 0 9px 16px" }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:equip_name")}
              className="select_in_built small-light"
              searchValue={searchEquipmentName}
              changeSearchValue={setsearchEquipmentName}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.equipmentCount,
      numeric: false,
      enableSorting: false,
      size: "140px",
      borderHeader: true,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.equipmentTypeName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.equipmentCount`}
          sx={{ padding: "9px 0 9px 16px", width: '100%' }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:equip_count")}
              className="select_in_built small-light"
              searchValue={searchequipmentCount}
              changeSearchValue={setsearchequipmentCount}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.regulationName,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.regulationName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.RegulationName`}
          sx={{ padding: "9px 0 9px 16px", width: '300px' }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:equip_reglament")}
              className="select_in_built small-light"
              searchValue={searchRegulationName}
              changeSearchValue={setsearchRegulationName}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.isCustom,
      numeric: false,
      enableSorting: false,
      size: "240px",
      borderHeader: true,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.regulationName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          label={t("tableHeader:isCustomReglament")}
          name={`${formFields.searchParams}.${formFields.isCustom}`}
          sx={{ padding: "13px 0 13px 16px", width: '100%' }}
          input={(props) => (
            <SelectSimpleInbuilt
              className="select_in_built small-light"
              searchValue={searchIsCustom}
              changeSearchValue={setSearchIsCustom}
              options={isCustomOptions}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.workscopeName,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.workscopeName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.WorkscopeName`}
          sx={{ padding: "9px 0 9px 16px", width: '250px' }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:workScopeName")}
              className="select_in_built small-light"
              searchValue={searchWorkscopeName}
              changeSearchValue={setsearchWorkscopeName}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.workRequirementsDetails,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      isVisible: true,
      //isVisible: columnsFromStorage[formFields.workRequirementsDetails],
      RowCell: (props) => <SimpleTextWithTooltipCellDetailes {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.WorkRequirementsDetails`}
          sx={{ padding: "9px 0 9px 16px", width: '240px' }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:workScopeDetails")}
              className="select_in_built small-light"
              searchValue={searchWorkDetailes}
              changeSearchValue={setsearchWorkDetailes}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.periodName,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.number],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.PeriodName`}
          sx={{ padding: "9px 0 9px 16px", width: '250px' }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:equip_period")}
              className="select_in_built small-light"
              searchValue={searchPeriodName}
              changeSearchValue={setsearchPeriodName}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.regulationDescription,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      //isVisible: columnsFromStorage[formFields.standardParagraphName],
      isVisible: true,
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box
          sx={{
            padding: "13px 16px 13px 0",
            color: "var(--headers)",
            fontSize: '16px'
          }}
        >
          {t("tableHeader:description")}
        </Box>
      ),
    },
    {
      id: formFields.standardName,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      //isVisible: columnsFromStorage[formFields.standardParagraphName],
      isVisible: true,
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.standardName`}
          sx={{
            padding: "9px 0 9px 16px",
            color: "16px",
            width: '150px',
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:standardName")}
              className="select_in_built small-light"
              searchValue={searchStandardName}
              changeSearchValue={setsearchStandardName}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.standardParagraphName,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      //isVisible: columnsFromStorage[formFields.standardParagraphName],
      isVisible: true,
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box
          sx={{
            padding: "13px 16px 13px 0",
            color: "var(--headers)",
            fontSize: '16px',
            width: '100%'
          }}
        >
          {t("tableHeader:standardParagraphName")}
        </Box>
      ),
    },
    {
      id: formFields.techCard,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      // isVisible: columnsFromStorage[formFields.number],
      isVisible: true,
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box
          sx={{
            padding: "13px 16px 13px 0",
            color: "var(--headers)",
            fontSize: '16px',
            fontWeight: '500'
          }}
        >
          {t("tableHeader:tech_card")}
        </Box>
      ),
    },
    {
      id: formFields.edit,
      numeric: false,
      enableSorting: false,
      label: "",
      isVisible: true,
      size: 50,
      mode: 'edit',
      clickable: false,
      HeaderCell: () => (
        <Box
          sx={{
            padding: "25px 16px",
            color: "var(--headers)",
          }}
        ></Box>
      ),
    },
  ].filter(({ isVisible }) => isVisible);
