import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import { PATHS } from "../constants";
import { useAction } from "../hooks/actions";
import { persistor } from "../redux/store";

export default function AlertNewVersion({open,setOpen}) {
  
    const navigate = useNavigate();
    const { setAuthStep } = useAction();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const refreshData = async () =>{
    await persistor.purge().then(() => {
        setAuthStep(0);
        navigate(PATHS.LOGIN_ROUTE);
      });
      localStorage.clear(); 
      setOpen(false)
  }
  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Вышла новая версия"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Вышла новая версия. Необходимо сбросить данные сайта для продолжения корректно работы
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отклонить</Button>
          <Button onClick={refreshData} autoFocus>
            Принять
          </Button>
        </DialogActions>
      </Dialog>
  );
}