import * as React from "react";
import { Dialog, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Dogovor3 = ({ open, onClose }) => {
  const textStyle = {
    fontSize: "14px",
    lineHeight: "1.5",
    marginBottom: "16px",
    textAlign: "justify",
  };

  const headingStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "12px",
  };

  const listStyle = {
    marginLeft: "24px",
    marginBottom: "16px",
  };
  return (
    <Dialog className="object_dialog" open={open} onClose={onClose}>
      <IconButton className="close_icon" onClick={onClose}>
        <CloseIcon />
      </IconButton>

      <Typography>
        <div>
          <h2 style={headingStyle}>
            Условия использования программного обеспечения ВДПО ПРО
          </h2>
          <h3 style={headingStyle}>Регистрация.</h3>
          <p style={textStyle}>
            Для того чтобы воспользоваться Программой Администратору Портала
            необходимо пройти процедуру Регистрации, в результате которой для
            Администратора Портала будет создана уникальная Учетная запись. Для
            добавления Пользователей порталов Администратору портала необходимо
            зарегистрировать их в Личном кабинете путем заполнения
            соответствующей формы.
          </p>
          <p style={textStyle}>
            Количество допустимых Пользователей портала в рамках одного Портала
            определяется исходя из типа лицензии.
          </p>
          <p style={textStyle}>
            Для регистрации Администратор Портала обязуется предоставить
            достоверную и полную информацию о себе и Пользователях портала по
            вопросам, предлагаемым в форме регистрации, и поддерживать эту
            информацию в актуальном состоянии. Если Администратор Портала
            предоставляет неверную информацию или у ВДПО ПРО есть основания
            полагать, что предоставленная им информация неполна или
            недостоверна, ВДПО ПРО имеет право по своему усмотрению
            заблокировать либо удалить Учетную запись Администратора Портала, а
            также запретить использование Программы.
          </p>

          <h3 style={headingStyle}>
            Логин и пароль для доступа к Учетной записи.
          </h3>
          <p style={textStyle}>
            При регистрации Пользователи портала самостоятельно выбирают себе
            логин (уникальное символьное имя) и пароль для доступа к Учетной
            записи. ВДПО ПРО вправе запретить использование определенных
            логинов, а также устанавливать требования к логину и паролю (длина,
            допустимые символы и т.д.).
          </p>
          <p style={textStyle}>
            Пользователи портала самостоятельно несут ответственность за
            безопасность (устойчивость к угадыванию) выбранного ими пароля, а
            также самостоятельно обеспечивают конфиденциальность своего пароля.
          </p>
          <p style={textStyle}>
            Пользователи портала несут ответственность за все
            действия/бездействие (а также их последствия) в рамках или с
            использованием Программы под своей Учетной записью, включая случаи
            добровольной передачи или несоблюдения конфиденциальности данных для
            доступа к Учетной записи третьим лицам на любых условиях (в том
            числе по договорам или соглашениям). При этом все действия в рамках
            или с использованием Программы под Учетной записью Пользователей
            портала считаются произведенными ими, за исключением случаев,
            имеющих место после получения ВДПО ПРО от Администратора Портала
            направленного в порядке, предусмотренном Соглашением, уведомления о
            несанкционированном использовании Программы под Учетной записью
            Администратора Портала или о любом нарушении (подозрениях о
            нарушении) конфиденциальности своего пароля.
          </p>
          <p style={textStyle}>
            Администратор Портала обязан немедленно уведомить ВДПО ПРО о любом
            случае несанкционированного доступа к Программе с использованием его
            Учетной записи и/или о любом нарушении (подозрениях о нарушении)
            конфиденциальности своего пароля. В целях безопасности Администратор
            Портала обязан самостоятельно осуществлять безопасное завершение
            работы под своей учетной записью (кнопка «Выход») по окончании
            каждой сессии работы с Программой. ВДПО ПРО не отвечает за возможную
            потерю данных, а также другие последствия любого характера, которые
            могут произойти из-за нарушения Администратором Портала положений
            этой части Соглашения.
          </p>

          <h3 style={headingStyle}>
            Блокировка и закрытие Профиля, удаление Учетной записи или Портала.
          </h3>
          <p style={textStyle}>
            ВДПО ПРО вправе установить временное ограничение доступа к Порталу
            (блокировку) в случаях:
          </p>
          <ul style={listStyle}>
            <li style={textStyle}>
              нарушения Пользователями портала условий Соглашения или условий
              иных связанных документов,
            </li>
            <li style={textStyle}>
              неиспользования Программы в течение 50 (Пятидесяти) календарных
              дней в рамках лицензии «Бесплатный». Под неиспользованием
              Программы в Соглашении понимается отсутствие данных в архивной
              системе ВДПО ПРО об активностях в Программе,
            </li>
            <li style={textStyle}>
              неудаления пользовательских данных, несовместимых с более низким
              типом лицензии в соответствии с п. 6.8. Соглашения.
            </li>
          </ul>
          <p style={textStyle}>
            Восстановить доступ к Порталу, заблокированному на основании пп. 3
            п. 7.3.1. Соглашения, можно путем приобретения платной лицензии с
            функциональными возможностями, не меньшими, чем требуется для работы
            заблокированного Портала.
          </p>
          <p style={textStyle}>
            ВДПО ПРО оставляет за собой право удалить Портал и все данные,
            относящихся к Порталу, без возможности их восстановления, в
            следующих случаях:
          </p>
          <ul style={listStyle}>
            <li style={textStyle}>
              по истечении срока, указанного в пп. 2. п. 7.3.1 Соглашения;
            </li>
            <li style={textStyle}>
              по истечении 50 (пятидесяти) календарных дней с даты блокировки
              Портала на основании пп. 3. п. 7.3.1. Соглашения.
            </li>
          </ul>
          <p style={textStyle}>
            После удаления Портала его доменное имя становится доступно для
            присвоения другим Порталам.
          </p>
          <h3 style={headingStyle}>Закрытие Профиля. </h3>
          <p style={textStyle}>
            Пользователи портала имеют возможность закрыть свой Профиль с
            самостоятельным удалением данных в Профиле, что повлечет за собой
            потерю доступа к Порталу. Администратор Портала будет
            проинформирован о предстоящем закрытии Профиля.
          </p>

          <h3 style={headingStyle}>Удаление Учетной записи. </h3>
          <p style={textStyle}>
            Администратор Портала может запросить удаление своей Учетной записи
            с учетом следующего:
          </p>
          <ul style={listStyle}>
            <li style={textStyle}>
              единственный Администратор для удаления своей Учетной записи
              должен наделить другого Авторизованного пользователя статусом
              Администратора, в противном случае ВДПО ПРО оставляет за собой
              право рассмотреть запрос Администратора на удаление Учетной записи
              в индивидуальном порядке.
            </li>
            <li style={textStyle}>
              если в Портале несколько Администраторов, другие Администраторы
              будут немедленно проинформированы о предстоящем удалении Учетной
              записи Администратора, запросившего такое удаление.
            </li>
          </ul>
          <p style={textStyle}>
            Администратор Портала имеет право (при наличии соответствующей
            функциональности) инициировать процесс удаления Портала и несет
            единоличную ответственность перед Конечным пользователем как за
            инициирование процесса удаления Портала, так и за удаление любых
            содержащихся в нем данных.
          </p>
          <p style={textStyle}>
            После инициирования процесса удаления Портал будет удален не позднее
            чем через 90 (девяносто) дней.
          </p>
          <h3 style={headingStyle}>
            Дополнительная функциональность Программы
          </h3>
          <p style={textStyle}>
            ВДПО ПРО предоставляет возможность по своему усмотрению использовать
            дополнительную функциональность Программы, которая может быть
            активирована или деактивирована Администратором портала и/или
            Авторизованным пользователем. Отдельный вид дополнительной
            функциональности может предоставляться третьими лицами, а также за
            отдельную плату.
          </p>
          <p style={textStyle}>
            Использование дополнительной функциональности может требовать
            обновления Программы и другого программного обеспечения.
          </p>
          <p style={textStyle}>
            Начиная использовать любой вид дополнительной функциональности,
            Авторизованный пользователь (Администратор портала) подтверждает,
            что ознакомился и принял правила его использования (если применимо)
            и обязуется соблюдать требования законодательства Российской
            Федерации.
          </p>
          <p style={textStyle}>
            Правила и условия использования конкретного вида дополнительной
            функциональности является неотъемлемой частью Соглашения.
          </p>
        </div>
      </Typography>
    </Dialog>
  );
};
