import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    OutlinedInput,
    InputLabel,
    MenuItem,
    ListItemText,
    FormControl,
    Select, Checkbox, Box
} from "@mui/material";

import { MyListSubheader, MyListSubheaderSearch } from "./MyListSubheader";
import checkImagechecked from "../../../image/icons/checkboxChecked.png"
import checkImage from "../../../image/icons/checkBox.png"
import selectArrowUp from "../../../image/icons/selectArrowUp.png";
import selectArrowDown from "../../../image/icons/selectArrowDown.png"


const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 30;


const SelectGroupingMultipleCheckmarks = ({
    options,
    label,
    onChange,
    error,
    value,
    searchValue,
    setSearchValue,
    displayEmpty,
    placeholder,
    firstdata,
    setValue,
    withSearch = true,
    ...rest
}) => {
    const SEARCH_HEIGHT = withSearch ? 72 : 0
    const { t } = useTranslation();

    const [updatedOptions, setUpdatedOptions] = useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const allOptionItemsCount = useMemo(() => {
        let totalCount = 0;
        totalCount += updatedOptions?.length;
        updatedOptions?.map((parent) => {
            if (parent?.open) totalCount += parent?.items?.length;
        })

        return totalCount;

    }, [updatedOptions])
    const MenuProps = {
        autoFocus: false,
        PaperProps: {
            style: {
                maxHeight: allOptionItemsCount >= 4 ? (ITEM_HEIGHT + ITEM_PADDING_TOP) * 4 + SEARCH_HEIGHT : (ITEM_HEIGHT + ITEM_PADDING_TOP) * allOptionItemsCount + SEARCH_HEIGHT,
            },
        },
    };
    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
        withSearch && setSearchValue("")
    };
    useEffect(() => {
        setUpdatedOptions(options.map(option => ({
            ...option
        })))
    }, [options])

    return (
        <Box sx={{ position: "relative" }} className="input_text">
            <InputLabel sx={{ mb: "8px" }}>
                {t(label)}
            </InputLabel>
            <FormControl fullWidth error={!!error} {...rest}>
                <Select
                    value={value}
                    input={<OutlinedInput />}
                    onChange={(e) => onChange(e.target.value)}
                    multiple
                    MenuProps={MenuProps}
                    open={isOpen}
                    displayEmpty={displayEmpty}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    IconComponent={() =>
                        <Box sx={{ mr: "24px", cursor: "pointer" }} onClick={() => { setIsOpen((prev) => !prev) }}>
                            {isOpen ? (
                                <img src={selectArrowUp} />
                            ) : (
                                <img src={selectArrowDown} />
                            )
                            }
                        </Box>}
                    sx={{ maxHeight: "56px" }}
                    renderValue={(selected) => (
                        selected?.length > 0 ? (
                            selected.map((value) => (
                                updatedOptions[0].items.find(({ id }) => id === value)
                            )?.name).join(", ") 
                        ) : (t(placeholder) || "Нет")
                    )}
                    {...rest}
                >
                    {withSearch && (
                        <MyListSubheaderSearch
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                        />
                    )}

                    {updatedOptions?.length ? updatedOptions.map((option) => {
                        const { open, items } = option || {};

                        if (!items) {
                            const { id, name } = option
                            return (
                                <MenuItem key={id} value={id} sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP, margin: "0px !important", }}>
                                    <Checkbox checked={false} disabled={true}
                                        sx={{ height: "28px", width: "28px", borderRadius: 0, mr: "8px" }}
                                        icon={<Box
                                            component="img"
                                            src={checkImage}
                                        />}
                                        checkedIcon={<Box
                                            component="img"
                                            src={checkImagechecked}
                                        />}
                                    />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            )
                        }

                        const Items = items?.length ? items.map((item) => {
                            const { id, name } = item
                            return (
                                <MenuItem
                                    key={id}
                                    value={id}

                                    sx={{
                                        display: `${open ? "flex" : "none"}`,
                                        paddingLeft: "55px",

                                        height: ITEM_HEIGHT + ITEM_PADDING_TOP
                                    }}
                                >
                                    <Checkbox checked={value.includes(id)}
                                        sx={{ height: "28px", width: "28px", borderRadius: 0, mr: "8px" }}
                                        icon={<Box
                                            component="img"
                                            src={checkImage}
                                        />}
                                        checkedIcon={<Box
                                            component="img"
                                            src={checkImagechecked}
                                        />}
                                    />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            )
                        }) : null;

                        return [
                            <MyListSubheader
                                key="MyListSubheader"
                                setOptions={setUpdatedOptions}
                                options={updatedOptions}
                                itemOption={option}
                                selectValue={value}
                                onChange={onChange}
                            />,
                            Items,
                        ];
                    })
                        : <MenuItem disabled={true} sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }}>
                            <ListItemText primary={t("label:nothingFound")} />
                        </MenuItem>
                    }
                </Select>
            </FormControl>
        </Box>
    );
};

export default SelectGroupingMultipleCheckmarks;
