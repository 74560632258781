import { t } from "i18next";
import { formFields } from "./form-config";
import { globalFields } from "../../../constants";
import { PRIORITY_ID, STATUS_ID, fields } from "./constants";

export const priorityOptions = [
  { value: t("options_name:everything"), name: t("options_name:everything") },
  {
    value: PRIORITY_ID[fields.highty],
    name: t(`${fields.highty}`),
  },
  {
    value: PRIORITY_ID[fields.middle],
    name: t(`${fields.middle}`),
  },
  { value: PRIORITY_ID[fields.lower], name: t(`${fields.lower}`) },
];

export const statusOptions = [
  { value: t("options_name:everything"), name: t("options_name:everything") },
  { value: STATUS_ID[fields.completely], name: t(`${fields.completely}`) },
  { value: STATUS_ID[fields.inprogress], name: t(`${fields.inprogress}`) },
  { value: STATUS_ID[fields.onreview], name: t(`${fields.onreview}`) },
  { value: STATUS_ID[fields.inwork], name: t(`${fields.inwork}`) },
];

export const listOfOrganisations = [
  { value: "", name: t("options_name:everything") },
  {
    value: fields.organization_customer,
    name: t(`options_name:${fields.organization_customer}`),
  },
  {
    value: fields.organization_contractor,
    name: t(`options_name:${fields.organization_contractor}`),
  },
  {
    value: fields.outside_organization,
    name: t(`options_name:${fields.outside_organization}`),
  },
];

export const deadlineStatusOptions = [
  { value: 0, name: t("options_name:everything") },
  {
    value: 4,
    name: t(`options_name:${globalFields.overdued_jobs_amount}`),
  }, 
  {
    value: 2,
    name: t(`options_name:${globalFields.near_deadline_jobs_amount}`),
  },
  {
    value: 3,
    name: t(`options_name:${globalFields.not_near_deadline_jobs_amount}`),
  },
  {
    value: 1,
    name: t(`options_name:${globalFields.completed_intime_jobs_amount}`),
  },
];

export const triggerCauseOptions = [
  { value: t(`tableHeader:${formFields.triggerCause}`), name: "" },
  {
    value: t(`options_name:${fields.alarm}`),
    name: t(`options_name:${fields.alarm}`),
  },
  {
    value: t(`options_name:${fields.malfunctions_IP}`),
    name: t(`options_name:${fields.malfunctions_IP}`),
  },
  {
    value: t(`options_name:${fields.maintenance}`),
    name: t(`options_name:${fields.maintenance}`),
  },
];