import React from "react";
import "./style.css";

const NewsWidget = () => {
  return (
    <div className="news-widget">
      {/* <div className="news-widget-dote-container">
        <div className="news-widget-dote active"></div>
        <div className="news-widget-dote"></div>
        <div className="news-widget-dote"></div>
        <div className="news-widget-dote"></div>
        <div className="news-widget-dote"></div>
        <div className="news-widget-dote"></div>
        <div className="news-widget-dote"></div>
      </div> */}
      <div className="news-widget-info">
        <p className="news-widget-title">Последние новости</p>
        <p className="news-widget-date">08.11.2024, Новый дизайн</p>
        <p className="news-widget-text">
          Мы полностью переработали пользовательский интерфейс системы так,
          чтобы он был более современным, нативным и удобным для пользователей.
        </p>
      </div>
    </div>
  );
};

export default NewsWidget;
