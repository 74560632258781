import React from "react";

const DeleteIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 1C7.90132 1 6.2 2.78044 6.2 4.97673V5.18603H3.6C3.26863 5.18603 3 5.46715 3 5.81393C3 6.16072 3.26863 6.44184 3.6 6.44184H16.4C16.7314 6.44184 17 6.16072 17 5.81393C17 5.46715 16.7314 5.18603 16.4 5.18603H13.8V4.97673C13.8 2.78044 12.0987 1 10 1ZM10 2.25581C11.4359 2.25581 12.6 3.47401 12.6 4.97673V5.18603H7.4V4.97673C7.4 3.47401 8.56406 2.25581 10 2.25581Z"
        fill="currentColor"
      />
      <path
        d="M4.99731 7.42895C4.96597 7.08373 4.67313 6.83046 4.34325 6.86326C4.01336 6.89606 3.77135 7.20251 3.80269 7.54774C3.87827 8.38016 4.01466 9.40581 4.18998 10.7242L4.41522 12.4179C4.63056 14.0377 4.75268 14.9563 5.01688 15.7089C5.50856 17.1094 6.38564 18.2321 7.51365 18.7302C8.12618 19.0007 8.82682 19.0004 9.86986 19H10.1301C11.1732 19.0004 11.8738 19.0007 12.4864 18.7302C13.6144 18.2321 14.4914 17.1094 14.9831 15.7089C15.2473 14.9563 15.3694 14.0377 15.5848 12.4179L15.81 10.7242C15.9853 9.40582 16.1217 8.38016 16.1973 7.54774C16.2287 7.20251 15.9866 6.89606 15.6568 6.86326C15.3269 6.83046 15.034 7.08373 15.0027 7.42895C14.9299 8.23097 14.7972 9.22976 14.6195 10.566L14.4096 12.1442C14.1772 13.8919 14.0708 14.6662 13.8567 15.2758C13.444 16.4513 12.7578 17.2473 12.0198 17.5732C11.6597 17.7322 11.2233 17.7441 10 17.7441C8.77667 17.7441 8.34035 17.7322 7.98019 17.5732C7.24224 17.2473 6.55597 16.4513 6.14326 15.2758C5.92925 14.6662 5.8228 13.8919 5.59038 12.1442L5.38051 10.566C5.20281 9.22976 5.07013 8.23097 4.99731 7.42895Z"
        fill="currentColor"
      />
      <path
        d="M9 8.32555C9 7.97877 8.73137 7.69765 8.4 7.69765C8.06863 7.69765 7.8 7.97877 7.8 8.32555V15.0232C7.8 15.37 8.06863 15.6511 8.4 15.6511C8.73137 15.6511 9 15.37 9 15.0232V8.32555Z"
        fill="currentColor"
      />
      <path
        d="M12.2 8.32555C12.2 7.97877 11.9314 7.69765 11.6 7.69765C11.2686 7.69765 11 7.97877 11 8.32555V15.0232C11 15.37 11.2686 15.6511 11.6 15.6511C11.9314 15.6511 12.2 15.37 12.2 15.0232V8.32555Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DeleteIcon;
