import React, { useMemo, useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useWatch } from "react-hook-form";
import {
  getDataToChart,
  getRoomAddressForScheme,
} from "../../../../helpers/helper";
import { formFields } from "../form-config";
import { useAppSelector } from "../../../../hooks/redux";
import { useAction } from "../../../../hooks/actions";

import SchemaWithEquipments from "../../../components/DrawCanvas/SchemaWithEquipments";
import TaskStateList from "../../../components/TaskStateList/TaskStateList";
import { Typography } from "@mui/material";

const FloorSchema = ({ control, data, setValue, floorScheme, roomAdd }) => {
  //console.log("floorSchemeFileLink",setValue)
  const { SCHEMA_CANVAS, openSidebar } = useAppSelector(
    (state) => state.settings
  );
  const { setSortParamsFacilityName } = useAction();

  const $container = useRef(null);
  const [widthContainer, setWidthContainer] = useState(0);

  useEffect(() => {
    if ($container) {
      const containerWidth = $container.current?.offsetWidth;
      const screenWidth = window.innerWidth;
      setWidthContainer(() =>
        screenWidth > 1150 ? containerWidth * 0.7 : containerWidth
      );

      window.addEventListener("resize", function () {
        const containerWidth = $container.current?.offsetWidth;
        const screenWidth = window.innerWidth;
        setWidthContainer(() =>
          screenWidth > 1150 ? containerWidth * 0.7 : containerWidth
        );
      });
    }
  }, [$container, openSidebar]);

  const {
    width: screenRelatedWidth,
    height: screenRelatedHeight,
    scale,
  } = useMemo(() => {
    const width = widthContainer;
    const height =
      widthContainer / (SCHEMA_CANVAS.WIDTH / SCHEMA_CANVAS.HEIGHT);
    return SCHEMA_CANVAS.WIDTH && widthContainer
      ? {
          width,
          height,
          scale: {
            x: width / SCHEMA_CANVAS.WIDTH,
            y: height / SCHEMA_CANVAS.HEIGHT,
          },
        }
      : {};
  }, [SCHEMA_CANVAS, widthContainer]);

  const screenRelatedRoomAddresses = useMemo(
    () => getRoomAddressForScheme(roomAdd, scale),
    [roomAdd, scale]
  );

  const dataToolTip = useMemo(
    () =>
      data
        ? getDataToChart({
            jobChart: data?.jobChart,
            action: `?equipmentId=${data?.id}&equipmentName=${data?.name}`,
          })
        : [],
    [data, setSortParamsFacilityName]
  );

  return (
    <Box
      ref={$container}
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {floorScheme ? (
        <SchemaWithEquipments
          name={formFields.equipmentsCoordinates}
          width={screenRelatedWidth || 0}
          height={screenRelatedHeight || 0}
          schemaImg={floorScheme}
          roomAddresses={screenRelatedRoomAddresses}
          control={control}
          draggable={true}
          setValue={setValue}
        />
      ) : (
        <Typography sx={{ color: "var(--headers)" }}>
          Схема отсутствует
        </Typography>
      )}
    </Box>
  );
};

export default FloorSchema;
