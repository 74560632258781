import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { t } from "i18next";

import { formFields } from "../form-config";
import {
  potentialRadiationHazardOptions,
  fireResistanceDegreeOptions,
  structuralFireHazardClassOptions,
  fireAndExplosionHazard,
  /* ventilationTypeOptions, */
  voltageOptions,
  typeWiringOptions,
} from "../options";

import {
  BuildingPassportFrom,
  BuildingCharacteristicsFrom,
  FacilitySystemsForm,
} from "./Components";
import InputText from "../../../components/InputText";
import SelectSimple from "../../../components/SelectSimple";
import DatePickerField from "../../../components/DatePickerField";
import CheckboxSimple from "../../../components/CheckboxSimple";
import { InputAdornment } from "@mui/material";

export const formFieldsBasicInf = {
  buildingPassport: "buildingPassport",
  buildingCharacteristics: "buildingCharacteristics",
  facilitySystems: "facilitySystems",
};

export const buildingPassportFormConfig = [
  {
    id: 0,
    name: formFields.cadastralNumber,
    label: "label:cadastralNumber",
    type: "text",
    placeholder: "placeholder:cadastr_number",
    rules: {},
    flexBasis: "49%",
    input: (props) => <InputText {...props} />,
  },
  {
    id: 2,
    name: formFields.objectKind,
    label: "label:objectKind",
    placeholder: "placeholder:vid_object",
    type: "text",
    rules: {},
    flexBasis: "49%",
    input: (props) => <InputText {...props} />,
  },
  {
    id: 5,
    name: formFields.objectType,
    label: "label:objectType",
    placeholder: "placeholder:type_object",
    type: "text",
    rules: {},
    flexBasis: "49%",
    sx: { paddingTop: "18px" },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 5,
    name: formFields.nearestFireStation,
    label: "label:nearestFireStation",
    placeholder: "placeholder:type_object",
    type: "text",
    rules: {},
    flexBasis: "49%",
    sx: { paddingTop: "18px" },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 15,
    name: formFields.riskCategoryAssignmentDate,
    label: "label:riskCategoryAssignmentDate",
    type: "date",
    rules: {},
    flexBasis: "49%",
    sx: { width: "50%" },
    input: (props) => <DatePickerField {...props} />,
  },
  {
    id: 3,
    name: formFields.registrationDate,
    label: "label:registrationDate",
    type: "date",
    rules: {},
    flexBasis: "49%",
    sx: { width: "50%" },
    input: (props) => <DatePickerField {...props} />,
  },
  {
    id: 4,
    name: formFields.lastCheckDate,
    label: "label:lastCheckDate",
    type: "date",
    rules: {},
    flexBasis: "49%",
    sx: { width: "50%" },
    input: (props) => <DatePickerField {...props} />,
  },
  {
    id: 6,
    name: formFields.roadsForSpecialEquipmentAvailability,
    label: "label:roadsForSpecialEquipmentAvailability",
    type: "checkbox",
    rules: {},
    flexBasis: "49%",
    sx: { ml: "10px" },
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    id: 7,
    name: formFields.bordersWithForestAreasAvailability,
    label: "label:bordersWithForestAreasAvailability",
    type: "checkbox",
    rules: {},
    flexBasis: "49%",
    sx: { ml: "10px" },
    input: (props) => <CheckboxSimple {...props} />,
  },
];

export const facilitySystemsFormConfig = [
  {
    name: formFields.fuelForStandaloneHeatingType,
    sectionName: formFields.buildingCharacteristics,
    label: "label:fuelForStandaloneHeatingType",
    placeholder: "placeholder:gas_type",
    type: "text",
    input: (props) => (
      <InputText {...props} />
    ),
  },
  {
    id: 1,
    name: formFields.electricityAvailable,
    label: "label:electricityAvailable",
    type: "checkbox",
    rules: {},
    block: true,
    sx: {ml: '10px'},
    expended: [
      {
        name: formFields.voltage,
        label: "label:voltage",
        type: "select-number",
        displayEmpty:true,
        placeholder: "placeholder:Volt",
        rules: {},
        options: voltageOptions,
        input: (props) => (
          <Box sx={{ width: "49%", m: '24px 0' }}>
            <SelectSimple {...props} />
          </Box>
        ),
      },
      {
        name: formFields.electricityInstallationDate,
        label: "label:electricityInstallationDate",
        type: "date",
        rules: {},
        flexBasis: "49%",
        sx: { width: "100%", },
        input: (props) => <DatePickerField {...props} />,
      },
      {
        name: formFields.electricityOverhaulDate,
        label: "label:electricityOverhaulDate",
        type: "date",
        rules: {},
        flexBasis: "49%",
        sx: { width: "100%" },
        input: (props) => <DatePickerField {...props} />,
      },
      {
        name: formFields.wiringType,
        label: "label:wiringType",
        type: "select-text",
        rules: {},
        displayEmpty:true,
        placeholder: "placeholder:electro_type",
        options: typeWiringOptions,
        input: (props) => (
          <Box sx={{ width: "49%", mt: '24px' }}>
            <SelectSimple {...props} />
          </Box>
        ),
      },
    ],
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    id: 2,
    name: formFields.gasAvailable,
    label: "label:gasAvailable",
    type: "checkbox",
    rules: {},
    block: true,
    sx: {ml: '10px'},
    expended: [
      {
        name: formFields.gasInstallationDate,
        label: "label:gasInstallationDate",
        type: "date",
        rules: {},
        flexBasis: "49%",
        sx: { width: "100%", mt: '24px' },
        input: (props) => <DatePickerField {...props} />,
      },
      {
        name: formFields.gasOverhaulDate,
        label: "label:gasOverhaulDate",
        type: "date",
        rules: {},
        flexBasis: "49%",
        sx: { width: "100%", mt: '24px' },
        input: (props) => <DatePickerField {...props} />,
      },
    ],
    input: (props) => <CheckboxSimple {...props} />,
  },
];

export const buildingCharacteristicsFormConfigCheckbox = [
  {
    name: formFields.elevatorToTransportFirefightersAvailability,
    label: "label:elevatorToTransportFirefightersAvailability",
    type: "checkbox",
    rules: {},
    flexBasis: "49%",
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    name: formFields.basementFloorAvailability,
    label: "label:basementFloorAvailability",
    type: "checkbox",
    rules: {},
    flexBasis: "49%",
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    name: formFields.internalFireWaterSupplyAvailability,
    label: "label:internalFireWaterSupplyAvailability",
    type: "checkbox",
    rules: {},
    flexBasis: "49%",
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    name: formFields.groundFloorAvailability,
    label: "label:groundFloorAvailability",
    type: "checkbox",
    rules: {},
    flexBasis: "49%",
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    name: formFields.fireWaterSupplyMaintenanceContractAvailability,
    label: "label:fireWaterSupplyMaintenanceContractAvailability",
    type: "checkbox",
    rules: {},
    flexBasis: "100%",
    input: (props) => <CheckboxSimple {...props} />,
  },
  /*  {
    name: formFields.ventilationAvailability,
    label: "label:ventilationAvailability",
    type: "checkbox",
    rules: {},
    flexBasis: "49%",
    expended: [
      {
        name: formFields.ventilationType,
        label: "label:ventilationType",
        type: "select-text",
        rules: {},
        flexBasis: "100%",
        options: ventilationTypeOptions,
        input: (props) => <SelectSimple {...props} />,
      },
    ],
    input: (props) => <CheckboxSimple {...props} />,
  }, */

  {
    name: formFields.externalFireWaterSupplyAvailability,
    label: "label:externalFireWaterSupplyAvailability",
    type: "checkbox",
    rules: {},
    flexBasis: "49%",
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    name: formFields.specializedOrganizationProtectionInvolvement,
    label: "label:specializedOrganizationProtectionInvolvement",
    type: "checkbox",
    rules: {},
    flexBasis: "49%",
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    name: formFields.fullTimeStayAvailability,
    sectionName: formFields.facilitySystems,
    label: "label:fullTimeStayAvailability",
    type: "checkbox",
    flexBasis: "49%",
    input: (props) => (
      <Box>
        <CheckboxSimple {...props} />
      </Box>
    ),
  },
  {
    name: formFields.newYearMassAvailabilityOfChildren,
    sectionName: formFields.facilitySystems,
    label: "label:newYearMassAvailabilityOfChildren",
    type: "checkbox",
    flexBasis: "49%",
    input: (props) => (
      <Box>
        <CheckboxSimple {...props} />
      </Box>
    ),
  },
  {
    name: formFields.storesAndSellsPyrotechnicProducts,
    sectionName: formFields.facilitySystems,
    label: "label:storesAndSellsPyrotechnicProducts",
    type: "checkbox",
    flexBasis: "49%",
    input: (props) => (
      <Box>
        <CheckboxSimple {...props} />
      </Box>
    ),
  },
  {
    name: formFields.involvedInElections,
    sectionName: formFields.facilitySystems,
    label: "label:involvedInElections",
    type: "checkbox",
    flexBasis: "49%",
    input: (props) => (
      <Box>
        <CheckboxSimple {...props} />
      </Box>
    ),
  },
  {
    name: formFields.particularlyHazardousAndTechnicallyComplex,
    sectionName: formFields.facilitySystems,
    label: "label:particularlyHazardousAndTechnicallyComplex",
    type: "checkbox",
    flexBasis: "49%",
    input: (props) => (
      <Box>
        <CheckboxSimple {...props} />
      </Box>
    ),
  },
  {
    name: formFields.criticalToCountriesNationalSecurity,
    sectionName: formFields.facilitySystems,
    label: "label:criticalToCountriesNationalSecurity",
    type: "checkbox",
    flexBasis: "49%",
    input: (props) => (
      <Box>
        <CheckboxSimple {...props} />
      </Box>
    ),
  },
  {
    name: formFields.hazardousProductionFacilitiesAvailability,
    sectionName: formFields.facilitySystems,
    label: "label:hazardousProductionFacilitiesAvailability",
    type: "checkbox",
    flexBasis: "49%",
    input: (props) => (
      <Box>
        <CheckboxSimple {...props} />
      </Box>
    ),
  },
  {
    name: formFields.bookRepositoryOrArchiveOfFederalImportance,
    sectionName: formFields.facilitySystems,
    label: "label:bookRepositoryOrArchiveOfFederalImportance",
    type: "checkbox",
    flexBasis: "49%",
    input: (props) => (
      <Box>
        <CheckboxSimple {...props} />
      </Box>
    ),
  },
];

export const buildingCharacteristicsFormConfig = [
  {
    name: formFields.potentialRadiationHazardCategory,
    label: "label:potentialRadiationHazardCategory",
    type: "select-number",
    placeholder: "placeholder:category",
    displayEmpty:true,
    rules: {},
    flexBasis: "49%",
    options: potentialRadiationHazardOptions,
    input: (props) => <SelectSimple {...props} />,
  },
  {
    name: formFields.fireResistanceDegree,
    label: "label:fireResistanceDegree",
    placeholder: "placeholder:step",
    displayEmpty:true,
    type: "select-text",
    rules: {},
    flexBasis: "49%",
    options: fireResistanceDegreeOptions,
    input: (props) => <SelectSimple {...props} />,
  },
  {
    name: formFields.structuralFireHazardClass,
    label: "label:structuralFireHazardClass",
    type: "select-text",
    placeholder: "placeholder:class_select",
    displayEmpty:true,
    rules: {},
    flexBasis: "49%",
    options: structuralFireHazardClassOptions,
    input: (props) => <SelectSimple {...props} />,
  },
  {
    name: formFields.fireAndExplosionHazardOutdoorInstCategory,
    label: "label:fireAndExplosionHazardOutdoorInstCategory",
    type: "select-text",
    placeholder: "placeholder:category",
    displayEmpty:true,
    rules: {},
    flexBasis: "49%",
    options: fireAndExplosionHazard,
    input: (props) => <SelectSimple {...props} />,
  },
  {
    name: formFields.evacuationExitsCount,
    label: "label:evacuationExitsCount",
    type: "number",
    placeholder: "placeholder:count_select",
   
    rules: {
      min: 1,
      pattern: /^([0-9]+)$/,
      integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
    },
    flexBasis: "49%",
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.simultaneousPeopleCount,
    label: "label:simultaneousPeopleCount",
    type: "number",
    rules: {
      min: 1,
      pattern: /^([0-9]+)$/,
      integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
    },
    flexBasis: "49%",
    placeholder: "placeholder:count_select",
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.height,
    label: "label:height",
    type: "number",
    placeholder: "placeholder:height",
    rules: {
      fractional: (v) => !!Number(v) && !/\s/g.test(v),
    },
    step: "any",
    flexBasis: "49%",
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <Typography className="adornment">м</Typography>
        </InputAdornment>
      ),
    },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.width,
    label: "label:width",
    type: "number",
    placeholder: "placeholder:width",
    rules: {
      fractional: (v) => !!Number(v) && !/\s/g.test(v),
    },
    step: "any",
    flexBasis: "49%",
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <Typography className="adornment">м</Typography>
        </InputAdornment>
      ),
    },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.length,
    label: "label:length",
    placeholder: "placeholder:length",
    type: "number",
    rules: {
      fractional: (v) => !!Number(v) && !/\s/g.test(v),
    },
    step: "any",
    flexBasis: "49%",
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <Typography className="adornment">м</Typography>
        </InputAdornment>
      ),
    },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.square,
    label: "label:square",
    placeholder: "placeholder:square",
    type: "number",
    rules: {
      fractional: (v) => !!Number(v) && !/\s/g.test(v),
    },
    step: "any",
    flexBasis: "49%",
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <Typography className="adornment">
            м<sup>2</sup>
          </Typography>
        </InputAdornment>
      ),
    },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.stairwellsCount,
    label: "label:stairwellsCount",
    placeholder: "placeholder:count_select",
    type: "number",
    rules: {
      min: 1,
      pattern: /^([0-9]+)$/,
      integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
    },
    flexBasis: "49%",
    input: (props) => <InputText {...props} />,
  },
];

export const sections = [
  {
    id: 0,
    title: formFieldsBasicInf.buildingPassport,
    Component: BuildingPassportFrom,
    config: buildingPassportFormConfig,
  },
  {
    id: 1,
    title: formFieldsBasicInf.buildingCharacteristics,
    Component: BuildingCharacteristicsFrom,
    config: buildingCharacteristicsFormConfig,
  },
  {
    id: 2,
    title: formFieldsBasicInf.facilitySystems,
    Component: FacilitySystemsForm,
    config: facilitySystemsFormConfig,
  },
];
