import BasicTable from "../../Table";
import { formConfig } from "./FormConfigs";
import {formFields} from "./FormConfigs/formFields";


const AfaActWorkingCorrectlyCheckForm = (props) => {
 
  return (
    <BasicTable {...props} formFields={formFields} control={props.control}   formConfig={formConfig}/>
  )
}

export default AfaActWorkingCorrectlyCheckForm;