import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useAppSelector } from "../../../hooks/redux";
import {  useGetFacilitySelectElementsWithSearchQuery } from "../../../middleware/facilitiesApi";
import SelectGrouping from "../../components/SelectGrouping";
import { Tooltip } from "@mui/material";
import SelectMultipleChipDeletable from "../../components/SelectMultipleChipDeletable";
import {SelectMultipleChipDeletableWithSearchFieldInbuilt} from "../../components/SelectMultipleChipDeletableWithSearchFieldInbuilt";
import { PATHS } from "../../../constants";

export const FacilityAndFireCompartmentSelectGroupingMultipleCheckmarks = ({
  selectValue,
  setSelectValue,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { data, refetch } = useGetFacilitySelectElementsWithSearchQuery({ name: searchValue });

  useEffect(() => {
    searchValue && refetch();
  }, [searchValue, refetch])

  const options = useMemo(() => {
    return data
      ? [{ id: "все", name: "все" }, ...data]//getFacilitiesOptionsGroup(data)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <Box className="medium_container" sx={{ width: "400px !important" }}>
      <SelectMultipleChipDeletableWithSearchFieldInbuilt
        label={"label:objects"}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        value={selectValue}
        onChange={setSelectValue}
        options={options}
      />
    </Box>
  );
};

export const SelectSimpleObject = (props) => {
  console.log("props", props)
  const { searchName, changesearchName } = props
  const { facilitySelectElements } = useAppSelector(
    (state) => state.facilities
  );

  const options = useMemo(() => {
    return facilitySelectElements
      ? [{ id: "все", name: "все" }, ...facilitySelectElements]//getFacilitiesOptionsGroup(data)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilitySelectElements]);

  return (
    <SelectGrouping
      {...props}
      setSearchValue={changesearchName}
      searchValue={searchName}
      options={options}
    // defaultValue={"все"}
    //bgcolor={bgcolor}
    />
  );
  //return <SelectSimple options={facilitySelectElements || []} {...props} />;
};
export const SimpleTextWithTooltipCell = (props) => {
  const content = props;

  return <Tooltip placement="left" componentsProps={{
    tooltip: {
      sx: {
        maxHeight: '300px',
        overflowY: 'auto'
      },
    },
  }} title={content?.row?.jobDescription} ><Typography sx={{

    textOverflow: "ellipsis",

    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
  }} className="standart_lg_text">{content?.row?.workScopeName}</Typography></Tooltip>;
};
export const SimpleTextWithTooltipCellDetailes = (props) => {
  const content = props;

  return <Tooltip placement="left" componentsProps={{
    tooltip: {
      sx: {
        maxHeight: '300px',
        overflowY: 'auto'
      },
    },
  }} title={content?.row?.workScopeDetails} ><Typography sx={{

    overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical'
  }} className="standart_lg_text">{content?.row?.workScopeDetails}</Typography></Tooltip>;
};
export const DateFormatCell = ({ item }) => {
  const dateFormat = "DD.MM.YYYY HH:mm";
  const content = dayjs(item).format(dateFormat).split(" ");

  return content.map((e, i) => (
    <Typography key={i} className="table_row_text">
      {e}
    </Typography>
  ));
};
export const ClickableEquipmentTextCell = ({ item, row }) => {
  const navigate = useNavigate();
  const { equipmentId } = row || {};

  return (
    <Typography
      className="table-row-cell-link"
      onClick={() =>
        equipmentId && navigate(PATHS.GET_EQUIPMENT_DETAILS(equipmentId))
      }
    >
      {item}
    </Typography>
  );
};
export const SimpleHHeader = ({ name }) => (
  <Box sx={{ paddingTop: "2px" }} className="table-head-up_level">
    <Typography className="standart">{name || ""}</Typography>
  </Box>
);
export const SignatureCell = (props) => {
  const { value } = props
  return <div dangerouslySetInnerHTML={{ __html: value }} />
}

export const SimpleTextCell = ({ item }) => {
  return <Typography>{item}</Typography>;
};
