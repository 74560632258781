import InputText from "../../../../../components/InputText";
import React from "react";
import { t } from "i18next";
import SelectSimple from "../../../../../components/SelectSimple";

export const measuringEquipmentFormFields = {
  name: "name",
  manufacturer: "manufacturer",
  serialNumber: "serialNumber",
};

export const equipments = {};

const commonFields = [
  {
    name: measuringEquipmentFormFields.manufacturer,
    label: "label:manufacturer",
    type: "text",
    rules: {},
    input: (props) => <InputText {...props} />,
  },
  
];

const specialFields = {
  smokeDetectors: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "text",
      disabled: true,
      value: t("label:opticalSmokeDetectorsTester"),
      rules: {},
      input: (props) => <InputText {...props} />,
    },
  ],
  linearDetectors: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "text",
      disabled: true,
      value: t("label:opticalSmokeDetectorsTester"),
      rules: {},
      input: (props) => <InputText {...props} />,
    },
  ],
  communicationLines: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
  ],
  effectOfFault: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
  ],
  RABCapacitySNME: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
  ],
  voltageCurrentPowerSupplySNME: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
  ],
  verificationODuplication: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
  ],
  flameDetectors: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
  ],
  manualDetectors: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
  ],
  RABCapacity: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "select-text",
      rules: {},
      options: [
        t("options_name:RABCapacityTester"),
        t("options_name:multimeter"),
        t("options_name:voltmeter"),
      ],
      input: (props) => <SelectSimple {...props} />,
    },
  ],
  voltageCurrentPowerSupply: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "select-text",
      rules: {},
      options: [
        t("options_name:multimeter"),
        t("options_name:voltmeter"),
        t("options_name:ammeter"),
      ],
      input: (props) => <SelectSimple {...props} />,
    },
  ],
};

export const getMeasuringEquipmentFormConfig = (pointName) => {
  let fields = commonFields;
  if (specialFields[pointName]) {
    fields = [...fields, ...specialFields[pointName]];
  }
  return fields;
};
