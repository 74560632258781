import { createSlice } from "@reduxjs/toolkit";
import { controllersApi } from "../../middleware/controllersApi";

const initialState = {
    allControllers: [],
    allConsoleControllers: [],
};

export const controllersSlice = createSlice({
    name: "controllers",
    initialState,
    reducers: {
        setAllControllers(state, action) {
            state.allControllers = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
          controllersApi.endpoints.getControllersTable.matchFulfilled,
          (state, { payload }) => {
              state.allControllers = payload;
          }
        );
        builder.addMatcher(
            controllersApi.endpoints.getControllersConsoleTable.matchFulfilled,
            (state, { payload }) => {
                state.allConsoleControllers = payload;
            }
          );
    },
});

export const controllersActions = controllersSlice.actions;
export const controllersReducer = controllersSlice.reducer;
