import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";

import { PATHS } from "../../../constants";
import SetNewPasswordDialog from "./SetNewPasswordDialog";
import EditBtnIcon from "../../components/icons/EditBtnIcon";

const ButtonsField = ({
  isEmptyPage = false,
  isEditedProfileData,
  setIsEditedProfileData,
  token,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [passwordDialog, setPasswordDialog] = useState(false);
  const handlePasswordDialog = () => setPasswordDialog(!passwordDialog);

  return (
    <>
      {passwordDialog && (
        <SetNewPasswordDialog
          token={token}
          open={passwordDialog}
          onClose={handlePasswordDialog}
        />
      )}
      <Box className="profile_container-header-buttons">
        {!isEmptyPage ? (
          <>
            {!isEditedProfileData ? (
              <Button
                className="btn btn-primary-fill edit-btn"
                onClick={() => {
                  setIsEditedProfileData(true);
                }}
                sx={{}}
              >
                <EditBtnIcon /> {t("button:edit")}
              </Button>
            ) : (
              <>
                <Button
                  className="btn btn-outlined-grey"
                  onClick={() => setIsEditedProfileData(false)}
                >
                  {t("button:comeback")}
                </Button>
                <Button
                  className="btn btn-outlined-primary"
                  variant="outlined"
                  onClick={handlePasswordDialog}
                >
                  {t("button:change_password")}
                </Button>
                <Button
                  form="updateProfileForm"
                  className="btn btn-primary-fill"
                  type="submit"
                >
                  {t("button:save")}
                </Button>
              </>
            )}
          </>
        ) : (
          <Button
            className="btn btn-outlined-primary"
            variant="outlined"
            onClick={() => navigate(PATHS.MY_ORGANIZATIONS)}
          >
            {t("button:add_account_entity")}
          </Button>
        )}
      </Box>
    </>
  );
};

export default ButtonsField;
