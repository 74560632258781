import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const fireCompartmentApi = createApi({
  reducerPath: "fireCompartment/api",
  tagTypes: ['FireCompartments'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getFireCompartment: build.query({
      query: (id) => ({
        url: `api/fire-compartments/get/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'FireCompartments', id: arg }],
      transformResponse: (baseQueryReturnValue) => {
        baseQueryReturnValue.floors = baseQueryReturnValue.floors.sort(
          (a, b) => a.floorNumber - b.floorNumber
        );
        return baseQueryReturnValue;
      },
    }),
    addFireCompartment: build.mutation({
      query: (data) => ({
        url: "api/fire-compartments/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ['FireCompartments']
    }),
    addShortFireCompartment: build.mutation({
      query: (data) => ({
        url: "api/fire-compartments/add-after-protection-object",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ['FireCompartments']
    }),
    editFireCompartment: build.mutation({
      query: (data) => ({
        url: "api/fire-compartments/edit",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'FireCompartments', id: arg.id }],
    }),
    deleteFireCompartment: build.mutation({
      query: (id) => ({
        url: `api/fire-compartments/delete/${id}`,
        method: "DELETE",
      }),
    }),
    getFloorInfoSelect: build.query({
      query: (params) => ({
        url: `api/fire-compartments/get-floor-info-select?compartmentId=${params}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, floorNumber, floorScheme }) => ({
          value: id,
          name: floorNumber,
        })),
    }),
    getFireCompartments: build.query({
      query: ({ pageNumber, searchValue, facilityFilter }) => ({
        url: `api/fire-compartments/get-fire-compartments?pageNumber=${pageNumber}&searchValue=${searchValue}&facilityFilter=${facilityFilter}`,
      }),
      providesTags: ['FireCompartments']
    }),
    getFloorInfoPopUp: build.query({
      query: (params) => ({
        url: `api/fire-compartments/get-floor-info-popup?floorId=${params}`,
      }),
    }),
  }),
});

export const {
  useGetFireCompartmentQuery,
  useAddFireCompartmentMutation,
  useAddShortFireCompartmentMutation,
  useEditFireCompartmentMutation,
  useDeleteFireCompartmentMutation,
  useLazyGetFloorInfoSelectQuery,
  useLazyGetFireCompartmentsQuery,
  useGetFloorInfoPopUpQuery,
} = fireCompartmentApi;
