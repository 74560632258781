import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import FormItem from "../../../components/FormItem";
import "./styles/style.css";

export default function BasicTable({ formFields, formConfig, minHeight = "55vh",
  maxHeight = "60vh", ...rest }) {

  const { t } = useTranslation();

  return (
    <TableContainer
      sx={{ mb: "20px", borderRadius: "2px", maxHeight: () => (window.innerHeight > 715 ? maxHeight : minHeight), overflow: 'auto' }}
    //component={Paper}
    >
      <Table
        sx={{ minWidth: 750 }}
        className="table"
        aria-labelledby="tableTitle"
        size={"medium"}
        stickyHeader
      >
        <TableHead className="table-head">
          <TableRow>
            {Object.values(formFields).map(fieldName => {
              let width = "auto"
              switch (fieldName) {
                case "number":
                  width = "50px"
                  break;
                case "equipments":
                  width = "230px"
                  break;
                case "corresponds":
                  width = "180px"
                  break;
                  
                case "measuringEquipment":
                  width = "500px"
                  break;
                default:
                  width = "auto"
                  break;
              }
              return <TableCell
                sx={{ width: width, minWidth: fieldName == "testName" ? "310px" :  fieldName == "measuringEquipment" ? "300px" : "auto", backgroundColor: "var(--bgTableHeader)" }}
                key={fieldName}
                className={`table-head-cell`}
              >
                {t(`tableHeader:actWorkingCorrectlyCheck:${fieldName}`)}
              </TableCell>
            }

            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {formConfig.map(({ name, items }) => (
            <TableRow
              key={name}
              sx={{
                '&:nth-of-type(odd)': {
                  backgroundColor: "var(--bgTableRow)",
                },
              }}
            >
              {items.map(({ CustomFormItem, ...item }) => {

                const fieldName = [rest.name, name, item.name]
                  .filter(name => name)
                  .join(".");
               
                const FieldComponent = CustomFormItem || FormItem;
                return (
                  <TableCell
                    key={fieldName}
                    component="th"
                    scope="row"

                    colSpan={item.name == "equipments" ? 3 : 1}
                    sx={{ verticalAlign: "top" }}
                  >
                    <FieldComponent {...rest}  {...item} name={fieldName} />
                  </TableCell>
                );
              })}
            </TableRow>
          ))}

        </TableBody>
      </Table>
    </TableContainer >
  );
}