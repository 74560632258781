import { createSlice } from "@reduxjs/toolkit";
import { magazineApi } from "../../middleware/magazineApi";

const initialState = {
  allMagazineEvents: {},
};

export const magazineSlice = createSlice({
  name: "magazine",
  initialState,
  reducers: {
    changeTasksValue(state, action) {
      state.tasksValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      magazineApi.endpoints.getAllMagazineEvents.matchFulfilled,
      (state, { payload }) => {
        state.allMagazineEvents = payload;
      }
    );
  },
});

export const magazineActions = magazineSlice.actions;
export const magazineReducer = magazineSlice.reducer;