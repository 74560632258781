import React from "react";
import { TextField,Box,InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";

const InputMaskPhone = ({ label, error, onChange, value,placeholder, disabled = false, InputProps = {} }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ position: "relative" }} className="input_text">
      <InputLabel sx={{ mb: "8px" }}>
        {t(label)}
      </InputLabel>
      <InputMask
        mask="+7 (999) 999 99 99"
        onChange={onChange}
        value={value}
        InputProps={InputProps}
        disabled={disabled}
      >
        {() => (

          <TextField
            type="tel"
            onChange={onChange}
            variant="outlined"
            error={!!error}
            placeholder={t(placeholder) || ""}
            helperText={
              error?.message ? t(`error_message:${error?.message}`) : ""
            }
            fullWidth
            value={value}
            disabled={disabled}
            InputProps={InputProps}
          />
        )}
      </InputMask>
    </Box>
  );
};

export default InputMaskPhone;
