import React, { useEffect, useMemo, useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ExecutiveUserNameSelectSimple } from "./Components";
import FormItem from "../../components/FormItem";
import Switcher from "../../components/Switcher";
import InputText from "../../components/InputText";

const ObjectSettingsListItem = ({ control, item, objectId, setobjectSystemsSettings, objectSystemsSettings, getValues }) => {
    const { t } = useTranslation();
    const turnOnOffStatuses = [
        { status: true, name: t("tableHeader:turnedOn") },
        { status: false, name: t("tableHeader:turnedOff") },
    ];
    let isOpen = false
    if (localStorage.getItem('systemSettingsAccord')) {
        let filterStorageSettings = JSON.parse(localStorage.getItem('systemSettingsAccord')).filter(obj => obj.id === item.id)
        console.log("mergedMap", JSON.parse(localStorage.getItem('systemSettingsAccord')))
        isOpen = filterStorageSettings.isOpen
    }
    
    const [openCollapse, setopenCollapse] = useState(isOpen);
    const handleClick = (id) => {
        const updatedIsOpenSystemTab = objectSystemsSettings.map(item => {
            if (item.id === id) {
                return { ...item, isOpen: !item.isOpen };
            }
            return item;
        });
        localStorage.setItem('systemSettingsAccord', JSON.stringify(updatedIsOpenSystemTab));
        setopenCollapse(!openCollapse);
    };
    const handleChange = (value, id, name) => {

        const updatedSystemSettings = objectSystemsSettings.map(system => system.id === id ? { ...system, [name]: value } : system)
        setobjectSystemsSettings(updatedSystemSettings)
    };

    return (

        <>
            <ListItemButton onClick={() => handleClick(item.id)}>
                <ListItemText sx={{ fontFamily: "var(--INTER)" }} primary={item.name} />
                {openCollapse ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                <Card sx={{ width: 550, marginTop: "10px", marginBottom: "10px", marginLeft: "15px" }}>
                    <CardContent>
                        <Box sx={{ display: 'inline' }}>
                            <Typography sx={{ fontSize: 14, fontFamily: "var(--INTER)", fontWeight: "bold", marginTop: "15px" }} color="text.secondary" gutterBottom>
                                {t("tableHeader:responsibleCustomer")}
                            </Typography>
                            <FormItem
                                control={control}
                                name={`${item.id}.defaultResponsibleCustomerUserId`}
                                objectId={objectId}
                                defaultValue={item.defaultResponsibleCustomerUserId}
                                sx={{ padding: "12px 0" }}
                                placeholder={"placeholder:responsible_user"}
                                displayEmpty={true}
                                value={item.defaultResponsibleCustomerUserId}
                                input={(props) => (
                                    <ExecutiveUserNameSelectSimple
                                        {...props}

                                        onChange={(e) => handleChange(e.target.value, item.id, "defaultResponsibleCustomerUserId")}
                                    />
                                )}
                            />
                        </Box>
                        <Box sx={{ display: 'inline' }}>
                            <Typography sx={{ fontSize: 14, fontFamily: "var(--INTER)", fontWeight: "bold", marginTop: "15px" }} color="text.secondary" gutterBottom>
                                {t("tableHeader:resUser")}
                            </Typography>
                            <FormItem
                                control={control}
                                name={`${item.id}.defaultAssignUserId`}
                                objectId={objectId}
                                displayEmpty={true}
                                placeholder={"placeholder:executive_user"}
                                defaultValue={item.defaultAssignUserId}
                                sx={{ padding: "12px 0" }}
                                value={item.defaultAssignUserId}
                                input={(props) => (
                                    <ExecutiveUserNameSelectSimple
                                        {...props}

                                        onChange={(e) => handleChange(e.target.value, item.id, "defaultAssignUserId")}
                                    />
                                )}
                            />
                        </Box>

                        <Box sx={{ display: 'inline', flexBasis: '100%' }}>
                            <Switcher
                                onChange={() => handleChange(!item?.isOperationStarted, item.id, "isOperationStarted")}
                                options={turnOnOffStatuses}
                                label={t('tableHeader:usageStarting')}
                                value={item?.isOperationStarted}
                            />
                        </Box>

                        <Box sx={{ display: 'inline', flexBasis: '100%' }}>
                            <Switcher
                                onChange={() => handleChange(!item?.generateJobsWeeklyRegulation, item.id, "generateJobsWeeklyRegulation")}
                                options={turnOnOffStatuses}
                                label={t('tableHeader:generateJobsWeeklyRegulation')}
                                value={item?.generateJobsWeeklyRegulation}
                            />
                        </Box>

                        <Box sx={{ display: 'inline', flexBasis: '100%' }}>
                            <Switcher
                                onChange={() => handleChange(!item?.generateJobsDailyRegulation, item.id, "generateJobsDailyRegulation")}
                                options={turnOnOffStatuses}
                                label={t('tableHeader:generateJobsDailyRegulation')}
                                value={item?.generateJobsDailyRegulation}
                            />
                        </Box>

                        <Box sx={{ display: 'inline' }}>
                            <Typography sx={{ fontSize: 14, fontFamily: "var(--INTER)", fontWeight: "bold", marginTop: "15px" }} color="text.secondary" gutterBottom>
                                {t("tableHeader:deadlineDays")}
                            </Typography>

                            <InputText
                                control={control}
                                name={`${item.id}.deadlineDays`}
                                objectId={objectId}
                                defaultValue={item.deadlineDays}
                                sx={{ padding: "12px 0" }}
                                maxLength={(v) => v?.length <= 3}
                                value={item.deadlineDays}
                                InputProps={{
                                    type: "number",
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "." || e.key === ",") {
                                        e.preventDefault()
                                    }
                                }}
                                onChange={(e) => handleChange(e.target.value, item.id, "deadlineDays")}
                            />

                        </Box>

                        <Box sx={{ display: 'inline' }}>
                            <Typography sx={{ fontSize: 14, fontFamily: "var(--INTER)", fontWeight: "bold", marginTop: "15px" }} color="text.secondary" gutterBottom>
                                {t("tableHeader:jobGenerationBeforeDays")}
                            </Typography>

                            <InputText
                                control={control}
                                name={`${item.id}.jobGenerationBeforeDays`}
                                objectId={objectId}
                                defaultValue={item.jobGenerationBeforeDays}
                                sx={{ padding: "12px 0" }}
                                value={item.jobGenerationBeforeDays}
                                InputProps={{ type: "number" }}
                                onKeyDown={(e) => {
                                    if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "." || e.key === ",") {
                                        e.preventDefault()
                                    }
                                }}
                                onChange={(e) => handleChange(e.target.value, item.id, "jobGenerationBeforeDays")}
                            />
                        </Box>
                        <Box sx={{ display: 'inline', flexBasis: '100%' }}>
                            <Switcher
                                onChange={() => handleChange(!item?.requireDefectActSignedByExecutiveUser, item.id, "requireDefectActSignedByExecutiveUser")}
                                options={turnOnOffStatuses}
                                label={t('tableHeader:requireDefectActSignedByExecutiveUser')}
                                value={item?.requireDefectActSignedByExecutiveUser}
                            />
                        </Box>
                        <Box sx={{ display: 'inline', flexBasis: '100%' }}>
                            <Switcher
                                onChange={() => handleChange(!item?.requireDefectActSignedByResponsibleCustomerUser, item.id, "requireDefectActSignedByResponsibleCustomerUser")}
                                options={turnOnOffStatuses}
                                label={t('tableHeader:requireDefectActSignedByResponsibleCustomerUser')}
                                value={item?.requireDefectActSignedByResponsibleCustomerUser}
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Collapse>
        </>

    );
    // isOpen = filterStorageSettings.isOpen;
  }

  // const [openCollapse, setopenCollapse] = useState(isOpen);
  // const handleClick = (id) => {
  //   const updatedIsOpenSystemTab = objectSystemsSettings.map((item) => {
  //     if (item.id === id) {
  //       return { ...item, isOpen: !item.isOpen };
  //     }
  //     return item;
  //   });
  //   localStorage.setItem(
  //     "systemSettingsAccord",
  //     JSON.stringify(updatedIsOpenSystemTab)
  //   );
  //   setopenCollapse(!openCollapse);
  // };

  // const handleChange = (value, id, name) => {
  //   const updatedSystemSettings = objectSystemsSettings.map((system) =>
  //     system.id === id ? { ...system, [name]: value } : system
  //   );
  //   setobjectSystemsSettings(updatedSystemSettings);
  // };

  // return (
  //   <>
  //     <ListItemButton onClick={() => handleClick(item.id)}>
  //       <ListItemText sx={{ fontFamily: "var(--INTER)" }} primary={item.name} />
  //       {openCollapse ? <ExpandLess /> : <ExpandMore />}
  //     </ListItemButton>
  //     <Collapse in={openCollapse} timeout="auto" unmountOnExit>
  //       <Card
  //         sx={{
  //           width: 550,
  //           marginTop: "10px",
  //           marginBottom: "10px",
  //           marginLeft: "15px",
  //         }}
  //       >
  //         <CardContent>
  //           <Box sx={{ display: "inline" }}>
  //             <Typography
  //               sx={{
  //                 fontSize: 14,
  //                 fontFamily: "var(--INTER)",
  //                 fontWeight: "bold",
  //                 marginTop: "15px",
  //               }}
  //               color="text.secondary"
  //               gutterBottom
  //             >
  //               {t("tableHeader:responsibleCustomer")}
  //             </Typography>
  //             <FormItem
  //               control={control}
  //               name={`${item.id}.defaultResponsibleCustomerUserId`}
  //               objectId={objectId}
  //               defaultValue={item.defaultResponsibleCustomerUserId}
  //               sx={{ padding: "12px 0" }}
  //               value={item.defaultResponsibleCustomerUserId}
  //               input={(props) => (
  //                 <ExecutiveUserNameSelectSimple
  //                   {...props}
  //                   onChange={(e) =>
  //                     handleChange(
  //                       e.target.value,
  //                       item.id,
  //                       "defaultResponsibleCustomerUserId"
  //                     )
  //                   }
  //                 />
  //               )}
  //             />
  //           </Box>
  //           <Box sx={{ display: "inline" }}>
  //             <Typography
  //               sx={{
  //                 fontSize: 14,
  //                 fontFamily: "var(--INTER)",
  //                 fontWeight: "bold",
  //                 marginTop: "15px",
  //               }}
  //               color="text.secondary"
  //               gutterBottom
  //             >
  //               {t("tableHeader:resUser")}
  //             </Typography>
  //             <FormItem
  //               control={control}
  //               name={`${item.id}.defaultAssignUserId`}
  //               objectId={objectId}
  //               defaultValue={item.defaultAssignUserId}
  //               sx={{ padding: "12px 0" }}
  //               value={item.defaultAssignUserId}
  //               input={(props) => (
  //                 <ExecutiveUserNameSelectSimple
  //                   {...props}
  //                   onChange={(e) =>
  //                     handleChange(
  //                       e.target.value,
  //                       item.id,
  //                       "defaultAssignUserId"
  //                     )
  //                   }
  //                 />
  //               )}
  //             />
  //           </Box>

  //           <Box sx={{ display: "inline", flexBasis: "100%" }}>
  //             <Switcher
  //               onChange={() =>
  //                 handleChange(
  //                   !item?.isOperationStarted,
  //                   item.id,
  //                   "isOperationStarted"
  //                 )
  //               }
  //               options={turnOnOffStatuses}
  //               label={t("tableHeader:usageStarting")}
  //               value={item?.isOperationStarted}
  //             />
  //           </Box>

  //           <Box sx={{ display: "inline", flexBasis: "100%" }}>
  //             <Switcher
  //               onChange={() =>
  //                 handleChange(
  //                   !item?.generateJobsWeeklyRegulation,
  //                   item.id,
  //                   "generateJobsWeeklyRegulation"
  //                 )
  //               }
  //               options={turnOnOffStatuses}
  //               label={t("tableHeader:generateJobsWeeklyRegulation")}
  //               value={item?.generateJobsWeeklyRegulation}
  //             />
  //           </Box>

  //           <Box sx={{ display: "inline", flexBasis: "100%" }}>
  //             <Switcher
  //               onChange={() =>
  //                 handleChange(
  //                   !item?.generateJobsDailyRegulation,
  //                   item.id,
  //                   "generateJobsDailyRegulation"
  //                 )
  //               }
  //               options={turnOnOffStatuses}
  //               label={t("tableHeader:generateJobsDailyRegulation")}
  //               value={item?.generateJobsDailyRegulation}
  //             />
  //           </Box>

  //           <Box sx={{ display: "inline" }}>
  //             <Typography
  //               sx={{
  //                 fontSize: 14,
  //                 fontFamily: "var(--INTER)",
  //                 fontWeight: "bold",
  //                 marginTop: "15px",
  //               }}
  //               color="text.secondary"
  //               gutterBottom
  //             >
  //               {t("tableHeader:deadlineDays")}
  //             </Typography>

  //             <InputText
  //               control={control}
  //               name={`${item.id}.deadlineDays`}
  //               objectId={objectId}
  //               defaultValue={item.deadlineDays}
  //               sx={{ padding: "12px 0" }}
  //               maxLength={(v) => v?.length <= 3}
  //               value={item.deadlineDays}
  //               InputProps={{
  //                 type: "number",
  //               }}
  //               onKeyDown={(e) => {
  //                 if (
  //                   e.key === "e" ||
  //                   e.key === "E" ||
  //                   e.key === "-" ||
  //                   e.key === "+" ||
  //                   e.key === "." ||
  //                   e.key === ","
  //                 ) {
  //                   e.preventDefault();
  //                 }
  //               }}
  //               onChange={(e) =>
  //                 handleChange(e.target.value, item.id, "deadlineDays")
  //               }
  //             />
  //           </Box>

  //           <Box sx={{ display: "inline" }}>
  //             <Typography
  //               sx={{
  //                 fontSize: 14,
  //                 fontFamily: "var(--INTER)",
  //                 fontWeight: "bold",
  //                 marginTop: "15px",
  //               }}
  //               color="text.secondary"
  //               gutterBottom
  //             >
  //               {t("tableHeader:jobGenerationBeforeDays")}
  //             </Typography>

  //             <InputText
  //               control={control}
  //               name={`${item.id}.jobGenerationBeforeDays`}
  //               objectId={objectId}
  //               defaultValue={item.jobGenerationBeforeDays}
  //               sx={{ padding: "12px 0" }}
  //               value={item.jobGenerationBeforeDays}
  //               InputProps={{ type: "number" }}
  //               onKeyDown={(e) => {
  //                 if (
  //                   e.key === "e" ||
  //                   e.key === "E" ||
  //                   e.key === "-" ||
  //                   e.key === "+" ||
  //                   e.key === "." ||
  //                   e.key === ","
  //                 ) {
  //                   e.preventDefault();
  //                 }
  //               }}
  //               onChange={(e) =>
  //                 handleChange(
  //                   e.target.value,
  //                   item.id,
  //                   "jobGenerationBeforeDays"
  //                 )
  //               }
  //             />
  //           </Box>
  //           <Box sx={{ display: "inline", flexBasis: "100%" }}>
  //             <Switcher
  //               onChange={() =>
  //                 handleChange(
  //                   !item?.requireDefectActSignedByExecutiveUser,
  //                   item.id,
  //                   "requireDefectActSignedByExecutiveUser"
  //                 )
  //               }
  //               options={turnOnOffStatuses}
  //               label={t("tableHeader:requireDefectActSignedByExecutiveUser")}
  //               value={item?.requireDefectActSignedByExecutiveUser}
  //             />
  //           </Box>
  //           <Box sx={{ display: "inline", flexBasis: "100%" }}>
  //             <Switcher
  //               onChange={() =>
  //                 handleChange(
  //                   !item?.requireDefectActSignedByResponsibleCustomerUser,
  //                   item.id,
  //                   "requireDefectActSignedByResponsibleCustomerUser"
  //                 )
  //               }
  //               options={turnOnOffStatuses}
  //               label={t(
  //                 "tableHeader:requireDefectActSignedByResponsibleCustomerUser"
  //               )}
  //               value={item?.requireDefectActSignedByResponsibleCustomerUser}
  //             />
  //           </Box>
  //         </CardContent>
  //       </Card>
  //     </Collapse>
  //   </>
  // );
// };

export default ObjectSettingsListItem;
