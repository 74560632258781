import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const signingSlice = createSlice({
  name: "signing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const signingActions = signingSlice.actions;
export const signingReducer = signingSlice.reducer;