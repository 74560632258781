import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";

import { PATHS } from '../../../constants';

const ButtonsField = ({ isEmptyPage = false, handleCancel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box className="object-header-buttons">
      {!isEmptyPage ? (
        <>
          <Button className="btn btn-outlined-grey" onClick={handleCancel}>
            {t("button:comeback")}
          </Button>
          <Button form="myform" className="btn btn-primary-fill" type="submit">
            {t("button:save")}
          </Button>
        </>
      ) : (
        <Button
          className="btn btn-outlined-primary"
          variant="outlined"
          onClick={() => navigate(PATHS.LIST_OBJECTS)}
        >
          {t("button:add_account_entity")}
        </Button>
      )}
    </Box>
  );
};

export default ButtonsField;
