import React from "react";

export const EmailIcon = () => {
  return (
    <svg
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.8 1H26.2C26.2 1 29 1 29 4V22C29 22 29 25 26.2 25H3.8C3.8 25 1 25 1 22V4C1 4 1 1 3.8 1Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29 5.28571L16.442 14.2488C16.0098 14.553 15.51 14.7143 15 14.7143C14.49 14.7143 13.9902 14.553 13.558 14.2488L1 5.28571"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
