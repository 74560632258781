import React from "react";
import "./styles/style.css";
import { Box, Typography } from "@mui/material";
import { getSum } from "./helpers";
import { useTranslation } from "react-i18next";

const ChartCard = ({ sx, data, label, labels, Chart }) => {
  const { t } = useTranslation();

  return (
    <Box className="chart_card_container" sx={sx}>
      <Typography className="chart_card_container-title">{t(label)}</Typography>
      <Box className="chart_card_container-values">
        <Typography className="chart_card_container-values-sum_value_title">
          {getSum(data)}
        </Typography>
      </Box>
      <Box className="chart_card_container-diagram_wrapper">
        <Chart data={data} labels={labels} />
      </Box>
    </Box>
  );
};

export default ChartCard;
