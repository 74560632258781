import React, { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";

import {
  WorkContractDateItemFormConfig,
  formFields,
  resetFields,
} from "./form-config";
import { dateFormat } from "../../../../constants";

import DateItems from "./DateItems";
import FormItem from "../../../components/FormItem";
import { getEmptyValue } from "../../../components/FormItem/helpers";

const WorkContractDateItem = ({
  name,
  label,
  control,
  errors,
  setValue,
  trigger,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const {
    termOfContract,
    equipmentContractFileId,
    contractDate,
    equipmentContractFileIdAvailability,
    havingServiceContract,
  } = useWatch({
    control,
  });

  const style = useMemo(() => {
    return {
      ".block-footer-text, .block-title, .adornment, .MuiOutlinedInput-input": {
        color:
          errors.termOfContract && !equipmentContractFileIdAvailability && havingServiceContract
            ? "#d32f2f!important"
            : "inherit",
      },
    };
    // eslint-disable-next-line
  }, [errors?.termOfContract, equipmentContractFileIdAvailability, havingServiceContract]);

  const date = useMemo(
    () => !equipmentContractFileId && contractDate && dayjs(contractDate),
    [contractDate, equipmentContractFileId]
  );

  useEffect(() => {
    if (equipmentContractFileIdAvailability) {
      Object.entries(resetFields).forEach(([name, type]) => {
        setValue(name, getEmptyValue(type));
      });
      setValue(formFields.performer, "test");
    }
    // eslint-disable-next-line
  }, [equipmentContractFileIdAvailability]);

  return (
    <Box sx={style} className="block">
      <Box>
        <Typography className="block-title">{t(label)}</Typography>
        <DateItems
          itemName={name}
          error={errors["termOfContract"]}
          setValue={setValue}
          itemValue={termOfContract}
          trigger={trigger}
          disabled={disabled || equipmentContractFileIdAvailability}
        />
        <Box className="block-footer">
          <Box sx={{ display: "none" }}>
            {WorkContractDateItemFormConfig.map(
              ({ name, label, input, ...rest }) => (
                <FormItem
                  key={name}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  input={input}
                  disabled={disabled || equipmentContractFileIdAvailability}
                  trigger={trigger}
                  {...rest}
                />
              )
            )}
          </Box>
          <Box className="block-footer-wrapper-text">
            <Typography className="block-footer-text">
              {`${t("label:activeUntil")}:`}
            </Typography>
            <Typography className="block-footer-text">
              {date?.$D ? date.add(termOfContract, "M").format(dateFormat) : ""}
            </Typography>            
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkContractDateItem;
