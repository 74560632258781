import React, { useEffect, useState } from "react";
import { TextField, Typography } from "@mui/material";
import SelectSimpleSearch from "../../components/SelectSimple/SelectSimpleSearch";
import { useLazyGetOrganizationByInnQuery } from "../../../middleware/apiOrganizations";
import { useLazyGetOrgInnQuery } from "../../../middleware/addressDataApi";
import InputMask from "react-input-mask";
import InputText from "../../components/InputText";


export const ClickableNameCell = ({ item }) => {
  const { name, action } = item || {};
console.log("item",item)
  return (
    <Typography
      className="table-row-cell-link"
      onClick={action ? action : () => { }}
    >
      {name || ""}
    </Typography>);
};
export const InnNumberSearchInput = (props) => {

  const [getOrganizationByInn] = useLazyGetOrgInnQuery();
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!searchValue.length) {
      return;
    }
    getOrganizationByInn(searchValue)
      .then(res => {
        const suggests = res?.data?.suggestions
        if (suggests && suggests?.length > 0) {

          let suggestArray = suggests.map((item) => {
              return {value : item.data.inn,name: item.value}
          })
          console.log("ress", suggestArray)
          setOptions(suggestArray);
        } else {
          setOptions([]);
        }
       // console.log("ress", res?.data)
      //  setOptions(res?.data || []);

      })
      .catch(err => {
        console.log(err);
      })
  }, [getOrganizationByInn, searchValue])

  return (
    <SelectSimpleSearch
      {...props}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      options={options}
    />
  )
}


export const InnNumber = (props) => {

  const [getOrganizationByInn] = useLazyGetOrganizationByInnQuery();
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!searchValue.length) {
      return;
    }
    getOrganizationByInn(searchValue)
      .then(res => {
        setOptions(res?.data || []);
      })
      .catch(err => {
        console.log(err);
      })
  }, [getOrganizationByInn, searchValue])

  return (
    <SelectSimpleSearch
      {...props}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      options={options}
    />
  )
}

