import React, { useEffect, useState } from "react";
import {
  Popper,
  Grow,
  MenuList,
  Paper,
  ClickAwayListener,
  Typography,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Button, Badge } from "@mui/material";
import { NotificationItem } from "./NotificationItem";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {
  useMarkNotificationAsReadMutation,
  useLazyGetNotificationsQuery,
  useLazyGetNotificationsCountQuery,
} from "../../../middleware/notificationApi";
import { useAction } from "../../../hooks/actions";
import notificationIcon from "../../../image/notificationImg.png";
// import NotificationsIconNone from "../icons/Header/NotificatialIconNone";
import "./style.css";

export const NotificationPopUp = () => {
  const [notifications, setNotifications] = useState({});
  const [notificationsCount, setnotificationsCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [pageNumber, changePageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const anchorRef = React.useRef(null);
  const { t } = useTranslation();

  const { setIsErrorSubmit } = useAction();

  const [
    getNotification,
    { error: errorNotif, refetch: refetchNotifications /* isLoading */ },
  ] = useLazyGetNotificationsQuery();
  const [getNotificationcount, { error: errorNotifcount }] =
    useLazyGetNotificationsCountQuery();

  const [markNotificationAsRead, { error }] =
    useMarkNotificationAsReadMutation();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      getNotificationcount().then((data) => setnotificationsCount(data.data));
    }, 15000); // Вызов функции callApi каждые 30 секунд

    return () => clearInterval(interval);
  }, []);
  const fetchData = async () => {
    try {
      const response = await getNotification({
        pageNumber: 0,
      });
      setNotifications({
        ...response?.data,
        hasMore:
          response?.data?.data.length < response?.data.recordsCount
            ? true
            : false,
      });
    } catch (error) {
      console.log("error");
    }
  };
  useEffect(() => {
    if (open) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const markNotification = (array) => {
    markNotificationAsRead(array).then(({ data, error }) => {
      if (!error) {
        fetchData();
        getNotificationcount().then((data) => setnotificationsCount(data.data));
      }
      // setOpen(false);
    });
  };
  const notiffetchData = () => {
    setLoading(true);
    setTimeout(() => {
      getNotification({
        pageNumber: pageNumber + 1,
      }).then(({ data, isSuccess }) => {
        changePageNumber((prev) => prev + 1);
        setNotifications({
          ...notifications,
          hasMore:
            data?.data.length + notifications?.data.length < data.recordsCount
              ? true
              : false,
          data: [...notifications?.data, ...data.data],
        });
        setLoading(false);
      });
    }, 1000);
  };
  useEffect(() => {
    setIsErrorSubmit(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
    if (bottom >= 1 && bottom <= 10 && !loading && notifications?.hasMore) {
      notiffetchData();
    }
  };
  return (
    <Box>
      <div className="notification-icon" ref={anchorRef} onClick={handleToggle}>
        <Badge
          sx={{ top: "-20px", right: "-45px" }}
          badgeContent={notificationsCount || 0}
          color="error"
        >
          <Box sx={{ color: "var(--text-color)" }}></Box>
        </Badge>
        {/* <NotificationsIconNone /> */}
        <img src={notificationIcon} alt="Иконка уведомлений" />
      </div>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className="paper-wrapper">
              <Box sx={{ position: "absolute", right: "40px", top: "40px" }}>
                <IconButton onClick={handleClose} sx={{ padding: 0 }}>
                  <CloseIcon />
                </IconButton>
              </Box>

              <div className="notification-wrapper">
                <div className="notification-title-wrapper">
                  <p className="notification-title">
                    {t("label:notifications")}
                  </p>
                </div>

                {notifications?.recordsCount > 0 ? (
                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="text"
                      onClick={() =>
                        markNotification(
                          notifications.data.map(
                            (notification) => notification?.id
                          )
                        )
                      }
                    >
                      <Typography className="mark-laber "></Typography>
                    </Button>
                  </Box>
                ) : (
                  ""
                )}

                <ClickAwayListener onClickAway={handleClose}>
                  {notifications?.recordsCount > 0 ? (
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                      onScroll={handleScroll}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        height: "50vh",
                        overflow: "hidden",
                        overflowY: "auto",
                        paddingBottom: "0px",
                      }}
                    >

                      {notifications.data.map((item) => {
                        return (
                          <NotificationItem
                            key={item?.id}
                            markNotification={markNotification}
                            notification={item}
                          />
                        );
                      })}

                      {loading && (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      )}
                    </MenuList>
                  ) : (
                    <Box
                      sx={{
                        height: "400px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography> {t("label:no_notifications")}</Typography>
                    </Box>
                  )}
                </ClickAwayListener>
              </div>
              <div className="notification-footer">
                <button
                  onClick={() =>
                    markNotification(
                      notifications.data.map((notification) => notification?.id)
                    )
                  }
                  className="info"
                >
                  {t("label:mark_all_as_read")}
                </button>
              </div>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
