import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Box,
  Chip,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import checkImagechecked from "../../../image/icons/checkboxChecked.png"
import checkImage from "../../../image/icons/checkBox.png"
import selectArrowUp from "../../../image/icons/selectArrowUp.png";
import selectArrowDown from "../../../image/icons/selectArrowDown.png"
import EquipmentDialog from "../../pages/EquipmentList/EquipDialog";

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 30;


const SelectMultipleChipDeletable = ({
  options,
  label,
  onChange,
  error,
  onClickEquip,
  displayEmpty=false,
  placeholder,
  value,
  isLink=true,
  disabled = false,
}) => {
  console.log("onClickEquip",onClickEquip)
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedValues, setSelectedValues] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: options?.length>=4 ? (ITEM_HEIGHT+ ITEM_PADDING_TOP) * 4 : (ITEM_HEIGHT+ ITEM_PADDING_TOP) * options?.length ,
      },
    },
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const requestData = value?.map(({ id }) => id) || [];
    
    setSelectedValues(value);
    onChange(requestData);
  };

  const handleDelete = (e, value) => {
    e.preventDefault();

    const requestData = selectedValues?.filter((e) => e?.id !== value?.id);
    setSelectedValues(requestData);
    onChange(requestData?.map(({ id }) => id));
  };
  const handleOpenEquipment = (id) => {
    setopenEquipDialog(true);
    setSelectedEquipId(id);
  };
  useEffect(() => {
    if (options?.length && value?.length) {
      const requestData =
        value?.reduce((acc, v) => {
          const item = options?.find(({ id }) => id === v);
          return item ? [...acc, item] : acc;
        }, []);

      setSelectedValues(requestData);
    } else if (!options?.length && value?.length) {
      setSelectedValues(value);
    }
  }, [value, options]);
  console.log("requestData", selectedValues)
  return (
    <Box sx={{ position: "relative" }} className="input_text">
      {openEquipDialog && (
        <EquipmentDialog
          open={openEquipDialog}
          setOpen={setopenEquipDialog}
          equipId={selectedEquipId}
          refetch={() => { }}

        />
      )}
      <InputLabel sx={{ mb: "8px" }}>
        {t(label)}
      </InputLabel>
      <FormControl fullWidth error={!!error}>

        <Select
          multiple
          value={selectedValues}
          disabled={disabled}
          onChange={handleChange}
          input={<OutlinedInput />}
          sx={onClickEquip && {
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: 'var(--main)',
              color: 'var(--main)',
            },
          }}
          open={isOpen}
          displayEmpty={displayEmpty}
          onOpen={handleOpen}
          onClose={handleClose}
          IconComponent={() =>
            <Box sx={{ mr: "24px",cursor:"pointer" }} onClick={()=>{setIsOpen((prev)=> !prev)}}>
              {isOpen ? (
                <img src={selectArrowUp} />
              ) : (
                <img src={selectArrowDown} />
              )
              }
            </Box>}
          renderValue={(selected) => (
            selected?.length>0 ? (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              
              {selected?.map((value, i) =>
                !disabled ? (
                  <Chip
                    key={value?.id || i}
                    label={value?.name}
                    clickable
                    onClick={() => { onClickEquip && handleOpenEquipment(value?.id) }}
                    deleteIcon={
                      <ClearIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    onDelete={(e) => handleDelete(e, value)}
                  />
                ) : (
                  <Chip
                    key={value?.id || i}
                    label={value?.name || value?.standard}
                    clickable
                    sx={{
                      color: 'var(--main)',
                    }}
                    onClick={() => { onClickEquip && handleOpenEquipment(value?.id) }}
                  />
                )
              )}
            </Box>
            ): (t(placeholder) || "Нет")
            
          )}
          MenuProps={MenuProps}
        >
          {options?.map((option, i) => (
            <MenuItem
              key={option?.id || i}
              value={option}
              sx={{ height: ITEM_HEIGHT + ITEM_PADDING_TOP }}
            >
              <Checkbox checked={selectedValues?.indexOf(option) > -1} sx={{ height: "28px", width: "28px", mr: "10px", borderRadius: 0 }}
                icon={<Box
                  component="img"
                  src={checkImage}
                />}
                checkedIcon={<Box
                  component="img"
                  src={checkImagechecked}
                />}
              />
              <ListItemText primary={option?.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectMultipleChipDeletable;
