import React from "react";
import { t } from "i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { fileTypeCheck, fileSizeCheck } from "../../../../validation";
import { formFields } from "../form-config";
import { InputFileMultiple } from "../../../components/InputFile/InputFileMultiple";

export const documentationsConfig = [
  {
    id: 0,
    name: formFields.projectDocumentation,
    label: "label:projectDocumentation",
    type: "file",
    flexBasis: "35%",
    rules: {
      validate: {
        type: (files) => {
          const availableFileTypes = ["image/jpeg", "image/png", "application/pdf"];
          return fileTypeCheck(files.filesToAdd, formFields.projectDocumentation, availableFileTypes);
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files.filesToAdd,
            formFields.projectDocumentation,
            maxAvailableFileSize
          );
        },
      },
    },
    canDeleteFile: true,
    input: (props) => (
      <Box>
        <Typography className="input-block-title">
          {t(`${props.label}`)}
        </Typography>
        <InputFileMultiple {...props} accept={".png, .jpg, .jpeg, .pdf"} isPrewImageColumn={true} />
      </Box>
    ),
  },
  {
    id: 1,
    name: formFields.technicalDocumentation,
    label: "label:technicalDocumentation",
    type: "file",
    flexBasis: "35%",
    rules: {
      validate: {
        type: (files) => {
          const availableFileTypes = ["image/jpeg", "image/png", "application/pdf"];
          return fileTypeCheck(files.filesToAdd, formFields.technicalDocumentation, availableFileTypes);
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files.filesToAdd,
            formFields.technicalDocumentation,
            maxAvailableFileSize
          );
        },
      },
    },
    canDeleteFile: true,
    input: (props) => (
      <Box>
        <Typography className="input-block-title">
          {t(`${props.label}`)}
        </Typography>
        <InputFileMultiple {...props} accept={".png, .jpg, .jpeg, .pdf"} isPrewImageColumn={true} />
      </Box>
    ),
  },
  {
    id: 3,
    name: formFields.permitDocumentation,
    label: "label:permitDocumentation",
    type: "file",
    flexBasis: "35%",
    rules: {
      validate: {
        type: (files) => {
          const availableFileTypes = ["image/jpeg", "image/png", "application/pdf"];
          return fileTypeCheck(files.filesToAdd, formFields.permitDocumentation, availableFileTypes);
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files.filesToAdd,
            formFields.permitDocumentation,
            maxAvailableFileSize
          );
        },
      },
    },
    canDeleteFile: true,
    input: (props) => (
      <Box>
        <Typography className="input-block-title">
          {t(`${props.label}`)}
        </Typography>
        <InputFileMultiple {...props} accept={".png, .jpg, .jpeg, .pdf"} isPrewImageColumn={true} />
      </Box>
    ),
  },
  {
    id: 4,
    name: formFields.specialTechnicalSpecificationsDocumentation,
    label: "label:specialTechnicalSpecificationsDocumentation",
    type: "file",
    flexBasis: "35%",
    rules: {
      validate: {
        type: (files) => {
          const availableFileTypes = [
            "image/jpeg",
            "image/png",
            "application/pdf",
          ];
          return fileTypeCheck(
            files.filesToAdd,
            formFields.specialTechnicalSpecificationsDocumentation,
            availableFileTypes
          );
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files.filesToAdd,
            formFields.specialTechnicalSpecificationsDocumentation,
            maxAvailableFileSize
          );
        },
      },
    },
    canDeleteFile: true,
    input: (props) => (
      <Box>
        <Typography className="input-block-title">
          {t(`${props.label}`)}
        </Typography>
        <InputFileMultiple {...props} accept={".png, .jpg, .jpeg, .pdf"} isPrewImageColumn={true} />
      </Box>
    ),
  },
  {
    id: 5,
    name: formFields.fireSafetyDeclarationDocumentation,
    label: "label:fireSafetyDeclarationDocumentation",
    type: "file",
    flexBasis: "35%",
    rules: {
      validate: {
        type: (files) => {
         const availableFileTypes = [
           "image/jpeg",
           "image/png",
           "application/pdf",
         ];
          return fileTypeCheck(
            files.filesToAdd,
            formFields.fireSafetyDeclarationDocumentation,
            availableFileTypes
          );
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files.filesToAdd,
            formFields.fireSafetyDeclarationDocumentation,
            maxAvailableFileSize
          );
        },
      },
    },
    canDeleteFile: true,
    input: (props) => (
      <Box>
        <Typography className="input-block-title">
          {t(`${props.label}`)}
        </Typography>
        <InputFileMultiple {...props} accept={".png, .jpg, .jpeg, .pdf"} isPrewImageColumn={true} />
      </Box>
    ),
  },
  {
    id: 6,
    name: formFields.calculationOFfireRisksDocumentation,
    label: "label:calculationOFfireRisksDocumentation",
    type: "file",
    flexBasis: "35%",
    rules: {
      validate: {
        type: (files) => {
          const availableFileTypes = [
            "image/jpeg",
            "image/png",
            "application/pdf",
          ];
          return fileTypeCheck(
            files.filesToAdd,
            formFields.calculationOFfireRisksDocumentation,
            availableFileTypes
          );
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files.filesToAdd,
            formFields.calculationOFfireRisksDocumentation,
            maxAvailableFileSize
          );
        },
      },
    },
    canDeleteFile: true,
    input: (props) => (
      <Box>
        <Typography className="input-block-title">
          {t(`${props.label}`)}
        </Typography>
        <InputFileMultiple {...props} accept={".png, .jpg, .jpeg, .pdf"} isPrewImageColumn={true} />
      </Box>
    ),
  },
  {
    id: 7,
    name: formFields.calculationOfPLIndividualisationIndexDocumentation,
    label: "label:calculationOfPLIndividualisationIndexDocumentation",
    type: "file",
    flexBasis: "35%",
    rules: {
      validate: {
        type: (files) => {
          const availableFileTypes = [
            "image/jpeg",
            "image/png",
            "application/pdf",
          ];
          return fileTypeCheck(
            files.filesToAdd,
            formFields.calculationOfPLIndividualisationIndexDocumentation,
            availableFileTypes
          );
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files.filesToAdd,
            formFields.calculationOfPLIndividualisationIndexDocumentation,
            maxAvailableFileSize
          );
        },
      },
    },
    canDeleteFile: true,
    input: (props) => (
      <Box>
        <Typography className="input-block-title">
          {t(`${props.label}`)}
        </Typography>
        <InputFileMultiple {...props} accept={".png, .jpg, .jpeg, .pdf"} isPrewImageColumn={true} />
      </Box>
    ),
  },
];