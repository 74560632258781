import React from "react";
import { Bar } from "react-chartjs-2";
import { CategoryScale, Chart, LinearScale, BarElement } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const AvailabilityStatistics = ({ perfomanceStatistics }) => {
  Chart.register(CategoryScale, LinearScale, BarElement);
  const { t } = useTranslation();

  const data = {
    labels: perfomanceStatistics.map((item) => item.name),
    datasets: [
      {
        data: perfomanceStatistics.map((item) => item.count),
        backgroundColor: "#A4A8FC",
        barPercentage: 0.2,
        borderWidth: 0,
        borderRadius: 4,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    plugins: {
      legend: { display: false },
      datalabels: {
        display: true,
        color: "black",
        formatter: Math.round,
        anchor: "end",
        offset: 0,
        align: "start",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        display: false,
      },
    },
  };

  return (
    <Box
      style={{
        height: "250px",
        border: "1px solid #B6BCC5",
        marginTop: "40px",
      }}
    >
      <Typography className="label-title">
        {t("label:systemAvailabilityStatistics")}
      </Typography>
      <Bar data={data} options={options} plugins={[ChartDataLabels]} />
    </Box>
  );
};

export default AvailabilityStatistics;
