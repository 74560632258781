import React from "react";
import { Box } from "@mui/material";

import "./styles/style.css";
import { RightBlockLogin } from "../LogIn/RightBlockLogin";
import { RegistrationForm } from "./RegistrationForm";

export const Registration = () => {
  return (
    <Box className="login-wrapper">
      <Box className="right_block">
        <RegistrationForm />
      </Box>
      <RightBlockLogin />
    </Box>
  );
};
