import * as React from "react";
import { Dialog, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Techcard = ({ open, setOpen, objectId }) => {
    const textStyle = {
        fontSize: "14px",
        lineHeight: "1.5",
        marginBottom: "16px",
        textAlign: "justify",
    };

    const headingStyle = {
        fontSize: "20px",
        fontWeight: "bold",
        marginTop: "50px",
    };
    const titleStyle = {
        fontSize: "18px",
        fontWeight: "bold",
        marginTop: "30px",
        textAlign: "center",
    };

    const handleClose = () => {
        setOpen(false);
    };
    const tableHeadStyle = {
        fontSize: "16px",
        fontWeight: "bold",
        padding: "40px 20px",

    };
    const tablecellStyle = {
        fontSize: "14px",
      
        padding: "0px 20px",

    };
    const listStyle = {
        marginLeft: "24px",
        marginBottom: "16px",
    };
    return (
        <Dialog className="object_dialog" open={open} onClose={handleClose}>
            <IconButton className="close_icon" onClick={handleClose}>
                <CloseIcon />
            </IconButton>

            <Typography>

                <h2 style={headingStyle}>
                    Страница находится в разработке. Пример технологической карты:
                </h2>
                <h3 style={titleStyle}>
                    Внешний осмотр ИП 212-3СУ
                </h3>
                <table border="0">

                    <tbody>
                        <tr >

                            <td style={tableHeadStyle}>ПЕРЕЧЕНЬ РАБОТ</td>
                            <td style={tableHeadStyle}><span >УСЛОВИЯ ВЫПОЛНЕНИЯ ОПЕРАЦИЙ</span></td>
                            <td style={tableHeadStyle}><span >ЧИСЛЕННОСТЬ И КВАЛИФИКАЦИЯ ПЕРСОНАЛА, НЕ НИЖЕ</span></td>
                            <td style={tableHeadStyle}><span >ПРИБОРЫ, ОБОРУДОВАНИЕ, ИНСТРУМЕНТЫ И МАТЕРИАЛЫ</span></td>
                            <td style={tableHeadStyle}><span >ПОДГОТОВИТЕЛЬНЫЕ МЕРОПРИЯТИЯ ПО ВЫПОЛНЕНИЮ ОПЕРАЦИЙ</span></td>
                        </tr>
                        <tr>

                            <td style={tablecellStyle}>Внешний осмотр на отсутствие повреждений.</td>
                            <td style={tablecellStyle}>Без снятия напряжения.</td>
                            <td style={tablecellStyle}>Техник АПС</td>
                            <td style={tablecellStyle}>Набор электромонтажного инструмента, ветошь.</td>
                            <td style={tablecellStyle}></td>
                        </tr>
                        <tr>

                            <td style={tablecellStyle}>Проверка наличия маркировок и надписей.</td>
                        </tr>
                        <tr>

                            <td style={tablecellStyle}>Контроль индикации. </td>
                        </tr>
                        <tr>
                            <td style={tablecellStyle}>Проверка прочности крепежа извещателя. </td>
                        </tr>
                        <tr>

                            <td style={tablecellStyle}>Проверка состояния соединений шлейфа сигнализации. </td>
                        </tr>
                        <tr>

                            <td style={tablecellStyle}>Очистка, при необходимости, внешней поверхности извещателя от пыли и грязи.</td>
                        </tr>
                    </tbody>
                </table>
            </Typography>
        </Dialog>
    );
};
