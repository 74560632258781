import { createSlice } from "@reduxjs/toolkit";
import { documentationApi } from "../../middleware/documentationApi";

const initialState = {
    allActs: [],
    isActExist:true,
    plumeEquip:[],
    plumeEquipSNNME:[]
    
};

export const documentationSlice = createSlice({
    name: "documentation",
    initialState,
    reducers: {
        changeDocumentationValue(state, action) {
            state.documentationValue = action.payload;
        },
        changeActExist(state, action) {
            state.isActExist = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
          documentationApi.endpoints.getActsTable.matchFulfilled,
          (state, { payload }) => {
              state.allActs = payload;
          }
        );
        builder.addMatcher(
            documentationApi.endpoints.actexists.matchFulfilled,
            (state, { payload }) => {
                state.isActExist = payload;
            }
          );
          builder.addMatcher(
            documentationApi.endpoints.getDocEquipmentSelectElementsByFacilityId.matchFulfilled,
          (state, { payload }) => {
            state.plumeEquip = payload;
          }
        );
        builder.addMatcher(
            documentationApi.endpoints.getDocEquipmentSelectElementsByFacilityIdSNNME.matchFulfilled,
          (state, { payload }) => {
            state.plumeEquipSNNME = payload;
          }
        );
    },
});

export const documentationActions = documentationSlice.actions;
export const documentationReducer = documentationSlice.reducer;
