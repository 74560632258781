export const getRequestData = (data) => {
    const files = data?.floors?.reduce(
        (filesList, floor) => {
            const fileItem = {...floor.floorSchema, floorNumber: floor.floorNumber};
            return filesList ? [...filesList, fileItem] :
                floor.floorSchema ? [fileItem] : []
        },
        null
    );

    const entities = {
        ...data,
        floors: data?.floors?.map(({floorNumber, comment, id}) => ({floorNumber, comment, id}))
    };

    return {entities, files};
};

export const getRequestShortData = ({ data, addedFacility }) => {
  const files = data?.floors?.reduce(
      (filesList, floor) => {
          const fileItem = {...floor.floorSchema, floorNumber: floor.floorNumber};
          return filesList ? [...filesList, fileItem] :
              floor.floorSchema ? [fileItem] : []
      },
      null
  );
  const { buildingPassport } = addedFacility || {};
  const entities = {
      ...data,
      protectionObjectId: buildingPassport?.id || null,
      floors: data?.floors?.map(({floorNumber, comment, id}) => ({ floorNumber: Number(floorNumber), comment, id }))
  };

  return {entities, files};
};

export const getRequestDataFilesForAdd = (floors, files) => {
    const formData = new FormData();

    if(!files) {
        return formData;
    }

    let fileConfigIndex = 0
    Object.entries(files).filter(([, value]) => value)
        .forEach(([, {floorNumber, filesToAdd, filesToDelete}]) => {
            const floorEntityId = floors.find(floor => floor.floorNumber === Number(floorNumber))?.id;
            if(filesToAdd?.length) {
                filesToAdd.forEach((file, index) => {
                    fileConfigIndex += index
                    formData.append(`entityFileList[${fileConfigIndex}].EntityId`, floorEntityId);
                    formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "FloorInfo");
                    formData.append(`entityFileList[${fileConfigIndex}].File`, file);
                    formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Add");
                })
                fileConfigIndex++
            }
            if(filesToDelete?.length) {
                filesToDelete.forEach((file, index) => {
                    fileConfigIndex += index
                    formData.append(`entityFileList[${fileConfigIndex}].EntityId`, floorEntityId);
                    formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "FloorInfo");
                    formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Delete");
                })
                fileConfigIndex++;
            }
        })

    return formData;
};

export const formatErrors = (response) => {
    const errors = {};
    response?.error?.data.errors.forEach(error => {
        errors[error.entityId] = errors[error.entityId] ?
            `${errors[error.entityId]} ${error.error}` : error.error
    });
    return errors;
}