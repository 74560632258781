import { Box } from '@mui/material'
import React from 'react'
import s from './index.module.css'

const ContextMenu = ({ data, position }) => {  
  return (
    <Box sx={{ 
      width: 200,
      borderRadius: '10px',
      position: 'absolute',
      background: '#fff',
      fontSize: 14,
      top: `${position.y}px`,
      left: `${position.x}px`,
      boxShadow: '0px 6px 8px 0px rgba(153, 155, 205, 0.36)',
      zIndex: 10000
    }}>
      {
        data && data.map((item, idx) => (
          <div key={item?.id || idx} className={s.item} onClick={() => item?.onClick && item.onClick(item)}>
            {item?.label}
          </div>
        ))
      }
    </Box>
  )
}

export default ContextMenu