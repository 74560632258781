import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/material";

import "./styles/styles.css";
import { PATHS } from '../../../constants';

const Equipments = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box className="equipment">
      <Box className="equipment-header">
        <Typography className="title-page">
          {t("title_page:equipment")}
        </Typography>
        <Button
          className="btn btn-outlined-primary"
          variant="outlined"
          onClick={() => navigate(PATHS.ADD_EQUIPMENT)}
        >
          {t("button:add_equipment")}
        </Button>
      </Box>
    </Box>
  );
};

export default Equipments;
