import React from "react";
import { useFieldArray } from "react-hook-form";
import { Box, Modal, Button, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import FormItem from "../FormItem";
import { formFields } from '../../pages/Plume/form-config'

export const ModalRoomAddressEdit = ({
  name,
  control,
  open,
  config,
  selectedname,
  Setselectedname,
  searchIntNum,
  setsearchIntNum,
  selectedplume,
  Setselectedplume,
  selectedintNum,
  SetselectedintNum,
  handleEdit,
  handleCancel,
  
}) => {
  const { t } = useTranslation();

  const { fields: plumes, append: appendPlume, remove: removePlume, update: updatePlume } = useFieldArray({
    control,
    name: formFields.plumes,
  });

  console.log(plumes);
  return (
    <Modal
      open={open}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      className="modal_address"
    >
      <Box className="modal_address-body modal-scroll" >
        <Box>
          <Box className="modal_address-header_right">
            <Typography className="modal_address-body-title">
              {t("title:enter_address_room")}
            </Typography>
            <IconButton onClick={handleCancel}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="modal_address-body-form">
            {config.map(({ id, name: subName, label, input, CustomFormItem, ...rest }) => (
              subName == "protectionObjectIntegrNumberAltId" ?
                <FormItem
                  key={id}
                  control={control}
                  name={`${name}.${subName}`}
                  searchIntNum={searchIntNum}
                  setsearchIntNum={setsearchIntNum}
                  value={selectedintNum}
                  onChange={((e) => SetselectedintNum(e.target.value))}
                  label={label}
                  input={input}
                  {...rest}
                />
                : CustomFormItem ? 
                <CustomFormItem 
                  plumes={plumes}
                  key={id}
                  appendPlume={appendPlume}
                  removePlume={removePlume}
                  control={control}
                  updatePlume={updatePlume}
                  name={`${name}.${subName}`}
                  plumeRoomAdressChange={subName == "name" ? Setselectedname : Setselectedplume}
                  value={subName == "name" ? selectedname : selectedplume}
                  label={label}
                  input={input}
                  {...rest}
                /> :
                <FormItem
                  key={id}
                  control={control}
                  name={`${name}.${subName}`}
                  plumeRoomAdressChange={subName == "name" ? Setselectedname : Setselectedplume}
                  value={subName == "name" ? selectedname : selectedplume}
                  label={label}
                  input={input}
                  {...rest}
                />
            ))}
          </Box>
        </Box>
        <Box className="modal_address-body-buttons">
          <Button
            className="btn btn-primary-fill"
            onClick={handleEdit()}
            disabled={selectedname?.length === 0 || !plumes.length || !!plumes.find(el => !el?.name)}
          >
            {t("button:save")}
          </Button>

        </Box>
      </Box>
    </Modal>
  );
};