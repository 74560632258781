import { createSlice } from "@reduxjs/toolkit";
import { addressDataApi } from "../../middleware/addressDataApi";

const initialState = {
  addressData: [],
  innOrgsData: [],
  bankOrgData:[],
};

export const addressDataSlice = createSlice({
  name: "addressData",
  initialState,
  reducers: {
    reducers: {
      changeAddressDataSelect(state, action) {
        state.addressData = action.payload;
      },
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      addressDataApi.endpoints.getAddressSelect.matchFulfilled,
      (state, { payload }) => {
        state.addressData = payload;
      }
    );
    builder.addMatcher(
      addressDataApi.endpoints.getOrgInn.matchFulfilled,
      (state, { payload }) => {
        state.innOrgsData = payload;
      }
    );
    builder.addMatcher(
      addressDataApi.endpoints.getBankBIC.matchFulfilled,
      (state, { payload }) => {
        state.bankOrgData = payload;
      }
    );
  },
});

export const addressDataActions = addressDataSlice.actions;
export const addressDataReducer = addressDataSlice.reducer;