import { t } from "i18next";

export const workingCorrectlyOptions = [
  {
    name: t("options_name:workingCorrectly"),
    value: "true",
  },
  {
    name: t("options_name:workingIncorrectly"),
    value: "false",
  }
];

export const correspondsOptions = [
  {
    name: t("options_name:corresponding"),
    value: "true",
  },
  {
    name: t("options_name:isNotCorresponding"),
    value: "false",
  }
];