import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery } from "../../../../middleware/usersApi";
import { ModalSelectColumnsServer } from "../../../components/Modals/ModalSelectColumnsServer";
import { useLazyGetGroupTaskQuery } from "../../../../middleware/groupJobsApi";
import { useAppSelector } from "../../../../hooks/redux";
import TableBasic from "../../../components/TableBasic";
import { useForm, useWatch } from "react-hook-form";
import EquipmentDialog from "../../EquipmentList/EquipDialog";
import TaskDialog from "../../TasksList/TaskDialog/TaskDialog";
import { Bar } from "react-chartjs-2";
import { getColumns } from "./columns";
import {
    formFields,
    allColumns,
    getConfigFormModalSelect,
} from "./form-config";
import settingsicon from "../../../../image/icons/settingsButtonWhite.png"
import { RatingCell } from "../../ObjectsList/Components";
const GroupTaskDetails = ({ open, setOpen, taskId, taskName }) => {
    const { t } = useTranslation();
    const { control, setValue, watch, handleSubmit, resetField } = useForm();
    const [PageNumber, changePageNumber] = useState(0);
    const [openSelectModal, setOpenSelectModal] = useState(false);
    const [openTaskDialog, setOpenTaskDialog] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [openEquipDialog, setopenEquipDialog] = useState(false);
    const [selectedEquipId, setSelectedEquipId] = useState(null);
    const [searchTaskName, setsearchTaskName] = useState("");
    const [searchresUser, setSearchresUser] = useState("");
    const [searchequip, setsearchequip] = useState("");
    const {
        grouptask,
    } = useAppSelector((state) => state.grouptasks);
    const params = useWatch({
        control,
        name: formFields.searchParams,
    });
    const {
        data: gridcolumns,
        error: errorgridColumns,
        isLoading: isLoadingerrorColumns,
    } = useGetUserGridSettingQuery({ gridSettingName: "GroupJobDetailsList" });

    const [getUserGrid, { error: errorgrid, refetch: refetchUserGrid /* isLoading */ }] =
        useLazyGetUserGridSettingQuery();

    const [
        getGroupTask,
        { error, isLoading: isLoadingGroupTask, isFetching, refetch: refetchgrouptask },
    ] = useLazyGetGroupTaskQuery();

    const columnsFromStorage = (() => {
        const storageData =
            gridcolumns?.columnSettings || [];
        return storageData?.reduce(
            (acc, { name, visible }) => ({ ...acc, [name]: visible }),
            {}
        );
    })();

    const columns = useMemo(
        () =>
            getColumns({
                control,
                columnsFromStorage,
                searchTaskName,
                setsearchTaskName,
                searchresUser,
                setSearchresUser,
                searchequip,
                setsearchequip,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [columnsFromStorage]
    );
    const handleOpenDetails = (id) => {
        console.log("taskId", id)
        setOpenTaskDialog(true);
        setSelectedTaskId(id);
    };
    const handleOpenEquipment = (id) => {
        setopenEquipDialog(true);
        setSelectedEquipId(id);
    };
    const getCellChartData = (completionProgress) => {

        const data = {
            labels: [""],
            datasets: [
                {
                    data: [completionProgress],
                    backgroundColor: "#4339F2",
                    hoverBackgroundColor: "#4339F2",
                },
            ],
        };
        return data;
    };
    const chartOptions = {
        indexAxis: "y",
        plugins: {
            legend: { display: false },
            datalabels: {
                display: true,
                color: "black",
                formatter: Math.round,
                anchor: "end",
                offset: 20,
                align: "start",
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
            },
            x: {
                display: false,
                barPercentage: 0.5,
                categoryPercentage: 0.8,
            },
        },
    };
    const rows = useMemo(() => {
        const jobs = grouptask?.jobs;
        return jobs?.data?.length
            ? jobs?.data.map(
                ({
                    taskId,
                    taskName,
                    completionProgress,
                    status,
                    deadlineStatus,
                    responsibleCustomerUser,
                    jobEquipments,
                }) => {
                    let equipment = {}
                    if (jobEquipments.length > 0)
                        equipment = { id: jobEquipments[0].id, name: `${jobEquipments[0].integrationNumber ? `${jobEquipments[0].integrationNumber},` : ""} ${jobEquipments[0].name}`, action: () => handleOpenEquipment(jobEquipments[0].id) }
                    console.log("equipment", equipment)
                    return {
                        taskId,
                        equipName: equipment,
                        taskname: {
                            name: taskName,
                            action: () => { handleOpenDetails(taskId) },
                        },
                        status,
                        deadlineStatus,
                        statistics: <div style={{ width: '230px' }}>{RatingCell(completionProgress)}</div>,
                        responsibleCustomerUser
                    
                    };
                }
            )
            : [];
        // eslint-disable-next-line
    }, [grouptask]);
    const recordsCount = useMemo(
        () => (grouptask && grouptask?.jobs) && grouptask?.jobs?.recordsCount,
        [grouptask]
    );
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        let filtersRes = {}
        let res = ""
        if (params) {
            Object.keys(params)?.forEach(key => {
                if (params[key] != "все" && params[key] != "")
                    if (key == "equipmentKindId") {
                        filtersRes[key] = JSON.parse(params[key]).id
                    } else if (key == "explotationExceeded" || key == "workingCorrectly") {

                        filtersRes[key] = params[key] == "true" ? true : false
                    } else {
                        filtersRes[key] = params[key]
                    }

            });
        }
        res = "&" + Object.keys(filtersRes)
            .map((key) => `${key}=${filtersRes[key]}`)
            .join("&")
        getGroupTask({
            filters: res,
            PageNumber: PageNumber,
            id: taskId,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PageNumber]);
    useEffect(() => {
        let filtersRes = {}
        let res = ""
        if (params) {
            Object.keys(params)?.forEach(key => {
                if (params[key] != "все" && params[key] != "")
                    if (key == "equipmentKindId") {
                        filtersRes[key] = JSON.parse(params[key]).id
                    } else if (key == "explotationExceeded" || key == "workingCorrectly") {

                        filtersRes[key] = params[key] == "true" ? true : false
                    } else {
                        filtersRes[key] = params[key]
                    }

            });
        }
        res = "&" + Object.keys(filtersRes)
            .map((key) => `${key}=${filtersRes[key]}`)
            .join("&")
        getGroupTask({
            filters: res,
            PageNumber: 0,
            id: taskId,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);
    const handleOpenSelectColumnsModal = () => {
        setOpenSelectModal(true);
    };
    useEffect(() => {
        setValue(
            `${formFields.searchParams}.${formFields.TaskName}`,
            searchTaskName
        );
    }, [searchTaskName, setValue]);
    useEffect(() => {
        setValue(
            `${formFields.searchParams}.${formFields.ExecutiveUserName}`,
            searchresUser
        );
    }, [searchresUser, setValue]);
    useEffect(() => {
        setValue(
            `${formFields.searchParams}.${formFields.EquipmentNumberAndName}`,
            searchequip
        );
    }, [searchequip, setValue]);
    console.log("grouptask", grouptask)
    return (
        <Dialog className="group_task_dialog" open={open} onClose={handleClose}>
            <Box>
                <Box className="modal_address-header_right">
                    <Typography className="modal_address-body-title">
                        {grouptask?.taskName || ""}
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>


            </Box>
            <Box sx={{ textAlign: "right", marginTop: "10px", marginBottom: "10px" }}>
                <Button
                    className="btn btn-primary-fill magazine_events-select_container-btn"
                    variant="outlined"
                    onClick={handleOpenSelectColumnsModal}

                    sx={{ height: "52px" }}
                >
                    <Box
                        component="img"
                        src={settingsicon}
                        sx={{ marginRight: "16px" }}
                    />
                    {t("button:customize_fields_to_display")}
                </Button>
            </Box>
            {openEquipDialog && (
                <EquipmentDialog
                    open={openEquipDialog}
                    setOpen={setopenEquipDialog}
                    equipId={selectedEquipId}
                    refetch={() => { }}

                />
            )}
            {openTaskDialog && (
                <TaskDialog
                    open={openTaskDialog}
                    setOpen={setOpenTaskDialog}
                    taskId={selectedTaskId}
                    refetch={() => getGroupTask({ id: taskId, PageNumber: PageNumber })}

                />
            )}
            <ModalSelectColumnsServer
                open={openSelectModal}
                setOpen={setOpenSelectModal}
                data={columnsFromStorage}
                refetch={() => getUserGrid({ gridSettingName: "GroupJobDetailsList" })}
                nameStorage={"GroupJobDetailsList"}
                config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
            />
            <TableBasic
                columns={columns}
                rows={rows}
                handleClickCell={() => { }}
                withSearchField={false}
                // isLoading={}
                pageNumber={PageNumber}
                changePageNumber={changePageNumber}
                count={recordsCount}
                minHeight={"calc(70vh - 170px)"}
                maxHeight={"calc(70vh - 170px)"}
                isNotBigTable={true}
            />
        </Dialog>
    );
};

export default GroupTaskDetails;
