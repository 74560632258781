import React from "react";
import { useFieldArray } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { formFields } from "./form-config";
import {
  IntegrationNumbersConfig,
  templateIntnumber,
} from "./form-config.jsx";

import FormItem from "../../../components/FormItem";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../components/icons/DeleteIcon.jsx";
import plusicon from "../../../../image/icons/plusBtnWhite.png"

const IntNumbersFields = ({ control, errors, rules, setValue }) => {
  const { t } = useTranslation();

  const {
    fields: intNumFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `buildingPassport.${formFields.protectionObjectIntegrNumbersAlts}`,
  });

  return (
    <Box sx={{ width: "130%" }}>
      <Typography
        sx={{
          marginBottom: "24px",
          fontWeight: "500",
          color: "var(--text-color)",
        }}
      >
        Интеграционные номера
      </Typography>

      {intNumFields.map((item, index) => (
        <Box
          key={item.id}
          sx={{
            display: "flex",
            gap: "24px",
            alignItems: "flex-end",
            mb: "24px",
            ".error-text": {
              whiteSpace: "normal",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "24px",
              width: "78%",
            }}
          >
            {IntegrationNumbersConfig.map(
              ({ name, label, input, type, ...rest }) => (
                <FormItem
                  key={`buildingPassport.${[formFields.protectionObjectIntegrNumbersAlts]}.${index}.${name}`}
                  control={control}
                  errors={errors}
                  name={`buildingPassport.${[formFields.protectionObjectIntegrNumbersAlts]}.${index}.${name}`}
                  label={label}
                  input={input}
                  rules={rules}
                  type={type}
                  setValue={setValue}
                  {...rest}
                />
              )
            )}
          </Box>

          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              className="btn btn-remove btn-basket-small"
              onClick={() => remove(index)}
            >
              <DeleteIcon />
              <p>{t("button:remove")}</p>
            </Button>
          </Box>
        </Box>
      ))}

      <Box sx={{ alignSelf: "center" }}>
        <Button
          className="btn btn-primary-fill"
          variant="outlined"
          onClick={() => append(templateIntnumber)}
        >
          <Box component="img" src={plusicon} sx={{ marginRight: "16px" }} />
          {t("button:add_number")}
        </Button>
      </Box>
    </Box>
  );
};

export default IntNumbersFields;
