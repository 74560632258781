import React from "react";
import { t } from "i18next";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

export const fields = {
  name: "name",
  actionField: "actionField",
};

const ButtonFieldForTest = ({ id, isInTest, action }) => {

  return (
    <Box className="btn-container">
      <Button
        className={`btn ${isInTest ? "btn-outlined-primary" : "btn-primary-fill"} btn_in_table`}
        onClick={action.bind(null, { id, isInTest: !isInTest })}
      >
        {t(`button:${isInTest ? "complete_test" : "put_an_object_on_test"}`)}
      </Button>
    </Box>
  );
};

export const columns = [
  {
    id: fields.name,
    label: t("tableHeader:list_objects"),
    numeric: false,
    enableSorting: false,
    size: 250,
  },
  {
    id: fields.actionField,
    numeric: false,
    enableSorting: false,
    size: 210,
    RowCell: ({ row }) => <ButtonFieldForTest { ...row?.actionField } />,
  },
];