import * as React from "react";
import { Dialog, IconButton, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

export const Dogovor2 = ({ open, onClose, objectId }) => {
  return (
    <Dialog className="object_dialog" open={open} onClose={onClose}>
      <IconButton className="close_icon" onClick={onClose}>
        <CloseIcon />
      </IconButton>

      <Typography>
        <div>
          <h2
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ (условия обработки информации о
            пользователях)
          </h2>

          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            Сфера и предмет регулирования
          </h3>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            Настоящая Политика конфиденциальности (условия обработки информации
            о пользователях) ООО «ВДПО ПРО» (далее – Условия) действует в
            отношении информации и данных, которую Оператор может получить о
            Пользователе. Условия применимы только к Сервисам Оператора.
            Оператор не контролирует и не несет ответственность за сайты /
            сервисы третьих лиц, на которые Пользователь может перейти по
            ссылкам, доступным из Сервисов Оператора, в том числе за
            обрабатываемую третьими лицами информацию о Пользователе.
            Использование Сервисов Оператора означает согласие Пользователя с
            Условиями и указанными в них порядком обработки его персональной
            информации; в случае несогласия с этими условиями Пользователь
            должен воздержаться от использования Сервисов Оператора.
          </p>

          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            Иными документами Оператора
          </h3>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            Иными документами Оператора, определяющими условия использования
            отдельных его Сервисов или отдельных функциональных возможностей
            (надстроек), предоставляемых такими Сервисами, включая совместные
            партнерские проекты и сервисы Оператора, могут быть определены
            дополнительные условия, состав, порядок сбора, хранения, защиты,
            обработки и распространения информации о Пользователях.
          </p>

          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            1. Термины и определения, применяемые в Условиях
          </h3>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            В целях настоящих Условий нижеприведенные термины используются в
            следующем значении:
          </p>
          <ul style={{ fontSize: "14px", marginLeft: "20px" }}>
            <li>
              Оператор – ООО «ВДПО ПРО» (ИНН, ОГРН), адрес местонахождения:
              почтовый адрес: Юридический адрес: 123423, г. Москва, пр-т Маршала
              Жукова, д. 39, к. 1 Фактический адрес: 129085, г. Москва, Звёздный
              бульвар, д. 7, Для корреспонденции: 123423, г. Москва, а/я 2,
              E-mail: vdpopro2023@mail.ru
            </li>
            <li>
              Пользователь - физическое лицо, пользователь сети Интернет,
              использующий Сервисы Оператора, а также иное физическое лицо, в
              интересах которого действует этот пользователь. Пользователь может
              являться представителем любого юридического лица, индивидуального
              предпринимателя, собственника или пользователя объекта защиты, и
              иных других физических и юридических лиц, государственных органов,
              но всегда является физическим лицом.
            </li>
            <li>
              Сервис(ы) Оператора – любые сайты Оператора в сети «Интернет»,
              программы для ЭВМ (мобильные приложения), функционирующие под
              управлением Android, iOS, Windows, Linux и других операционных
              систем, а также непосредственно продукты и (или) услуги,
              предоставляемые Оператором при помощи Сервисов.
            </li>
            <li>
              «Объект защиты» - продукция, в том числе имущество граждан или
              юридических лиц, государственное или муниципальное имущество
              (включая объекты, расположенные на территориях поселений, а также
              здания, сооружения, транспортные средства, технологические
              установки, оборудование, агрегаты, изделия и иное имущество), к
              которой установлены или должны быть установлены требования
              пожарной безопасности для предотвращения пожара и защиты людей при
              пожаре.
            </li>
            <li>
              Платформа «ВДПО ПРО» - Интернет-ресурс глобальной цифровой
              автоматизации противопожарной безопасности на объектах защиты.
            </li>
            <li>
              Оцифровщик – уполномоченный специалист, владеющий знаниями и
              правилами расположения электронных приборов, элементов, датчиков
              по ППБ на объекте защиты, осуществляющий оцифровку объекта защиты.
            </li>
            <li>
              Правила пожарной безопасности (ППБ) - комплекс положений,
              устанавливающих порядок соблюдения требований и норм пожарной
              безопасности при строительстве и эксплуатации объекта.
            </li>
            <li>
              Пожарная часть (ПЧ) – основное подразделение пожарной охраны,
              создаваемое в установленном законом порядке для профилактики и
              тушения пожаров на территории населённых пунктов и объектах.
            </li>
            <li>
              Агрегатор – компания, оказывающая Оператору услуги биллинга -
              системы контроля оплаты услуг, в т.ч. расчета тарификации,
              выставления счетов на оплату.
            </li>
            <li>
              Учетная запись или аккаунт – хранимая в компьютерной системе
              совокупность данных о пользователе, необходимая для его
              опознавания (аутентификации) и предоставления доступа к его личным
              данным и настройкам.
            </li>
            <li>
              Администратор - лицо, осуществляющее техническую поддержку и
              информирование пользователей на платформе «ВДПО ПРО».
            </li>
            <li>
              Электронные журналы блокчейн - современный метод защиты записи
              данных криптографическим способом, используемая в сфере
              программирования.
            </li>
          </ul>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            Платформа «ВДПО ПРО» базируется на формировании электронных журналов
            по эксплуатации систем противопожарной защиты в соответствии с
            Постановлением Правительства РФ от 16 сентября 2020 года N 1479 «Об
            утверждении Правил противопожарного режима в Российской Федерации».
          </p>
          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            2. Информация Пользователей, которую может получать и обрабатывать
            Оператор.
          </h3>
          <h4
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            2.1. В соответствии с настоящими Условиями к информации о
            Пользователях Сервисов Оператора относятся:
          </h4>
          <p style={{ fontSize: "14px", margin: "10px 0 10px 20px" }}>
            2.1.1. Информация, которую Пользователь предоставляет о себе
            самостоятельно или через третье лицо:
          </p>
          <ul style={{ fontSize: "14px", marginLeft: "40px" }}>
            <li>при регистрации в любом из Сервисов Оператора,</li>
            <li>в процессе использования Сервисов Оператора,</li>
            <li>при коммуникации с Оператором,</li>
            <li>
              при использовании сервисов (интерфейсов) третьих лиц, в том числе,
              в рамках протоколов OpenID и OAuth.
            </li>
          </ul>
          <p style={{ fontSize: "14px", margin: "10px 0 10px 20px" }}>
            Использование Пользователем определенных Сервисов Оператора или их
            отдельной функциональности возможно только при условии
            предоставления необходимых данных.
          </p>

          <h4
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            2.1.2. Стандартные данные, автоматически получаемые http-сервером
            при доступе Пользователя к Сервису Оператора:
          </h4>
          <p style={{ fontSize: "14px", margin: "10px 0 10px 20px" }}>
            (ip-адрес хоста, адрес запрошенного ресурса, время, тип и информация
            о программе просмотра, пославшей запрос ресурса, вид операционной
            системы пользователя, адрес интернет-страницы, с которой был
            осуществлен переход на запрошенный ресурс, заголовок, содержащий
            идентификатор сессии для авторизованного пользователя)
          </p>

          <h4
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            2.1.3. Информация, автоматически получаемая при доступе к Сервисам
            Оператора и хранящаяся на стороне браузера:
          </h4>
          <p style={{ fontSize: "14px", margin: "10px 0 10px 20px" }}>
            в том числе, но не ограничиваясь информацией, содержащейся в
            закладках (cookies).
          </p>

          <h4
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            2.1.4. Информация о геолокации:
          </h4>
          <p style={{ fontSize: "14px", margin: "10px 0 10px 20px" }}>
            в случае реализации соответствующих технологий в Сервисе Оператора и
            установлении соответствующих настроек Пользователем в браузере и/или
            при использовании мобильного приложения (программы для ЭВМ).
          </p>

          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            2.2. За исключением случаев идентификации Пользователей по
            основаниям и в порядке, предусмотренным действующим
            законодательством РФ и применимыми соглашениями Оператора с
            Пользователем, Оператор не проверяет достоверность персональной
            информации, предоставляемой Пользователем. Однако Оператор исходит
            из того, что Пользователь предоставляет достоверную и достаточную
            персональную информацию и поддерживает эту информацию в актуальном
            состоянии.
          </h3>

          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            2.3. Вся собранная, хранящаяся и обработанная Оператором информация
            о Пользователях считается информацией ограниченного доступа, если
            иное не установлено законодательством Российской Федерации или
            настоящими Условиями.
          </h3>

          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            3. Применимое законодательство
          </h3>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            3.1. К настоящим Условиям и отношениям между Пользователям и
            Оператором, возникающим в связи с применением Условий, подлежит
            применению законодательство Российской Федерации и нормативные акты,
            регулирующие отношения в сфере обеспечения безопасности персональных
            данных.
          </p>
          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            4. Условия обращения к Сервисам Оператора
          </h3>

          <h4
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            4.1. Оператор исходит из того, что Пользователь, инициирующий
            обращение к Сервисам Оператора:
          </h4>
          <p style={{ fontSize: "14px", margin: "10px 0 10px 20px" }}>
            4.1.1. Сознательно использует Сервисы Оператора от своего имени и
            достоверно указывает информацию о себе в объеме и в случаях, когда
            это требуется при регистрации, доступе и использовании Сервисов
            Оператора;
          </p>
          <p style={{ fontSize: "14px", margin: "10px 0 10px 20px" }}>
            4.1.2. Сознательно определил и контролирует настройки используемого
            им программного обеспечения в соответствии со своими предпочтениями
            относительно защиты информации, хранящейся на стороне браузера,
            персональных данных, информации о собственном аппаратно-программном
            обеспечении и интернет-соединении;
          </p>
          <p style={{ fontSize: "14px", margin: "10px 0 10px 20px" }}>
            4.1.3. Имеет возможность ознакомиться с правилами и протоколами
            информационного обмена, закрепленными в открытых стандартах
            Интернета;
          </p>
          <p style={{ fontSize: "14px", margin: "10px 0 10px 20px" }}>
            4.1.4. Ознакомился и имеет возможность в любой момент ознакомиться с
            действующими Условиями путем перехода по гипертекстовой ссылке,
            размещенной в Сервисе Оператора, с условиями использования
            соответствующих сервисов Оператора.
          </p>
          <p style={{ fontSize: "14px", margin: "10px 0 10px 20px" }}>
            4.1.5. Заполняя размещенную на Сервисе Оператора регистрационную
            форму и предоставляя свои персональные данные Оператору,
            пользователь подтверждает, что он принимает условия предоставления
            таких Сервисов, а также настоящие Условия и дает согласие на
            обработку своих персональных данных Оператору в соответствии с
            условиями предоставления таких Сервисов и настоящими Условиями.
          </p>
          <p style={{ fontSize: "14px", margin: "10px 0 10px 20px" }}>
            4.1.6. Предоставляя данные третьих лиц, необходимые для
            использования Сервиса Оператора, пользователь подтверждает получение
            им согласия этих лиц на обработку их персональных данных или наличие
            у Пользователя полномочий на выражение согласия от имени таких лиц.
          </p>

          <h4
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            4.2. При регистрации и доступе к Сервисам Оператора пользователю
            запрещается указывать о себе недостоверную и/или неполную
            информацию. Пользователь самостоятельно несет риски наступления
            любых неблагоприятных последствий в случаях предоставления чужих и
            (или) недостоверных персональных данных.
          </h4>

          <h4
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            4.3. Согласие, предоставляемое Пользователем при использовании
            Сервисов Оператора и сообщении своих персональных данных и (или)
            персональных данных третьих лиц Оператору, распространяется на любую
            обработку (любым способом), если это преследует цель обеспечения
            возможности использования Сервисов Оператора согласно их назначению,
            в т.ч. для целей поддержания работоспособности Сервисов Оператора,
            анализа информации об их использовании, осуществления действий
            направленных на подключение пользователя к услугам Оператора,
            включая заключение от имени Оператора договора о предоставлении
            услуг Оператора, а также совершения Оператором иных действий,
            предусмотренных Условиями.
          </h4>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            4.4. Оператор не отвечает за то, что пользователь действительно
            является тем лицом, от имени которого осуществлена регистрация и/или
            использование Сервиса Оператора, и не несет ответственности за
            возможный ущерб, причиненный другим пользователям или иным лицам в
            случае, если Пользователь не является таким лицом.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            4.5. Если иное не установлено Условиями Оператор не принимает на
            себя никаких обязательств по проверке достоверности персональных
            данных, указанных пользователем, и не несет ответственности в
            случае, если пользователь предоставит больший объем данных, чем это
            предусмотрено размещенной на Сервисе регистрационной формой и целями
            обработки персональных данных. Предоставляя персональные данные в
            объеме большем, чем это предусмотрено регистрационной формой и
            целями обработки персональных данных, пользователь выражает свое
            согласие на обработку таких персональных данных Оператором.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            4.6. Пользователь осознает и подтверждает, что при регистрации и/или
            использовании Сервисов Оператора с использованием аккаунтов
            Пользователя в социальных сетях Оператор может получить больший
            объем персональных данных по сравнению с объемом, который
            предусмотрен размещенной на Сервисе Оператора регистрационной
            формой, и что в этом случае на обработку таких данных Оператором
            дополнительное согласие пользователя не требуется.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            4.7. Предусматривается смешанная обработка персональных данных
            пользователей и иных лиц, персональные данные которых указаны при
            регистрации и использовании Сервиса Оператора, то есть как обработка
            без использования средств автоматизации, так и автоматизированная
            обработка с передачей полученной информации с использованием сети
            Интернет.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            4.8. Настоящее согласие предоставляется на весь период использования
            Сервиса Оператора согласно его назначению, а также 3 (трех) лет
            после прекращения такого использования, если иное не предусмотрено
            законодательством РФ.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            4.9. Пользователь осведомлен, что может отозвать согласие на
            обработку персональных данных, направив Оператору заявление в
            бумажной или в электронной форме, в том числе с использованием
            своего аккаунта (учетной записи) в Сервисе Оператора с указанием в
            таком заявлении данных, предусмотренных Федеральным законом от
            27.07.2006 № 152-ФЗ «О персональных данных».
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            4.10. В случае получения отзыва согласия пользователя на обработку
            персональных данных в бумажной форме Оператор в целях идентификации
            субъекта персональных данных вправе запросить у такого лица
            дополнительные сведения, предоставленные субъектом персональных
            данных при регистрации в Сервисе, либо попросить такое лицо
            направить электронный запрос на отзыв согласия на обработку
            персональных данных через соответствующий аккаунт (учетную запись)
            субъекта персональных данных в Сервисе.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            В случае невыполнения обратившимся лицом таких дополнительных
            действий Оператор вправе отказать такому лицу в отзыве согласия на
            обработку персональных данных в целях защиты прав третьих лиц.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            В случае отзыва ранее выданного согласия на обработку персональных
            данных пользователем или третьими лицами, персональные данные
            которых были получены Оператором от пользователя, указанные субъекты
            не смогут воспользоваться Сервисом Оператора и предоставляемыми с
            его помощью услугами и возможностями.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            4.11. Оператор вправе продолжить обработку персональных данных
            пользователя после отзыва им своего согласия в случаях,
            предусмотренных законодательством РФ. При этом пользователь
            уведомлен, что продолжает нести установленную законодательством РФ
            ответственность в случае указания некорректных контактных данных, а
            также данных третьих лиц без получения соответствующих полномочий от
            них.
          </p>
          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            5. Сбор и обработка информации о Пользователях
          </h3>

          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            5.1. Оператор обрабатывает информацию Пользователей в соответствии с
            настоящими Условиями, условиями конкретных Сервисов Оператора и
            внутренними регламентами.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            5.2. Оператор осуществляет сбор и обработку только той информации,
            которая необходима для предоставления Сервисов Оператора, исполнения
            соглашений с Пользователем, за исключением случаев, когда
            законодательством предусмотрено обязательное получение и хранение
            определенной информации. Хранение информации осуществляется не
            дольше, чем этого требуют цели обработки, если только срок хранения
            такой информации не установлен законодательством РФ, соглашением об
            использовании Пользователем Сервисов Оператора.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            5.3. Оператор может использовать информацию Пользователя в следующих
            целях:
          </p>
          <ul
            style={{ fontSize: "14px", margin: "10px 0", paddingLeft: "20px" }}
          >
            <li>
              5.3.1. Заключения и исполнения соглашений Пользователя с
              Оператором, в том числе в целях проведения идентификации
              Пользователя, создания учетных записей Пользователя, использования
              Пользователем иных Сервисов Оператора;
            </li>
            <li>
              5.3.2. Осуществления связи с Пользователем, в том числе
              направления уведомлений, запросов и информации, касающихся
              использования Сервисов Оператора, а также обработки запросов и
              заявок от Пользователя;
            </li>
            <li>
              5.3.3. Улучшения качества Сервисов Оператора, удобства их
              использования, разработки новых Сервисов Оператора, предложения
              Пользователю персонализированных Сервисов Оператора;
            </li>
            <li>
              5.3.4. Проведения статистических и иных исследований на основе
              обезличенных данных;
            </li>
            <li>
              5.3.5. Проведения маркетинговых акций, в том числе в целях
              распространения Пользователю предложений об участии в акции и
              получения предусмотренных акцией поощрений; распространения
              Пользователю рекламно-информационных материалов как в отношении
              товаров (работ, услуг) Оператора, так и в отношении товаров
              (работ, услуг) третьих лиц по сетям электросвязи, в том числе
              посредством использования телефонной, факсимильной, подвижной
              радиотелефонной связи, или путем прямых контактов; таргетирования
              рекламных материалов и иной информации, доводимой до сведения
              Пользователя;
            </li>
            <li>
              5.3.6. Выявления и (или) предотвращения условий, способствующих
              использованию Сервисов Оператора в противоправных или запрещенных
              правилами соответствующих Сервисов Оператора целях;
            </li>
            <li>
              5.3.7. Повышения лояльности Пользователей к Сервисам Оператора;
            </li>
            <li>
              5.3.8. Выявления и устранения ошибок в программном обеспечении
              Оператора.
            </li>
          </ul>
          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            6. Защита информации
          </h3>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            6.1. Оператор принимает все разумные меры для защиты информации о
            пользователях от уничтожения, искажения или разглашения.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            6.2. Оператор обеспечивает конфиденциальность соответствующей
            информации в соответствии с действующим законодательством Российской
            Федерации и настоящими Условиями.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            6.3. Платформа ВДПО ПРО использует блокчейн-журналы для
            автоматического сохранения данных. Это обеспечивает высокий уровень
            защиты конфиденциальной информации при хранении персональных данных
            и электронного документооборота. Данные всегда доступны владельцу и
            государственным противопожарным службам.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            6.4. Платформа ВДПО ПРО обслуживается специалистами 24/7,
            производится обновление системы контента и технической части в
            соответствии с требованиями и регламентами, включая правила ППБ.
          </p>
          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            7. Доступ к информации о пользователях
          </h3>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            7.1. Помимо Оператора, доступ к информации о пользователях может
            быть предоставлен следующим лицам при соблюдении требований
            законодательства Российской Федерации:
          </p>
          <ul
            style={{ fontSize: "14px", margin: "10px 0", paddingLeft: "20px" }}
          >
            <li>
              7.1.1. Лица, права и обязанности которых по доступу к информации
              установлены федеральными законами Российской Федерации и при
              соответствующих законных запросах;
            </li>
            <li>
              7.1.2. Пользователи Сервисов Оператора, имеющие доступ к
              информации, идентифицирующей их личность (персональные данные
              пользователей), с использованием указанного ими при регистрации
              пароля и логина;
            </li>
            <li>
              7.1.3. Партнеры Оператора в отношении совместных партнерских
              проектов и сервисов Оператора в соответствии с соответствующими
              документами;
            </li>
            <li>
              7.1.4. Пользователи иных проектов и сервисов Оператора в
              соответствии с соответствующими документами Оператора;
            </li>
            <li>
              7.1.5. Лица и организации, осуществляющие техническую поддержку
              Сервисов Оператора в объеме, необходимом для осуществления такой
              технической поддержки, с соблюдением конфиденциальности информации
              о Пользователях.
            </li>
          </ul>
          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            8. Права Пользователя в отношении информации
          </h3>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            8.1. Оператор гарантирует соблюдение следующих прав Пользователя
            Сервисов Оператора:
          </p>
          <ul
            style={{ fontSize: "14px", margin: "10px 0", paddingLeft: "20px" }}
          >
            <li>
              8.1.1. Право на получение информации о том, осуществляет ли
              Оператор обработку персональных данных;
            </li>
            <li>
              8.1.2. Право на удаление обрабатываемых персональных данных;
            </li>
            <li>
              8.1.3. Право на редактирование, уточнение или исправление
              обрабатываемых персональных данных в соответствии с требованиями
              настоящих Условий. Удаление персональных данных происходит в
              течение 3 (трех) рабочих дней после получения соответствующего
              запроса Пользователя и может привести к невозможности
              использования соответствующих Сервисов Оператора.
            </li>
            <li>
              8.1.4. Оператор вправе удалить информацию о Пользователе в случае
              нарушения норм документов Оператора, включая условия использования
              отдельных Сервисов Оператора.
            </li>
          </ul>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            8.2. Пользователь имеет право распространять свои персональные
            данные законными способами. Оператор не несет ответственности за
            действия третьих лиц, получивших доступ к информации о Пользователях
            в результате такого распространения или неправомерного доступа к
            защищенной информации о Пользователях.
          </p>
          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            9. Ограничение использования информации Пользователей
          </h3>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            9.1. В случае, если Пользователь узнал логин и пароль другого
            Пользователя, а также получил доступ к другой конфиденциальной
            информации о нем, он обязан уведомить об этом Оператора и не
            использовать полученную информацию.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            9.2. Оператор использует информацию о Пользователях для анализа их
            интересов и предпочтений, адаптации Сервисов Оператора на основе
            результатов такого анализа, а также для целей рекламы на Сервисах
            Оператора, ориентированной на целевую аудиторию.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            Информация из профилей Пользователей не используется Оператором для
            рассылки любой информации пользователям, за исключением случаев,
            предусмотренных правилами (регламентами) использования
            соответствующих Сервисов Оператора или по явному запросу
            пользователей.
          </p>
          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            10. Использование информации, хранящейся на стороне браузера
          </h3>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            10.1. Оператор использует информацию, хранящуюся на стороне
            браузера, в соответствии с целями, указанными в настоящих Условиях,
            для следующих целей:
          </p>
          <ul
            style={{ fontSize: "14px", margin: "10px 0", paddingLeft: "20px" }}
          >
            <li>
              10.1.1. Поддержки функционирования ресурсов, требующих
              использования информации, хранящейся на стороне браузера;
            </li>
            <li>10.1.2. Показа рекламы для целевой аудитории;</li>
            <li>
              10.1.3. Измерения размеров аудиторий сайтов, их объединений и
              пересечений;
            </li>
            <li>
              10.1.4. Определения статистических предпочтений пользователей при
              доступе к различным Сервисам Оператора;
            </li>
            <li>
              10.1.5. Использования статистических данных о посещаемости сайта и
              социометрических данных о Пользователях Оператора в
              исследовательских целях для определения корреляции.
            </li>
          </ul>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            10.2. Пользователь может запретить использование информации,
            хранящейся в браузере на его компьютере (мобильном устройстве) путем
            настройки программного обеспечения. Однако это может привести к
            частичной или полной потере функциональности Сервисов Оператора.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            10.3. Оператор вправе предоставлять соответствующую информацию
            лицам, указанным в пункте 7 настоящих Условий.
          </p>
          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            11. Доступ к сервисам и интернет-ресурсам третьих лиц
          </h3>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            11.1. При доступе Пользователя к Сервисам Оператора может
            происходить обращение к сервисам и интернет-ресурсам третьих лиц и
            загрузка с них программного кода и/или графических объектов, включая
            невидимые при отображении страниц браузером, которые используются в
            рекламных целях и для сбора статистики.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            11.2. Владельцы интернет-ресурсов имеют возможность собирать
            информацию о Пользователях и определять условия ее использования.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            11.3. Пользователь может заблокировать запросы на графические
            изображения, размещенные на серверах третьих лиц, путем настройки
            программного обеспечения. Однако это может привести к потере
            читаемости и возникновению ошибок при отображении ресурсов.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            11.4. При переходе Пользователей со страниц Сервисов Оператора на
            страницы сервисов и интернет-ресурсов третьих лиц, Пользователи сами
            определяют условия использования информации о них в соответствии с
            правилами, установленными владельцами соответствующих сервисов и
            интернет-ресурсов. Владельцы имеют технологическую возможность
            доступа к информации, хранящейся на стороне браузера, и несут
            ответственность за соблюдение прав Пользователей в связи с
            использованием такой информации.
          </p>

          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            12. Обращения пользователей
          </h3>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            12.1. Оператор принимает обращения, связанные с настоящими
            Условиями, включая запросы Пользователей относительно использования
            их персональных данных, с помощью формы обратной связи.
          </p>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            12.2. Срок ответа на поступившие обращения составляет 30 (тридцать)
            рабочих дней с даты получения соответствующих запросов. Анонимные
            обращения не рассматриваются. Вся переписка, направленная
            Пользователями Оператору (в письменной или электронной форме),
            рассматривается как конфиденциальная информация и может быть
            опубликована только с письменного согласия пользователя. Адреса,
            персональные данные и другая информация о пользователе, отправившем
            такую переписку, не могут быть использованы без его специального
            согласия, за исключением ответа на запрос, указанный в обращении.
          </p>
          <h3
            style={{ fontSize: "16px", fontWeight: "bold", margin: "20px 0" }}
          >
            13. Доступ к Условиям
          </h3>
          <p
            style={{ fontSize: "14px", margin: "10px 0", textAlign: "justify" }}
          >
            13.1. Настоящие Условия являются открытым и общедоступным
            документом. Действующая редакция доступна в Интернете по следующей
            Интернет-ссылке: <a href="https://vdpo.ru">https://vdpo.ru</a>.
          </p>
        </div>
      </Typography>
    </Dialog>
  );
};
