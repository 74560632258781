import React from "react";

const SettingsIcon = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0C6.61503 0 3.87097 2.48973 3.87097 5.56098C3.87097 8.63222 6.61503 11.122 10 11.122C13.385 11.122 16.129 8.63222 16.129 5.56098C16.129 2.48973 13.385 0 10 0ZM5.80645 5.56098C5.80645 3.4596 7.68397 1.7561 10 1.7561C12.316 1.7561 14.1935 3.4596 14.1935 5.56098C14.1935 7.66235 12.316 9.36585 10 9.36585C7.68397 9.36585 5.80645 7.66235 5.80645 5.56098Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.12903 12.878C2.74406 12.878 0 15.3678 0 18.439C0 21.5103 2.74406 24 6.12903 24H13.871C17.2559 24 20 21.5103 20 18.439C20 15.3678 17.2559 12.878 13.871 12.878H6.12903ZM1.93548 18.439C1.93548 16.3376 3.813 14.6341 6.12903 14.6341H13.871C16.187 14.6341 18.0645 16.3376 18.0645 18.439C18.0645 20.5404 16.187 22.2439 13.871 22.2439H6.12903C3.813 22.2439 1.93548 20.5404 1.93548 18.439Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SettingsIcon;
