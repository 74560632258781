// libs imports
import React from "react";

import InputText from "../../../components/InputText";
import SelectSimple from "../../../components/SelectSimple";
import DatePickerField from "../../../components/DatePickerField";
import { SNMETypeOptions } from "./constants";
import { SelectSimpleObjectNameAndAddress, SelectMultipleComposedCommission } from "./Components";
import { AfaActWorkingCorrectlyCheckForm } from "./AfaActWorkingCorrectlyCheckForm";

export const formFields = {
  comission: "comission",
  protectionObjectId: "protectionObjectId",
  workingProject: "workingProject",
  warnAndEvacControlSystemType: "warnAndEvacControlSystemType",
  cableBrand: "cableBrand",
  warnAndEvacControlSystemManufacturer: "warnAndEvacControlSystemManufacturer",
  installationDate: "installationDate",
  organizationType: "organizationType",
  organizationName: "organizationName",
  //ogrnNumber: "ogrnNumber",
  notifAndEvacControlSysType: "notifAndEvacControlSysType",
  notifAndEvacControlSysInstallationDate: "notifAndEvacControlSysInstallationDate",
  objectNameAndAddress: "objectNameAndAddress",
  systemProject: "systemProject",
  AFAType: "AFAType",
  AFAInstallationDate: "AFAInstallationDate",
  AFAManufacturer: "AFAManufacturer",
  cableMark: "cableMark",
  installationOrganization: "installationOrganization",
  afaActWorkingCorrectlyCheck: "afaActWorkingCorrectlyCheck",
  performanceTests: "performanceTests",
};

export const createSnmeActFormConfig = [
  {
    id: 1,
    label: "label:afa_act_header_accordion",
    items: [
      {
        name: formFields.comission,
        label: "label:composedCommission",
        type: "select-multi",
        rules: {
          required: true,
          validate: (value, formValues) => value?.length >= 3,
        },

        input: (props) => <SelectMultipleComposedCommission {...props} />,
      },
      {
        name: formFields.protectionObjectId,
        label: "label:objectNameAndAddress",
        type: "select-text",
        rules: { required: true },
        input: (props) => <SelectSimpleObjectNameAndAddress {...props} />,
      },
      {
        name: formFields.workingProject,
        label: "label:systemProject",
        type: "text",
        rules: {},
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.notifAndEvacControlSysType,
        label: "label:SNMEType",
        type: "select-text",
        flexBasis: "25%",
        options: SNMETypeOptions,
        input: (props) => <SelectSimple {...props} />,
      },
      {
        name: formFields.cableBrand,
        label: "label:cableMark",
        type: "text",
        flexBasis: "74%",
        rules: {},
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.warnAndEvacControlSystemManufacturer,
        label: "label:SNMEManufacturer",
        type: "text",
        flexBasis: "74%",
        rules: {},
        input: (props) => <InputText {...props} />,
      },
      {
        name: formFields.notifAndEvacControlSysInstallationDate,
        label: "label:SNMEInstallationDate",
        type: "date",
        sx: { padding: "2px 0 0  0 " },
        flexBasis: "25%",
        input: (props) => <DatePickerField {...props} />,
      },
      {
        name: formFields.organizationName,
        label: "label:installationOrganization",
        type: "text",

        input: (props) => <InputText {...props} />,
      },
      /*{
        name: formFields.ogrnNumber,
        label: "label:ogrnNumber",
        type: "number",
        flexBasis: "25%",
        rules: {
          required: false,
          maxLength: (v) => v?.length <= 13,
          validate: (value, formValues) => value?.length === 13 || !value,
        },
        input: (props) => <InputText {...props} />,
      },*/
    ],
  },
  {
    id: 2,
    label: "label:snme_act_working_сorrectly_check_accordion",
    items: [
      {
        id: 5,
        name: formFields.performanceTests,
        CustomFormItem: AfaActWorkingCorrectlyCheckForm,
      },
    ],
  },
];
