import { t } from "i18next";
import React from "react";
import { formFields } from "./formFields";
import CustomFormItem from "../CustomFormItem";
import { Box, IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import InputTextarea from "../../../../../components/InputTextarea";
import InputText from "../../../../../components/InputText";
import EquipColumn from "../EquipColumn";
import {getMeasuringEquipmentFormConfig} from "./measuringEquipment";
import EquipColumnExist from "../EquipColumnExist";
import {getEquipmentFormConfig} from "./equipments"
const name = "automaticSwitching";
export const equipmentsFormFields = {
  ppyNumber: "ppyNumber",
  broNumber: "broNumber",
  bbpNumber: "bbpNumber",
};

export const equipmentsConfig = [
  {
    name: equipmentsFormFields.ppyNumber,
    type: "text",
    label: "Номер ППУ",
    rules: {},
    disabled: false,
  
    input: (props) => <InputText {...props} />,
  },
  {
    name: equipmentsFormFields.bbpNumber,
    type: "text",
    label: "Номер БРО",
    rules: {},
    disabled: false,

    input: (props) => <InputText {...props} />,
  },
  {
    name: equipmentsFormFields.broNumber,
    type: "text",
    label: "Номер ББП",
    rules: {},
    disabled: false,
  
    input: (props) => <InputText {...props} />,
  },
];

export const smokeDetectorsFormConfig = {

  name,
  items: [
    {
      name: formFields.number,
      type: "number",
      rules: {},
      disabled: true,
      value: 3,
      sx: {
        "& .MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
        },
      },
      input: (props) => <InputText {...props} />,
    },
    {
      name: formFields.testName,
      type: "text",
      rules: {},
      disabled: true,
      variant:"standard",
      rows:11,
    InputProps:{
      disableUnderline: true
    },
      value: t(`label:testNameItems:${name}`),
      sx: {
        "& .MuiOutlinedInput-root": {
          width: "100%",
          height: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
        },
      },
      input: (props) => (
        <Box sx={{ display: "flex" }}>
          <InputTextarea {...props} />
          <Tooltip title={t(`label:AFADocumentTooltips:${name}`)}>
            <IconButton className="icon-btn">
              <InfoIcon sx={{ color: "var(--main)" }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    {
      name: formFields.equipments,
      CustomFormItem: (props) => (
        !props.isActExist ?
          <EquipColumn
            formConfig={getEquipmentFormConfig(name)}
            CircuitName={name}
            indexCirc={1}
            {...props}
          />
          :
          <EquipColumnExist
            formConfig={getEquipmentFormConfig(name)}
            CircuitName={name}
            indexCirc={1}
            {...props}
          />
      )
    },
    {
      name: formFields.measuringEquipment,
      CustomFormItem: (props) => (
        <CustomFormItem
          value= {t("label:doesNotExist")}
          disabled={true}
          label={""}
          formConfig={getMeasuringEquipmentFormConfig(name)}
          {...props}
        />
      )
    },
    
  ],
};
