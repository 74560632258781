import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";


const TableWithCharts = ({ employeesStatistics }) => {
  const { t } = useTranslation();

  const employees = employeesStatistics?.length
    ? employeesStatistics?.map(
        ({
          name,
          completedInTimeJobsAmount,
          overduedJobsAmount,
          notCompletedJobsAmount,
          completedNotInTimeJobsAmount,
        }) => ({
          fullName: name || t("text_empty_page:no_data"),
          tasksCompletedOnTime: completedInTimeJobsAmount,
          tasksOverdue: overduedJobsAmount,
          tasksInProgress: notCompletedJobsAmount,
          tasksCompletedLate: completedNotInTimeJobsAmount,
        })
      )
    : [];

  const getCellChartData = (value, second) => {

    const data = {
      labels: [""],
      datasets: [
        {
          data: [value, second],
          backgroundColor: "#A4A8FC",
          hoverBackgroundColor: "#A4A8FC",
        },
      ],
    };
    return data;
  };

  const chartOptions = {
    indexAxis: "y",
    plugins: {
      legend: { display: false },
      datalabels: {
        display: true,
        color: "black",
        formatter: Math.round,
        anchor: "end",
        offset: 20,
        align: "start",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        display: false,
        barPercentage: 0.5,
        categoryPercentage: 0.8,
      },
    },
  };

  const getMaxValue = (employees, property) => {
    let max = 0;
    employees.forEach((employee) => {
      if (employee[property] > max) {
        max = employee[property];
      }
    });
    return max;
  };

  const maxValues = {
    tasksCompletedOnTime: getMaxValue(employees, "tasksCompletedOnTime"),
    tasksOverdue: getMaxValue(employees, "tasksOverdue"),
    tasksInProgress: getMaxValue(employees, "tasksInProgress"),
    tasksCompletedLate: getMaxValue(employees, "tasksCompletedLate"),
  };

  return (
    <TableContainer
      component={Paper}
      style={{ marginBottom: "50px", marginTop: "40px" }}
    >
      <Typography className="label-title">
        {t("label:performerStatistics")}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ color: "#4339F2" }}>
              {t("tableHeader:FIO")}
            </TableCell>
            <TableCell style={{ color: "#4339F2" }}>
              {t("tableHeader:numberOfTasksCompletedOnTime")}
            </TableCell>
            <TableCell style={{ color: "#4339F2" }}>
              {t("tableHeader:numberOfOverdueTasks")}
            </TableCell>
            <TableCell style={{ color: "#4339F2" }}>
              {t("tableHeader:numberOfTasksAtWork")}
            </TableCell>
            <TableCell style={{ color: "#4339F2" }}>
              {t("tableHeader:tasksCompletedBeyondTheDeadline")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employees.map((employee, index) => (
            <TableRow key={index}>
              <TableCell style={{ padding: 10 }}>{employee.fullName}</TableCell>
              <TableCell style={{ padding: 10 }}>
                <Box style={{ display: "flex", padding: 0 }}>
                  <Box style={{ height: "50px" }}>
                    <Bar
                      data={getCellChartData(
                        employee.tasksCompletedOnTime,
                        maxValues.tasksCompletedOnTime
                      )}
                      options={{ ...chartOptions, maintainAspectRatio: false }}
                    />
                  </Box>
                  <Box style={{ marginTop: "10px", marginLeft: "10px" }}>
                    {employee.tasksCompletedOnTime}
                  </Box>
                </Box>
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                <Box style={{ display: "flex", padding: 0 }}>
                  <Box style={{ height: "50px" }}>
                    <Bar
                      data={getCellChartData(
                        employee.tasksOverdue,
                        maxValues.tasksOverdue
                      )}
                      options={{ ...chartOptions, maintainAspectRatio: false }}
                    />
                  </Box>
                  <Box style={{ marginTop: "10px", marginLeft: "10px" }}>
                    {employee.tasksOverdue}
                  </Box>
                </Box>
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                <Box style={{ display: "flex", padding: 0 }}>
                  <Box style={{ height: "50px" }}>
                    <Bar
                      data={getCellChartData(
                        employee.tasksInProgress,
                        maxValues.tasksInProgress
                      )}
                      options={{ ...chartOptions, maintainAspectRatio: false }}
                    />
                  </Box>
                  <Box style={{ marginTop: "10px", marginLeft: "10px" }}>
                    {employee.tasksInProgress}
                  </Box>
                </Box>
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                <Box style={{ display: "flex", padding: 0 }}>
                  <Box style={{ height: "50px" }}>
                    <Bar
                      data={getCellChartData(
                        employee.tasksCompletedLate,
                        maxValues.tasksCompletedLate
                      )}
                      options={{
                        ...chartOptions,
                        maintainAspectRatio: false,
                      }}
                    />
                  </Box>
                  <Box style={{ marginTop: "10px", marginLeft: "10px" }}>
                    {employee.tasksCompletedLate}
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableWithCharts;
