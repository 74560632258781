import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const responsibleOrganizationsApi = createApi({
    reducerPath: "responsibleOrganizations/api",
    tagTypes: ['ResponsibleOrganizations'],
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const { token } = getState().users;
            headers.set("Content-Type", "application/json");
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    endpoints: (build) => ({
        getResponsibleOrganizationsTable: build.query({
          query: ({searchValue, pageNumber}) => ({
              url: `api/organizations-responsible/get-resp-orgs-table?searchValue=${searchValue}&pageNumber=${pageNumber}`,
          }),
          providesTags: ['ResponsibleOrganizations'],
        }),
        getResponsibleOrganization: build.query({
          query: (id) => ({
              url: `api/organizations-responsible/get/${id}`,
          }),
          providesTags: (result, error, arg) => [{ type: 'ResponsibleOrganizations', id: arg }]
        }),
        addResponsibleOrganization: build.mutation({
            query: (data) => ({
                url: `api/organizations-responsible/add`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ['ResponsibleOrganizations'],
        }),
        editResponsibleOrganization: build.mutation({
            query: (data) => ({
                url: `api/organizations-responsible/edit`,
                method: "PUT",
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'ResponsibleOrganizations', id: arg.id }],
        }),
        deleteResponsibleOrganization: build.mutation({
            query: (id) => ({
                url: `/api/organizations-responsible/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ['ResponsibleOrganizations'],
        }),
        sendResponsibleOrganizationDeletionRequest: build.mutation({
            query: (id) => ({
                url: `/api/organizations-responsible/send-deletion-request/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'ResponsibleOrganizations', id: arg }],
        }),
    }),
});

export const {
    useLazyGetResponsibleOrganizationsTableQuery,
    useGetResponsibleOrganizationQuery,
    useAddResponsibleOrganizationMutation,
    useEditResponsibleOrganizationMutation,
    useDeleteResponsibleOrganizationMutation,
    useSendResponsibleOrganizationDeletionRequestMutation,
} = responsibleOrganizationsApi;