import React, { useRef, useState, useEffect } from "react";
import FormItem from "../../../components/FormItem";

import { Box } from "@mui/material";

const EquipmentsForm = ({ control, errors, data, config }) => {
  const $container = useRef(null);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    $container && setWidth($container.current.offsetWidth);
  }, [$container])

  window.addEventListener(
    "resize",
    function () {
      setWidth($container.current?.offsetWidth);
    },
    false
  );

  return (
    <Box ref={$container}>
       {config.map(({ name, label, input, Component, ...rest }, i) => (
        Component 
          ? <Component
              key={i} 
              data={data} 
              control={control} 
              widthContainer={width}
              {...rest}
            />
          : (
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={name}
                label={label}
                input={input}
                data={data}
                {...rest}
              />
            )
      ))}
    </Box>
  );
};

export default EquipmentsForm;
