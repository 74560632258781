import FormItem from "../../../../components/FormItem";

import {
    Button,
    Box,
    Typography,InputAdornment
} from "@mui/material";
import React, { useState, useEffect } from "react";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { formFields } from "../form-config";
import { useWatch, useFieldArray } from "react-hook-form";
import { RABFields, voltageFileds } from "./FormConfigs/equipments"
const EquipColumnExist = ({ indexCirc, CircuitName, isActExist, plumeequip, formConfig, ...rest }) => {

    const { control, setValue, reset } = rest;


    const { [formFields.protectionObjectId]: selectedFacilityId } = useWatch({
        control
    });



    const { fields: equipments, append, remove } = useFieldArray({
        control,
        name: rest.name

    });
    //equipments=[]
    const [ranOnce, setranOnce] = useState(false)
    const [copySelVal, setcopySelVal] = useState()
    const [selectedValues, setSelectedValues] = useState([]);
    const [IndexAppend, setIndexAppend] = useState(0);
    const [PrevselectedValues, setPrevselectedValues] = useState([]);
    const [PrevWorking, setPrevWorkings] = useState([]);
    const [isAppendDisabled, setAppendDisabled] = useState(false);
    useEffect(() => { remove() }, [selectedFacilityId])
    if (selectedFacilityId) {

        let PlumesName = plumeequip.find(obj => obj.name === CircuitName);
        const EquipCircuitExist = PlumesName?.equipmentSelectElements.map(({ id, name, plume, workingCorrectly }, index) => ({
            name: `${plume ? "шлейф 0" + plume + "," : ""} ${name}`,
            value: id,
            workingCorrectly: workingCorrectly
        }))


        const actEquipOnChange = (value, index) => {

            let prevbb = PrevselectedValues
            if (prevbb[index]) {
                prevbb[index] = value
            } else {
                prevbb.push(value)
            }

            setPrevselectedValues(prevbb);
            let prevWork = PrevWorking
            if (prevWork[index]) {
                prevWork[index] = EquipCircuitExist.find(obj => obj.value === value)?.workingCorrectly.toString()
            } else {
                prevWork.push(EquipCircuitExist.find(obj => obj.value === value)?.workingCorrectly.toString())
            }
            setPrevWorkings(prevWork)
            let copyBB = EquipCircuitExist.filter(item => !PrevselectedValues.includes(item.value))
            setcopySelVal(copyBB)
            if (copyBB.length > 0)
                setAppendDisabled(false)

        }
        const handleRemove = (index) => {


            remove(index)
            const changePrev = PrevselectedValues.filter((item, ItemIndex) => ItemIndex != index)

            setPrevselectedValues(changePrev)
            setAppendDisabled(false)


        }
        const handleAppend = () => {
            if (IndexAppend == 0)
                setcopySelVal(EquipCircuitExist)

            setAppendDisabled(true)

            setIndexAppend(IndexAppend + 1)

            //setSelectedValues(selectedValues.push(changedplumeEquip))
            append({})
        }

        return (
            <Box>
                {(EquipCircuitExist && EquipCircuitExist?.length > 0) ?
                    equipments?.map((field, index) => {

                        return (

                            <Box sx={{
                                display: "flex",
                                gap: "15px",
                            }}>
                                <Button
                                    className="btn btn-remove btn-basket-small"
                                    onClick={() => { handleRemove(index) }}
                                    sx={{ height: 34, alignSelf: "center", marginRight: "10px" }}
                                >
                                    <DeleteForeverIcon />
                                </Button>
                                {formConfig.map((item) => {
                                    let adorment = ""
                                    if(CircuitName == "RABCapacitySNME")
                                        adorment = "А\ч"
                                    else if( CircuitName == "measuringBackgroundNoise" || CircuitName == "alertMeasurement"){
                                        adorment = "дб"
                                    }else if(CircuitName == "RABCapacityFormConfig"){
                                        adorment = "В"
                                    }
                                    return (
                                        item.name == "equipmentId" ?
                                            <Box>
                                                <FormItem
                                                    key={`${[rest.name]}.${index}.${item.name}`}
                                                    actEquipOnChange={actEquipOnChange}
                                                    indexAxtEquip={index}
                                                    optionsActs={EquipCircuitExist?.filter(item => !PrevselectedValues.includes(item.value) || (PrevselectedValues.includes(item.value) && PrevselectedValues[index] == item.value))}
                                                    value={PrevselectedValues[index]}
                                                    {...item}
                                                    {...rest}
                                                    name={`${[rest.name]}.${index}.${item.name}`}
                                                />
                                            </Box>
                                            :
                                            (item.name == "workingCorrectly") ?

                                                <Box>
                                                    <FormItem
                                                        key={`${[rest.name]}.${index}.${item.name}`}
                                                        {...item}
                                                        defaultValue={PrevWorking[index] ? '' + PrevWorking[index] : "true"}
                                                        {...rest}
                                                        name={`${[rest.name]}.${index}.${item.name}`}
                                                    />
                                                    {(CircuitName == "effectOfFault" || CircuitName == "RABCapacitySNME" || CircuitName == "RABCapacityFormConfig" || CircuitName == "measuringBackgroundNoise" || CircuitName == "alertMeasurement") &&
                                                        RABFields.map((item) => {
                                                            return (
                                                                <FormItem
                                                                    key={`${[rest.name]}.${index}.${item.name}`}
                                                                    {...item}
                                                                    InputProps={{ endAdornment:<InputAdornment position="end">{adorment}</InputAdornment>,type:"number" }}
                                                                    {...rest}
                                                                    name={`${[rest.name]}.${index}.${item.name}`}
                                                                />
                                                            );


                                                        })
                                                    }
                                                
                                                </Box>
                                                :
                                                <Box>
                                                    <FormItem
                                                        key={`${[rest.name]}.${index}.${item.name}`}
                                                        {...item}
                                                        {...rest}
                                                        name={`${[rest.name]}.${index}.${item.name}`}
                                                    />
                                                </Box>
                                    )


                                })}

                            </Box>

                        )

                    }) :
                    <Box><Typography sx={{ fontWeight: 600, fontSize: "18px", textAlign: "center" }}> Нет оборудования для этого испытания</Typography></Box>
                }

                <Button className="btn btn-outlined-grey" sx={{ display: !EquipCircuitExist || EquipCircuitExist?.length == 0 ? "none" : "block" }} disabled={(selectedFacilityId == "" || !selectedFacilityId || isAppendDisabled) ? true : false} onClick={() => {

                    handleAppend()

                }}>
                    + Добавить
                </Button>
            </Box>




        );

    }


};


export default EquipColumnExist;