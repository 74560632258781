import React, {useEffect} from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
    Box,
    Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import "./styles/style.css";

import { useAction } from "../../../hooks/actions";
import { PATHS } from '../../../constants';
import {formatErrors, getRequestData, getRequestDataFilesForAdd} from "./helpers";

import { WarningValidationForm } from "../../components/Notifications";
import ButtonsField from "./ButtonsField";
import {useManipulateFilesMutation} from "../../../middleware/apiUploadFile";
import { FireCompartmentShortForm } from "./FireCompartmentShortForm";
import {useEditFireCompartmentMutation, useGetFireCompartmentQuery} from "../../../middleware/fireCompartmentApi";
import {useGetFacilitySelectElementsQuery} from "../../../middleware/facilitiesApi";

const EditFireCompartmentPage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {id} = useParams();
    const {
        changeTitlePage,
        setIsErrorRequest,
        setIsErrorSubmit,
        setIsSuccessSubmit,
    } = useAction();
    const {
        data: fireCompartment,
        isLoading: isLoadingFireCompartment,
        error: errorGetFireCompartment,
    } = useGetFireCompartmentQuery(id, {skip: !id});
    const [editFireCompartment, {isError: isErrorEdit, isSuccess: isSuccessEdit}] =
        useEditFireCompartmentMutation();
    const [manipulateFiles, { isError: isErrorFile, isSuccess: isSuccessFile }] = useManipulateFilesMutation();

    const isError = isErrorEdit || isErrorFile;
    const isSuccess = isSuccessEdit && isSuccessFile;

    //mock data
    const isEmptyPage = false;
    // ======

    const {
        control,
        reset,
        handleSubmit,
        formState: {errors},
        register,
        unregister,
        setValue,
        getValues,
        setError
    } = useForm({
        mode: "onChange",
    });
    const isWarningValidation = !!Object.keys(errors).length;

    const onSubmit = async (data) => {
        const {entities, files} = getRequestData(data);

        editFireCompartment(entities).then((result) => {
            if (!result?.error)  {
                const requestDataFiles = getRequestDataFilesForAdd(result?.data?.floors, files);
                manipulateFiles(requestDataFiles).then((response) => {
                    if(response?.error?.data?.errors) {
                        const errors = formatErrors(response);
                        Object.entries(errors).forEach(([floorId, message]) => {
                            const floorIndex = result?.data?.floors.findIndex(({id}) => id === floorId);
                            setError(`floors[${floorIndex}].floorSchema`, { type: "custom", message });
                        })
                        return;
                    }
                    navigate(PATHS.LIST_OBJECTS);
                });
            }
        });
    };

    const handleCancel = () => navigate(-1);
    // const handleCancel = () => {
    //     isDirty
    //         ? setPathForModalClosePage(PATHS.LIST_OBJECTS)
    //         : navigate(-1);;
    // };

    useEffect(() => {
        fireCompartment && changeTitlePage(fireCompartment.name || "");
    }, [fireCompartment, changeTitlePage]);

    useEffect(() => () => changeTitlePage(""), [changeTitlePage]);

    useEffect(() => {
        if (!fireCompartment) {
            return;
        }

        let floors = [];

        if(fireCompartment?.floors?.length) {
            floors = fireCompartment.floors.map(floor => ({
                ...floor,
                floorSchema: {
                    filesPrev: floor?.floorSchemeFileName ? [{fileName: floor?.floorSchemeFileName}] : [],
                    filesToAdd: [],
                    filesToDelete: [],
                }
            }))
        }

        const updateData = {
            ...fireCompartment,
            floors
        };

        reset(updateData);
    }, [fireCompartment, reset]);

    // useEffect(() => {
    //     setIsChangeForm(isSuccess ? false : isDirty);
    // }, [isSuccess, isDirty, setIsChangeForm]);

    useEffect(() => {
        setIsErrorRequest(errorGetFireCompartment);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorGetFireCompartment]);

    useEffect(() => {
        setIsErrorSubmit(isError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError]);

    useEffect(() => {
        setIsSuccessSubmit(isSuccess);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    useGetFacilitySelectElementsQuery();

    return (
        <Box className="fire-compartment">
            <Box className="fire-compartment-header">
                <Typography className="title-page">
                    {t("title_page:edit_fire_compartment")}
                </Typography>
                <ButtonsField isEmptyPage={isEmptyPage} handleCancel={handleCancel} />
            </Box>
            {!isLoadingFireCompartment && !!fireCompartment ? (
                <FireCompartmentShortForm
                  control={control}
                  errors={errors}
                  onSubmit={handleSubmit(onSubmit)}
                  setValue={setValue}
                  register={register}
                  unregister={unregister}
                  getValues={getValues}
                />
            ): (
                <Typography className="fire-compartment-content-empty_text">
                    {t("text_empty_page:edit_fire_compartment")}
                </Typography>
            )}
            {isWarningValidation && (
                <WarningValidationForm isWarning={isWarningValidation} />
            )}
        </Box>
    );
};

export default EditFireCompartmentPage;
