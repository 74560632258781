import React from "react";

const SuccesIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4466 8.82587C17.7422 8.49574 17.7141 7.98851 17.384 7.69294C17.0539 7.39737 16.5466 7.42539 16.2511 7.75552L12.6468 11.7813C11.9165 12.597 11.4247 13.1435 11.0042 13.4969C10.6036 13.8336 10.3683 13.907 10.1628 13.907C9.95726 13.907 9.72198 13.8336 9.3214 13.4969C8.90089 13.1435 8.40906 12.597 7.67873 11.7812L6.74892 10.7427C6.45335 10.4126 5.94612 10.3845 5.61599 10.6801C5.28586 10.9757 5.25784 11.4829 5.5534 11.813L6.52292 12.8959C7.20314 13.6558 7.77051 14.2895 8.28893 14.7253C8.83734 15.1862 9.42885 15.5116 10.1628 15.5116C10.8967 15.5116 11.4882 15.1862 12.0366 14.7253C12.5551 14.2895 13.1224 13.6558 13.8026 12.896L17.4466 8.82587Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0ZM1.60465 11.5C1.60465 6.03495 6.03495 1.60465 11.5 1.60465C16.9651 1.60465 21.3953 6.03495 21.3953 11.5C21.3953 16.9651 16.9651 21.3953 11.5 21.3953C6.03495 21.3953 1.60465 16.9651 1.60465 11.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SuccesIcon;
