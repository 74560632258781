import React from "react";

const PoligonIcon = ({ isActive }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9708 8.07715L5.74219 14.231L17.3993 21.9233L24.2565 9.61561L13.9708 8.07715Z" stroke={isActive ? "#d1d8e0" : "#1876d2"} stroke-linecap="square" />
      <rect x="11.5" y="5.5" width="4" height="4" fill="white" stroke={isActive ? "#d1d8e0" : "#1876d2"} />
      <rect x="3.5" y="11.5" width="4" height="4" fill="white" stroke={isActive ? "#d1d8e0" : "#1876d2"} />
      <rect x="15.5" y="20.5" width="4" height="4" fill="white" stroke={isActive ? "#d1d8e0" : "#1876d2"} />
      <rect x="22.5" y="7.5" width="4" height="4" fill="white" stroke={isActive ? "#d1d8e0" : "#1876d2"} />
    </svg>

  );
};

export default PoligonIcon;
