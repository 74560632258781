import { ADDRESSES_TYPES_INT } from "../MyOrganizations/constants";

export const DEFAULT_VALUE = {
  name: "",
  email: "",
  phoneNumber: "",

  organizationRegistrationInfo: {
    name: "",
    ogrnNumber: "",
    innNumber: "",
    kppCode: "",
    okpoCode: "",
    okvedCode: "",
    bankDetails: {
      bankName: "",
      account: "",
      correspondentAccount: "",
      bankBIC: "",
    },
    organizationAddresses: [
      {
        type: ADDRESSES_TYPES_INT.UR,
        address: null,
        extension: "",
      },
    ],
  },
};
