import React from "react";

const IntegrationIcon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.95005 4.95L8.53605 6.364L6.00005 3.828V17H4.00005V3.828L1.46505 6.364L0.0500488 4.95L5.00005 0L9.95005 4.95ZM19.9501 13.05L15 18L10.05 13.05L11.464 11.636L14.001 14.172L14 1H16V14.172L18.536 11.636L19.9501 13.05Z"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default IntegrationIcon;
