import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import {
    Box,
    Typography,
} from "@mui/material";

import "./styles/style.css";

import { WarningValidationForm } from "../../components/Notifications";
import ButtonsField from "./ButtonsField";
import { PATHS } from '../../../constants';
import {useAction} from "../../../hooks/actions";
import {
  useAddShortFireCompartmentMutation,
  useDeleteFireCompartmentMutation
} from "../../../middleware/fireCompartmentApi";
import {formatErrors, getRequestShortData, getRequestDataFilesForAdd} from "./helpers";
import { useAppSelector } from "../../../hooks/redux";
import {
    useGetFacilitySelectElementsQuery
} from "../../../middleware/facilitiesApi";

import { FireCompartmentShortForm } from "./FireCompartmentShortForm";
import {useManipulateFilesMutation} from "../../../middleware/apiUploadFile";


export const AddShortFireCompartmentPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        setIsErrorSubmit,
        setIsSuccessSubmit,
        clearAddedFacility,
    } = useAction();
    const { addedFacility } = useAppSelector(state => state.facilities);
    const [addShortFireCompartment, {error: errorAdd, isSuccess: isSuccessAdd}] = useAddShortFireCompartmentMutation();
    const [deleteFireCompartment] = useDeleteFireCompartmentMutation();
    const [manipulateFiles, { error: errorFile, isSuccess: isSuccessFile }] = useManipulateFilesMutation();

    const isError = errorAdd || errorFile;
    const isSuccess = isSuccessAdd && isSuccessFile;

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        register,
        unregister,
        getValues,
        setError
    } = useForm({
        mode: "onChange",
    });

    const isWarningValidation = !!Object.keys(errors).length;

    const onSubmit = async (data) => {
        const { entities, files } = getRequestShortData({ data, addedFacility });

        addShortFireCompartment(entities).then((result) => {
          if (!result?.error) {
            const requestDataFiles = getRequestDataFilesForAdd(result?.data?.floors, files);
            manipulateFiles(requestDataFiles).then((response) => {
              if(response?.error?.data?.errors) {
                const errors = formatErrors(response);
                Object.entries(errors).forEach(([floorId, message]) => {
                    const floorIndex = result?.data?.floors.findIndex(({id}) => id === floorId);
                    setError(`floors[${floorIndex}].floorSchema`, { type: "custom", message });
                })
                deleteFireCompartment(result?.data?.id)
                return;
              }
              if (response?.error) {
                  deleteFireCompartment(result?.data?.id)
              } else {
                clearAddedFacility();
                navigate(PATHS.LIST_FIRE_COMPARTMENTS);
              }
            });
          }
        });
    };

    useEffect(() => {
        setIsErrorSubmit(isError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError]);

    useEffect(() => {
        setIsSuccessSubmit(isSuccess);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    const handleCancel = () => navigate(-1);

    useGetFacilitySelectElementsQuery();

    return (
        <Box className="fire-compartment">
            <Box className="fire-compartment-header">
                <Typography className="title-page">
                    {t("title_page:add_fire_compartment")}
                </Typography>
                <ButtonsField handleCancel={handleCancel} />
            </Box>
            <FireCompartmentShortForm
                control={control}
                errors={errors}
                onSubmit={handleSubmit(onSubmit)}
                setValue={setValue}
                register={register}
                unregister={unregister}
                getValues={getValues}
            />
            {isWarningValidation && (
                <WarningValidationForm isWarning={isWarningValidation} />
            )}
        </Box>
    );
};