import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const billingActions = billingSlice.actions;
export const billingReducer = billingSlice.reducer;
