import React from "react";
import { useAppSelector } from "../../../hooks/redux";
import SelectGrouping from "../../components/SelectGrouping";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SelectSimple from "../../components/SelectSimple";
// import {
//   useGetFacilitySelectElementsWithSearchQuery,useGetFacIntNumbersQuery
// } from "../../../middleware/facilitiesApi";
import { Button, Typography } from '@mui/material'
import { t } from 'i18next'
import InputText from '../../components/InputText'
export const SelectSimpleObject = (props) => {
  const { facilitySelectElements } = useAppSelector(
    (state) => state.facilities
  );
  const { searchObject,setsearchObject} = props
//console.log("options",options)
  return (
    <SelectGrouping
      {...props}
      setSearchValue={setsearchObject}
      searchValue={searchObject}
      options={[{id:"все",name:"все"},...facilitySelectElements]}
      //bgcolor={bgcolor}
    />
  );

};
export const SelectSimpleFloor = (props) => {
  const { floorOptionsSelectByFacility } = useAppSelector(
    (state) => state.facilities
  );

  return <SelectSimple options={floorOptionsSelectByFacility} {...props} />;
};
export const SelectIntNumbers = (props) => {
  const { facIntNum } = useAppSelector(
    (state) => state.facilities
  );
  const { searchIntNum,setsearchIntNum} = props
  
   return (
     <SelectGrouping
       {...props}
       setSearchValue={setsearchIntNum}
       searchValue={searchIntNum}
       options={facIntNum}
       //bgcolor={bgcolor}
     />
   );
 
 };
 



export const PlumesList = (props) => {
  function onKeyPress(e)
  {
    if (e.charCode < 48 || e.charCode > 57)
    {
      e.preventDefault();
    }
  }
  return (
    <>
      <Typography sx={{ fontWeight: 500, marginBottom: '10px' }}>{t("label:plumes")}</Typography>
      <div style={{ display: 'flex', flexDirection: 'column'}}>
        {
          Array.isArray(props.plumes) && props.plumes.map((el, idx) => (
            <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center' }}>
              <div style={{flexBasis: props.plumes?.length > 1 ? '90%' : '100%'}}>
                <InputText onChange={e => props.updatePlume(idx, {name: e.target.value})} key={idx} value={el?.name} onKeyPress={onKeyPress} />
              </div>
              {props.plumes?.length > 1 && <div style={{flexBasis: '10%'}}>
                <Button
                    className="btn btn-remove btn-basket-small"
                    onClick={() => { props.removePlume(idx) }}
                    sx={{ height: 34, alignSelf: "center", marginLeft: "10px" }}
                >
                    <DeleteForeverIcon />
                </Button>
              </div>}
            </div>

          ))
        }
      </div>
        <Button
          className="btn btn-primary-fill"
          onClick={() => props.appendPlume({
            name: ''
          })}
        >
          {t("button:add")}
        </Button>
    </>
  )
}