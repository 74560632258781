import { createSlice } from "@reduxjs/toolkit";
import { usersApi } from "../../middleware/usersApi";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PURGE } from "redux-persist";

const initialState = {
  info: {
    email: "",
    login: "",
    firstName: "",
    lastName: "",
    id: "",
    role: "",
    isActive: true,
    surname: "",
    phone: "",
    jobTitle: "",
    roleId: "",
    avatarLink: "",
    userEmployers: [],
  },
  employee: {
    email: "",
    login: "",
    firstName: "",
    lastName: "",
    id: "",
    role: "",
    isActive: true,
    surname: "",
    phone: "",
    jobTitle: "",
    avatarLink: "",
    userEmployers: [],
    facilities: [],
  },
  token: null,
  isLoggedBefore: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    login(state, action) {
      state = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
    builder.addMatcher(
      usersApi.endpoints.checkUsersLogin.matchFulfilled,
      (state, { payload }) => {
        state.info = {
          email: payload.email,
          login: payload.login,
          firstName: payload.firstName,
          lastName: payload.lastName,
          role: payload.role,
          roleId: payload.roleId,
          avatarLink: payload.avatarLink,
          isActive: payload.isActive,
        };
      }
    );
    builder.addMatcher(
      usersApi.endpoints.getUsersByPassword.matchFulfilled,
      (state, { payload }) => {
        if (typeof payload === "object" && payload.hasOwnProperty("isActive")) {
          state.info.isActive = payload.isActive;
        } else if (typeof payload === "object") {
          state.token = payload?.userToken;
          state.isLoggedBefore = payload?.isLoggedBefore;
          localStorage.setItem("isLoggedBefore", payload?.isLoggedBefore);
        }
      }
    );
    builder.addMatcher(
      usersApi.endpoints.getProfileInfo.matchFulfilled,
      (state, { payload }) => {
        state.info = payload;
      }
    );
    builder.addMatcher(
      usersApi.endpoints.getProfileInfoById.matchFulfilled,
      (state, { payload }) => {
        state.employee = payload;
      }
    );
  },
});

export const usersActions = usersSlice.actions;

export const usersReducer = persistReducer(
  {
    key: "auth",
    storage: storage,
    whitelist: ["token"],
  },
  usersSlice.reducer
);
