import React from "react";
import {
  Box,
  CardMedia,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import authPrez from "../../../image/authPrez.png";

import VdpoLogoText from "../../components/icons/VdpoLogoText";

export const RightBlockLogin = () => {
  const { t } = useTranslation();

  return (
    <Box className="left_block" sx={{height:"100%",}}>
       <Box
          component="img"
          src={authPrez}
          sx={{width:"100%",objectFit: "cover",objectPosition: "0 0",height:"100%"}}
          alt="ВДПО"
        />
     {/*  <Box  className="left_block-logo">
       
      </Box>

      <Box className="left_block-content">
       <Box className="left_block-content-logo_text_field">
          <VdpoLogoText />
        </Box>
        <Typography className="left_block-content-text">
          {t("login_page:auth_title_v2")}
        </Typography>
        
      </Box>*/}
    </Box>
  );
};
