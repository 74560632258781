import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Modal, Button, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import FormItem from "../../../components/FormItem";

const ModalRoomAddress = ({
  open,
  setOpen,
  config,
  handleSave,
  handleCancel,
}) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    handleSave(data);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    handleCancel();
  };

  useEffect(() => {
    !open && reset();
  }, [open, reset])

  return (
    <Modal
      open={open}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      className="modal_address"
    >
      <Box className="modal_address-body">
        <Box>
          <Box className="modal_address-header_right">
            <Typography className="modal_address-body-title">
              {t("title:enter_address_room")}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="modal_address-body-form">
            <form id="formModal" onSubmit={handleSubmit(onSubmit)}>
              {config.map(({ id, name, label, input, ...rest }) => (
                <FormItem
                  key={id}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}
            </form>
          </Box>
        </Box>
        <Box className="modal_address-body-buttons">
          <Button
            form="formModal"
            className="btn btn-primary-fill"
            type="submit"
            // disabled={!watch(config.name)}
          >
            {t("button:save")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalRoomAddress;