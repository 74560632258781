import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormItem from "../../../components/FormItem";
import { formConfigSetPassword } from "../form-config";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import SetPasswordSuccess from "./SetPasswordSuccess";
import { useParams } from "react-router-dom";
import { useSetNewPasswordMutation } from "../../../../middleware/usersApi";

const SetPassword = () => {
  const { t } = useTranslation();
  const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);
  const { tokenLink } = useParams();
  const [setNewPassword] = useSetNewPasswordMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    const response = await setNewPassword({
      token: tokenLink,
      newPass: data.newPassword,
    });
    if (!response.error) {
      setIsSuccessSubmit(true);
    }
  };

  if (isSuccessSubmit) {
    return <SetPasswordSuccess />;
  }
  return (
    <>
      <Typography className="login-title" sx={{ mb: "16px" }} variant={"h4"}>
        {t("label:setPassword")}
      </Typography>
      <form
        id="myform"
        className="object-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {formConfigSetPassword.map(({ name, label, input, ...rest }) => (
          <FormItem
            key={name}
            control={control}
            errors={errors}
            name={name}
            label={label}
            input={input}
            {...rest}
          />
        ))}
        <Button
          sx={{ mt: "30px" }}
          fullWidth
          className="btn btn-primary-fill"
          variant="contained"
          type="submit"
        >
          {t("button:setPassword")}
        </Button>
      </form>
    </>
  );
};

export default SetPassword;
