import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const employeeApi = createApi({
  reducerPath: "employee/api",
  tagTypes: ['Employees'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getAllEmployees: build.query({
      query: (params) => {
        const stringParams = Object.entries(params).reduce(
          (acc, [key, value]) => `${acc ? acc + "&" : ""}${key}=${value}`,
          ""
        );
        return {
          url: `api/users/get-employees-table?${stringParams}`,
        };
      },
      providesTags: ['Employees']
    }),
    
  }),
});

export const { useLazyGetAllEmployeesQuery } = employeeApi;
