import React from "react";
import { useTranslation } from "react-i18next";
import {
  ListItemText,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};

const RadioButtonsGroup = ({
  options,
  label,
  onChange,
  error,
  value,
  disabled = false,
  setValue,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth error={!!error && !disabled} {...rest}>
      <InputLabel>{t(label)}</InputLabel>
      <RadioGroup
        value={value || ""}
        onChange={onChange}
        MenuProps={MenuProps}
        sx={{
          ".MuiSelect-select": { height: 0 },
          ".MuiListItemText-root": { m: 0 },
        }}
        disabled={disabled}
      >
        {options.map((option, i) => (
          <FormControlLabel
            key={i}
            value={option?.value}
            label={option?.name}
            control={<Radio/>}
            sx={{ height: ITEM_HEIGHT }}
          />

        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonsGroup;
