import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const executiveApi = createApi({
  reducerPath: "executive/api",
  tagTypes: ['Executives'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getExecutiveSelectElements: build.query({
      query: (id) => ({
        url: `api/organizations-responsible/get-executive-users-select?responsibleOrganizationId=${id}`,
      }),
    }),
    getExecutiveList: build.query({
      query: () => ({
        url: "api/organizations-responsible/get-resp-orgs-with-exec-users",
      }),
      providesTags: ['Executives']
    }),
  }),
});

export const {
  useGetExecutiveSelectElementsQuery,
  useGetExecutiveListQuery,
} = executiveApi;