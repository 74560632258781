import { t } from "i18next";
import light from "../image/equipmentIconsNew/light.png";
import street_sound from "../image/equipmentIconsNew/street_sound.png";
import energy_block from "../image/equipmentIconsNew/street_sound.png";
import warm from "../image/equipmentIconsNew/warm.png";
import smoke from "../image/equipmentIconsNew/smoke.png";
import fire from "../image/equipmentIconsNew/fire.png";
import manage from "../image/equipmentIconsNew/manage.png";
import newDefault from "../image/equipmentIconsNew/newdefault.png";
import fireIcon from "../image/equipmentIconsNew/fireicon.png";
import detector_manual from "../image/equipmentIconsNew/detector_combined.png";
import detector_combined from "../image/equipmentIconsNew/detector_combined.png";

const ICONS = {
  // Оповещатель речевой
  18: {
    icon: street_sound,
    description: t("icon_description:sound"),
  },
  // Световой указатель
  20: {
    icon: light,
    description: t("icon_description:light"),
  },
  // Оповещатель световой (лампа)
  19: {
    icon: light,
    description: t("icon_description:light"),
  },
  // Оповещатель звуковой 
  21: {
    icon: street_sound,
    description: t("icon_description:sound"),
  },
  // Оповещатель свето-звуковой
  22: {
    icon: light,
    description: t("icon_description:combo"),
  },
  // Громкоговоритель уличный
  23: {
    icon: street_sound,
    description: t("icon_description:street_sound"),
  },
  // Блок питания, источник питания не резервированный
  15: {
    icon: energy_block,
    description: t("icon_description:energy_block"),
  },
  // Блок питания, источник питания резервированный
  24: {
    icon: energy_block,
    description: t("icon_description:energy_block"),
  },
  153: {
    icon: energy_block,
    description: t("icon_description:energy_block"),
  },
  154: {
    icon: energy_block,
    description: t("icon_description:energy_block"),
  },
  155: {
    icon: energy_block,
    description: t("icon_description:energy_block"),
  },
  // Блок питания, источник питания не резервированный
  25: {
    icon: energy_block,
    description: t("icon_description:energy_block"),
  },
  // ИП тепловой
  2: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  183: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  184: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  185: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  // ИП линейный тепловой невосстанавливаемый
  12: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  // ИП линейный тепловой восстанавливаемый
  13: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  170: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  171: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  172: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  173: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  174: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  175: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  // ИП дымовой
  1: {
    icon: smoke,
    description: t("icon_description:smoke"),
  },
  161: {
    icon: smoke,
    description: t("icon_description:smoke"),
  },
  162: {
    icon: smoke,
    description: t("icon_description:smoke"),
  },
  163: {
    icon: smoke,
    description: t("icon_description:smoke"),
  },
  // ИП пламени
  3: {
    icon: fire,
    description: t("icon_description:fire"),
  },
  179: {
    icon: fire,
    description: t("icon_description:fire"),
  },
  180: {
    icon: fire,
    description: t("icon_description:fire"),
  },
  181: {
    icon: fire,
    description: t("icon_description:fire"),
  },
  // ИПР ручной
  10: {
    icon: detector_manual,
    description: t("icon_description:hand"),
  },
  187: {
    icon: detector_manual,
    description: t("icon_description:hand"),
  },
  188: {
    icon: detector_manual,
    description: t("icon_description:hand"),
  },
  189: {
    icon: detector_manual,
    description: t("icon_description:hand"),
  },
  // Прибор контроля и управления
  17: {
    icon: manage,
    description: t("icon_description:manage"),
  },
  // Прибор контроля / управления (функциональный модуль)
  14: {
    icon: manage,
    description: t("icon_description:manage"),
  },
  198: {
    icon: manage,
    description: t("icon_description:manage"),
  },
  199: {
    icon: manage,
    description: t("icon_description:manage"),
  },
  200: {
    icon: manage,
    description: t("icon_description:manage"),
  },
  // Огнетушители
  84: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  85: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  86: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  87: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  88: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  89: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  90: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  91: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  // ИП комбинированный
  5: {
    icon: detector_combined,
    description: t("icon_description:combo"),
  },
  164: {
    icon: detector_combined,
    description: t("icon_description:combo"),
  },
  165: {
    icon: detector_combined,
    description: t("icon_description:combo"),
  },
  166: {
    icon: detector_combined,
    description: t("icon_description:combo"),
  }
};

export const getEquipmentIcon = (typeId) => ICONS[typeId]?.icon || newDefault;
