import React from "react";
import { MissingFloorSchemaFormFormConfig } from "./form-config.jsx";
import FormItem from "../../../../components/FormItem";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { getRequestDataFilesForAdd } from "./helpers";
import { useManipulateFilesMutation } from "../../../../../middleware/apiUploadFile";

export const MissingFloorSchemaForm = ({ floor, setFloorScheme }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [manipulateFiles] = useManipulateFilesMutation();

  const onSubmit = (data) => {
    
    const requestDataFiles = getRequestDataFilesForAdd(floor, data);
    console.log("GAGAGA",requestDataFiles)
    if (requestDataFiles) {
      manipulateFiles(requestDataFiles).then((response) => {
        if (response?.error?.data?.errors) {
          const { errors = [] } = response?.error?.data;
          let errorText = "";
          errors.forEach(({ error }) => (errorText += ` ${error}`));
          setError("floorSchema", { type: "custom", message: errorText });
          return;
        }
        setFloorScheme(response?.data[0].fileLink);
      });
    }
  };

  return (
    <Box className="schema-field-image-missing">
      {MissingFloorSchemaFormFormConfig.map(
        ({ name, label, input, ...rest }) => (
          <FormItem
            key={name}
            control={control}
            errors={errors}
            name={name}
            label={label}
            input={input}
            {...rest}
          />
        )
      )}
      <Button
        onClick={handleSubmit(onSubmit)}
        fullWidth
        className="btn btn-primary-fill"
        type="submit"
      >
        {t("button:upload")}
      </Button>
    </Box>
  );
};
