import { createSlice } from "@reduxjs/toolkit";
import { equipmentsApi } from "../../middleware/equipmentsApi";
const initialState = {
  equipmentsValue: "",
  equipments: [],
  plumeEquip: [],
  plumeEquipSNNME: [],
  equipForPeriods: [],
};

export const equipmentsSlice = createSlice({
  name: "equipments",
  initialState,
  reducers: {
    changeEquipmentsValue(state, action) {
      state.equipmentsValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      equipmentsApi.endpoints.getEquipmentList.matchFulfilled,
      (state, { payload }) => {
        state.equipments = payload;
      }
    );
  },
});

export const equipmentsActions = equipmentsSlice.actions;
export const equipmentsReducer = equipmentsSlice.reducer;
