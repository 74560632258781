import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SetSMSCodeDialog } from "../SetSMSCodeDialog";
import { useLazySendPhoneVerificationCodeQuery } from "../../../../middleware/signingApi";
import { useAction } from "../../../../hooks/actions";
import SuccesIcon from "../../../components/icons/SuccesIcon";
import AttentionIcon from "../../../components/icons/AttentionIcon";

export const ConfirmPhoneNumber = ({ isConfirmed }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { setIsErrorRequest } = useAction();
  const [sendPhoneVerificationCode, { error }] =
    useLazySendPhoneVerificationCodeQuery();

  const handleOpen = () => {
    setIsDisabled(true);
    sendPhoneVerificationCode().then(({ error }) => {
      !error && setOpen(true);
      setIsDisabled(false);
    });
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      {isConfirmed ? (
        <div className="user-profile__confirmation succes" onClick={handleOpen}>
          <p>{t("button:confirm_phone_number")}</p>
          <SuccesIcon />
        </div>
      ) : (
        <div className="user-profile__confirmation bad" onClick={handleOpen}>
          <p>{t("button:not_confirm_phone_number")}</p>
          <AttentionIcon />
        </div>
      )}

      {!isConfirmed && (
        <SetSMSCodeDialog
          open={open}
          onClose={handleClose}
          actionRequest={sendPhoneVerificationCode}
        />
      )}
    </>
  );
};
