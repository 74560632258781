import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { PATHS } from "../../../../constants";
import { useNavigate } from "react-router-dom";

const SetPasswordSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box>
      <Typography className="login-title" sx={{ mb: "16px" }} variant={"h4"}>
        {t("label:setPasswordSuccess")}
      </Typography>
      <Button
        sx={{ mt: "30px" }}
        className="btn btn-link"
        onClick={() => navigate(PATHS.SLASH)}
      >
        {t("button:authPage")}
      </Button>
    </Box>
  );
};

export default SetPasswordSuccess;
