import React from "react";
import { useFieldArray } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import plusicon from "../../../../image/icons/plusBtnWhite.png"

import { FloorsConfig, templateFloor } from "./form-config.jsx";

import FormItem from "../../../components/FormItem";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../components/icons/DeleteIcon.jsx";

export const FloorShortFields = ({ name, control, errors, rules, setValue,disabled }) => {
  const { t } = useTranslation();

  const {
    fields: floorFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name,
  });
  floorFields.map((item, index) => (
    console.log("floorFields",item?.canDelete)
  ))
  return (
    <>
      {floorFields.map((item, index) => (
        <Box
          key={item.id}
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: 'center',
            ".error-text": {
              whiteSpace: "normal",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: 'baseline',
              gap: "15px",
            }}
          >
            {FloorsConfig.map(({ name : itemName, label, input, type, ...rest }) => (
              <FormItem
                key={`${name}.${index}.${itemName}`}
                control={control}
                errors={errors}
                disabled={disabled}
                name={`${name}.${index}.${itemName}`}
                label={label}
                input={input}
                rules={rules}
                type={type}
                setValue={setValue}
                {...rest}
              />
            ))}
          </Box>

          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {item?.canDelete != false ? (
              <Button
                className="btn btn-remove btn-basket-small"
                onClick={() => remove(index)}
              >
                <DeleteIcon />
                <p>{t('button:remove')}</p>
              </Button>
            ) : <div style={{width: '145px'}}></div>}
          </Box>
        </Box>
      ))}

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <Button
          className="btn btn-primary-fill"
          variant="outlined"
          disabled={disabled}
          onClick={() => append(templateFloor)}
        >
          <Box component="img" src={plusicon} sx={{ marginRight: "16px" }} />
          {t("button:add_floor")}
        </Button>
      </Box>
    </>
  );
};
