export const getRequestDataFilesForAdd = (floorId, files) => {
    const formData = new FormData();

    if(!files) {
        return formData;
    }

    let fileConfigIndex = 0
    Object.entries(files).filter(([, value]) => value)
        .forEach(([, {filesToAdd}]) => {
            if(filesToAdd?.length) {
                filesToAdd.forEach((file, index) => {
                    fileConfigIndex += index
                    formData.append(`entityFileList[${fileConfigIndex}].EntityId`, floorId);
                    formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "FloorInfo");
                    formData.append(`entityFileList[${fileConfigIndex}].File`, file);
                    formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Add");
                })
                fileConfigIndex++
            }
        })

    return formData;
};