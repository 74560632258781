import React, { useState, useEffect, useRef, memo } from "react";
import { Stage, Layer, Line, Group, Image, Rect, Text } from "react-konva";
import { useWatch } from "react-hook-form";
import { Box } from "@mui/material";

import { loadImg } from "../../../helpers/helper";
import { getParamsTextWithScale } from "./helpers";
import { getEquipmentIcon } from "../../../helpers/getEquipmentIcon";
import { useChartsIcons } from "../../pages/EquipmentDetails/hooks";

import alarm from "../../components/icons/alarm.svg";
import ModalFullScreen from "../../pages/Equipments/ImageSchema/ModalFullScreen";
import DonutChart from "../DonutChart";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";
import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";

const SchemaWithEquipments = ({
  name,
  width,
  height,
  schemaImg,
  roomAddresses = [],
  setValue,
  control,
  draggable = false,
  title,
  isPlan = false,
  xyGroup,
  setscaleAddEquip,
  setoffsetAddEquip,
  fullscreen = false,
  setFullScreen,
}) => {
  const canvasRef = useRef(null);
  const { equipmentsCoordinates: data } = useWatch({
    control,
  });
  const [isRenderIconsBlocked, setIsRenderIconsBlocked] = useState(false)
  const [image, setImage] = useState(null);
  const [icons, setIcons] = useState(null);
  const [count, setcount] = useState(0);
  const [scale, setScale] = useState(1);
  const [stageposition, setstageposition] = useState({ x: 0, y: 0 });
  const navigate = useNavigate();
  const { SCHEMA_CANVAS } = useAppSelector((state) => state.settings);
  const { setCanvasSize } = useAction();
  const stageRef = useRef(null);
  const [ChartsList, getSrcSVG] = useChartsIcons({
    Chart: DonutChart,
    equipments: data,
  });
  
  const drawIcons = async (
    data,
    height = SCHEMA_CANVAS.HEIGHT,
    width = SCHEMA_CANVAS.WIDTH
  ) => {
    console.log("DSDS",data?.length)
    if (data) {
      
      if(data?.length>0){
        const response = await Promise.all(
          data.map(
            async (
              {
                id,
                floorSchemeCoordinateX: x,
                floorSchemeCoordinateY: y,
                typeId,
                workingCorrectly,
                jobChart
              },
              index
            ) => {
              return loadImg(getEquipmentIcon(typeId)).then(async (icon) => {
                const scaleX = width / SCHEMA_CANVAS.WIDTH;
                const scaleY = height / SCHEMA_CANVAS.HEIGHT;
                icon.width = !isPlan ? 25 :(scale <= 0.65) ? icon.width / scale * 0.5 : (scale <= 1.1) ? icon.width / scale * 0.7 : (scale <= 1.7) ? icon.width / scale : (scale <= 1.8) ? icon.width / scale * 0.2 : icon.width / scale * 1.4;
                icon.height = !isPlan ? 25 :(scale <= 0.65) ? icon.height / scale * 0.5 : (scale <= 1.1) ? icon.height / scale * 0.7 : (scale <= 1.7) ? icon.height / scale : (scale <= 1.8) ? icon.height / scale * 0.2 : icon.height / scale * 1.4;
                const sizeIcon = icon.width;
                return loadImg(getSrcSVG(index)).then(async (chartIcons) => {
                  return loadImg(alarm).then(async (alarmIcon) => {
                    alarmIcon.width = (scale >= 1) ? alarmIcon.width / scale * 1.7 : alarmIcon.width * scale * 1.7;
                    alarmIcon.height = (scale >= 1) ? alarmIcon.height / scale * 1.7 : alarmIcon.height * scale * 1.7;
                    const iconWithChart = {
                      id,
                      x: x * scaleX,
                      y: y * scaleY,
                      img: icon,
                      chart: {
                        x: sizeIcon - sizeIcon / 5 - 2,
                        y: -(sizeIcon * 2 / 5),
                        img: chartIcons,
                        width: jobChart?.totalJobsAmount > 0 ? ((scale >= 1) ? 17 / scale * 1.7 : 17 * scale * 1.7) : 0,
                        height: jobChart?.totalJobsAmount > 0 ? ((scale >= 1) ? 17 / scale * 1.7 : 17 * scale * 1.7) : 0,
                      },
                    };
  
                    const alarm = {
                      x: -(alarmIcon.width / 2),
                      y: sizeIcon - alarmIcon.width / 1.5,
                      img: alarmIcon,
                    };
  
                    return !workingCorrectly
                      ? { ...iconWithChart, alarm }
                      : iconWithChart;
                  });
                });
              });
            }
          )
        );
        setIcons(response);
      }
     
      
    } else {
      setIcons([]);
    }
  };
  
  const drawScheme = async (schemaImg, height, width) => {
    await loadImg(schemaImg).then((image) => {
      setCanvasSize({
        WIDTH: image.width,
        HEIGHT: image.height,
      });
      image.width = width;
      image.height = height;
      setImage(image);
    });
  };

  useEffect(() => {
    schemaImg && drawScheme(schemaImg, height, width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaImg, height, width]);
  useEffect(() => {
    setoffsetAddEquip && setoffsetAddEquip((prev) => stageposition)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageposition]);
  useEffect(() => {
    setscaleAddEquip && setscaleAddEquip((prev) => scale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);
  useEffect(() => {
    console.log("dataass",data)
    if (data) {
      const newData = data?.filter(({ floorSchemeCoordinateX, floorSchemeCoordinateY }) =>
        floorSchemeCoordinateX !== null && floorSchemeCoordinateY !== null
      );
      SCHEMA_CANVAS.WIDTH && drawIcons(newData, height, width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data,SCHEMA_CANVAS]);

  useEffect(() => {
    if(isRenderIconsBlocked) {
      setTimeout(() => {
        setIsRenderIconsBlocked(false)
      }, 300);
    }
  }, [isRenderIconsBlocked, scale])
  const handleMouseOver = () => {
    if (draggable) document.body.style.cursor = "pointer";
  };

  const handleMouseOut = () => {
    document.body.style.cursor = "default";
  };

  const handleDragEnd = (e, id) => {
    const node = e.target;
    
    const scaleX = SCHEMA_CANVAS.WIDTH / width;
    const scaleY = SCHEMA_CANVAS.HEIGHT / height;

    const requestData = data.map((item) => {
      return item.id === id
        ? {
          ...item,
          floorSchemeCoordinateX: node.x() * scaleX,
          floorSchemeCoordinateY: node.y() * scaleY,
        }
        : item;
    });
   // console.log("noode",requestData)
    setValue && setValue(name, requestData);
  };
  const findValueById = (dataArray, id, coor) => {
    const foundObject = dataArray?.find(item => item.id === id);
    return foundObject ? coor == "x" ? foundObject.x : foundObject.y : 0; // Возвращает значение, если объект найден, иначе возвращает null или другое значение по умолчанию
  };
  const findcoordById = (dataArray, id) => {
    const foundObject = xyGroup?.find(item => item.id === id);
    let resArray = dataArray
    if (foundObject)
      resArray = dataArray.map((item, index) => index % 2 === 0 ? item * foundObject.scaleX : item * foundObject.scaleY)
    return resArray; // Возвращает значение, если объект найден, иначе возвращает null или другое значение по умолчанию
  };
  const handleWheel = (e) => {
    if (!isPlan) return
    
    setIsRenderIconsBlocked(true)
    e.evt.preventDefault();
    const stage = stageRef.current;
    let newScale = scale;
    if (e.evt.deltaY < 0) {
      newScale = scale <= 5 ? scale + 0.1 : scale; // Ограничение максимального зума (2 в данном случае)
    } else {
      newScale = scale >= 0.5 ? scale - 0.1 : scale; // Ограничение минимального зума (0.5 в данном случае)
    }
    setScale(() => newScale);

    const pointTo = {
      x: stage.getPointerPosition().x / scale - stage.x() / scale,
      y: stage.getPointerPosition().y / scale - stage.y() / scale,
    };
    //setoffsetAddEquip((prev) => stageposition)
    setstageposition({
      x: -(pointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(pointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    });

  };
  const hexToRgbA = (hex, alpha) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    else return "#f1b8bc80"
  }
  const handleDragEndStage = (e) => {
    if (setoffsetAddEquip) {
      const stage = stageRef.current;
      const coordinates = [-stage.x() / scale, -stage.y() / scale, (width - stage.x()) / scale, (height - stage.y()) / scale];
      setoffsetAddEquip({ x: stage.x(), y: stage.y() })
    }

  }

  return (
    <Box sx={{ position: "relative" }}>
      <ChartsList />

      {!!setFullScreen && (
        <ModalFullScreen
          open={fullscreen}
          setOpen={setFullScreen}
          title={title || ""}
          canvasRef={canvasRef.current?.canvas._canvas || null}
          SCHEMA_CANVAS={SCHEMA_CANVAS}
        />
      )}
      <Stage width={width} height={height} scaleX={scale}
        scaleY={scale}
        ref={stageRef}
        x={stageposition.x}
        y={stageposition.y}
        draggable={isPlan}
        onWheel={handleWheel}
        onDragEnd={handleDragEndStage}
      >

        <Layer ref={canvasRef}>
          <Image image={image} x={0} y={0} />
          {roomAddresses?.map(({ id, coordinates, name, plumes, borderColor, color }, index) => (
            <Group key={id || index}
              x={findValueById(xyGroup, id, "x")}
              y={findValueById(xyGroup, id, "y")}
            >
              <Line
                points={findcoordById(coordinates, id)}
                stroke={borderColor ? borderColor : "red"}
                fill={color ? hexToRgbA(color, 0.5) : "#f1b8bc80"}
                strokeWidth={3 / scale}
                closed={true}
              />
              <Text {...getParamsTextWithScale({ coordinates: findcoordById(coordinates, id), text: name, line: 0, scale })} />
              <Text {...getParamsTextWithScale({ coordinates: findcoordById(coordinates, id), text: plumes[0]?.name || plumes[0], line: 1, scale })} />
            </Group>
          ))}
          {!!icons?.length &&
            icons.map(({ id, x, y, img, chart, alarm }, index) => (
              <CachedRect key={id} id={id} x={x} y={y} draggable={draggable} height={height} width={width} navigate={navigate} isPlan={isPlan} handleMouseOver={handleMouseOver} handleMouseOut={handleMouseOut} handleDragEnd={handleDragEnd} img={img} chart={chart} alarm={alarm} index={index}/>
            ))}
        </Layer>
      </Stage>
    </Box>
  );
};
export const CachedRect = ({ id, x, y, img, chart, alarm,index,draggable,height,width,navigate,isPlan,handleMouseOver,handleMouseOut,handleDragEnd  }) => {
  const rectRef = useRef();
  const chartRef = useRef();
  const imageRef = useRef();

  useEffect(() => {

    if (imageRef.current) {
      imageRef.current.cache();
    }
  }, [height,width,img,chart]);


  return <Group
    key={id || index}
    ref={rectRef}
    draggable={draggable}
    onDragEnd={(e) => handleDragEnd(e, id)}
    onMouseOut={handleMouseOut}
    onMouseOver={handleMouseOver}
    /*dragBoundFunc={(pos) => {
      if (pos.y <= 0) pos.y = 10;
      if (pos.y > height - ICON_SIZE.HEIGHT)
        pos.y = height - ICON_SIZE.HEIGHT;

      if (pos.x <= 0) pos.x = 1;
      if (pos.x > width - ICON_SIZE.WIDTH)
        pos.x = width - ICON_SIZE.WIDTH;
      return {
        x: pos.x,
        y: pos.y,
      };

    }}*/
    onMouseEnter={e => {
      const container = e.target.getStage().container();
      container.style.cursor = 'grab';

    }}
    onMouseLeave={e => {

      const container = e.target.getStage().container();
      container.style.cursor = 'default';

    }}
    x={x}
    y={y}
  >
    <Rect
      x={0}
      y={0}
      width={img.width}
      height={img.height}
      fill="rgba(57,57,242,0.4)"

    />
    <Image ref={imageRef} image={img} x={0} y={0} onClick={() => { if (!isPlan) navigate(PATHS.GET_EQUIPMENT_DETAILS(id)) }} />
    <Image
      ref={chartRef}
      image={chart.img}
      onClick={() => { if (!isPlan) navigate(PATHS.LIST_TASKS + `?equip=${id}`) }}
      x={chart.x}

      y={chart.y}
      width={chart.width}
      height={chart.height}
    />
    {!!alarm && <Image image={alarm.img} x={alarm.x} y={alarm.y} />}
  </Group>;
};

export default memo(SchemaWithEquipments)