import React, { useEffect } from "react";
import {
  WorkContractFileFormItem,
  formFields,
  prevContractResetFields,
} from "./form-config.jsx";
import FormItem from "../../../components/FormItem";
import { Box } from "@mui/material";
import { useWatch } from "react-hook-form";
import { getEmptyValue } from "../../../components/FormItem/helpers";

const WorkContractFile = ({
  control,
  errors,
  register,
  unregister,
  setValue,
  getValues,
  trigger,
  disabled = false,
}) => {
  const isUploadFileItemDisabled = useWatch({
    control,
    name: formFields.equipmentContractFileIdAvailability,
  });

  const isHavingServiceContract = useWatch({
    control,
    name: formFields.havingServiceContract,
  });

  useEffect(() => {
    trigger(Object.keys(prevContractResetFields));
    if (isUploadFileItemDisabled || !isHavingServiceContract) {
      Object.entries(prevContractResetFields).forEach(([name, type]) => {
        setValue(name, getEmptyValue(type));
      });
    }
    // eslint-disable-next-line
  }, [isUploadFileItemDisabled, isHavingServiceContract]);

  return (
    <Box>
      {WorkContractFileFormItem.map(
        ({ id, name, label, input, expended, CustomFormItem, ...rest }) => (
          <FormItem
            key={name}
            control={control}
            errors={errors}
            register={register}
            unregister={unregister}
            getValues={getValues}
            name={name}
            label={label}
            input={input}
            disabled={disabled || isUploadFileItemDisabled}
            trigger={trigger}
            {...rest}
          />
        )
      )}
    </Box>
  );
};

export default WorkContractFile;
