import React from "react";
import { Box, Button, Typography } from "@mui/material";

import ArrowCancelBack from "../../../components/icons/ArrowCancelBack";
import CheckIcon from "../../../components/icons/CheckIcon";
import CloseRedIcon from "../../../components/icons/CloseRedIcon";

export const SubNotificationPanel = ({
  style,
  handleCloseIcon,
  handleCheckIcon,
  handleBackIcon = () => {},
  withCancelBtn,
}) => {
  return (
    <Box className="sub_notification" sx={style}>
      <Typography sx={{mr: '10px'}}>Сохранить помещение?</Typography> 
      {withCancelBtn && (
        <Button onClick={handleBackIcon}>
          <ArrowCancelBack isActive={true} />
        </Button>
      )}
      <Button onClick={handleCheckIcon}>
        <CheckIcon />
      </Button>
      <Button onClick={handleCloseIcon}>
        <CloseRedIcon />
      </Button>
    </Box>
  );
};
