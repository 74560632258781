import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import FormItemCheckboxControlledRecursive from "../FormItem/FormItemCheckboxControlledRecursive";
import FormItem from "../FormItem";

const InputBlockField = ({
  name,
  label,
  input,
  expended,
  title,
  control,
  errors,
  register,
  unregister,
  controlExpandedCheck,
  sectionName,
  setValue,
  watch,
  formFields,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Box className="input-block-field" sx={{ width: "50%", ml: '10px' }}>
      <Typography className="input-block-title">{t(title)}</Typography>
      <Box className="input-block-wrapper">
        {
          input ? (
              <FormItemCheckboxControlledRecursive
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  input={input}
                  register={register}
                  unregister={unregister}
                  setValue={setValue}
                  controlExpandedCheck = {controlExpandedCheck}
                  formFieldss={formFields}
                  expended={expended}
                  sectionName={sectionName}
                  {...rest}
              />
          ) : expended.map(({name: expendedName, label, input, expended, Component, ...rest}) => (
              Component ? <Component disabled={true} key={expendedName}/>
              : (<FormItem
                  key={expendedName}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  
                  name={ rest?.type === "file" ?
                      `${[formFields.files]}.${expendedName}`
                      : sectionName ? `${sectionName}.${expendedName}` : expendedName}
                  label={label}
                  input={input}
                  {...rest}
              />)
          ))
        }

      </Box>
    </Box>
  );
};

export default InputBlockField;
