import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import "./styles/styles.css";

export const ModalConfirmDialog = ({
  open,
  handleAgreeButton,
  handleDisagreeButton,
  contentText,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
      <Dialog
        open={open}
        className="confirm_modal"
      >
        <DialogContent className="confirm_modal-content_field">
          <Typography className="confirm_modal-content_field-text">
            {contentText}
          </Typography>
        </DialogContent>
        <DialogActions className="confirm_modal-btn_field">
          <Button
            className="btn btn-outlined-primary "
            variant="outlined"
            onClick={handleDisagreeButton}
          >
            {t("button:no")}
          </Button>
          <Button
            className="btn btn-primary-fill"
            onClick={handleAgreeButton}
            autoFocus
            { ...rest }
          >
            {t("button:yes")}
          </Button>
        </DialogActions>
      </Dialog>
  );
};