import { createSlice } from "@reduxjs/toolkit";
import { employeeApi } from "../../middleware/employeeApi";

const initialState = {
  allEmployees: {},
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    changeTasksValue(state, action) {
      state.tasksValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      employeeApi.endpoints.getAllEmployees.matchFulfilled,
      (state, { payload }) => {
        state.allEmployees = payload;
      }
    );
  },
});

export const employeeActions = employeeSlice.actions;
export const employeeReducer = employeeSlice.reducer;