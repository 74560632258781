import React from "react";
import {
    ListSubheader,
    ListItemButton,
    ListItemText,
    TextField, Checkbox, Box
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";
import checkImagechecked from "../../../image/icons/checkboxChecked.png"
import checkImage from "../../../image/icons/checkBox.png"
import selectArrowUp from "../../../image/icons/selectArrowUp.png";
import selectArrowDown from "../../../image/icons/selectArrowDown.png"

export const MyListSubheader = ({ options, setOptions, itemOption, selectValue, onChange }) => {
    const { id, name, open, checked, items } = itemOption || {};

    const handleClick = () => {
        const updatedOptions = options?.length && options.map((option) => ({
            ...option,
            open: !option.open && option.id === id,
        }));
        setOptions(updatedOptions);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        const updatedOptions = options?.length && options.map((option) => ({
            ...option,
            checked: option.id === id ? !option.checked : option.checked,
            open: option.id === id ? !option.checked : option.open,
        }));
        const uniqueItems = checked ?
            selectValue.filter(value => !items.find(({ id }) => value === id))
            : [...new Set([...selectValue, ...items.map(({ id }) => id)])];
        onChange(uniqueItems);
        setOptions(updatedOptions);
    }

    return (
        <ListSubheader sx={{ padding: 0,position: "inherit" }}>
            <ListItemButton onClick={handleClick} sx={{ padding: 0,height:"90px" }}>
                <Box sx={{ mt:"14px",mr: "24px" }}>
                    {open ? (
                        <img src={selectArrowUp} />
                    ) : (
                        <img src={selectArrowDown} />
                    )
                    }
                </Box>
                <Checkbox onClick={handleCheckboxClick} checked={checked}
                    sx={{ height: "28px", width: "28px", borderRadius: 0, mr: "8px" }}
                    icon={<Box
                        component="img"
                        src={checkImage}
                    />}
                    checkedIcon={<Box
                        component="img"
                        src={checkImagechecked}
                    />}
                />
                <ListItemText primary={name} />
            </ListItemButton>
        </ListSubheader>
    );
};

MyListSubheader.muiSkipListHighlight = true;

export const MyListSubheaderSearch = ({ searchValue, setSearchValue }) => {
    const { t } = useTranslation();

    const handleChange = (e) => {
        setSearchValue(e.target.value)
    }

    const handleKeyDown = (e) => {
        if (e.key !== "Escape") {
            e.stopPropagation();
        }
    }

    return (
        <ListSubheader
            sx={{
                zIndex: 5,
                paddingLeft: 0,
                paddingRight: 0,
            }}
        >
            <TextField
                fullWidth
                autoFocus
                placeholder={t("placeholders:search_select")}
                value={searchValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
        </ListSubheader>
    );
};

MyListSubheaderSearch.muiSkipListHighlight = true;
