import React from "react";
import { IconButton, Box } from "@mui/material";
import EditBtnIcon from "../icons/EditBtnIcon";

const EditButton = ({ handleClick }) => {
  return (
    <Box
      className="edit-button-image"
      onClick={handleClick}
      sx={{
        display: 'flex',
        width: "30px",
        height: "30px",
      }}
    >
      <EditBtnIcon />
    </Box>
  );
};

export default EditButton;
