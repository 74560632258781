import {Avatar, Box, Typography} from "@mui/material";
import React from "react";
import {useAppSelector} from "../../../hooks/redux";

const ProfileItemView = () => {

    const { firstName, lastName, avatarLink, role } = useAppSelector(
        (state) => state.users.info
    );

    return (
        <Box
            sx={{
                display: "flex",
                columnGap: "15px",
                alignItems: "center"
            }}
        >
            <Avatar
                sx={{ backgroundColor: "var(--main)", width: '52px', height: '52px' }}
                alt={`${firstName} ${lastName}`}
                src={avatarLink}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography className="default-label-text strong">
                    {firstName} {lastName}
                </Typography>
                <Typography className="default-label-text sm">{role}</Typography>
            </Box>
        </Box>
    )
}

export default ProfileItemView;