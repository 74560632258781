import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ListItemText, Box } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import selectArrowUp from "../../../image/icons/selectArrowUp.png";
import selectArrowDown from "../../../image/icons/selectArrowDown.png"


const SelectSimpleInbuilt = ({
  options,
  label,
  onChange,
  error,
  value,
  withEmptyField = false,
  bgcolor,
  setValue,
  ...rest
}) => {
  const { t } = useTranslation();

  const [currentValue, setCurrentValue] = useState(
    withEmptyField ? value : value || t(label)
  );
  const [isOpen, setIsOpen] = useState(false);
  const ITEM_HEIGHT = 45;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: options?.length>=4 ? (ITEM_HEIGHT+ ITEM_PADDING_TOP) * 4 : (ITEM_HEIGHT+ ITEM_PADDING_TOP) * options?.length ,
      },
    },
  };
  
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleChange = (v) => {
    const updatedValue = v === t(label) ? (withEmptyField ? "" : v) : v;

    setCurrentValue(updatedValue);
    onChange(v === t(label) ? "" : v);
  };

  return (
    <FormControl fullWidth {...rest} sx={{ background: bgcolor ? bgcolor : "inherit", height: "24px" }}>
      {!withEmptyField && <InputLabel>{t(label)}</InputLabel>}
      <Select

        value={currentValue}
        onChange={({ target }) => handleChange(target?.value)}
        open={isOpen}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}

        onOpen={handleOpen}
        onClose={handleClose}
        IconComponent={() =>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => { setIsOpen((prev) => !prev) }}>
            {isOpen ? (
              <img src={selectArrowUp} />
            ) : (
              <img src={selectArrowDown} />
            )
            }
          </Box>}
        renderValue={(value) => {
          const { name } =
            options.find((option) => option.value === value) || {};
          return (
            <ListItemText
              primary={
                value === t(label)
                  ? withEmptyField && !currentValue
                    ? name
                    : t(label)
                  : name
              }
            />
          );
        }}

        MenuProps={MenuProps}
        sx={{ ".MuiSelect-select": { height: 0 }, }}

      >
        <MenuItem disabled value="">
          {t(label)}
        </MenuItem>
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value} sx={{ height: ITEM_HEIGHT }}>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectSimpleInbuilt;
