import React from "react";
import {
  ListSubheader,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const Search = ({searchValue, setSearchValue}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
     setSearchValue(e.target.value)
  }

  const handleKeyDown = (e) => {
      if (e.key !== "Escape") {
          e.stopPropagation();
      }
  }

  return (
    <ListSubheader
      sx={{
        zIndex: 5,
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <TextField
          fullWidth
          autoFocus
          placeholder={t("placeholders:search_select")}
          value={searchValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
      />
    </ListSubheader>
  );
};

Search.muiSkipListHighlight = true;

export default Search;