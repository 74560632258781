import FormItem from "../../../../components/FormItem";

const CustomFormItem = ({ formConfig, ...rest }) => {
  return formConfig.map(item => {
    const name = [rest.name, item.name].filter(name => name).join(".")
    return (
      <FormItem
        key={name}
        {...item}
        {...rest}
        name={name}
      />
    );
  });
};

export default CustomFormItem;