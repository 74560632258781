import * as React from "react";
import { Dialog, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Dogovor1 = ({ open, onClose, objectId }) => {
  const textStyle = {
    fontSize: "14px",
    lineHeight: "1.5",
    marginBottom: "16px",
    textAlign: "justify",
  };

  const headingStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "12px",
  };

  const listStyle = {
    marginLeft: "24px",
    marginBottom: "16px",
  };
  return (
    <Dialog className="object_dialog" open={open} onClose={onClose}>
      <IconButton className="close_icon" onClick={onClose}>
        <CloseIcon />
      </IconButton>

      <Typography>
        <div>
          <h2 style={headingStyle}>
            Согласие пользователя сайта на обработку персональных данных
          </h2>
          <p style={textStyle}>
            Оператор ООО «ВДПО-ПРО», в соответствии с Конституцией РФ,
            Федеральным законом от 27.07.2006 N 152-ФЗ "О персональных данных",
            Регламентом N 2016/679 Европейского парламента и Совета Европейского
            союза "О защите физических лиц при обработке персональных данных и о
            свободном обращении таких данных, а также об отмене Директивы
            95/46/ЕС (Общий Регламент о защите персональных данных)" (Принят в
            г. Брюсселе 27.04.2016), настоящим получает мое согласие на
            обработку моих персональных данных со следующими условиями:
          </p>

          <h3 style={headingStyle}>
            1. Данное согласие дается на обработку персональных данных
          </h3>
          <p style={textStyle}>
            как без использования средств автоматизации, так и с их
            использованием.
          </p>

          <h3 style={headingStyle}>
            2. Согласие дается на обработку следующих персональных данных:
          </h3>
          <ul style={listStyle}>
            <li style={textStyle}>фамилия, имя, отчество;</li>
            <li style={textStyle}>адрес субъекта персональных данных;</li>
            <li style={textStyle}>
              номер основного документа, удостоверяющего личность субъекта
              персональных данных;
            </li>
            <li style={textStyle}>
              сведения о дате выдачи основного документа, удостоверяющего
              личность субъекта персональных данных и сведения о выдавшем его
              органе;
            </li>
            <li style={textStyle}>
              идентификационный номер налогоплательщика;
            </li>
            <li style={textStyle}>
              страховой номер индивидуального лицевого счета;
            </li>
            <li style={textStyle}>
              название, реквизиты официального места трудоустройства;
            </li>
            <li style={textStyle}>
              название должности на официальном месте трудоустройства;
            </li>
            <li style={textStyle}>
              фамилия, имя, отчество представителя субъекта персональных данных;
            </li>
            <li style={textStyle}>
              адрес представителя субъекта персональных данных;
            </li>
            <li style={textStyle}>
              номер основного документа, удостоверяющего личность представителя
              субъекта персональных данных;
            </li>
            <li style={textStyle}>
              сведения о дате выдачи основного документа, удостоверяющего
              личность представителя субъекта персональных данных и сведения о
              выдавшем его органе;
            </li>
            <li style={textStyle}>
              идентификационный номер налогоплательщика представителя субъекта
              персональных данных;
            </li>
            <li style={textStyle}>
              страховой номер индивидуального лицевого счета представителя
              субъекта персональных данных;
            </li>
            <li style={textStyle}>
              название, реквизиты официального места трудоустройства
              представителя субъекта персональных данных;
            </li>
            <li style={textStyle}>
              название должности на официальном месте трудоустройства
              представителя субъекта персональных данных;
            </li>
            <li style={textStyle}>
              реквизиты доверенности или иного документа, подтверждающего
              полномочия представителя субъекта персональных данных;
            </li>
            <li style={textStyle}>
              номер телефона (домашний/сотовый) субъекта персональных данных;
            </li>
            <li style={textStyle}>
              электронная почта субъекта персональных данных;
            </li>
            <li style={textStyle}>
              пользовательские данные субъекта персональных данных (сведения о
              местоположении, тип и версия ОС, тип и версия браузера, тип
              устройства и разрешение его экрана; источник, откуда пришел на
              сайт пользователь; с какого сайта или по какой рекламе; язык ОС и
              браузера; какие страницы открывает и на какие кнопки нажимает
              пользователь; ip-адрес; информация поискового или рекламного
              запроса; пользовательские клики, просмотры страниц, заполнения
              полей, показы и просмотры баннеров и видео; данные,
              характеризующие аудиторные сегменты; параметры сессии; данные о
              времени посещения; идентификатор пользователя, хранимый в cookie);
            </li>
            <li style={textStyle}>
              данные обо мне, которые станут известны в ходе исполнения
              договоров (в случае заключения договора между мной и Оператором),
              а также иная общедоступная информация обо мне.
            </li>
          </ul>

          <h3 style={headingStyle}>
            3. Персональные данные не являются общедоступными.
          </h3>

          <h3 style={headingStyle}>4. Цель обработки персональных данных:</h3>

          <h3 style={headingStyle}>
            5. В ходе обработки с персональными данными будут совершены
            следующие действия:
          </h3>

          <h3 style={headingStyle}>
            6. Настоящим я уведомлен Оператором, что предполагаемыми
            пользователями персональных данных являются работники Оператора, а
            также лица, привлеченные Оператором на условиях гражданско-правовых
            договоров.
          </h3>

          <h3 style={headingStyle}>
            7. Даю свое согласие на предоставление ООО «ВДПО-ПРО» моих
            персональных данных как посетителя сайта третьим лицам, с которыми
            ООО «ВДПО-ПРО» сотрудничает и взаимодействует.
          </h3>

          <h3 style={headingStyle}>8. Я ознакомлен (а), что:</h3>
          <p style={textStyle}>
            8.1. настоящее Согласие на обработку моих персональных данных
            является бессрочным и может быть отозвано посредством направления
            мною в адрес Оператора по номеру телефона кол-центра отраженного в
            действующем ресурсе ВДПО_ПРО, либо на электронную почту Оператора
            VDPOPROADM2023@mail.ru письменного заявления. Датой отзыва считается
            день, следующий за днем вручения Оператору письменного заявления об
            отзыве согласия пользователя сайта на обработку персональных данных
            либо дата получения письма посредством электронной почты;
          </p>
          <p style={textStyle}>
            8.2. имею право на доступ к моим персональным данным, требовать
            уточнения (обновление, изменение) моих персональных данных, а также
            удаления и уничтожения моих персональных данных в случае их
            обработки Оператором, нарушающих мои законные права и интересы,
            законодательство Российской Федерации.
          </p>
          <p style={textStyle}>
            8.3. в случае отзыва согласия на обработку персональных данных
            Оператором вправе продолжить обработку персональных данных без моего
            согласия при наличии оснований, указанных в пунктах 2.11 части 1
            статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона
            №152-ФЗ «О персональных данных» от 27.07.2006 г.
          </p>

          <h3 style={headingStyle}>
            9. Я обязуюсь в случае изменения моих персональных данных, сведений
            обо мне, незамедлительно сообщить об этом Оператору, с
            предоставлением подтверждающих документов.
          </h3>

          <h3 style={headingStyle}>
            10. Настоящим Согласием я подтверждаю, что являюсь субъектом
            предоставляемых персональных данных, а также подтверждаю
            достоверность предоставляемых данных.
          </h3>

          <h3 style={headingStyle}>
            11. Настоящее Согласие действует все время до момента прекращения
            обработки персональных данных, согласно п. 8.1 Согласия
          </h3>
        </div>
      </Typography>
    </Dialog>
  );
};
