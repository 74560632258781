import React from "react";
import { List, ListItem, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { PATHS, globalFields } from "../../../constants";
import { useTranslation } from "react-i18next";

const TaskStateList = ({
  fontSize = "var(--standartLg)",
  lineHeight = null,
  height = null,
  width = null,
  marginLeft = null,
  fontWeight = 400,
  close,
  data,
  isShowAlternativeName = false,
}) => {
  const { t } = useTranslation();
  const getPath = (name) => `&status=${globalFields[name]}`;
  return (
    <List sx={{ marginLeft }}>
      {!!data?.length && data.filter((item)=>item.name != "total_jobs_amount").map(({ value, color, name, alternativeName, action }, i) => (
        <ListItem key={name} sx={{ padding: 0, marginBottom: `${data.length === i + 1 ? "0px" : "12px"}` }}>
          <Box
            sx={{
              height,
              width,
              display: "inline-block",
              borderRadius: "50%",
              marginRight: "10px",
              backgroundColor: color,
            }}
          />
  
          <Box
            sx={{
              fontFamily: "var(--INTER)",
              lineHeight,
              fontWeight,
              fontSize,
            }}
          >
            <Link onClick={close} className="link-black" to={`${PATHS.LIST_TASKS}${action && action.length ? action : ""}${action && action.length ? `&status=${globalFields[name]}` : `?status=${globalFields[name]}`}`}>
              {`${t(`chart:${isShowAlternativeName ? alternativeName : name}`)} ${value}`}
            </Link>
          </Box>
        </ListItem>
      ))}
    </List>
  );
};
export default TaskStateList;
