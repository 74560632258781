import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const calendarActions = calendarSlice.actions;
export const calendarReducer = calendarSlice.reducer;
