import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const documentationApi = createApi({
  reducerPath: "documentation/api",
  tagTypes: ["Documentation"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getActsTable: build.query({
      query: ({ pageNumber, ...params }) => ({
        url: `api/documentation/get-acts-table?pageNumber=${pageNumber}&${Object.keys(
          params
        )
          .map((key) => `${key}=${params[key]}`)
          .join("&")}`,
      }),
      providesTags: ["Documentation"],
    }),
    addAFASystemAct: build.mutation({
      query: (data) => ({
        url: `api/documentation/add-afa-system-act`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Documentation"],
    }),
    addSNMESystemAct: build.mutation({
      query: (data) => ({
        url: `api/documentation/add-notif-and-evac-system-act`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Documentation"],
    }),
    generateFASAct: build.mutation({
      query: ({ protectionObjectId }) => ({
        url: `api/documentation/generate-fas-act?protectionObjectId=${protectionObjectId}`,
        method: "POST",
      }),
      invalidatesTags: ["Documentation"],
    }),
    generateDefect: build.mutation({
      query: (data) => ({
        url: `api/documentation/add-defect-act-for-equipment`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Documentation"],
    }),
    toSignAct: build.mutation({
      query: ({ actId, actType }) => ({
        url: `api/documentation/sign-act`,
        method: "POST",
        body: { actId, actType },
      }),
      invalidatesTags: ["Documentation"],
    }),
    toSignDefectAct: build.mutation({
      query: ({ actId, actType }) => ({
        url: `api/documentation/sign-defect-act`,
        method: "POST",
        body: { actId, actType },
      }),
      invalidatesTags: ["Documentation"],
    }),
    actexists: build.query({
      query: ({protectionObjectId,actType}) => ({
        url: `api/documentation/act-exists?protectionObjectId=${protectionObjectId}&actType=${actType}`,
      }),
    }),
    getDocEquipmentSelectElementsByFacilityId: build.query({
      query: ({ searchValue, facilityId,actType }) => ({
        url: `api/documentation/get-equipment-select-elements-by-facility-id-groupped?searchValue=${searchValue}&protectionObjectId=${facilityId}&actType=${actType } `,
      }),
     
    }),
    getDocEquipmentSelectElementsByFacilityIdSNNME: build.query({
      query: ({ searchValue, facilityId,actType  }) => ({
        url: `api/documentation/get-equipment-select-elements-by-facility-id-groupped?searchValue=${searchValue}&protectionObjectId=${facilityId}&actType=${actType } `,
      }),
     
    }),
  }),
});

export const {
  useLazyGetActsTableQuery,
  useAddAFASystemActMutation,
  useAddSNMESystemActMutation,
  useGenerateFASActMutation,
  useToSignActMutation,
  useToSignDefectActMutation,
  useGenerateDefectMutation,
  useLazyActexistsQuery,
  useLazyGetDocEquipmentSelectElementsByFacilityIdQuery,
  useLazyGetDocEquipmentSelectElementsByFacilityIdSNNMEQuery
} = documentationApi;
