import React from 'react';
import {useDroppable} from '@dnd-kit/core';

const Droppable = (props) => {
  const {setNodeRef} = useDroppable({
    id: 'droppable',
  });

  return (
    <div ref={setNodeRef} className="droppable">
      {props.children}
    </div>
  );
};

export default Droppable;