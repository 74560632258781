import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";

const ModalClosePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { pathForModalClosePage } = useAppSelector((state) => state.settings);
  const { setIsChangeForm, setPathForModalClosePage } = useAction();
  const open = !!pathForModalClosePage;

  const handleLeave = () => {
    navigate(pathForModalClosePage);
    setPathForModalClosePage(false);
    setIsChangeForm(false);
  };

  const handleClose = () => {
    setPathForModalClosePage(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="modal"
      >
        <DialogTitle className="modal-title-right">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0 40px" }}>
          <DialogContentText className="modal-text">
            {t("modal_close:text")}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="modal-btn-field modal-btn-field-center">
          <Button
            className="btn btn-outlined-primary "
            variant="outlined"
            onClick={handleLeave}
          >
            {t("modal_close:btn_leave")}
          </Button>
          <Button
            className="btn btn-primary-fill"
            onClick={handleClose}
            autoFocus
          >
            {t("modal_close:btn_stay")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalClosePage;
