import { createSlice } from "@reduxjs/toolkit";
import { fireCompartmentApi } from "../../middleware/fireCompartmentApi";
const initialState = {
  fireCompartment: null,
  searchValue: "",
  fireCompartments: [],
  fireCompartmentValue: {},
};

export const fireCompartmentSlice = createSlice({
  name: "fireCompartment",
  initialState,
  reducers: {
    changeFireCompartmentValue(state, action) {
      state.fireCompartmentValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      fireCompartmentApi.endpoints.getFireCompartment.matchFulfilled,
      (state, { payload }) => {
        state.fireCompartment = payload;
      }
    );
    builder.addMatcher(
      fireCompartmentApi.endpoints.getFireCompartments.matchFulfilled,
      (state, { payload }) => {
        state.fireCompartments = payload;
      }
    );
  },
});

export const fireCompartmentActions = fireCompartmentSlice.actions;
export const fireCompartmentReducer = fireCompartmentSlice.reducer;
