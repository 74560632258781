import React,{useState} from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AccordionSection = ({ title, children,expandedAccordions,handleAccordionChange,idd}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const handleExpandAll = () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion
      square={true}
      disableGutters={true}
      sx={{
        background: "var(--white)",
        border: "none",
        boxShadow: "none",
        borderTop: "2px solid #E2E8F0",
      }}
      expanded={(expandedAccordions) ? expandedAccordions.includes(idd) : expanded}
      onChange={handleAccordionChange ? handleAccordionChange(idd) : handleExpandAll}
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          flexDirection: "row-reverse",
          padding: '0',
          svg: { color: "var(--black)" },
        }}
      >
        <Typography className="form-section-label">
          {t(`title:${title}`)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "16px 0", background: "var(--white)" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionSection;
