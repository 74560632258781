// libs imports
import React from 'react';
import {fileSizeCheck, fileTypeCheck} from "../../../../validation";
import {Box, Typography} from "@mui/material";
import {t} from "i18next";
import InputFileSingle from "../../../components/InputFile/InputFileSingle";
import DatePickerField from "../../../components/DatePickerField";
import WorkContractDateItem from "../DateRelatedItems/WorkContractDateItem";
import CheckboxSimple from "../../../components/CheckboxSimple";
import WorkContractFile from "./WorkContractFile";
import InputText from "../../../components/InputText";
import {
    PerformerSelectGrouping,
    PrevWorkContractSelect
} from "../Components";

export const formFields = {
    workContract: "workContract",
    equipmentContractFileIdAvailability: "equipmentContractFileIdAvailability",
    contractDate: "contractDate",
    termOfContract: "termOfContract",
    equipmentContractFileId: "equipmentContractFileId",
    selfService: "selfService",
    contractNumber: "contractNumber",
    responsibleOrganizationId: "responsibleOrganizationId",
    havingServiceContract: "havingServiceContract",
};

export const resetFields = {
    [formFields.workContract] : "file",
    [formFields.contractDate] : "date",
    [formFields.termOfContract] : "number",
    [formFields.equipmentContractFileIdAvailability] : "checkbox",
    [formFields.equipmentContractFileId]: "select-text",
    [formFields.contractNumber]: "text",
    [formFields.responsibleOrganizationId]: "select-text",
}

export const prevContractResetFields = {
    [formFields.workContract] : "file",
    [formFields.contractDate] : "date",
    [formFields.contractNumber]: "text",
}

export const WorkContractFileFormItem = [
    {
        name: formFields.workContract,
        type: "file",
        rules: {
            validate: {
                type: (files, formValues) => {
                    if(formValues.equipmentContractFileIdAvailability) {
                        return null;
                    }
                    const availableFileTypes = ["image/jpeg", "image/png", "application/pdf"];
                    return fileTypeCheck(files.filesToAdd, formFields.workContract, availableFileTypes);
                },
                size: (files, formValues) => {
                    if(formValues.equipmentContractFileIdAvailability) {
                        return null;
                    }
                    const maxAvailableFileSize = 10 // MBs
                    return fileSizeCheck(files.filesToAdd, formFields.workContract, maxAvailableFileSize)
                },
            },
        },
        canDeleteFile: false,
        preview: false,
        input: (props) => (
            <Box>
                <Typography className="label">{t("label:workContract")}</Typography>
                <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} />
            </Box>
        ),
    },
    {
        name: formFields.contractDate,
        label: "label:contractDate",
        type: "date",
        rules: {
            validate: {
                required: (value, formValues) => {
                    if(formValues.selfService || 
                      formValues.equipmentContractFileIdAvailability || 
                      !formValues.havingServiceContract
                    ) {
                        return null;
                    }
                    return !!value
                },
            }
        },
        input: (props) => (
          <Box className="medium_container">
            <DatePickerField {...props} />
          </Box>
        ),
    },
    {
        name: formFields.contractNumber,
        label: "label:contractNumber",
        type: "text",
        rules: {
            validate: {
                required: (value, formValues) => {
                    if(formValues.selfService ||
                      formValues.equipmentContractFileIdAvailability ||
                      !formValues.havingServiceContract
                    ) {
                        return null;
                    }
                    return !!value
                },
            }
        },
        input: (props) => (
          <Box className="medium_container">
            <InputText {...props} />
          </Box>
        ),
    },
]

export const WorkContractFormItem = [
    {
        name: formFields.responsibleOrganizationId,
        label: "label:executiveUserName",
        type: "select-text",
        rules: {
            validate: {
                required: (value, formValues) => {
                    if(formValues.selfService || !formValues.havingServiceContract) {
                        return null
                    }
                    return !!value
                },
            }
        },
        input: (props) => (
            <PerformerSelectGrouping {...props} />
        ),
    },
    {
        name: formFields.equipmentContractFileIdAvailability,
        label: "label:equipmentContractFileIdAvailability",
        type: "checkbox",
        rules: {},
        flexBasis: "100%",
        expended: [
            {
                name: formFields.equipmentContractFileId,
                label: "label:equipmentContractFileId",
                type: "select-text",
                options: [],
                rules: {
                    validate: {
                        required: (value, formValues) => {
                            if (!formValues.havingServiceContract) return null;
                            if(formValues.equipmentContractFileIdAvailability) {
                                return !!value
                            }
                        },
                    }
                },
                input: (props) => <PrevWorkContractSelect {...props} />,
            },
        ],
        input: (props) => (
          <Box className="resizing">
            <CheckboxSimple {...props} />
          </Box>
        ),
    },
    {
        id: 8,
        rules: {},
        CustomFormItem: WorkContractFile,
    },
    {
        id: 4,
        name: formFields.termOfContract,
        label: "label:termOfContract",
        rules: {},
        CustomFormItem: WorkContractDateItem,
    },
];