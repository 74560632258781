import React from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Checkbox, Typography,Box } from "@mui/material";
import checkImagechecked from "../../../image/icons/checkboxChecked.png"
import checkImage from "../../../image/icons/checkBox.png"

const CheckboxSimple = ({
  label,
  onChange,
  value,
  disabled,
  tLabel = true,
  className = "",

  shouldTriggerValidation,
  trigger,
}) => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      className="checkbox"
      label={
        <Typography className={`checkbox-label ${className}`}>
          {tLabel ? t(label) : label}
        </Typography>
      }
      control={
        <Checkbox checked={!!value} onChange={onChange} disabled={disabled} 
        sx={{height:"28px",width:"28px",borderRadius:0,mr:"8px"}}
        icon={<Box
          component="img"
          src={checkImage}
        />}
          checkedIcon={<Box
            component="img"
            src={checkImagechecked}
          />}
        />
      }
    />
  );
};

export default CheckboxSimple;
