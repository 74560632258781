import { screenSizes } from "../constants";

export const getTableHieght = (currentScreenSize, documentPageFlag) => {

  if (documentPageFlag) {
    return {
        max:
          currentScreenSize.height < screenSizes.defaultDesktop.height
            ? "46vh"
            : "53vh",
        min:
          currentScreenSize.height < screenSizes.defaultDesktop.height
            ? "50vh"
            : "53vh",
      };
  } else {
    return {
      max:
        currentScreenSize.height < screenSizes.defaultDesktop.height
          ? "63vh"
          : "65vh",
      min:
        currentScreenSize.height < screenSizes.defaultDesktop.height
          ? "55vh"
          : "60vh",
    };
  }
};
