import SelectSimple from "../../../components/SelectSimple";
import React, { useMemo, useState } from "react";
import { Box, TextField,Typography,IconButton, Tooltip   } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGetOrganizationsOgrnQuery } from "../../../../middleware/apiOrganizations";

import { formFields } from "./form-config";
import { useGetFacilitiesSelectWithAddressForActQuery } from "../../../../middleware/facilitiesApi";
import {  useGetUsersForOrganizationQuery } from "../../../../middleware/usersApi";
import { SelectMultipleChipDeletableWithSearchField } from "../../../components/SelectMultipleChipDeletableWithSearchField";
import SelectSimpleFieldArray from "../../../components/SelectSimpleFieldArray";
import InputText from "../../../components/InputText";
import SelectSimpleSearch from "../../../components/SelectSimple/SelectSimpleSearch";
import InfoIcon from "@mui/icons-material/Info";
import { useAppSelector } from "../../../../hooks/redux";
export const SelectSimpleObjectNameAndAddress = (props) => {
  const { value,setValue } = props;
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();

  const { data = [] } = useGetFacilitiesSelectWithAddressForActQuery({actType:1,facilityName:searchValue});

  const options = data.map(({ name, id }) => ({
    name,
    value: id,
  }));

  const address = useMemo(
    () => data.find(({ id }) => id === value)?.address,
    [data, value]
  );
  const hakuna =  useMemo(() =>
  data.find(({ id }) => id === value),
  [data, value]);
 
  setValue(formFields.notifAndEvacControlSysType ,hakuna?.notifAndEvacControlSysType )
  setValue(formFields.notifAndEvacControlSysInstallationDate ,hakuna?.notifAndEvacControlSysInstallationDate )
  return (
    <>
      <SelectSimpleSearch sx={{ ".MuiTypography-root": { padding:0 } }} {...props}  searchValue={searchValue}
      setSearchValue={setSearchValue} options={options} />
      {address && (
        <Box sx={{ mt: "20px", display: "flex", columnGap: "10px" }}>
          <TextField
            label={t("label:city")}
            variant="outlined"
            value={address.city}
            onChange={() => {}}
            disabled
          />
          <TextField
            label={t("label:street")}
            variant="outlined"
            value={address.street}
            onChange={() => {}}
            disabled
          />
          <TextField
            label={t("label:building")}
            variant="outlined"
            value={address.building}
            onChange={() => {}}
            disabled
          />
          <TextField
            label={t("label:extension")}
            variant="outlined"
            value={address.extension || ""}
            onChange={() => {}}
            disabled
          />
        </Box>
      )}
    </>
  );
};

export const SelectSimpleInstallationOrganization = (props) => {
  const { value, setValue } = props;
  const { t } = useTranslation();

  const { data: organizationsData } = useGetOrganizationsOgrnQuery();

  const options = useMemo(() => {
    if (!organizationsData) {
      return [];
    }
    return organizationsData?.map(({ name, id }) => ({
      name,
      value: id,
    }));
  }, [organizationsData]);

  const selectedOgrn = useMemo(() => {
    if (!organizationsData?.length || !value) {
      setValue("organizationType", "");
      return null;
    }
    const { ogrn, organizationTypeId } = organizationsData.find(
      ({ id }) => id === value
    );
    setValue("organizationType", organizationTypeId);
    return ogrn;
  }, [organizationsData, value, setValue]);

  return (
    <Box sx={{ display: "flex", columnGap: "10px" }}>
      <SelectSimple options={options} {...props} />
      <TextField
        label={t("label:ogrnNumber")}
        variant="outlined"
        value={selectedOgrn || ""}
        onChange={() => {}}
        disabled
      />
    </Box>
  );
};


export const FacilityEquipmentSelectGrouping = (props) => {

  
  const { isActExist } = useAppSelector(
    (state) => state.documentation
  );
  
  if (!isActExist) {
   
    return <Box sx={{width:"260px",}}><InputText sx={{display:"none"}} disabled {...props} /><Typography sx={{ whiteSpace: "normal !important"}}>{props.equiptyp}</Typography></Box>
    
  }
  else
    return <SelectSimpleFieldArray  {...props} />
};


export const SelectMultipleComposedCommission = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: organizationsSelect = [] } = useGetUsersForOrganizationQuery({ searchValue });
  const { data: firstData = [] } =
  useGetUsersForOrganizationQuery("");

  const getAllOrganization = (data) => {
    return data?.map(({ id, name }) => ({
      name: name,
      id: id,
    }));
  };  
  const { t } = useTranslation();
  const selectedOptions = useMemo(
    () => getAllOrganization(organizationsSelect),
    [organizationsSelect]
  );
  return (
    <Box sx={{display: "flex", position: 'relative', width:"100%", "& .input_text": { width: '100%'}}}>
      <SelectMultipleChipDeletableWithSearchField
        options={selectedOptions}
        firstData={firstData}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        {...props}
      />
      <Tooltip title={t(`label:comissionvalidate`)}>
        <IconButton sx={{position: 'absolute', right: '-35px', top: '-20px'}} className="icon-btn">
          <InfoIcon sx={{ color: "var(--main)" }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}; 