import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  /* maintainAspectRatio: false, */
  plugins: {
    legend: {
      display: true,
      position: "top",
      labels: {
        usePointStyle: true,
      },
    },
  },
};

const AreaChart = ({ data }) => {
  const { t } = useTranslation();
  const labels = data.datesOrMonths.map((date) => t(`${date}`));

  const datasets = data.chartDatasets.map((dataset) => ({
    label: t(`label:${dataset.name}`),
    fill: true,
    backgroundColor:
      dataset.name === "requiresAttention"
        ? "rgba(249, 247, 219, 0.4)"
        : dataset.name === "overdue"
        ? "rgba(250, 177, 177, 0.4)"
        : dataset.name === "implementedWithinTheDeadline"
        ? "rgba(253, 232, 233, 0.4)"
        : "rgba(222, 247, 236, 0.4)",
    borderColor:
      dataset.name === "requiresAttention"
        ? "#F59E0B"
        : dataset.name === "overdue"
        ? "#B90C2B"
        : dataset.name === "implementedWithinTheDeadline"
        ? "#FB6A6A"
        : "#1DA37B",
    tension: 0.4,
    data: dataset.data,
  }));

  return (
    <Box
      style={{
        border: "1px solid #B6BCC5",
        marginTop: "40px",
      }}
    >
      <Line
        style={{ height: "350px" }}
        options={options}
        data={{ labels, datasets }}
      />
    </Box>
  );
};

export default AreaChart;
