import { ROLES } from "../../../../constants";
import { getIsDisabledField } from "./options";

export const getConfigByRole = ({ roleId, config }) => {
  const roleUser = ROLES[roleId];
let arr = config.map((item) => ({
  ...item,
  disabled: false,
  role: roleUser, 
}));

  return arr
};
export const getFacilityAndFireCompartmentOptionsGroup = (data) => 
  data.map(el => ({
    id: el.id,
    name: el.name,
    items: el.fireCompartments,
    open: false,
  }));


  export const getRequestDataAdd = ({data,id}) => {
    const { equipmentKindsIds,techcardFiles} = data || {};
    const entities = {
      ...data, 
      id:id,
      equipmentKindId:(equipmentKindsIds && equipmentKindsIds?.length > 0) ? JSON.parse(equipmentKindsIds).id : null,
      equipmentTypeId:(equipmentKindsIds && equipmentKindsIds?.length > 0) ? JSON.parse(equipmentKindsIds).parentId.replace("parent", "") : null,
    };
    delete entities.equipmentKindsIds
    delete entities.techcardFiles
    
  
    return {entities,techcardFiles};
  };  

export const getRequestData = ({data,id}) => {
  const { equipmentKindsIds,techcardFiles} = data || {};
  const entities = {
    ...data, 
    id:id,
    equipmentTypeId:(equipmentKindsIds && equipmentKindsIds?.length > 0) ? JSON.parse(equipmentKindsIds).id : null,
    equipmentKindId:(equipmentKindsIds && equipmentKindsIds?.length > 0) ? JSON.parse(equipmentKindsIds).parentId.replace("parent", "") : null,

  };
  delete entities.equipmentKindsIds
  delete entities.techcardFiles
  

  return {entities,techcardFiles};
};

export const getRequestDataFilesForAdd = (id, files) => {
  const formData = new FormData();

  if(!files) {
      return formData;
  }

  const { filesToAdd, filesToDelete } = files || {};
  let fileConfigIndex = 0;

  if(filesToAdd?.length) {
    filesToAdd.forEach((file, index) => {
        fileConfigIndex += index
        formData.append(`entityFileList[${fileConfigIndex}].EntityId`, id);
        formData.append(`entityFileList[${fileConfigIndex}].EntityField`, "TechMap");
        formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "Regulation");
        formData.append(`entityFileList[${fileConfigIndex}].File`, file);
        formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Add");
    })
    fileConfigIndex++
  }
  if(filesToDelete?.length) {
    filesToDelete.forEach((file, index) => {
        if (file.id) {
            fileConfigIndex += index
            formData.append(`entityFileList[${fileConfigIndex}].EntityId`, id);
            formData.append(`entityFileList[${fileConfigIndex}].EntityField`, "TechMap");
            formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "Regulation");
            formData.append(`entityFileList[${fileConfigIndex}].id`, file.id);
            formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Delete");
        }
    })
    fileConfigIndex++;
  }

  return formData;
};
export const getEquipmentTypeIdOptionsGroup = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((el) => ({
    id: el?.id,
    name: el?.title,
    items: el?.equipmentTypes,
    open: false,
  }));
};