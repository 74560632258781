import React from "react";

const PdfIcon = () => {
    return (
        <svg width="30" height="42" viewBox="0 0 30 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1523 36.867V30.7246H13.5987C14.1502 30.7246 14.5726 30.883 14.8659 31.1998C15.1651 31.5107 15.3147 31.939 15.3147 32.4846C15.3147 33.0302 15.1651 33.4614 14.8659 33.7782C14.5726 34.0891 14.1502 34.2446 13.5987 34.2446H11.8915V36.867H11.1523ZM11.8915 33.5934H13.5987C13.8921 33.5934 14.1179 33.5171 14.2763 33.3646C14.4406 33.2121 14.5227 32.995 14.5227 32.7134V32.2558C14.5227 31.9742 14.4406 31.7571 14.2763 31.6046C14.1179 31.4521 13.8921 31.3758 13.5987 31.3758H11.8915V33.5934Z"
                  fill="#4339F2"/>
            <path d="M16.4891 30.7246H18.5483C18.9296 30.7246 19.2757 30.7891 19.5867 30.9182C19.8976 31.0473 20.1616 31.2409 20.3787 31.499C20.6016 31.7513 20.7717 32.071 20.8891 32.4582C21.0064 32.8395 21.0651 33.2854 21.0651 33.7958C21.0651 34.3062 21.0064 34.755 20.8891 35.1422C20.7717 35.5235 20.6016 35.8433 20.3787 36.1014C20.1616 36.3537 19.8976 36.5443 19.5867 36.6734C19.2757 36.8025 18.9296 36.867 18.5483 36.867H16.4891V30.7246ZM18.5483 36.2158C18.8005 36.2158 19.0323 36.1747 19.2435 36.0926C19.4547 36.0046 19.6365 35.8785 19.7891 35.7142C19.9416 35.5499 20.0589 35.3505 20.1411 35.1158C20.2291 34.8753 20.2731 34.6025 20.2731 34.2974V33.2942C20.2731 32.9891 20.2291 32.7193 20.1411 32.4846C20.0589 32.2441 19.9416 32.0417 19.7891 31.8774C19.6365 31.7131 19.4547 31.5899 19.2435 31.5078C19.0323 31.4198 18.8005 31.3758 18.5483 31.3758H17.2283V36.2158H18.5483Z"
                  fill="#4339F2"/>
            <path d="M22.393 36.867V30.7246H26.0538V31.3758H23.1322V33.435H25.7986V34.0862H23.1322V36.867H22.393Z"
                  fill="#4339F2"/>
            <path d="M0.832031 2.4668C0.832031 1.63837 1.5036 0.966797 2.33203 0.966797H19.6607C20.0628 0.966797 20.448 1.12819 20.73 1.41478L28.7346 9.5504C29.0107 9.83098 29.1654 10.2088 29.1654 10.6024V39.5335C29.1654 40.3619 28.4938 41.0335 27.6654 41.0335H2.33203C1.50361 41.0335 0.832031 40.3619 0.832031 39.5335V2.4668Z"
                  stroke="#4339F2"/>
            <path d="M20.1328 1.2002V10.0002H28.5661" stroke="#4339F2"/>
        </svg>
    );
};

export default PdfIcon;
