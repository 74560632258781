import React, { useMemo, useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";

import { getDataToChart, getRoomAddressForScheme } from "../../../helpers/helper";
import { formFields } from "../EquipmentDetails/form-config";
import { useAppSelector } from "../../../hooks/redux";
import { useAction } from "../../../hooks/actions";

import { SchemaWithEquipmentsModal } from "../../components/DrawCanvas/SchemaWithEquipmentsModal";

const FloorSchema = ({ control, data,equipmentsCoordinates, setValue }) => {
  const { roomAddresses, floorSchemeFileLink } = data || {};
  const { SCHEMA_CANVAS, openSidebar } = useAppSelector(state => state.settings);
  const { setSortParamsFacilityName } = useAction();

  const $container = useRef(null);
  const [widthContainer, setWidthContainer] = useState(0);

  useEffect(() => {
    if ($container) {
      const containerWidth = $container.current?.offsetWidth;
      const screenWidth = window.innerWidth;
      setWidthContainer(() => screenWidth > 1150 ? containerWidth : containerWidth);

      window.addEventListener("resize", function() {
        const containerWidth = $container.current?.offsetWidth;
        const screenWidth = window.innerWidth;
        setWidthContainer(() => screenWidth > 1150 ? containerWidth : containerWidth);
      });
    }
  }, [$container, openSidebar]);

  const { width: screenRelatedWidth, height: screenRelatedHeight, scale } = useMemo(() => {
    const width = widthContainer;
    const height = (widthContainer / (SCHEMA_CANVAS.WIDTH / SCHEMA_CANVAS.HEIGHT));
    return SCHEMA_CANVAS.WIDTH && widthContainer 
      ? {
          width,
          height,
          scale: {
            x: width / SCHEMA_CANVAS.WIDTH,
            y: height / SCHEMA_CANVAS.HEIGHT,
          }
        }
      : {}}, [SCHEMA_CANVAS, widthContainer]
  );

  const screenRelatedRoomAddresses = useMemo(() => 
    getRoomAddressForScheme(roomAddresses, scale),
    [roomAddresses, scale],
  );



  return (
    <Box
      ref={$container}
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
        gap: "10px",
        paddingBottom: "14px",
      }}
    >
      <Box sx={{height: "505px",
    overflow: "auto"}}>
        <SchemaWithEquipmentsModal
          name={formFields.equipmentsCoordinates}
          data={equipmentsCoordinates}
          width={screenRelatedWidth || 0}
          height={screenRelatedHeight || 0}
          schemaImg={floorSchemeFileLink}
          roomAddresses={screenRelatedRoomAddresses}
          control={control}
          draggable={false}
          setValue={setValue}
        />
      </Box>
    </Box>
  );
};

export default FloorSchema;
