import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEditProfileInfoMutation } from "../../../../middleware/usersApi";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";
import { useAction } from "../../../../hooks/actions";
import FormItem from "../../../components/FormItem";
import { WarningValidationForm } from "../../../components/Notifications/index.jsx";
import {
  EditProfileFormDataInfo,
  EditProfileFormDataName,
  getDefaultValues,
  ProfileMainDataFormFormConfig,
} from "../ProfileMainDataForm/form-config.jsx";
import {
  getRequestData,
  getRequestDataFilesForAdd,
} from "../ProfileContactData/helpers.js";
import "./styles.css";
import { AvatarFormItemConfig } from "../ProfileContactData/form-config.jsx";

const EditProfileMainDataForm = ({
  profileData,
  token,
  setIsEditedProfileData,
  isEmpoyee = false,
}) => {
  const { t } = useTranslation();
  const [
    editProfileInfo,
    { error: errorEditProfileInfo, isSuccess: isSuccessEditProfileInfo },
  ] = useEditProfileInfoMutation();
  const [manipulateFiles, { error: errorFile, isSuccess: isSuccessFile }] =
    useManipulateFilesMutation();

  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: getDefaultValues(profileData),
  });

  const isWarningValidation = !!Object.keys(errors).length;
  const isError = errorEditProfileInfo || errorFile;
  const isSuccess = isSuccessEditProfileInfo && isSuccessFile;

  useEffect(() => {
    setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onSubmit = async (data) => {
    console.log(data);
    const { entities, files } = getRequestData(data);
    const response = await editProfileInfo({ token, data: entities });
    console.log(response)
    if (!response.error) {
      if (profileData?.id) {
        const formData = getRequestDataFilesForAdd(profileData.id, files);
        manipulateFiles(formData);
      }
    }
    setIsEditedProfileData(false)
  };

  return (
    <>
      <div className="profile">
        <form
          className="edit-profile"
          id="updateProfileForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="edit-profile__main">
            <div className="edit-profile__img">
              {AvatarFormItemConfig.map(
                ({
                  id,
                  name,
                  label,
                  input,
                  expended,
                  block,
                  title,
                  Component,
                  ...rest
                }) => (
                  <FormItem
                    key={name}
                    control={control}
                    errors={errors}
                    name={name}
                    label={label}
                    input={input}
                    expended={expended}
                    {...rest}
                  />
                )
              )}
            </div>
            <div className="edit-profile__info">
              {EditProfileFormDataName.map(
                ({
                  id,
                  name,
                  label,
                  input,
                  expended,
                  block,
                  title,
                  CustomFormItem,
                  Component,
                  ...rest
                }) => {
                  return (
                    <FormItem
                      key={id}
                      control={control}
                      errors={errors}
                      name={name}
                      label={label}
                      input={input}
                      expended={expended}
                      {...rest}
                    />
                  );
                }
              )}

              <div className="edit-profile__roles">
                <div>
                  {t("label:role")}: {profileData.role}
                </div>
                <div>
                  {t("label:position")}: {profileData.jobTitle}
                </div>
              </div>

              {EditProfileFormDataInfo.map(
                ({
                  id,
                  name,
                  label,
                  input,
                  expended,
                  block,
                  title,
                  CustomFormItem,
                  Component,
                  ...rest
                }) => (
                  <FormItem
                    key={id}
                    control={control}
                    errors={errors}
                    name={name}
                    label={label}
                    input={input}
                    expended={expended}
                    {...rest}
                  />
                )
              )}
            </div>
          </div>

          <div className="edit-profile-notification">
            <p className="profile-notification__title">
              {t("label:notifications")}
            </p>
            {ProfileMainDataFormFormConfig.map(
              ({
                id,
                name,
                label,
                input,
                expended,
                block,
                title,
                CustomFormItem,
                Component,
                ...rest
              }) => (
                <FormItem
                  key={id}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  input={input}
                  expended={expended}
                  {...rest}
                />
              )
            )}
          </div>
        </form>
      </div>

      {isWarningValidation && (
        <WarningValidationForm isWarning={isWarningValidation} />
      )}
    </>
  );
};

export default EditProfileMainDataForm;
