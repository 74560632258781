import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const token = "950bee972e3be4ba16db8a19163100fc6573f872";
const BASE_URL = "https://suggestions.dadata.ru/suggestions/";

export const addressDataApi = createApi({
  reducerPath: "addressData/api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
      headers.set("Authorization", `Token ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getAddressSelect: build.query({
      query: (value) => ({
        url: "api/4_1/rs/suggest/address",
        method: "POST",
        body: { query: value },
      }),
    }),
    getOrgInn: build.query({
      query: (value) => ({
        url: "api/4_1/rs/findById/party",
        method: "POST",
        body: { query: value },
      }),
    }),
    getBankBIC: build.query({
      query: (value) => ({
        url: "api/4_1/rs/findById/bank",
        method: "POST",
        body: { query: value },
      }),
    }),
  }),
});

export const {
  useLazyGetAddressSelectQuery,
  useLazyGetOrgInnQuery,
  useLazyGetBankBICQuery,
} = addressDataApi;