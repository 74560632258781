import React from "react";
import { useAction } from "../../../hooks/actions";
import EditButton from "../../components/EditButton";
import { getDataToChart } from "../../../helpers/helper";

export const getRowsData = (data, clickLink, handleOpenDetails) => {
  
  return data?.length
    ? data.map(
        ({ id, name, organizationAddresses, typeName, jobChart,enableForEdit,innNumber, typeId }) => ({
          id,
          innNumber: innNumber || "",
          typeName: typeName || "",
          name: {
            name,
            action: () => handleOpenDetails({id, typeId}),
          },
          organizationAddresses: organizationAddresses.map(
            ({ city, street, building, extension }) =>
              [city, street, building, extension]
                .filter((item) => !!item)
                .join(", ")
          ),
          tasks: getDataToChart({ jobChart,action: `?responsibleUserId=${id}&responsibleUser=${name}`}),
          edit: enableForEdit ? <EditButton handleClick={() => clickLink(id, typeId)} /> : <></>,
        })
      )
    : [];
};

export const getUpdatedResponsibleContracts = (organizationResponsibleContracts) => organizationResponsibleContracts
  .map((item) => ({
    ...item,
    equipmentKind: JSON.stringify({
      id: item?.equipmentKindId,
      name: item?.equipmentKindName,
    }),
    organization: JSON.stringify({
      id: item?.organizationId,
      name: item?.organizationName,
    }),
    contractFile: {
      filesPrev: item?.contractFileName
        ? [{ fileName: item?.contractFileName, fileLink: item?.contractFileId || "" }]
        : [{ fileName: "Без имени", fileLink: item?.contractFileId || "" }],
      filesToAdd: [],
      filesToDelete: [],
    },
  }));
