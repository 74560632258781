import React from "react";

const GostsIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00002 0.00300002V0H16.998C17.55 0 18 0.455 18 0.992V19.008C17.9998 19.2712 17.895 19.5235 17.7088 19.7095C17.5226 19.8955 17.2702 20 17.007 20H0.993025C0.861702 19.9991 0.731845 19.9723 0.61087 19.9212C0.489895 19.8701 0.38017 19.7957 0.287961 19.7022C0.195752 19.6087 0.122864 19.4979 0.0734597 19.3762C0.0240555 19.2545 -0.000897804 19.1243 2.4673e-05 18.993V6L6.00002 0.00300002ZM2.83002 6H6.00002V2.83L2.83002 6ZM8.00002 2V7C8.00002 7.26522 7.89467 7.51957 7.70713 7.70711C7.5196 7.89464 7.26524 8 7.00002 8H2.00002V18H16V2H8.00002Z"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default GostsIcon;
