import React from "react";

const AttentionIcon = ({ isActive }) => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5698 16.2791C12.5698 16.8699 12.0908 17.3488 11.5 17.3488C10.9092 17.3488 10.4302 16.8699 10.4302 16.2791C10.4302 15.6883 10.9092 15.2093 11.5 15.2093C12.0908 15.2093 12.5698 15.6883 12.5698 16.2791Z"
        fill="currentColor"
      />
      <path
        d="M12.3023 6.65116C12.3023 6.20805 11.9431 5.84884 11.5 5.84884C11.0569 5.84884 10.6977 6.20805 10.6977 6.65116V13.0698C10.6977 13.5129 11.0569 13.8721 11.5 13.8721C11.9431 13.8721 12.3023 13.5129 12.3023 13.0698V6.65116Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5 0.5C5.14873 0.5 0 5.64873 0 12C0 18.3513 5.14873 23.5 11.5 23.5C17.8513 23.5 23 18.3513 23 12C23 5.64873 17.8513 0.5 11.5 0.5ZM1.60465 12C1.60465 6.53495 6.03495 2.10465 11.5 2.10465C16.9651 2.10465 21.3953 6.53495 21.3953 12C21.3953 17.4651 16.9651 21.8953 11.5 21.8953C6.03495 21.8953 1.60465 17.4651 1.60465 12Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AttentionIcon;
