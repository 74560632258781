import React from "react";
import { Box } from "@mui/material";

import FormItem from "../../../../components/FormItem";
import { formFields } from "../../form-config";

export const PossibilityOfViewingForm = ({ control, errors, config }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "24px",
        width: "50%",
      }}
    >
      {config.map(({ id, name, label, input, ...rest }, i) => (
        <FormItem
          key={id || i}
          control={control}
          errors={errors}
          name={
            name === "availableOrganizations"
              ? name
              : `${[formFields.permissionsIds]}.${name}`
          }
          label={label}
          input={input}
          {...rest}
        />
      ))}
    </Box>
  );
};
