import React from "react";

const RefreshIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        fill="currentColor"
        width="50px"
        height="50px"
        viewBox="0 -0.08 20 20"
        data-name="Capa 1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.59,11.46a.39.39,0,0,0-.41-.08.38.38,0,0,0-.23.35v1.72H8.88a4.83,4.83,0,0,1-2.81-.55A3.61,3.61,0,0,1,4.52,10a3.88,3.88,0,0,1,1-2.5A.38.38,0,0,0,4.94,7a4.56,4.56,0,0,0-1.17,3,4.34,4.34,0,0,0,1.84,3.5,4.82,4.82,0,0,0,3,.71H11v1.72a.41.41,0,0,0,.22.34.45.45,0,0,0,.16,0,.36.36,0,0,0,.24-.09c1.33-1.14,2.19-2,2.2-2a.38.38,0,0,0,.11-.26.39.39,0,0,0-.11-.27Zm.13,3.63,0-2.45L13,13.93C12.73,14.18,12.29,14.59,11.72,15.09Z" />
        <path d="M14.39,6.41a5.27,5.27,0,0,0-3.28-.71H9.3V3.93a.38.38,0,0,0-.64-.27L6.46,5.87a.36.36,0,0,0-.11.26.39.39,0,0,0,.11.27s.88.86,2.2,2a.38.38,0,0,0,.25.09.28.28,0,0,0,.15,0,.38.38,0,0,0,.22-.34V6.45h1.83A4.67,4.67,0,0,1,13.93,7a3.66,3.66,0,0,1,1.55,2.93,3.85,3.85,0,0,1-1,2.5.38.38,0,0,0,0,.53.35.35,0,0,0,.26.1.4.4,0,0,0,.27-.11,4.63,4.63,0,0,0,1.17-3A4.39,4.39,0,0,0,14.39,6.41Zm-5.86.88c-.57-.5-1-.91-1.27-1.16L8.55,4.84Z" />
      </svg>
    </div>
  );
};

export default RefreshIcon;
