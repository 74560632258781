import React from "react";

const EmployeesIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 28.9999H29V27.7112C29 22.1913 26.008 17.572 22 16.3999"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.2002 2.53222C19.5582 2.44585 19.9335 2.3999 20.3202 2.3999C22.7945 2.3999 24.8002 4.28031 24.8002 6.5999C24.8002 8.9195 22.7945 10.7999 20.3202 10.7999C19.9335 10.7999 19.5582 10.754 19.2002 10.6676"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.5 16.3999C5.70101 16.3999 1 21.477 1 27.7399V28.9999H22V27.7399C22 21.477 17.299 16.3999 11.5 16.3999Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.5001 10.8C14.2063 10.8 16.4001 8.6062 16.4001 5.9C16.4001 3.1938 14.2063 1 11.5001 1C8.7939 1 6.6001 3.1938 6.6001 5.9C6.6001 8.6062 8.7939 10.8 11.5001 10.8Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EmployeesIcon;
