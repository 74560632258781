import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FormItem from "../../../components/FormItem";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGenerateFASActMutation } from "../../../../middleware/documentationApi";
import { useAction } from "../../../../hooks/actions";
import { useForm } from "react-hook-form";
import { formConfig } from "./form-config";

const FASDialog = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const [generateFASAct, { isLoading, error, isSuccess }] = useGenerateFASActMutation();
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();

  const {
    control,
    handleSubmit,
    reset,
  } = useForm();

  const closeModal = () => {
    handleClose();
    reset();
  };

  const onSubmit = (data) => {
    generateFASAct(data).then(() => closeModal());
  };

  useEffect(() => {
    setIsErrorSubmit(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      className="modal select_columns"
      maxWidth="md"
    >
      <DialogTitle className="modal-title-between border_bottom">
        <Typography className="modal-title">
          {t("button:create_common_control_act")}
        </Typography>
        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "0 36px" }}>
        <form
          id="formModalSelect"
          className="modal select_columns-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {formConfig.map(({ name, label, input, ...rest }) => (
            <FormItem
              key={name}
              control={control}
              name={name}
              label={label}
              input={input}
              {...rest}
            />
          ))}
        </form>
      </DialogContent>
      <DialogActions className="select_columns-btns modal-btn-field modal-btn-field-right">
        <Button className="btn btn-outlined-grey" onClick={closeModal}>
          {t("button:comeback")}
        </Button>
        <Button
          form="formModalSelect"
          className="btn btn-primary-fill"
          type="submit"
          autoFocus
        >
          {t("button:create_act")}
          {isLoading && (
              <CircularProgress
                sx={{
                  "&.MuiCircularProgress-root" : {
                    color: "white",
                    marginLeft: "15px"
                  }
                }}
                style={{ height: "24px", width: "24px" }}
              />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FASDialog;