import { createSlice } from "@reduxjs/toolkit";
import { logsApi } from "../../middleware/logsApi";

const initialState = {
  allLogOfWorkDoneOnTasks: [],
  authStep: 0,
};

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    setAuthStep(state, action) {
      state.authStep = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      logsApi.endpoints.getLogOfWorkDoneOnTasks.matchFulfilled,
      (state, { payload }) => {
        state.allLogOfWorkDoneOnTasks = payload;
      }
    );
  },
});

export const logsActions = logsSlice.actions;
export const logsReducer = logsSlice.reducer;
