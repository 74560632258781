import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";

import { PATHS } from "../../../../constants";

import InputPassword from "../../../components/InputPassword";

const ForgotPasswordFormItem = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <InputPassword {...props} />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{ minWidth: "auto!important", mt: "8px" }}
          className="btn btn-link"
          onClick={() => navigate(PATHS.RESET_PASSWORD_ROUTE)}
        >
          {t("button:forgotPasswordQuestion")}
        </Button>
      </Box>
    </>
  );
};

export default ForgotPasswordFormItem;
