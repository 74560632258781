import React from "react";
import { Box, InputLabel } from "@mui/material";

import InputText from "../../components/InputText";
import InputMaskPhone from "../../components/InputMaskPhone";
import { AddressInput } from "../../components/AddressInputOrg";
import { AdressCheck } from "../../../validation";
import { ContactsForm } from "./ContactsForm";
import AddressFormItem from "./AddressFormItem";
import { TarifForm } from "./TarifForm";
import {SelectSimpleGetRegoinsSearch} from "./Components"
import { t } from "i18next";
import SelectSimple from "../../components/SelectSimple";

export const formFields = {
  name: "name",
  ogrnNumber: "ogrnNumber",
  innNumber: "innNumber",
  equipmentCount:"equipmentCount",
  organizationRegistrationType:"organizationRegistrationType",
  regionId:"regionId",
  kppCode: "kppCode",
  ur_address: "ur_address",
  fact_address: "fact_address",
  type: "type",
  address: "address",
  okpoCode: "okpoCode",
  okvedCode: "okvedCode",

  bankName: "bankName",
  account: "account",
  correspondentAccount: "correspondentAccount",
  bankBIC: "bankBIC",

  organizationRegistrationInfo: "organizationRegistrationInfo",

  organizationAddresses: "organizationAddresses",
  extension: "extension",
  bankDetails: "bankDetails",
  city: "city",

  typeName: "typeName",
  tasks: "tasks",
  edit: "edit",
  orgTypeFilter: "orgTypeFilter",

  fullName: "fullName",
  email: "email",
  phoneNumber: "phoneNumber",

  organization_add_edit: "organization_add_edit",
  all_organizations: "all_organizations",
  added_organizations: "added_organizations",
};

export const fields = {
  organization_customer: "organization_customer",
  organization_contractor: "organization_contractor",
  outside_organization: "outside_organization",
  /* control_and_executive_organization: "control_and_executive_organization", */
};

export const templatePerson = {
  [formFields.fullName]: "",
  [formFields.phone]: "",
  [formFields.email]: "",
  [formFields.position]: "",
};

export const templateAddress = {
  address: "",
  type: "Фактический",
};

export const formConfigOrganization = [
  
];

export const element_count = [
  {value:"0",name:"До 500"},
  {value:"1",name:"500-1000"},
  {value:"2",name:"1000-2000"},
  {value:"3",name:"2000-3000"},
  {value:"4",name:"3000-4000"},
  {value:"5",name:"более 4000"},
]

export const object_types = [
  {value:0,name:"Некоммерческий СЗО"},
  {value:1,name:"Коммерческий СЗО"},
  {value:2,name:"Некоммерческий объект"},
  {value:3,name:"Коммерческий объект"},
]

export const formConfigBasicInf = [
  {
    name: formFields.innNumber,
    label: "label:innNumber",
    type: "number",
    rules: {
      required: true,
      maxLength: (v) => v?.length <= 12,
      validate: (value, formValues) => value >=0 ,
    },
    onKeyDown:(e) => {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "." || e.key === ",") {
        e.preventDefault()
      }
    },
    InputProps:{ type:"number" },
    input: (props) => (
      <Box sx={{ minWidth: "300px",width:"90%" }}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.name,
    label: "label:name",
    placeholder: "placeholder:organizationName",
    type: "text",
    rules: {
      required: true,
    },
    input: (props) => {
      const { name, label, ...rest } = props;
      return (
        <Box sx={{ minWidth: "250px",width:"90%" }}>
          <InputLabel sx={{  }} htmlFor={name}>
            {t(label)}
          </InputLabel>
          <InputText id={name} name={name} {...rest} />
        </Box>
      )
    }
  },
  {
    name: formFields.ogrnNumber,
    label: "label:ogrnNumber",
    placeholder: "placeholder:ogrn",
    type: "number",
    rules: {
      required: false,
      maxLength: (v) => v?.length <= 13,
      validate: (value) => {
        if (!value) return true;
        if (value.length === 0) return true;
        if (value.length >= 1 && value.length <= 12) return false;
        if (value.length === 13) return true;
        return false;
      } 
    },
    input: (props) => (
      <Box sx={{width:"90%"}}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.kppCode,
    label: "label:kppCode",
    type: "number",
    rules: {
      required: true,
      maxLength: (v) => v?.length <= 9,
      validate: (value, formValues) => value?.length === 9,
    },
    input: (props) => (
      <Box sx={{ minWidth: "210px",width:"90%" }}>
        <InputText {...props} />
      </Box>
    ),
  },
  /*{
    name: formFields.okpoCode,
    label: "label:okpoCode",
    type: "number",
    input: (props) => (
      <Box sx={{ width: "210px" }}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.okvedCode,
    label: "label:okvedCode",
    type: "text",
    input: (props) => (
      <Box sx={{ width: "210px" }}>
        <InputText {...props} />
      </Box>
    ),
  },*/
];
export const formConfigTarif = [
  {
    name: formFields.equipmentCount,
    label: "label:element_count",
    rules: {
      required: true,
    },
    options: element_count,
    input: (props) => (
      <Box sx={{ minWidth: "300px",width:"90%" }}>
        <SelectSimple  {...props} />
      </Box>
    ),
  },
  {
    name: formFields.organizationRegistrationType,
    label: "label:objectType",
    rules: {
      required: true,
    },
    options: object_types,
    input: (props) => (
      <Box sx={{ minWidth: "300px",width:"90%" }}>
        <SelectSimple  {...props} />
      </Box>
    ),
  },
  {
    name: formFields.regionId,
    label: "label:region",
    rules: {
      required: true,
    },
    input: (props) => (
      <Box sx={{ minWidth: "300px",width:"90%" }}>
        <SelectSimpleGetRegoinsSearch  {...props} />
      </Box>
    ),
  },
  
];

export const formConfigBank = [
  {
    name: formFields.bankBIC,
    label: "label:bik",
    type: "number",
    rules: {
      maxLength: (v) => v && v.length <= 9,
      validate: (v) => {
        if (v) {
          return v.length === 9;
        }
      },
    },
    input: (props) => (
      <Box className="variable_width_form" sx={{width:"90%"}}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.bankName,
    label: "label:name_bank",
    type: "text",
    rules: {},
    input: (props) => (
      <Box className="variable_width_form" sx={{width:"90%"}}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.account,
    label: "label:rs_organization",
    type: "number",
    rules: {
      maxLength: (v) => v && v.length <= 20,
      validate: (v) => {
        if (v) {
          return v.length === 20;
        }
      },
    },
    input: (props) => (
      <Box className="variable_width_form"  sx={{width:"90%"}}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.correspondentAccount,
    label: "label:kor_schet",
    type: "number",
    rules: {
      maxLength: (v) => v && v.length <= 20,
      validate: (v) => {
        if (v) {
          return v.length === 20;
        }
      },
    },
    input: (props) => (
      <Box className="variable_width_form"  sx={{width:"90%"}}>
        <InputText {...props} />
      </Box>
    ),
  },
  
];

export const formConfigPerson = [
  {
    name: formFields.fullName,
    label: "label:fullName",
    type: "text",
    firstUpperCase: true,
    flexBasis: "100%",
    rules: {
      required: true,
    },
    input: (props) =><Box sx={{width:"100%"}}> <InputText {...props} /> </Box>,
  },

  {
    name: formFields.phoneNumber,
    label: "label:phone",
    flexBasis: "45%",
    rules: {
      required: true,
      validate: (value, formValues) =>
        value.replace(/[+() ]/g, "", "")?.length === 11,
    },
    input: (props) => <Box sx={{width:"100%"}}><InputMaskPhone {...props} /></Box>,
  },
  {
    name: formFields.email,
    label: "label:email",
    type: "text",
    flexBasis: "45%",
    rules: {
      required: true,
      pattern:
        /[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,}$/g,
    },
    input: (props) => <Box sx={{width:"100%"}}><InputText {...props} /></Box>,
  },
];

export const formConfigAddresses = [
  {
    name: formFields.organizationAddresses,
    CustomFormItem: AddressFormItem,
  },
];

export const addressFormConfig = [
  {
    name: formFields.address,
    label: "label:yandex_address",
    rules: {
      required: true,
    },
    shouldSetCoordinates: false,
    input: (props) => <Box sx={{width:"80%"}}><AddressInput {...props} /></Box>,
  },
  /*{
    name: formFields.extension,
    type: "number",
    label: "label:extension",
    rules: {},
    input: (props) => <Box sx={{width:"90%"}}><InputText  {...props} /></Box>,
  },*/
];

export const sections = [
  {
    id: 1,
    title: "tarif",
    Component: TarifForm,
    config: formConfigTarif,
  },
  {
    id: 2,
    title: "location",
    Component: ContactsForm,
    config: formConfigPerson,
  },
];
