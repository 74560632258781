import React from "react";
import vdpoLogoFull from './../../../image/vdpoLogoFull.png'
import vdpoLogoShort from './../../../image/vdpoLogoShort.png'

const LogoLabelIcon = ({isFullIcon}) => {
  return (
    <img src={isFullIcon ? vdpoLogoFull : vdpoLogoShort} alt="Логотип ВДПО" />
  );
};

export default LogoLabelIcon;
