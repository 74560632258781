import React from "react";

const DefaultAvatarIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="26" cy="26" r="25.5" fill="white" stroke="currentColor" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26 12C22.2765 12 19.2581 14.9047 19.2581 18.4878C19.2581 22.0709 22.2765 24.9756 26 24.9756C29.7235 24.9756 32.7419 22.0709 32.7419 18.4878C32.7419 14.9047 29.7235 12 26 12ZM21.3871 18.4878C21.3871 16.0362 23.4524 14.0488 26 14.0488C28.5476 14.0488 30.6129 16.0362 30.6129 18.4878C30.6129 20.9394 28.5476 22.9268 26 22.9268C23.4524 22.9268 21.3871 20.9394 21.3871 18.4878Z"
        fill="#051D39"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.7419 27.0244C18.0185 27.0244 15 29.9291 15 33.5122C15 37.0953 18.0185 40 21.7419 40H30.2581C33.9815 40 37 37.0953 37 33.5122C37 29.9291 33.9815 27.0244 30.2581 27.0244H21.7419ZM17.129 33.5122C17.129 31.0606 19.1943 29.0732 21.7419 29.0732H30.2581C32.8057 29.0732 34.871 31.0606 34.871 33.5122C34.871 35.9638 32.8057 37.9512 30.2581 37.9512H21.7419C19.1943 37.9512 17.129 35.9638 17.129 33.5122Z"
        fill="#051D39"
      />
    </svg>
  );
};

export default DefaultAvatarIcon;
