import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const signingApi = createApi({
  reducerPath: "signing/api",
  tagTypes: ["Signing"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    sendPhoneVerificationCode: build.query({
      query: () => ({
        url: `api/users/send-phone-verification-code`,
        method: "POST",
      }),
    }),
    verifyPhoneNumber: build.mutation({
      query: (smsCode) => ({
        url: `api/users/verify-phone-number?token=${smsCode}`,
        method: "PUT",
      }),
    }),
    toSignTask: build.query({
      query: (taskId) => ({
        url: "api/tasks/sign",
        method: "POST",
        body: { taskId },
      }),
    }),
  }),
});

export const {
  useLazySendPhoneVerificationCodeQuery,
  useVerifyPhoneNumberMutation,
  useLazyToSignTaskQuery,
} = signingApi;