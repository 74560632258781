import React from 'react';
import OTPInput from 'react-otp-input';

export const formFields = {
  smsCode: "smsCode",
};

export const SetSMSCodeDialogFormConfig = [
  {
    name: formFields.smsCode,
    input: (props) =>
      <OTPInput
        numInputs={4}
        renderInput={(props) => <input {...props} />}
        inputType="number"
        shouldAutoFocus={true}
        containerStyle="sms_code"
        inputStyle="sms_code-input"
        {...props}
      />
  },
];