import React, { useMemo } from "react";
import { Box } from "@mui/material";
import FormItem from "../../../components/FormItem";

const TaskDetailsForm = ({ control, errors, data, config, params }) => {

  // const TaskDetailsForm = useMemo

  return (
    <Box width={'50%'}>
      {config.map(({ name, label, input, ...rest }) => (
        <FormItem
          key={name}
          control={control}
          errors={errors}
          name={name}
          label={label}
          input={input}
          data={data}
          params={params}
          {...rest}
        />
      ))}
    </Box>
  );
};

export default TaskDetailsForm;
