import React from "react";
import { Box } from "@mui/material";

import "./styles/style.css";

import { RightBlockLogin } from "./RightBlockLogin";

const LogIn = ({ children }) => {
  return (
    <Box className="login_wrapper">
      
      <Box className="right_block">
        {children}
      </Box>
      <RightBlockLogin />
    </Box>
  );
};

export default LogIn;
