export const getRequestData = (data,dadata) => {
  const { file, organizationResponsibleContracts } = data || {};

  /*const files = [
    { ...file, key: "file" },
    ...organizationResponsibleContracts?.reduce((filesList, contract) => {
      const fileItem = {
        ...contract.contractFile,
        additionalData: contract.contractNumber,
        key: "organizationResponsibleContracts",
      };
      return filesList
        ? [...filesList, fileItem]
        : contract.contractFile
        ? [fileItem]
        : [];
    }, []),
  ];
*/
  delete data.file;
  console.log(data.organizationResponsibleContracts);
  data.organizationResponsibleContracts = data.organizationResponsibleContracts
    ?.length
    ? data.organizationResponsibleContracts.map(
        ({ id, organization, contractNumber, equipmentKind, expirationDate }) => ({
          id,
          organizationId: organization ? JSON.parse(organization)?.id : "",
          organizationName: organization ? JSON.parse(organization)?.name : "",
          contractNumber,
          equipmentKindId: equipmentKind
            ? JSON.parse(equipmentKind)?.id
            : "",
          equipmentKindName: equipmentKind
            ? JSON.parse(equipmentKind)?.name
            : "",
          expirationDate,
          organizationResponsibleId: data?.id || null,
        })
      )
    : [];
  const organizationResponsibleAddresses = dadata ?
    data.organizationResponsibleAddresses?.map(
      ({ address, extension, type, id, organizationAddressId },index) => ({
        city:dadata[index]?.data.city,
        street:dadata[index]?.data.street,
        building:dadata[index]?.data.house,
        extension,
        type,
        id: id || 0,
        organizationResponsibleAddressId: organizationAddressId || 0,
      })
    )
    :
    data.organizationResponsibleAddresses?.map(
      ({ address, extension, type, id, organizationAddressId }) => ({
        ...address,
        extension,
        type,
        id: id || 0,
        organizationResponsibleAddressId: organizationAddressId || 0,
      })
    );


  const entities = { ...data, organizationResponsibleAddresses };

  return { entities, file };
};

export const getRequestDataFilesForAdd = (response, files) => {
  const formData = new FormData();

  const entityCfg = {
    file: {
      EntityType: "OrganizationResponsible",
      EntityField: "Ogrn",
    },
    organizationResponsibleContracts: {
      EntityType: "OrganizationResponsibleContract",
      EntityField: "ContractFile",
    },
  };

  const getEntityId = (entityType, additionalData) => {
    if (entityType === "file") {
      return response.id;
    }
    if (
      entityType === "contracts" ||
      entityType === "organizationResponsibleContracts"
    ) {
      return response.organizationResponsibleContracts.find(
        ({ contractNumber }) => contractNumber === additionalData
      )?.id;
    }
  };

  if (!files) {
    return formData;
  }

  let fileConfigIndex = 0;
  files
    .filter((item) => item)
    .forEach(({ key, filesToAdd, additionalData = null }) => {
      if (filesToAdd?.length) {
        filesToAdd.forEach((file, index) => {
          fileConfigIndex += index;
          formData.append(
            `entityFileList[${fileConfigIndex}].EntityId`,
            getEntityId(key, additionalData)
          );
          formData.append(
            `entityFileList[${fileConfigIndex}].EntityField`,
            entityCfg[key].EntityField
          );
          formData.append(
            `entityFileList[${fileConfigIndex}].EntityType`,
            entityCfg[key].EntityType
          );
          formData.append(`entityFileList[${fileConfigIndex}].File`, file);
          formData.append(
            `entityFileList[${fileConfigIndex}].ActionType`,
            "Add"
          );
        });
        fileConfigIndex++;
      }
    });

  return formData;
};

export const getEquipmentKindOptions = (data) => {
  return data?.map(({ id, fullTitle }) => ({
    value: JSON.stringify({
      equipmentKindId: id,
      equipmentKindName: fullTitle,
    }),
    name: fullTitle,
  }));
};

export const getEquipmentOptions = (data) => {
  return data?.map(({ id, name }) => ({
    id: id,
    name: name,
  }));
};

export const getOrganizationOptions = (data) => {
  return data?.map(({ id, name }) => ({
    value: JSON.stringify({ id, name }),
    name: name,
  }));
};

export const getUserRoleOptions = (data) => {
  return data?.map(({ id, name }) => ({
    value: id,
    name: name,
  }));
};

export const formatPhoneNumber = (phoneNumber) => {
  let digits = phoneNumber.replace(/\D/g, "");

  return digits;
};

export const getAllOrganization = (data) => {
  return data?.map(({ id, name, typeId }) => ({
    name: name,
    id: id,
    typeId: typeId,
  }));
};

export const selectOrganization = (data) => {
  return data?.map(({ id, name }) => ({
    value: id,
    name: name,
  }));
};

export const selectFacilitiesSelect = (data) => {
  return data?.map(({ facilityId, facilityName }) => ({
    value: facilityId,
    name: facilityName,
  }));
};


export const getDataForm = (organization) => {
  const {
    organizationResponsibleAddresses: addresses,
    organizationResponsibleContracts: contracts,
    ogrnNumberFileId,
    ogrnNumberFileName,
  } = organization || {};

  const file = getOrgFileDataForm({ ogrnNumberFileId, ogrnNumberFileName });
  const organizationResponsibleContracts = getContractsDataForm(contracts);
  const organizationResponsibleAddresses = getAddressDataForm(addresses);

  return {
    ...organization,
    file,
    organizationResponsibleContracts,
    organizationResponsibleAddresses,
  };
};

const getFileInArray = (link, name) => (link ? [new File([link], name)] : []);

const getContractsDataForm = (contracts) =>
  contracts.map(
    ({
      organizationId,
      organizationName,
      equipmentKindId,
      equipmentKindName,
      contractNumber,
      expirationDate,
      contractFileId,
      contractFileName,
    }) => ({
      organization: JSON.stringify({
        id: organizationId,
        name: organizationName,
      }),
      equipmentKind: JSON.stringify({
        equipmentKindId,
        equipmentKindName,
      }),
      contractNumber,
      expirationDate,
      contractFile: {
        filesPrev: getFileInArray(contractFileId, contractFileName),
        filesToAdd: [],
        filesToDelete: [],
      },
    })
  );

const getAddressDataForm = (addresses) =>
  addresses.map(
    ({
      building,
      city,
      street,
      extension,
      id,
      organizationResponsibleAddressId,
      type,
    }) => ({
      address: {
        building,
        city,
        street,
      },
      extension,
      id,
      organizationAddressId: organizationResponsibleAddressId,
      type,
    })
  );

const getOrgFileDataForm = ({ ogrnNumberFileId, ogrnNumberFileName }) => ({
  filesPrev: getFileInArray(ogrnNumberFileId, ogrnNumberFileName),
  filesToAdd: [],
  filesToDelete: [],
});
