import React, { useMemo } from "react";
import { Typography, Box } from "@mui/material";

import { getRoomAddressForScheme } from "../../../../helpers/helper";
import { useAppSelector } from "../../../../hooks/redux";

import SchemaWithEquipments from "../../../components/DrawCanvas/SchemaWithEquipments";
import { useTranslation } from "react-i18next";

export const FloorSchemaImage = ({ data, control, widthContainer }) => {
  const { t } = useTranslation();

  const { floorSchemeEquipmentAndRoomCoordinates } = data || {};
  const { roomAddresses, floorSchemeFileLink } =
    floorSchemeEquipmentAndRoomCoordinates || {};

  const { SCHEMA_CANVAS } = useAppSelector((state) => state.settings);
  const {
    width: screenRelatedWidth,
    height: screenRelatedHeight,
    scale,
  } = useMemo(() => {
    const width = widthContainer;
    const height =
      widthContainer / (SCHEMA_CANVAS.WIDTH / SCHEMA_CANVAS.HEIGHT);

    return SCHEMA_CANVAS.WIDTH
      ? {
          width,
          height,
          scale: {
            x: width / SCHEMA_CANVAS.WIDTH,
            y: height / SCHEMA_CANVAS.HEIGHT,
          },
        }
      : {};
  }, [SCHEMA_CANVAS, widthContainer]);

  const screenRelatedRoomAddresses = useMemo(
    () => getRoomAddressForScheme(roomAddresses, scale),
    [roomAddresses, scale]
  );

  return floorSchemeFileLink ? (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          width: screenRelatedWidth,
          height: screenRelatedHeight,
          position: "absolute",
          zIndex: "40",
        }}    
      />
      <SchemaWithEquipments
        width={screenRelatedWidth || 0}
        height={screenRelatedHeight || 0}
        schemaImg={floorSchemeFileLink}
        roomAddresses={screenRelatedRoomAddresses}
        control={control}
        draggable={false}
      />
    </Box>
  ) : (
    <Typography className="object-content-empty_text">
      {t("text_empty_page:schema_is_empty")}
    </Typography>
  );
};
