import { Box, Typography } from "@mui/material";
import { addressFormConfig } from "./form-config";
import FormItem from "../../components/FormItem";
import React, { useMemo, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ADDRESSES_TYPES_INT } from "../MyOrganizations/constants";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import { AddressInput } from "../../components/AddressInputOrg";
import checkImagechecked from "../../../image/icons/checkboxChecked.png"
import checkImage from "../../../image/icons/checkBox.png"

const AddressFormItem = ({
  name,
  control,
  errors,
  indCheck,
  addressFromDadata,
  setaddressFromDadata,
  ...rest
}) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <>
      <FormControlLabel
        control={<Checkbox sx={{height:"28px",width:"28px",borderRadius:0,mr:"8px",ml:"10px",mt:"12px",mb:"12px"}}
        icon={<Box
          component="img"
          src={checkImage}
        />}
          checkedIcon={<Box
            component="img"
            src={checkImagechecked}
          />}/>}
        label={t("label:ip")}
        
        sx={{
          "& .MuiTypography-root": {
            fontFamily: "var(--INTER)",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "var(--standart)",
            lineHeight: "24px",
            color: "#212121",
          },
        }}
      />
        <Box>
          <Typography className="title-block" sx={{ margin: "12px 0" }}>
            {t("label:ur_address")}
          </Typography>

          {addressFormConfig.map(
            ({ name: itemName, type, label, input, rest, rules }) =>
              itemName == "address" && addressFromDadata ? (
                <FormItem
                  key={`${name}.${itemName}`}
                  control={control}
                  type={type}
                  errors={errors}
                  value={addressFromDadata[0]}
                  onChangeForOrg={setaddressFromDadata}
                  indexAdress={0}
                  label={label}
                  name={`${name}.${itemName}`}
                  input={input}
                 
                  {...rest}
                />
              ) : (
                <FormItem
                  key={`${name}.${itemName}`}
                  control={control}
                  type={type}
                  errors={errors}
                  label={label}

                  name={`${name}.${itemName}`}
                  input={input}
                  {...rest}
                />
              )
          )}
        </Box>
    </>
  );
};

export default AddressFormItem;
