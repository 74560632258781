import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";

const Header = ({ title, formId, handleCancel, isSubmitDisabled }) => {
  const { t } = useTranslation();

  return (
    <Box className="acts-header-form">
      <Typography className="title-page">
        {t(`title_page:${title}`)}
      </Typography>
      <Box className="acts-header-form-buttons">
        <Button className="btn btn-outlined-grey" onClick={handleCancel}>
          {t("button:comeback")}
        </Button>
        <Button
          form={formId}
          className="btn btn-primary-fill"
          type="submit"
          disabled={isSubmitDisabled}
        >
          {t("button:save")}
        </Button>
      </Box>
    </Box>
  );
};

export default Header;
