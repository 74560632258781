import React, { useEffect } from "react";
import { createAfaActFormSections } from "./form-config.jsx";
import Form from "../Form";

const CreateAfaAct = () => {
  
  return (
    
      <Form
        formId="createAfaActForm"
        formTitle="create_afa_act"
        actType={0}
        formConfig={createAfaActFormSections}
      />
     
  );
};

export default CreateAfaAct;
