import React from "react";

const ResponsibleIcon = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0002 15L4.12223 18.59L5.72023 11.89L0.490234 7.41L7.35523 6.86L10.0002 0.5L12.6452 6.86L19.5112 7.41L14.2802 11.89L15.8782 18.59L10.0002 15ZM10.0002 12.656L12.8172 14.376L12.0512 11.166L14.5582 9.019L11.2682 8.755L10.0002 5.708L8.73223 8.755L5.44223 9.019L7.94923 11.166L7.18323 14.376L10.0002 12.657V12.656Z"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default ResponsibleIcon;
