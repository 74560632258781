import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import "./styles/styles.css";

export const LinearRating = ({ legend, procent, options, width="100%", minWidthLegend = "260px" }) => {
  const gradientparams = options
    .map(({ size, color }) => `${color} ${size}%`)
    .join(", ");

  const getPositionPointer = (value) => {
    return value <= 0 ? 0 : value;
  };

  return (
    <Box className="rating" sx={{ width }}>
      <Typography className="rating-legend" sx={{ minWidth: minWidthLegend }} >{legend}</Typography>

      <Box
        className="rating-content"
        sx={{ background: `linear-gradient(270deg, ${gradientparams})` }}
      >
        <Box className="rating-pointer" sx={{ left: `${getPositionPointer(procent)}%` }}>
          <Typography className="rating-pointer-text">{`${procent <= 0 ? 0 : procent}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
