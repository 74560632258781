import React, { useRef, useState, useEffect, useCallback } from "react";

import { ICON_SIZE, SCHEMA_CANVAS } from "../../../constants";

import alarm from "../../components/icons/alarm.svg";
import TaskStateList from "../TaskStateList/TaskStateList";
import { getEquipmentIcon } from "../../../helpers/getEquipmentIcon";
import {useChartsList} from "../../pages/EquipmentDetails/hooks";
import DonutChart from "../DonutChart";
import {Box} from "@mui/material";

const Popup = ({ x, y }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: y,
        left: x,
        padding: "10px 14px 10px 14px",
        zIndex: 10,
        background: "#FFFFFF",
        boxShadow: "0px 0px 0px 2px #EFF3F9",
        borderRadius: "2px",
      }}
    >
      <TaskStateList
        fontSize="12px"
        lineHeight="18px"
        height="8px"
        width="8px"
      />
    </div>
  );
};

const DrawCanvas = ({ width, height, schemaImg, data, isResize = false }) => {
  const canvasRef = useRef(null);
  const [popupData, setPopupData] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [ChartsList, updateChartsList] = useChartsList({
    Chart: DonutChart, canvas: canvasRef.current
  });

  const wIcon = ICON_SIZE.WIDTH;
  const hIcon = ICON_SIZE.HEIGHT;

  window.onmousemove = function (e) {
    const rect = canvasRef.current.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    data?.forEach(
      ({
        floorSchemeCoordinateX: x,
        floorSchemeCoordinateY: y,
        id,
        dynamicData = "TEXT",
      }) => {
        if (
          mouseX >= x &&
          mouseX <= x + wIcon &&
          mouseY >= y &&
          mouseY <= y + hIcon
        ) {
          setPopupData(dynamicData);
          setPopupPosition({ x: mouseX, y: mouseY });
        }
      }
    );
  };

  window.onmousedown = function (e) {
    // Обработка события нажатия мыши
  };

  window.onmouseup = function (e) {
    // Обработка события отпускания мыши
  };

  const draw = useCallback(
    (ctx, data) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.canvas.width = ctx.canvas.width;
      context.canvas.height = ctx.canvas.height;

      const image = new Image();
      image.src = schemaImg;
      image.addEventListener("load", (e) => {
        context.drawImage(image, 0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.drawImage(canvas, 0, 0, ctx.canvas.width, ctx.canvas.height);
        data?.forEach(
          ({
            floorSchemeCoordinateX: x,
            floorSchemeCoordinateY: y,
            workingCorrectly,
            id,
            typeId,
            jobChart
          }) => {
            const image = new Image(wIcon, hIcon);
            image.src = getEquipmentIcon(typeId);
            image.addEventListener("load", (e) => {
              ctx.drawImage(image, x, y);
              updateChartsList({
                key: id,
                props: jobChart,
                coordinates: {
                  x: x + 25,
                  y: y - 10
                }
              });
              if (!workingCorrectly) {
                const imageAlarm = new Image(20, 20);
                imageAlarm.src = alarm;
                imageAlarm.addEventListener("load", (e) => {
                  ctx.drawImage(imageAlarm, x - 2, y + 28);
                });
              }
            });
          }
        );
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schemaImg]
  );

  const drawFullScreen = useCallback(
    (ctx, data) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.canvas.width = SCHEMA_CANVAS.WIDTH;
      context.canvas.height = SCHEMA_CANVAS.HEIGHT;

      ctx.clearRect(0, 0, context.canvas.width, context.canvas.height);
      const image = new Image();
      image.src = schemaImg;
      image.addEventListener("load", (e) => {
        context.drawImage(
          image,
          0,
          0,
          context.canvas.width,
          context.canvas.height
        );
        data?.forEach(
          ({
            floorSchemeCoordinateX: x,
            floorSchemeCoordinateY: y,
            workingCorrectly,
          }) => {
            const image = new Image(wIcon, hIcon);
            image.src = "/fireicon.svg";
            image.addEventListener("load", (e) => {
              context.drawImage(image, x, y);
              const svgImage = new Image(22, 22);
              svgImage.src = "/donut-chart.svg";
              svgImage.addEventListener("load", (e) => {
                context.drawImage(svgImage, x + 15, y - 10, 22, 22);
                ctx.drawImage(
                  canvas,
                  0,
                  0,
                  ctx.canvas.width,
                  ctx.canvas.height
                );
              });
              if (!workingCorrectly) {
                const imageAlarm = new Image(20, 20);
                imageAlarm.src = alarm;
                imageAlarm.addEventListener("load", (e) => {
                  context.drawImage(imageAlarm, x - 5, y + 20);
                  ctx.drawImage(
                    canvas,
                    0,
                    0,
                    ctx.canvas.width,
                    ctx.canvas.height
                  );
                });
              }
            });
          }
        );
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schemaImg]
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    isResize ? drawFullScreen(context, data) : draw(context, data);

    return () => {
      window.onmousemove = null;
      window.onmousedown = null;
      window.onmouseup = null;
    };
  }, [data, schemaImg, isResize, draw, drawFullScreen]);

  return (
    <>
      <ChartsList/>
      <canvas ref={canvasRef} width={width} height={height} />
      {/*TODO needs to be fixed  */}
      {popupData && (
          <Box sx={{display: 'none'}}>
              <Popup x={popupPosition.x} y={popupPosition.y} data={popupData} />
          </Box>
      )}
    </>
  );
};

export default DrawCanvas;
