import InputText from "../../../../../components/InputText";
import React from "react";
import { t } from "i18next";
import SelectSimple from "../../../../../components/SelectSimple";
export const measuringEquipmentFormFields = {
  name: "name",
  manufacturer: "manufacturer",
  serialNumber: "serialNumber",
  deviceReading:"deviceReading"
}

const commonFields = [
  {
    name: measuringEquipmentFormFields.manufacturer,
    label: "label:manufacturer",
    type: "text",
    rules: {},
    input: (props) => <InputText {...props}/>,
  },
  
]

const specialFields = {
  smokeDetectorsFormConfig: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props}/>,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "text",
      disabled: true,
      value: t("label:opticalSmokeDetectorsTester"),
      rules: {},
      input: (props) => <InputText {...props} />,
    },
  ],
  linearDetectorsFormConfig: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props}/>,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "text",
      disabled: true,
      value: t("label:opticalSmokeDetectorsTester"),
      rules: {},
      input: (props) => <InputText {...props} />,
    },
  ],
  heatDetectorsFormConfig: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props}/>,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
  ],
  flameDetectorsFormConfig: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props}/>,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
  ],
  manualDetectorsFormConfig: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props}/>,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props} />,
    },
  ],
  RABCapacityFormConfig: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props}/>,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "select-text",
      rules: {},
      options: [
        t("options_name:RABCapacityTester"),
        t("options_name:multimeter"),
        t("options_name:voltmeter")
      ],
      input: (props) => <SelectSimple {...props} />,
    },
    /*{
      name: measuringEquipmentFormFields.deviceReading,
      label: "label:deviceReading",
      type: "number",
      onKeyDown:(e) => {
        if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "," || e.Key == ".") {
          e.preventDefault()
        }
      },
      InputProps:{ type:"number" },
      rules: {},
      input: (props) => <InputText {...props}/>,
    },*/
  ],
  voltageCurrentPowerSupplyFormConfig: [
    {
      name: measuringEquipmentFormFields.serialNumber,
      label: "label:serialNumber",
      type: "text",
      rules: {},
      input: (props) => <InputText {...props}/>,
    },
    {
      name: measuringEquipmentFormFields.name,
      label: "label:measuringEquipmentName",
      type: "select-text",
      rules: {},
      options: [
        t("options_name:multimeter"),
        t("options_name:voltmeter"),
        t("options_name:ammeter")
      ],
      input: (props) => <SelectSimple {...props} />,
    },
    /*{
      name: measuringEquipmentFormFields.deviceReading,
      label: "label:deviceReading",
      type: "number",
      onKeyDown:(e) => {
        if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "," || e.Key == ".") {
          e.preventDefault()
        }
      },
      InputProps:{ type:"number" },
      rules: {},
      input: (props) => <InputText {...props}/>,
    },*/
  ]
}

export const getMeasuringEquipmentFormConfig = pointName => {
  let fields = commonFields;
  if (specialFields[pointName]) {
    fields = [...fields, ...specialFields[pointName]];
  }
  return fields;
}