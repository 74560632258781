import React from "react";
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {store, persistor} from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react'
import "./i18n";
import "./styles/globalStyles/globalstyles.css";
import App from "./App";
import AbilityContextComponent from "./AbilityContextComponent";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <AbilityContextComponent>
                    <App/>
                </AbilityContextComponent>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);
