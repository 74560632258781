import React, { useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "../../../image/icons/InputFileIcon.png";

const InputFileBtn = ({
  onChange,
  uploadMode,
  label,
  labelBtn,
  disabled,
  accept = "",
  isError = false,
}) => {
  const { t } = useTranslation();

  const filePicker = useRef(null);

  const handlePick = () => {
    filePicker.current.value = "";
    filePicker.current.click();
  };
  return (
    <Box sx={{ display: "inline-block", position: "relative" }}>
      {labelBtn && (
        <Typography className="input-block-title">{t(labelBtn)}</Typography>
      )}
      <input
        type="file"
        className="hidden"
        ref={filePicker}
        multiple={uploadMode}
        onChange={onChange}
        disabled={disabled}
        accept={accept}
      />
      <Button
        className="btn btn-upload"
        variant="outlined"
        endIcon={<Box
          component="img"
          src={FileUploadIcon}
        />}
        onClick={handlePick}
        disabled={disabled}
        sx={{ height: "48px", whiteSpace: "nowrap",width:300,justifyContent: "space-between"}}
      >
        {t(label)}
      </Button>
      {!isError && <Typography className="input_block_label-helper" sx={{ position: "absolute",mt:"4px" }}>
        {accept ? `${accept.split(",").join("")} ` : ""}
      </Typography>}
    </Box>
  );
};

export default InputFileBtn;
