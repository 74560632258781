import React, { useEffect, useState } from "react";
import { AbilityContext } from "./casl/Can";
import { defineAbility } from "@casl/ability";
import { useAppSelector } from "./hooks/redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes, NonAuthRoutes } from "./views/AppRoutes";
import { PATHS } from "./constants";
import { useLazyGetProfileInfoQuery } from "./middleware/usersApi";
import { Backdrop, CircularProgress } from "@mui/material";
import { parseJsonToken } from "./helpers/helper";

function AbilityContextComponent({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [ability, setAbility] = useState(null);
  const token = useAppSelector((state) => state.users.token);
  const { roleId } = useAppSelector((state) => state.users.info);
  const [permissions, setPermissions] = useState(null);
  const [getProfileInfo, { isLoading }] = useLazyGetProfileInfoQuery();

  useEffect(() => {
    if (!roleId && token) {
      getProfileInfo(token);
    }
  }, [getProfileInfo, roleId, token]);

  useEffect(() => {
    parseJsonToken({ token, setPermissions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  function checkEquipmentsAvailability(array) {
    return array.includes("facilities");
  }

  useEffect(() => {
    setAbility(
      defineAbility((can) => {
        let redirectRoute = null;
        if (token) {
          can("manage", "auth");
          if (
            NonAuthRoutes.find((route) => route.path === location.pathname) &&
            permissions
          ) {
            const equipmentsAvailable =
              checkEquipmentsAvailability(permissions);
            redirectRoute =
              roleId !== 4 && equipmentsAvailable
                ? PATHS.OBJECTS_MAP
                : PATHS.LIST_TASKS;
          }
        } else {
          can("manage", "notAuth");
          if (AppRoutes.find((route) => route.path === location.pathname)) {
            redirectRoute = PATHS.LOGIN_ROUTE;
          }
        }
        if (redirectRoute && redirectRoute !== location.pathname) {
          navigate(redirectRoute);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigate, token, roleId]);

  if (isLoading) {
    return (
      <Backdrop sx={{ color: "var(--white)", zIndex: 9999 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    ability && (
      <AbilityContext.Provider value={ability}>
        {children}
      </AbilityContext.Provider>
    )
  );
}

export default AbilityContextComponent;
