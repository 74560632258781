export const getBgColor = (option) => (row, index) => {
  const { messageClassTypeId, triggerCause, messageCodeTypeColor } = row || {};
  console.log("messageCodeTypeBorderColor",messageCodeTypeColor)  
  if (messageCodeTypeColor != "") return `${messageCodeTypeColor} !important`;
  if (triggerCause === "ТО") return "var(--bgGrey)";

  const colorItem =
    messageClassTypeId &&
    option.find((item) => item.value === messageClassTypeId);
    
    
  return colorItem
    ? `${colorItem?.color} !important`
    : index % 2
    ? "var(--bgTableRow)"
    : "var(--bgWhite)";
};
export const getBorderColor = (option) => (row, index) => {
  const { messageCodeTypeBorderColor} = row || {};
 
  if (messageCodeTypeBorderColor != "") return `${messageCodeTypeBorderColor} !important`;
 
};
