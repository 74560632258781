import React from "react";

export const ArrowIconLeftBlue = () => {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 1L27 23"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M0.292694 10.5072C-0.0978298 10.8978 -0.0978299 11.5309 0.292694 11.9215L6.65666 18.2854C7.04718 18.6759 7.68034 18.6759 8.07087 18.2854C8.46139 17.8949 8.46139 17.2617 8.07087 16.8712L2.41401 11.2144L8.07087 5.5575C8.46139 5.16698 8.46139 4.53381 8.07087 4.14329C7.68035 3.75276 7.04718 3.75276 6.65666 4.14329L0.292694 10.5072ZM23.1479 10.2144L0.999802 10.2144L0.999802 12.2144L23.1479 12.2144L23.1479 10.2144Z"
        fill="#051D39"
      />
    </svg>
  );
};
