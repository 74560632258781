import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ModalRemove = ({ open, setOpen, action, text }) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    action();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} className="modal">
        <DialogTitle className="modal-title-right">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0 40px" }}>
          <DialogContentText className="modal-text">
            {t(`modal_remove:${text}`)}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="modal-btn-field modal-btn-field-center">
          <Button className="btn btn-outlined-grey" onClick={handleClose}>
            {t("modal_remove:btn_no")}
          </Button>
          <Button className="btn btn-red-fill" onClick={handleSubmit} autoFocus>
            {t("modal_remove:btn_yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalRemove;
