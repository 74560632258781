import React, { useMemo, useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";

import { getRoomAddressForScheme } from "../../../../../helpers/helper";
import { formFields } from "../form-config";
import { useAppSelector } from "../../../../../hooks/redux";

import SchemaWithEquipments from "../../../../components/DrawCanvas/SchemaWithEquipments";

const FloorSchema = ({ control, data, setValue }) => {
  const { roomAddresses, floorSchemeFileLink } = data || {};
  const { SCHEMA_CANVAS, openSidebar } = useAppSelector(
    (state) => state.settings
  );

  const $container = useRef(null);
  const [widthContainer, setWidthContainer] = useState(0);

  useEffect(() => {
    if ($container) {
      const containerWidth = $container.current?.offsetWidth;
      const screenWidth = window.innerWidth;
      setWidthContainer(() =>
        screenWidth > 1150 ? containerWidth * 0.7 : containerWidth
      );

      window.addEventListener("resize", function () {
        const containerWidth = $container.current?.offsetWidth;
        const screenWidth = window.innerWidth;
        setWidthContainer(() =>
          screenWidth > 1150 ? containerWidth * 0.7 : containerWidth
        );
      });
    }
  }, [$container, openSidebar]);

  const {
    width: screenRelatedWidth,
    height: screenRelatedHeight,
    scale,
  } = useMemo(() => {
    const width = widthContainer;
    const height =
      widthContainer / (SCHEMA_CANVAS.WIDTH / SCHEMA_CANVAS.HEIGHT);
    return SCHEMA_CANVAS.WIDTH && widthContainer
      ? {
          width,
          height,
          scale: {
            x: width / SCHEMA_CANVAS.WIDTH,
            y: height / SCHEMA_CANVAS.HEIGHT,
          },
        }
      : {};
  }, [SCHEMA_CANVAS, widthContainer]);

  const screenRelatedRoomAddresses = useMemo(
    () => getRoomAddressForScheme(roomAddresses, scale),
    [roomAddresses, scale]
  );

  return (
    <Box
      ref={$container}
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <SchemaWithEquipments
        name={formFields.equipmentsCoordinates}
        width={screenRelatedWidth || 0}
        height={screenRelatedHeight || 0}
        schemaImg={floorSchemeFileLink}
        roomAddresses={screenRelatedRoomAddresses}
        control={control}
        draggable={true}
        setValue={setValue}
      />
    </Box>
  );
};

export default FloorSchema;
