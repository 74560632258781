import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const controllersApi = createApi({
  reducerPath: "controllers/api",
  tagTypes: ["Controllers"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getControllersTable: build.query({
      query: ({ pageNumber }) => ({
        url: `api/get-control-consoles-table?pageNumber=${pageNumber}`,
      }),
      providesTags: ["Controllers"],
    }),
    getControllersConsoleTable: build.query({
      query: ({ pageNumber, consoleId, filters }) => ({
        url: `api/get-control-console-protection-objects?pageNumber=${pageNumber}&consoleId=${consoleId}${filters}`,
      
    }),
    providesTags: ["Controllers"],
  }),
  checkControl: build.query({
    query: ({ uuid }) => ({
      url: `api/check-control-console?ControlConsoleId=${uuid}`,
    }),
    providesTags: ["Controllers"],
  }),
  addController: build.mutation({
    query: (data) => ({
      url: "api/add-control-console",
      method: "POST",
      body: data,
    }),
    providesTags: ["Controllers"],
  }),
  addControllerObject: build.mutation({
    query: (data) => ({
      url: "api/add-control-console-object",
      method: "POST",
      body: data,
    }),
    providesTags: ["Controllers"],
  }),
  deleteControllerObject: build.mutation({
    query: (data) => ({
      url: "api/delete-control-console-object",
      method: "POST",
      body: data,
    }),
    providesTags: ["Controllers"],
  }),
  getDebuggersList: build.query({
    query: () => ({
      url: "api/get-control-consoles-debugger",
    }),
    providesTags: ["Controllers"],
  }),
}),
});

export const {
  useLazyGetControllersTableQuery,
  useLazyGetControllersConsoleTableQuery,
  useAddControllerMutation,
  useAddControllerObjectMutation,
  useDeleteControllerObjectMutation,
  useLazyCheckControlQuery,
  useGetDebuggersListQuery,
} = controllersApi;
