import { createSlice } from "@reduxjs/toolkit";
import { groupJobsApi } from "../../middleware/groupJobsApi";
const initialState = {
  grouptasks: [],
  grouptask:[]
};

export const grouptasksSlice = createSlice({
  name: "grouptasks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
        groupJobsApi.endpoints.getGroupTasksList.matchFulfilled,
      (state, { payload }) => {
        state.grouptasksList = payload;
      }
    );
    builder.addMatcher(
      groupJobsApi.endpoints.getGroupTask.matchFulfilled,
    (state, { payload }) => {
      state.grouptask = payload;
    }
  );
  },
});

export const grouptasksActions = grouptasksSlice.actions;
export const grouptasksReducer = grouptasksSlice.reducer;
