import React from "react";
import { Box } from "@mui/material";

import {
  useGetJobAnalyticsQuery,
  useGetSystemsUnderControlCharDataQuery,
} from "../../../middleware/analyticsApi";
import {
  getDefaultSatrtDate,
  getDefaultEndDate,
  getTasksChartBarData,
} from "./helpers";
import { getLabels } from "../../components/Charts/ChartCard/helpers";

import ChartCard from "../../components/Charts/ChartCard";
import VerticalBar from "../../components/Charts/VerticalBar";
import HorizontalBar from "../../components/Charts/HorizontalBar";

const FacilityAnalytics = () => {
  const {
    data: systemsUnderControlCharData,
    isLoading: isLoadingUnderControl,
  } = useGetSystemsUnderControlCharDataQuery();
  const { data: jobAnalytics, isLoading: isLoadingJobAnalytics } =
    useGetJobAnalyticsQuery({
      startDate: getDefaultSatrtDate(),
      endDate: getDefaultEndDate(),
    });

  const controlledSystemsChartBarData =
    !isLoadingUnderControl && 
    systemsUnderControlCharData?.systems.map((system) => {
      return {
        label: `label:${system.name}`,
        data: system.count,
        backgroundColor: getRandomColor(),
      };
    });

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  return (
    <Box
      sx={{
        display: "flex",
        maxHeight: "350px",
        justifyContent: "space-between",
        gap: "25px",
      }}
    >
      {!isLoadingJobAnalytics && (
        <ChartCard
          sx={{ flexBasis: "40%" }}
          label="label:completedTasksCount"
          data={getTasksChartBarData(jobAnalytics)}
          Chart={VerticalBar}
        />
      )}
      {!isLoadingUnderControl && (
        <ChartCard
          label="label:controlledSystemsCount"
          data={controlledSystemsChartBarData}
          labels={getLabels(controlledSystemsChartBarData)}
          Chart={HorizontalBar}
        />
      )}
    </Box>
  );
};

export default FacilityAnalytics;
