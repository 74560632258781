import React from "react";
import "./styles/styles.css";
import { SetNewPasswordDialogFormConfig } from "./form-config.jsx";
import FormItem from "../../../components/FormItem";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useUpdateProfileInfoPasswordMutation } from "../../../../middleware/usersApi";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";

const SetNewPasswordDialog = ({
  token,
  open,
  onClose,
  firstEntered = false,
}) => {
  const { t } = useTranslation();
  const [updateProfileInfoPassword, { isSuccess, error: updateError }] =
    useUpdateProfileInfoPasswordMutation();
  const [isOpenAlert, setIsOpenAlert] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    const { oldPassword, newPassword } = data;
    updateProfileInfoPassword({
      token,
      data: {
        oldPassword,
        newPassword,
      },
    }).then(({ error }) => {
      handleAlert({ error });
      localStorage.setItem("isLoggedBefore", true);
    });
  };

  const handleAlert = ({ error }) => {
    setIsOpenAlert(true);
    setTimeout(() => {
      setIsOpenAlert(false);
      !error && onClose();
    }, 2000);
  };

  return (
    <Dialog
      className="new_password_dialog"
      open={open}
      onClose={!firstEntered && !isOpenAlert ? onClose : () => {}}
    >
      <DialogTitle sx={{ p: 0, mb: "30px" }}>
        <Typography className="new_password_dialog-header">
          {t("title:setPassword")}
        </Typography>
        {(isOpenAlert || !firstEntered) && (
          <IconButton
            className="new_password_dialog-close_icon"
            onClick={isOpenAlert ? setIsOpenAlert.bind(null, false) : onClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        {isOpenAlert && (
          <Alert
            icon={<CheckIcon />}
            variant="filled"
            severity={isSuccess ? "success" : "error"}
          >
            {isSuccess
              ? t("notification:success_set_password")
              : updateError.data || t("notification:error_set_password")}
          </Alert>
        )}

        {!isOpenAlert && (
          <form id="setNewPasswordForm" onSubmit={handleSubmit(onSubmit)}>
            {SetNewPasswordDialogFormConfig.map(
              ({ name, label, input, ...rest }) => (
                <FormItem
                  key={name}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              )
            )}
            <Box className="new_password_dialog-submit_btn_wrapper">
              <Button
                className="btn btn-primary-fill"
                variant="contained"
                type="submit"
              >
                {t("button:change_password")}
              </Button>
            </Box>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SetNewPasswordDialog;
