import React from "react";
import { t } from "i18next";

import { formFields } from "./form-config";
import {
  SimpleHeader,
  SimpleTextCell,
  DeadlineStatusTextCell,
  StatusCell,
} from "../../TasksList/Components";
import { SearchInbuilt } from "../../../components/SearchInbuilt";
import SelectSimpleInbuilt from "../../../components/SelectSimpleInbuilt";
import FormItem from "../../../components/FormItem";
import { Box } from "@mui/material";
import { ClickableEquipCell } from "../Components";
import { ClickableNameCell } from "../../MyOrganizations/Components";
import { deadlineStatusOptions, statusOptions } from "./options";

export const getColumns = ({
  control,
  columnsFromStorage,
  searchTaskName,
  setsearchTaskName,
  searchresUser,
  setSearchresUser,
  searchequip,
  setsearchequip,
}) =>
  [
    {
      id: formFields.equipName,
      numeric: false,
      enableSorting: false,
      size: "250px",
      borderHeader: true,
      isVisible: columnsFromStorage["jobEquipments"],
      RowCell: (props) => <ClickableEquipCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "250px" }}>
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.EquipmentNumberAndName}`}
            input={(props) => (
              <SearchInbuilt
                placeholder={t("tableHeader:equip")}
                className="select_in_built small-light"
                searchValue={searchequip}
                changeSearchValue={setsearchequip}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.taskname,
      numeric: false,
      enableSorting: false,
      size: "150px",
      clickable: true,
      isSticky: true,
      borderHeader: true,
      isVisible: columnsFromStorage["taskName"],
      RowCell: (props) => <ClickableNameCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "100px" }}>
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.JobName}`}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                placeholder={t("tableHeader:group_task")}
                searchValue={searchTaskName}
                changeSearchValue={setsearchTaskName}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.status,
      numeric: false,
      enableSorting: false,
      size: "250px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.status],
      RowCell: (props) => <StatusCell {...props} />,
      HeaderCell: () => (
        <>
          {/* <SimpleHeader name={t("tableHeader:status")} /> */}
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.status}`}
            label={t("options_name:everything")}
            options={statusOptions}
            width="100%"
            input={(props) => (
              <SelectSimpleInbuilt
                {...props}
                className="select_in_built small-light"
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.deadlineStatus,
      numeric: false,
      enableSorting: false,
      size: "250px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.deadlineStatus],
      RowCell: (props) => <DeadlineStatusTextCell {...props} />,
      HeaderCell: () => (
        <>
          {/* <SimpleHeader name={t("tableHeader:state")} /> */}
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.deadlineStatus}`}
            label={t("options_name:everything")}
            options={deadlineStatusOptions}
            width="100%"
            input={(props) => (
              <SelectSimpleInbuilt
                {...props}
                className="select_in_built small-light"
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.responsibleCustomerUser,
      numeric: false,
      enableSorting: false,
      size: "240px",
      clickable: false,
      isSticky: true,
      borderHeader: true,
      isVisible: columnsFromStorage["executiveUser"],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "100%" }}>
          {
            <FormItem
              control={control}
              name={`${formFields.searchParams}.${formFields.ExecutiveUserName}`}
              input={(props) => (
                <SearchInbuilt
                  placeholder={t("tableHeader:resUser")}
                  className="select_in_built small-light"
                  searchValue={searchresUser}
                  changeSearchValue={setSearchresUser}
                  {...props}
                />
              )}
            />
          }
        </Box>
      ),
    },
    {
      id: formFields.statistics,
      numeric: false,
      enableSorting: false,
      size: "130px",
      clickable: false,
      isSticky: true,
      borderHeader: true,
      isVisible: columnsFromStorage["completionProgress"],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "100%" }}>
          <SimpleHeader name={t("tableHeader:task_statistics")} />
        </Box>
      ),
    },
  ].filter(({ isVisible }) => isVisible);

  // {
  //   id: formFields.status,
  //   numeric: false,
  //   enableSorting: false,
  //   size: "130px",
  //   clickable: true,
  //   isSticky: true,
  //   borderHeader: true,
  //   isVisible: columnsFromStorage["completionProgress"],
  //   RowCell: (props) => <SimpleTextCell {...props} />,
  //   HeaderCell: () => (
  //     <Box
  //       sx={{
  //         fontSize: "16px",
  //         fontWeight: "500",
  //         color: "var(--headers)",
  //       }}
  //     >
  //       {t("tableHeader:task_statistics")}
  //     </Box>
  //   ),
  // },