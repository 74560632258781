import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import {
  formConfigBasicInf,
  formConfigOrganization,
  formLicense,
  formFields,
  formConfigAddress,
  templateAddress,
} from "./form-config";
import { ADDRESSES_TYPES } from "./constants";

import FormItem from "../../components/FormItem";
import {
  formConfigInn,
  listOfOrganisationsConfig,
} from "../MyOrganizations/form-config";
import { AddressInput } from "../../components/AddressInputOrg";
import "./styles/style.css";

const BasicInformation = ({
  okved,
  control,
  errors,
  disabled,
  isLicense,
  indCheck,
  watch,
  resAddressFromDadata,
  setresAddressFromDadata,
}) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: formFields.organizationResponsibleAddresses,
  });
  const [setokved, setokvedCode] = useState("");
  const [checkedIp, setCheckedIp] = useState(fields.length === 1);

  const handleCheckbox = (e) => {
    const isChecked = e.target.checked;
    setCheckedIp(checkedIp);
    if (isChecked && fields.length > 1) remove(1);
    if (!isChecked && fields.length < 2) append(templateAddress);
  };
  useEffect(() => {
    if (checkedIp && fields.length === 2) remove(1);
    if (!checkedIp && fields.length === 1) append(templateAddress);
  }, [checkedIp]);
  useEffect(() => {
    setokvedCode(okved);
  }, [okved]);
  useEffect(() => {
    if (fields.length > 2) remove(1);
    //setCheckedIp(fields.length === 1);
  }, [fields]);
  useEffect(() => {
    if (indCheck) {
      setCheckedIp(indCheck);
      if (indCheck && fields.length === 2) remove(1);
      if (!indCheck && fields.length === 1) append(templateAddress);
      if (fields.length > 2) remove(1);
    }
  }, [indCheck]);
  const isReadySection = watch ? !!watch(`${[formFields.organizationResponsibleAddresses]}`) : true;
  return (
    <div className="add-organization-block">
      <Accordion
        square={true}
        disableGutters={true}
        sx={{
          background: "var(--white)",
          padding: "20px 32px",
          borderRadius: "32px",
          "& .MuiAccordionSummary-expandIconWrapper svg": {
            color: "var(--black)",
          },
        }}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            flexDirection: "row-reverse",
            padding: "0",
            minHeight: "fit-content",
            "& .MuiAccordionSummary-content": {
              transition: "margin-bottom 0.3s ease-in-out",
              margin: "12px 0",
            },
            "& .Mui-expanded": { mb: "24px" },
          }}
        >
          <Typography className="form-section-label">
            {t("title:basic_information")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            background: "var(--white)",
            width: "50%",
            padding: "0",
            mb: "12px",
            "& .input-wrapper": { padding: "0 !important" },
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            {listOfOrganisationsConfig.map(
              ({ name, label, input, ...rest }) => (
                <FormItem
                  key={name}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              )
            )}

            {formConfigInn.map(({ name, label, input, ...rest }) => (
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={name}
                label={label}
                input={input}
                {...rest}
              />
            ))}
          </Box>

          <Box
            sx={{
              display: "grid",
              justifyContent: "flex-start",
              gridTemplateColumns: "1fr 1fr",
              gap: "24px",
            }}
          >
            {formConfigOrganization.map(({ name, label, input, ...rest }) => (
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={name}
                label={label}
                input={input}
                disabled={disabled}
                {...rest}
              />
            ))}

            {formConfigBasicInf.map(({ name, label, input, ...rest }) =>
              name == "okvedCode" ? (
                <FormItem
                  key={name}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  setokvedCode={setokvedCode}
                  value={setokved}
                  input={input}
                  disabled={disabled}
                  {...rest}
                />
              ) : (
                <FormItem
                  key={name}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  rules={{
                    maxLength: (v) =>
                      checkedIp ? v?.length <= 10 : v?.length <= 8,
                    validate: (value, formValues) =>
                      checkedIp ? value?.length === 10 : value?.length === 8,
                  }}
                  input={input}
                  disabled={disabled}
                  {...rest}
                />
              )
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              columnGap: "24px",
            }}
          >
            {/* {formConfigBasicInf.map(({ name, label, input, ...rest }) =>
              name == "okvedCode" ? (
                <FormItem
                  key={name}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  setokvedCode={setokvedCode}
                  value={setokved}
                  input={input}
                  disabled={disabled}
                  {...rest}
                />
              ) : (
                <FormItem
                  key={name}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  rules={{
                    maxLength: (v) =>
                      checkedIp ? v?.length <= 10 : v?.length <= 8,
                    validate: (value, formValues) =>
                      checkedIp ? value?.length === 10 : value?.length === 8,
                  }}
                  input={input}
                  disabled={disabled}
                  {...rest}
                />
              )
            )} */}
            {isLicense &&
              formLicense.map(({ name, label, input, ...rest }) =>
                name == "okvedCode" ? (
                  <FormItem
                    key={name}
                    control={control}
                    errors={errors}
                    name={name}
                    label={label}
                    setokvedCode={setokvedCode}
                    value={setokved}
                    input={input}
                    disabled={disabled}
                    {...rest}
                  />
                ) : (
                  <FormItem
                    key={name}
                    control={control}
                    errors={errors}
                    name={name}
                    label={label}
                   /* rules={{
                      maxLength: (v) =>
                        checkedIp ? v?.length <= 10 : v?.length <= 8,
                      validate: (value, formValues) =>
                        checkedIp ? value?.length === 10 : value?.length === 8,
                    }}*/
                    input={input}
                    disabled={disabled}
                    {...rest}
                  />
                )
              )}
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        square={true}
        disableGutters={true}
        sx={{
          background: "var(--white)",
          padding: "20px 32px 20px 32px",
          borderRadius: "32px",
          "& .MuiAccordionSummary-expandIconWrapper svg": {
            color: "var(--black)",
          },
          "&::before": {
            height: "0",
          },
        }}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            flexDirection: "row-reverse",
            padding: "0",
            minHeight: "fit-content",
            "& .MuiAccordionSummary-content": {
              transition: "margin-bottom 0.3s ease-in-out",
              margin: "12px 0",
            },
            "& .Mui-expanded": { mb: "24px" },
          }}
        >
          <Typography className="form-section-label" sx={{}}>
            {t("label:ur_address")}
          </Typography>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            background: "var(--white)",
            width: "50%",
            padding: "0",
            mb: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              "& .input-wrapper": { padding: "0 !important" },
            }}
          >
            {isReadySection && formConfigAddress.map(
              ({ name, type, label, input, rest,placeholder, rules, dynamicRules }) =>
                name == "address" && resAddressFromDadata ? (
                  <FormItem
                    key={name}
                    control={control}
                    type={type}
                    errors={errors}
                    placeholder={placeholder}
                    //  defaultValue={addressFromDadata}
                    value={resAddressFromDadata[0]}
                    onChangeForOrg={setresAddressFromDadata}
                    indexAdress={0}
                    label={label}
                    name={`${[
                      formFields.organizationResponsibleAddresses,
                    ]}.${0}.${name}`}
                    input={(props) => <AddressInput {...props} />}
                    rules={{ ...rules }}
                    disabled={disabled}
                    {...rest}
                  />
                ) : (
                  <FormItem
                    key={name}
                    control={control}
                    type={type}
                    errors={errors}
                    placeholder={placeholder}
                    
                    label={label}
                    name={`${[
                      formFields.organizationResponsibleAddresses,
                    ]}.${0}.${name}`}
                    input={input}
                    rules={{ ...rules }}
                    disabled={disabled}
                    {...rest}
                  />
                )
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BasicInformation;
