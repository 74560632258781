import { createSlice } from "@reduxjs/toolkit";
// import { uploadFileApi } from "../../middleware/apiUploadFile";

const initialState = {};

export const uploadFileSlice = createSlice({
  name: "uploadFile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const uploadFileActions = uploadFileSlice.actions;
export const uploadFileReducer = uploadFileSlice.reducer;
