export const getFacilityAndFireCompartmentOptionsGroup = (data) => 
  data.map(el => ({
    id: el.id,
    name: el.name,
    items: el.fireCompartments,
    open: false,
  }));

export const getEquipmentTypeIdOptionsGroupAddEquip = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((el) => ({
    id: `${el?.id}`,
    name: el?.fullTitle,
    items: el?.equipmentTypes,
    open: false,
  }));
};
export const getEquipmentTypeIdOptionsGroup = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((el) => ({
    id: `parent${el?.id}`,
    name: el?.title,
    items: el?.equipmentTypes,
    open: false,
  }));
};