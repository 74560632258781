import { useEffect, useMemo, useState } from "react";
import { useLazyGetOrgInnQuery, useLazyGetBankBICQuery } from "../../../../middleware/addressDataApi";
import { useLazyGetOrganizationByInnQuery } from "../../../../middleware/apiOrganizations";
import { useWatch } from "react-hook-form";
import { formFields, EMPTY_VALUES } from "../form-config";
import { TYPE_ID, fields } from "../constants";

export const useWatchOrganizationInnNumber = ({ control, errors, reset, setValue, resetField }) => {
  const [getOrganizationByInn] = useLazyGetOrgInnQuery();
  const [getAlreadyOrganizationByInn] = useLazyGetOrganizationByInnQuery();
  const [okvedCode, setokvedcode] = useState("");
  const [addressFromDadata, setaddressFromDadata] = useState([null, null]);
  const [resAddressFromDadata, setresAddressFromDadata] = useState([null, null]);
  const [indCheck, setindCheck] = useState(false);
  const data = useWatch({ control })

  const innNumberValue = useWatch({
    control,
    name: formFields.innNumber,
  });
  const nameValue = useWatch({
    control,
    name: formFields.name,
  });

  console.log("innNumberValue", innNumberValue)
  const shouldDisableAllFields = useMemo(
    () => !innNumberValue || errors.innNumber,
    [errors.innNumber, innNumberValue]
  );

  useEffect(() => {
    if (!innNumberValue || innNumberValue?.length < 10 || innNumberValue?.length > 13 || !reset) {
      setokvedcode("")
      //setaddressFromDadata("")
      return;
    }
    getAlreadyOrganizationByInn(innNumberValue)
      .then((res) => {
        const organization = res?.data ? { ...res?.data } : null;

        if (nameValue && !organization?.id) {
          reset({
            innNumber: innNumberValue,
            ...EMPTY_VALUES,
          });
          return;
        }
        if (organization?.id) {

          const updatedOrg = organization?.typeId === 2
            ? { ...organization,organizationResponsibleBankDetails:organization.bankDetails,organizationResponsibleAddresses:organization.organizationAddresses}
            : organization;
          console.log("updatedOrg", { ...organization,organizationResponsibleBankDetails:organization.bankDetails})

          reset({
            ...updatedOrg,
            file: {
              filesPrev: organization?.ogrnNumberFileName
                ? [{ fileName: organization?.ogrnNumberFileName }]
                : [{ fileName: "Без имени" }],
              filesToAdd: [],
              filesToDelete: [],
            },
          });
          setaddressFromDadata(undefined)
          setresAddressFromDadata(undefined)


        } else {

          console.log("organization", res)
          getOrganizationByInn(innNumberValue)
            .then((res) => {
              const organization = res?.data?.suggestions && res?.data?.suggestions?.length > 0 ? { ...res?.data?.suggestions[0] } : null;


              if (!organization?.data) {
                return;
              }
              const organizationData = organization?.data
              const updatedOrg = {
                name: organizationData?.name?.short_with_opf,
                ogrnNumber: organizationData.ogrn,
                kppCode: organizationData.kpp,
                okpoCode: organizationData.okpo,
                address: organizationData?.address.data

              }

              setokvedcode(organizationData.okved)
              console.log("updatedOrg", updatedOrg)
              setValue("name", organizationData?.name?.short_with_opf)
              setValue("ogrnNumber", organizationData.ogrn)
              setValue("kppCode", organizationData.kpp)
              setValue("okpoCode", organizationData.okpo)
              setValue("okvedCode", organizationData.okved)
              setindCheck(organizationData?.type == "INDIVIDUAL" ? true : false)
              setValue("indCheck", organizationData?.type == "INDIVIDUAL" ? true : false)
              setaddressFromDadata([organizationData?.address, organizationData?.address])//( { city: organizationData?.address.data.city, street: organizationData?.address.data.street, building: organizationData?.address.data.house })
              setresAddressFromDadata([organizationData?.address, organizationData?.address])
              //setValue("organizationAddresses[1].address",organizationData?.address)
              //  setValue("organizationAddresses[0].address",organizationData?.address.data)
              // setValue("organizationAddresses.1",organizationData?.address)
              setValue("organizationAddresses.0.address", organizationData?.address)
              setValue("organizationAddresses.1.address", organizationData?.address)
              setValue("organizationResponsibleAddresses.0.address", organizationData?.address)
              setValue("organizationResponsibleAddresses.1.address", organizationData?.address)
              /*   resetField("organizationAddresses.1.address",  { city: organizationData?.address.data.city, street: organizationData?.address.data.street, building: organizationData?.address.data.house })
                resetField("organizationAddresses.0.address", { city: organizationData?.address.data.city, street: organizationData?.address.data.street, building: organizationData?.address.data.house  })
                resetField("organizationResponsibleAddresses.0.address", { city: organizationData?.address.data.city, street: organizationData?.address.data.street, building: organizationData?.address.data.house })
                resetField("organizationResponsibleAddresses.1.address",  { city: organizationData?.address.data.city, street: organizationData?.address.data.street, building: organizationData?.address.data.house  })
                reset({
                   innNumber: innNumberValue,
                   ...updatedOrg,
                 });*/
            })
            .catch((err) => {
              console.log(err);
            });
        }

      })
      .catch((err) => {
        console.log(err);
      });


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innNumberValue, errors.innNumber, resetField, reset, setValue, getOrganizationByInn, getAlreadyOrganizationByInn]);

  return { innNumberValue, shouldDisableAllFields, okvedCode: okvedCode, indCheck, addressFromDadata, setaddressFromDadata, resAddressFromDadata, setresAddressFromDadata };
};

export const useWatchBicNumber = ({ control, errors, setValue, typeId }) => {
  const [getBankByBIC] = useLazyGetBankBICQuery();
  const data = useWatch({ control })

  const BankInfo = useWatch({
    control,
    name: typeId === TYPE_ID[fields.organization_contractor] ? "organizationResponsibleBankDetails." + formFields.bankBIC : "bankDetails." + formFields.bankBIC,
  });

  useEffect(() => {

    if (!BankInfo || BankInfo?.length < 9 || !setValue) {
      return;
    }

    getBankByBIC(BankInfo)
      .then((res) => {
        const bank = res?.data?.suggestions && res?.data?.suggestions?.length > 0 ? { ...res?.data?.suggestions[0] } : null;

        if (!bank?.data) {
          return;
        }
        const bankData = bank?.data
        setValue("bankDetails." + formFields.bankBIC, bankData.bic)
        setValue("bankDetails." + formFields.bankName, bankData.name.short)
        setValue("bankDetails." + formFields.correspondentAccount, bankData.correspondent_account)
        setValue(`organizationResponsibleBankDetails.${[formFields.organizationResponsibleBankDetails]}.${formFields.bankBIC}`, bankData.bic)
        setValue(`organizationResponsibleBankDetails.${formFields.bankName}`, bankData.name.short)
        setValue(`organizationResponsibleBankDetails.${formFields.correspondentAccount}`, bankData.correspondent_account)
        console.log("bankData", bankData)

      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BankInfo, errors.bankBIC, setValue, getBankByBIC]);

  return { BankInfo };
};

