import { createSlice } from "@reduxjs/toolkit";
import { tasksApi } from "../../middleware/tasksApi";
const initialState = {
  tasks: [],
  sortParams: {
    executiveUser: "",
    executiveUserId:"",
    facilityName: "",
    equipmentName: "",
    equipmentId:"",
  },
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    changeTasksValue(state, action) {
      state.tasksValue = action.payload;
    },
    setSortParamsExecutiveUser(state, action) {
      state.sortParams.executiveUser = action.payload;
    },
    setSortParamsFacilityName(state, action) {
      state.sortParams.facilityName = action.payload;
    },
    setSortParams(state, action) {
      state.sortParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      tasksApi.endpoints.getTasksList.matchFulfilled,
      (state, { payload }) => {
        state.tasks = payload;
      }
    );
  },
});

export const tasksActions = tasksSlice.actions;
export const tasksReducer = tasksSlice.reducer;
