import React from "react";
import "./styles/style.css";
import { Box } from "@mui/material";
import Header from "./Header";
import { PATHS } from "../../../../constants";
import { useNavigate } from "react-router-dom";

const AddController = () => {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(PATHS.CONTROLLERS);
  };

  return (
    <Box className="add-controller-container">
      <Header isSubmitDisabled={false} handleCancel={handleCancel} />
    </Box>
  );
};

export default AddController;