
import CheckboxSimple from "../../components/CheckboxSimple";

export const formFields = {
  name: "name",
  searchParams: "searchParams",
  integrationNumber: "integrationNumber",
  number: "number",
  plume: "plume",
  regulationDescription:"regulationDescription",
  periodName:"periodName",
  protectionObjectName: "protectionObjectName",
  protectionObjectId: "protectionObjectId",
  workingProperly: "workingProperly",
  edit: "edit",
  lastServiceDate: "lastServiceDate",
  lifeTimeDate: "lifeTimeDate",
  floorNumber: "floorNumber",
  floorId: "floorId",
  explotationExceeded: "explotationExceeded",
  equipmentKindName: "equipmentKindName",
  equipmentKindId: "equipmentKindId",
  workingCorrectly: "workingCorrectly",
  searchName: "searchName",
  searchPlume: "searchPlume",
  searchIntegrationNumber: "searchIntegrationNumber",
  searchNumber: "searchNumber",
  tasks: "tasks",
  equipmentCount:"equipmentCount",
  equipmentKindName:"equipmentKindName",
  equipmentName:"equipmentName",
  equipmentTypeName:"equipmentTypeName",
  isCustom:"isCustom",
  protectionObjectName:"protectionObjectName",
  regulationName:"regulationName",
  workingProperly:"workingProperly",
  standardName:"standardName",
  isCustom:"isCustom",
  standardName:"standardName",
  standardParagraphName:"standardParagraphName",
  workRequirementsDetails:"workRequirementsDetails",
  techCard:"techCard",
  workscopeName:"workscopeName"
}

export const allColumns = [
  { name: formFields.name, showName: "equip_name", value: true },
  { name: formFields.number, value: true, showName: "number" },
  { name: formFields.integrationNumber, value: true, showName: "intNumber" },
  { name: formFields.plume, value: true, showName: "plume" },
  { name: formFields.protectionObjectName, value: true, showName: "protectionObjectName" },
  { name: formFields.workingProperly, showName: "workingCorrectly", value: true },
  { name: formFields.lastServiceDate, value: true, showName: "lastServiceDate" },
  { name: formFields.lifeTimeDate, showName: "lifeTimeDate", value: true },
  { name: formFields.floorNumber, showName: "floorNumber", value: true },
  { name: formFields.equipmentKindName, showName: "system", value: true },
  { name: formFields.tasks, showName: "tasks", value: true },
];

export const getConfigFormModalSelect = (columns) =>
  columns?.map(({ name }) => ({
    name,
    label: `tableHeader:${name}`,
    type: "checkbox",
    rules: {},
    flexBasis: "50%",
    input: (props) => <CheckboxSimple {...props} />,
  }));
