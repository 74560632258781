import { createSlice } from "@reduxjs/toolkit";
import { organizationsApi } from "../../middleware/apiOrganizations";

const initialState = {
  organization: {},
  allOrganizations: [],
  isChangeForm: false,
  organizationsSelect: [],
};

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setIsChangeForm(state, action) {
      state.isChangeForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      organizationsApi.endpoints.getOrganization.matchFulfilled,
      (state, { payload }) => {
        state.organization = payload;
      }
    );
    builder.addMatcher(
      organizationsApi.endpoints.getOrganizationsSelect.matchFulfilled,
      (state, { payload }) => {
        state.organizationsSelect = payload;
      }
    );
    builder.addMatcher(
      organizationsApi.endpoints.getAllOrganizations.matchFulfilled,
      (state, { payload }) => {
        state.allOrganizations = payload;
      }
    );
  },
});

export const organizationsActions = organizationsSlice.actions;
export const organizationsReducer = organizationsSlice.reducer;
