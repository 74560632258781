import React from "react";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import emptyImage from "../../../image/icons/emptyImage.png";

export const PreviewFileBlock = ({ file }) => {
  const isFile = file instanceof File;

  return (
    <Box sx={{ textAlign: "-webkit-center", mb: "4px" }}>
      {isFile ? (
        file?.type == "application/pdf" ? (
          <CardMedia
            component="img"
            image={emptyImage}
            alt="preview image"
            sx={{ width: "48px", height: "48px", borderRadius: "4px" }}
          />
        ) : (
          <CardMedia
            component="img"
            image={URL.createObjectURL(file)}
            alt="preview image"
            sx={{ width: "48px", height: "48px", borderRadius: "4px" }}
          />
        )
      ) : file && file.fileLink === "/unnamed.png" ? (
        <></>
      ) : file && file.fileLink ? (
        <CardMedia
          component="img"
          image={file.fileLink}
          alt="preview image"
          sx={{ width: "48px", height: "48px", borderRadius: "4px" }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};
