import React from "react";
import { Controller } from "react-hook-form";
import { Box } from "@mui/material";
import { getEmptyValue } from "./helpers";

const FormItem = ({
  children,
  input,
  name,
  label,
  rules,
  control,
  errors,
  type,
  register,
  unregister,
  setValue,
  getValues,
  flexBasis,
  display,
  trigger,
  shouldTriggerValidation,
  width,
  sx,
  defaultValue,
  optionsActs,
  setokvedCode = null,
  actEquipOnChange = null,
  plumeRoomAdressChange = null,
  indexAxtEquip,
  setSelectedOrganizationTypes = null,
  changePaginSearch = null,
  additionalClassName = "",
  ...rest
}) => {
  const CustomInput = input;

  const style = {
    display: display || "block",
    padding: "12px 0",
    flexBasis,
    width: width || "100%",
    ...sx,
  };
  if(optionsActs){
    rest.optionsActs = optionsActs
  }
  const handleChange = (e, onChange, rules) => {
    const { maxLength, integer, fractional, integerNegative, customValidate } = rules || {};

    if (type === "number") {
      const currentValue = e.target.value;
      if (!currentValue) {
        onChange(null);
        return;
      }

      if (integerNegative) {
        integerNegative(currentValue) && onChange(e);
        return;
      }

      if (integer) {
        integer(currentValue) && onChange(e);
        return;
      }

      if (fractional) {
        fractional(currentValue) && onChange(e);
        return;
      }

      if (maxLength) {
        maxLength(currentValue) && onChange(e);
        return;
      }

      if (customValidate) {
        customValidate(currentValue) && onChange(e);
        return;
      }
    }

    onChange(e);
    if (setokvedCode) {
      
      
      setokvedCode(e.target.value.replace(/(\d{2})(?=\d)/g, '$1.'));
    }

    if (actEquipOnChange) {

      actEquipOnChange(e.target.value,indexAxtEquip)
    }
    if(changePaginSearch){
      changePaginSearch(e.target.value)
    }
    if (setokvedCode) {
      
      
      setokvedCode(e.target.value.replace(/(\d{2})(?=\d)/g, '$1.'));
    }
    if(plumeRoomAdressChange){
      plumeRoomAdressChange(e.target.value)
    }
    setSelectedOrganizationTypes &&
      setSelectedOrganizationTypes(e.target.value);

    if (trigger && shouldTriggerValidation) {
      trigger();
    }
  };

  return (
    <Controller
      control={control}
      errors={errors}
      name={name}
      rules={{ ...rules }}
      defaultValue={defaultValue ? defaultValue : rest.value || getEmptyValue(type)}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <Box className={`input-wrapper ${additionalClassName}`} sx={style}>
          <CustomInput
            type={type}
            control={control}
            onChange={(e) => handleChange(e, onChange, rules)}
            onBlur={onBlur}
            label={label}
            error={error}
            value={typeof value === "number" ? value : value || ""}
            setValue={setValue}
            {...rest}
          />
          {children}
        </Box>
      )}
    />
  );
};

export default FormItem;
