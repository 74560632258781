import React, {useMemo} from "react";
import {Box} from "@mui/material";
import {DateItemsFormConfig, formFields} from "./form-config";

const monthsCount = 12

const DateItems = ({itemName, itemValue, error, setValue, trigger, disabled = false}) => {

    const dateItemValues = useMemo(() => {
        const years = Math.floor(+itemValue / monthsCount) || 0;
        const months = +itemValue - years * monthsCount || 0;
        return {
            years,
            months
        }
    }, [itemValue])

    const handleChange = (e) => {
        let {value, name, id} = e.target;
        console.log(e.target)
        console.log(value)
        value = value?.toString().replace(/\D/g, "");
        if(id === '0') {
            setValue(itemName, +value * monthsCount + dateItemValues.months)
        } else if (id === '1' && +value < monthsCount) {
            setValue(itemName, +value + dateItemValues.years * monthsCount)
        }
        trigger(itemName)
    }

    return (
        <Box className="block-form">
            {DateItemsFormConfig.map(({ name, label, Input, ...rest }, index) => (
                <Input
                    id={index}
                    key={name}
                    error={error}
                    name={name}
                    label={label}
                    onChange={handleChange}
                    defaultValue={dateItemValues[name]}
                    disabled={disabled}
                    {...rest}
                />
                
            ))}
        </Box>
    )
}

export default DateItems;