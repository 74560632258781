import React,{useState,useEffect,useMemo} from "react";
import dayjs from "dayjs";
import { Box, Chip, Typography } from "@mui/material";
import SelectGrouping from "../../components/SelectGrouping";
import { dateFormat,dateFormatWithTimeWithoutA } from "../../../constants";
import {
  useGetFacilitySelectElementsWithSearchQuery,
} from "../../../middleware/facilitiesApi";
import { PATHS } from "../../../constants";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import { useAppSelector } from "../../../hooks/redux";

export const SelectSimpleFloor = (props) => {
  const { floorOptionsSelectByFacility } = useAppSelector(
    (state) => state.facilities
  );
  let resoption = [{value:"все",name:"все"},...floorOptionsSelectByFacility]

  return <SelectSimpleInbuilt  options={resoption} {...props} />;
};

export const SelectSimpleObject = (props) => {
   const [searchValue, setSearchValue] = useState("");
   const { data, refetch } = useGetFacilitySelectElementsWithSearchQuery({name:searchValue});
 
   useEffect(() => {
    
     searchValue && refetch();
   }, [searchValue, refetch])
 
   const options = useMemo(() => {
     return data
       ? [{id:"все",name:"все"},...data]//getFacilitiesOptionsGroup(data)
       : [];
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data]);
   
   return (
     <SelectGrouping
       {...props}
       setSearchValue={setSearchValue}
       searchValue={searchValue}
       options={options}
      
     />
   );
 };

export const SimpleTextCell = ({ item, type }) => {
  const content = type === "date" ? dayjs(item).format(dateFormat) : type === "dateTime" ? dayjs(item).format(dateFormatWithTimeWithoutA) : item;
  
  return <Typography>{content}</Typography>;
};

export const JobEquipmentsCell = ({ item, navigate }) => {
  return item?.map(({ id, integrationNumber, name }) => (
    <Typography
      key={id}
      className="table-row-cell-link"
      onClick={() => navigate(PATHS.GET_EQUIPMENT_DETAILS(id))}
    >
      {`${integrationNumber ? `${integrationNumber},` : ""} ${name}`}
    </Typography>
  ));
};

export const ClickableSchemaCell = ({ item }) => {
  const { floorNumber, action, floorComment } = item || {};

  return (
    <Typography
      className="table-row-cell-link"
      onClick={action ? action : () => {}}
    >
      {floorNumber != null && floorNumber ? floorNumber : ""}
      {floorComment != null && floorComment != "" &&floorComment ? ", " + floorComment : ""}
    </Typography>);
};
export const ClickableEquipmentCell = ({ item }) => {
  const { name, replacingEquipmentName, action } = item || {};

  return (
    <Typography
      className="table-row-cell-link"
      onClick={action ? action : () => {}}
    >
      {name || replacingEquipmentName || ""}
    </Typography>);
};

export const SimpleHeader = ({ name }) => (
  <Box className="table-head-up_level">
    <Typography className="standart">{name || ""}</Typography>
  </Box>
);
