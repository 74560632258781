import React, { useRef, useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ModalFullScreen = ({
  open,
  setOpen,
  canvasRef,
  title,
  SCHEMA_CANVAS,
}) => {
  const canvasFullRef = useRef(null);
  const heightScreen = window.innerHeight;
  const widthScreen = window.innerWidth;
  
  const getSize = ({ widthScreen, heightScreen }) => {
    return heightScreen > widthScreen
      ? {
        width: widthScreen * 0.9,
        height: ((widthScreen * 0.9) / (SCHEMA_CANVAS.WIDTH / SCHEMA_CANVAS.HEIGHT)),
      }
      : {
        height: heightScreen * 0.7,
        width: (heightScreen * (SCHEMA_CANVAS.WIDTH / SCHEMA_CANVAS.HEIGHT)) * 0.7,
      }
  };

  const { width, height } = getSize({ widthScreen, heightScreen });

  const drawFullSize = () => {
    const canvas = canvasFullRef.current;
    const context = canvas.getContext("2d");
    context.canvas.width = width;
    context.canvas.height = height;

    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    context.drawImage(
      canvasRef?.current || canvasRef,
      0,
      0,
      context.canvas.width,
      context.canvas.height
    );
    setOpen(true);
  };

  const closeFullScreen = () => {
    setOpen(false);
  };

  useEffect(() => {
    open && drawFullSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, height, width]);

  return (
    <Box
      className="modal_full_screen"
      sx={{

        display: `${open ? "block" : "none"}`
      }}
    >
      <Box className="modal_full_screen-container">
        <Box className="modal_full_screen-modal">
          <Box className="modal_full_screen-modal-header">
            <Typography className="modal_full_screen-modal-header-title">
              {title || ""}
            </Typography>
            <IconButton onClick={closeFullScreen}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", padding: "10px" }}>
            <canvas ref={canvasFullRef} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ModalFullScreen;
