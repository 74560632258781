import InputText from "../../../../../components/InputText";
import { t } from "i18next";
import CustomFormItem from "../CustomFormItem";
import React from "react";
import {formFields} from "./formFields";
import {getMeasuringEquipmentFormConfig} from "./measuringEquipment";
import { Box, IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import InputTextarea from "../../../../../components/InputTextarea";
import EquipColumn from "../EquipColumn";
import EquipColumnExist from "../EquipColumnExist";
import {getEquipmentFormConfig} from "./equipments"
const name = "RABCapacityFormConfig";

export const RABCapacityFormConfig = {
  name,
  items : [
    {
      name: formFields.number,
      type: "number",
      rules: {},
      disabled: true,
      value: 9,
      sx: {
        "& .MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
          },
        }
      },
      input: (props) => <InputText {...props} />,
    },
    {
      name: formFields.testName,
      type: "text",
      rules: {},
      disabled: true,
      variant:"standard",
      InputProps:{
        disableUnderline: true
      },
      rows:3,
      value: t(`label:testNameItems:${name}`),
      sx: {
        "& .MuiOutlinedInput-root": {
          width: "100%",
          height: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
          },
        }
      },
      input: (props) => (
        <Box sx={{ display: "flex" }}>
          <InputTextarea sx={{marginTop: "10px",}} {...props} />
          <Tooltip title={t(`label:AFADocumentTooltips:${name}`)}>
            <IconButton className="icon-btn">
              <InfoIcon sx={{color: "var(--main)"}}/>
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    {
      name: formFields.equipments,
      CustomFormItem: (props) => (
        !props.isActExist ?
          <EquipColumn
            formConfig={getEquipmentFormConfig(name)}
            CircuitName={name}
            indexCirc={1}
            {...props}
          />
          :
          <EquipColumnExist
            formConfig={getEquipmentFormConfig(name)}
            CircuitName={name}
            indexCirc={1}
            {...props}
          />
      )
    },
    {
      name: formFields.measuringEquipment,
      CustomFormItem: (props) => (
        <CustomFormItem formConfig={getMeasuringEquipmentFormConfig(name)} {...props}/>
      )
    },
  ],
}