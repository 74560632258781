import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const messageCodeApi = createApi({
  reducerPath: "message-code/api",
  tagTypes: ["MessageCodes"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getMessageCode: build.query({
      query: ({ pageNumber, controlConsoleId,protectionObjectId,filters, }) => ({
        url: `api/get-message-codes-table?pageNumber=${pageNumber}&controlConsoleId=${controlConsoleId}${protectionObjectId}${filters}`,
      }),
      providesTags: ["MessageCodes"],
    }),
    resetMessageCodes: build.query({
      query: ({controlConsoleId,protectionObjectId, }) => ({
        url: `api/reset-message-codes?controlConsoleId=${controlConsoleId}${protectionObjectId}`,
      }),
      providesTags: ["MessageCodes"],
    }),
    getMessageCodeTypes: build.query({
      query: () => ({
        url: `api/get-message-code-types`,
      }),
      transformResponse: (baseQueryReturnValue) =>
      baseQueryReturnValue.map(({ id, name,color,borderColor }) => ({
        value: id,
        name: name,
        color:color,
        borderColor:borderColor,
      })),
      providesTags: ["MessageCodes"],
    }),
    getMessageCodeElements: build.query({
      query: () => ({
        url: `api/get-message-code-elements`,
      }),
      transformResponse: (baseQueryReturnValue) =>
      baseQueryReturnValue.map(({ id, name }) => ({
        value: id,
        name: name,
      })),
      providesTags: ["MessageCodes"],
    }),
    editMessageCode: build.mutation({
      query: (data) => ({
        url: "api/edit",
        method: "PUT",
        body: data,
      }),
      providesTags: ["MessageCodes"],
    }),
  }),
});

export const {
  useLazyGetMessageCodeQuery,
  useLazyGetMessageCodeElementsQuery,
  useLazyResetMessageCodesQuery,
  useLazyGetMessageCodeTypesQuery,
  useGetMessageCodeQuery,
  useEditMessageCodeMutation,
} = messageCodeApi;
