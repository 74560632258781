import { t } from "i18next";
import { TYPE_ID, fields } from "../MyOrganizations/constants";

export const adminEmployeeOptions = [
  t("options_name:performing_technician"),
  t("options_name:responsible_checker"),
  t("options_name:local_admin_system"),
  t("options_name:other"),
];
export const ROLE_TYPES = {
  SUPER_ADMIN: "super_admin",
  REGION_ADMIN: "region_admin",
  ADMINISTRATOR_OF_OBJECT: "administrator_of_object",
  SUPERVISOR: "supervisor",
  DEPUTY_SUPERVISOR: "deputy_supervisor",
  DIGITIZER: "digitizer",
  EMPLOYEE: "employee",
  RESPONSIBLE_FOR_PB:"responsible_for_PB",
};

export const ROLES = {
  1: ROLE_TYPES.SUPER_ADMIN,
  2: ROLE_TYPES.REGION_ADMIN,
  3: ROLE_TYPES.ADMINISTRATOR_OF_OBJECT,
  4: ROLE_TYPES.SUPERVISOR,
  5: ROLE_TYPES.DEPUTY_SUPERVISOR,
  6: ROLE_TYPES.DIGITIZER,
  7: ROLE_TYPES.EMPLOYEE,
  8: ROLE_TYPES.RESPONSIBLE_FOR_PB,
};

export const rolesOptions = [
  { value: "все", name: t("options_name:everything") },
  {
    value: t(`roles:${ROLE_TYPES.SUPER_ADMIN}`),
    name: t(`roles:${ROLE_TYPES.SUPER_ADMIN}`),
  },
  {
    value: t(`roles:${ROLE_TYPES.REGION_ADMIN}`),
    name: t(`roles:${ROLE_TYPES.REGION_ADMIN}`)
  },
  {
    value: t(`roles:${ROLE_TYPES.ADMINISTRATOR_OF_OBJECT}`),
    name: t(`roles:${ROLE_TYPES.ADMINISTRATOR_OF_OBJECT}`),
  },
  {
    value: t(`roles:${ROLE_TYPES.SUPERVISOR}`),
    name: t(`roles:${ROLE_TYPES.SUPERVISOR}`),
  },
  {
    value: t(`roles:${ROLE_TYPES.DEPUTY_SUPERVISOR}`),
    name: t(`roles:${ROLE_TYPES.DEPUTY_SUPERVISOR}`),
  },
  {
    value: t(`roles:${ROLE_TYPES.DIGITIZER}`),
    name: t(`roles:${ROLE_TYPES.DIGITIZER}`),
  },
  {
    value: t(`roles:${ROLE_TYPES.EMPLOYEE}`),
    name: t(`roles:${ROLE_TYPES.EMPLOYEE}`),
  },
  {
    value: t(`roles:${ROLE_TYPES.RESPONSIBLE_FOR_PB}`),
    name: t(`roles:${ROLE_TYPES.RESPONSIBLE_FOR_PB}`),
  },
  
];

export const typeOptions = [
  { value: "все", name: t("options_name:everything") },
  {
    value: TYPE_ID[fields.organization_customer],
    name: t(`options_name:${fields.organization_customer}`),
  },
  {
    value: TYPE_ID[fields.organization_contractor],
    name: t(`options_name:${fields.organization_contractor}`),
  },
  {
    value: TYPE_ID[fields.outside_organization],
    name: t(`options_name:${fields.outside_organization}`),
  },
];


export const activeOptions = [
  { value: "", name: "все" },
  { value: "true", name: "Активный" },
  { value: "false", name: "Заблокирован" }
 
]

export const verifiedOptions = [
  { value: "", name: "все" },
  { value: "true", name: "Подтвержден" },
  { value: "false", name: "Не подтвержден" }
 
]