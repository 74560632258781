import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../constants";
import { useAddLogClientErrorMutation } from "../../middleware/usersApi";

export function ErrorFallback({error}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addLogClientError] = useAddLogClientErrorMutation();
  const currentUrl = window.location.href;

  useEffect(() => {
    const errorData = {
      link: currentUrl,
      title: error.stack,
    };
    addLogClientError(errorData);
  }, [error]);

  const handleLogout = () => {
    navigate(PATHS.LOGIN_ROUTE);
  };

  return (
    <div role="alert">
      <div>
        <Typography variant="h1" component="h2">
          {t("notification:error_fall_back")}
        </Typography>
        <Typography>
          {error.message}
        </Typography>
        
      </div>
      <Button variant="contained" size="large" onClick={handleLogout}>
        {t("label:logout")}
      </Button>
    </div>
  );
}
