import * as React from 'react';
import {
    Backdrop,
    CircularProgress,
    Dialog,
} from "@mui/material";
import {useGetFacilityOnMapInfoQuery} from "../../../middleware/facilitiesApi";
import ObjectDetails from "./ObjectDetails";

const ObjectDialog = ({open, onClose, objectId}) => {

    const {data: object, isLoading: isLoadingObject} = useGetFacilityOnMapInfoQuery(objectId, {
        skip: !objectId,
    });

    if (isLoadingObject || !object) {
        return (
            <Backdrop
                sx={{ color: "var(--white)", zIndex: 9999}}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <Dialog
            className="object_dialog"
            open={open}
            onClose={onClose}
        >
            <ObjectDetails data={object} onClose={onClose}/>
        </Dialog>
    );
}

export default ObjectDialog;