export const getRequestData = (data) => {
    const files = {
        avatarLink: data.avatarLink,
    };
    delete data.avatarLink;

    data.phone = data.phone.replace(/[+() ]/g, "");

    return {entities: data, files}
}

export const getRequestDataFilesForAdd = (id, files) => {
    const formData = new FormData();

    if(!files) {
        return formData;
    }

    let fileConfigIndex = 0

    Object.entries(files).filter(([, value]) => value)
        .forEach(([, { filesToAdd }]) => {
            if(filesToAdd?.length) {
                filesToAdd.forEach((file, index) => {
                    fileConfigIndex += index
                    formData.append(`entityFileList[${fileConfigIndex}].EntityId`, id);
                    formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "User");
                    formData.append(`entityFileList[${fileConfigIndex}].EntityField`, "Avatar");
                    formData.append(`entityFileList[${fileConfigIndex}].File`, file);
                    formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Add");
                })
                fileConfigIndex++;
            }
        })

    return formData;
};