import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";

import { PATHS } from '../../../constants';
import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";
import { useLazyGetFireCompartmentsQuery } from "../../../middleware/fireCompartmentApi";
import { useGetFacilitySelectElementsQuery } from "../../../middleware/facilitiesApi";
import { getDataToChart } from "../../../helpers/helper";

import TableBasic from "../../components/TableBasic";
import FloorCompartmentDetails from "./FloorCompartmentDetails";
import SelectSimple from "./SelectSimple";
import { SimpleTextCell } from "../TasksList/Components";
import { SearchInbuilt } from "../../components/SearchInbuilt";

const ListFireCompartments = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedId, setSelectedId] = useState(null);
  const [openDiteilsPopUp, setOpenDiteilsPopUp] = useState(false);
  const [facilityFilter, setFacilityFilter] = useState("");
  const [pageNumber, changePageNumber] = useState(0);
  const [searchValue, changeSearchValue] = useState("");
  const { facilitySelectElements } = useAppSelector(
    (state) => state.facilities
  );

  const { setIsErrorRequest } =
    useAction();
  const { fireCompartments } = useAppSelector((state) => state.fireCompartment);

  useGetFacilitySelectElementsQuery();
  const handleOpenDetails = (id) => {
    setOpenDiteilsPopUp(!openDiteilsPopUp);
    setSelectedId(id);
  };

  const handleChange = (event) => {
    setFacilityFilter(event.target.value);
  };

  const [getFireCompartmentsTable, { error, isLoading }] =
    useLazyGetFireCompartmentsQuery();

  useEffect(() => {
    getFireCompartmentsTable({ pageNumber: 0, searchValue, facilityFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, facilityFilter, setFacilityFilter]);

  useEffect(() => {
    getFireCompartmentsTable({ searchValue, pageNumber, facilityFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const FacFireCompSelect = (/* props */) => {
    return (
      <Box className="input-wrapper" sx={{ maxWidth: "320px" }}>
        <SelectSimple
          value={facilityFilter}
          onChange={handleChange}
          label={"label:objects"}
          options={facilitySelectElements} /* {...props} */
        />
      </Box>
    );
  };

  const columns = [
    {
      id: "compartmentNumberAndName",
      numeric: false,
      enableSorting: false,
      size: "360px",
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box className="input-wrapper" sx={{ width: "360px" }}>
          <SearchInbuilt
            className="input_in_built standart"
            changeSearchValue={changeSearchValue}
            isLoading={isLoading}
            searchValue={searchValue}
            placeholder={t("tableHeader:numberAndNameFireCompartments")}
          />
        </Box>
      ),
    },
    {
      id: "floorAmount",
      numeric: false,
      clickable: false,
      enableSorting: false,
      label: t("tableHeader:numberOfFloors"),
      size: 150,
    },
    {
      id: "floorSchemas",
      numeric: false,
      clickable: true,
      enableSorting: false,
      label: t("tableHeader:floorPlans"),
      size: 150,
      mode: "list",
      color: "#4339F2",
    },
    {
      id: "tasks",
      numeric: false,
      enableSorting: false,
      clickable: true,
      label: t("tableHeader:tasks"),
      size: 78,
      mode: "diagram",
    },
  ];

  const rows = useMemo(() => {
    const { data } = fireCompartments;
    return data?.length
      ? data.map(
          ({ id, name, compartmentNumber, floorAmount, floors, jobChart }) => {
            return {
              id,
              compartmentNumberAndName: (
                <Button
                  className="table-row-cell-link"
                  sx={{ verticalAlign: "initial" }}
                  onClick={() => navigate(PATHS.GET_EDIT_FIRE_COMPARTMENT(id))}
                >
                  {`${compartmentNumber ?? ""} ${name}`}
                </Button>
              ),
              floorAmount,
              floorSchemas: floors?.length
                ? floors.map(({ id, floorNumber }) => ({
                    floorId: id,
                    floor: `Этаж ${floorNumber}`,
                  }))
                : [],
              tasks: getDataToChart({ jobChart }),
            };
          }
        )
      : [];
    // eslint-disable-next-line
  }, [fireCompartments]);

  const recordsCount = useMemo(
    () => fireCompartments && fireCompartments?.recordsCount,
    [fireCompartments]
  );

  return (
    <Box>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography className="title-page">
          {t("title_page:list_fire_compartments")}
        </Typography>
        <Button
          className="btn btn-outlined-primary"
          variant="outlined"
          onClick={() => {
            navigate(PATHS.ADD_FIRE_COMPARTMENT);
          }}
        >
          {t("button:add_fire_compartment")}
        </Button>
      </Box>
      <Box sx={{ mt: "29px" }}>
        <FacFireCompSelect />
        <TableBasic
          withSearchField={false}
          columns={columns}
          rows={rows}
          handleClickCell={() => {}}
          searchValue={searchValue}
          changeSearchValue={changeSearchValue}
          pageNumber={pageNumber}
          changePageNumber={changePageNumber}
          isLoading={isLoading}
          floorModalHandler={handleOpenDetails}
          placeholder={t("placeholders:search_fire_compartment")}
          count={recordsCount}
          minHeight={"51vh"}
          maxHeight={"60vh"}
        />
      </Box>
      <FloorCompartmentDetails
        open={openDiteilsPopUp}
        setOpen={setOpenDiteilsPopUp}
        id={selectedId}
      />
    </Box>
  );
};

export default ListFireCompartments;
