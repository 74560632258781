import React from "react";

function polarToCartesian(radius, angleInRadians) {
  const x = radius * Math.cos(angleInRadians);
  const y = radius * Math.sin(angleInRadians);
  return { x, y };
}

const emptyChartParams = { value: 0, color: "#e2e8f0", fontFamily: "var(--INTER)" };

const DonutChart = ({
  id = "",
  data,
  width,
  height,
  radius,
  thickness = radius / 2,
  isText = false,
  fontSize = "16px",
  style = { pointerEvents: "all", fontFamily: "var(--INTER)" },
}) => {
  let overdued_jobs_amount_value = "";
  let completed_intime_jobs_amount = "";
  let near_deadline_jobs_amount = "";
  let not_near_deadline_jobs_amount = "";
  let fillColor = emptyChartParams.color;
  data &&
    data.length > 0 &&
    data?.map(({ value, color, name }, i) => {
      if (name == "overdued_jobs_amount" && value > 0)
        overdued_jobs_amount_value = color;
      if (name == "completed_intime_jobs_amount" && value > 0)
        completed_intime_jobs_amount = color;
      if (name == "near_deadline_jobs_amount" && value > 0)
        near_deadline_jobs_amount = color;
      if (name == "not_near_deadline_jobs_amount" && value > 0)
        not_near_deadline_jobs_amount = color;
    });
  if (
    overdued_jobs_amount_value == "" &&
    !completed_intime_jobs_amount == "" &&
    !near_deadline_jobs_amount == "" &&
    !not_near_deadline_jobs_amount == ""
  )
    fillColor = emptyChartParams.color;
  else if (overdued_jobs_amount_value != "")
    fillColor = overdued_jobs_amount_value;
  else if (
    completed_intime_jobs_amount != "" &&
    near_deadline_jobs_amount != ""
  )
    fillColor = near_deadline_jobs_amount;
  else if (
    completed_intime_jobs_amount == "" &&
    near_deadline_jobs_amount != ""
  )
    fillColor = near_deadline_jobs_amount;
  else if (
    completed_intime_jobs_amount == "" &&
    not_near_deadline_jobs_amount != ""
  )
    fillColor = not_near_deadline_jobs_amount;
  else if (
    completed_intime_jobs_amount != "" &&
    not_near_deadline_jobs_amount != ""
  )
    fillColor = not_near_deadline_jobs_amount;
  else if (
    completed_intime_jobs_amount != "" &&
    near_deadline_jobs_amount == "" &&
    not_near_deadline_jobs_amount == ""
  )
    fillColor = completed_intime_jobs_amount;
  const total = data?.reduce((sum, d) => sum + d.value, 0);

  let startAngle = 0;
  let endAngle = 0;
  const drawSectorChart = ({ value, color }, i) => {
    let offset = 0;
    if (value > 0) offset = 0;
    const percentage = total ? value / total : 1;
    endAngle = startAngle + percentage * 2 * Math.PI - offset;
    const innerRadius = radius - thickness;
    const outerStart = polarToCartesian(radius, startAngle);
    const outerEnd = polarToCartesian(radius, endAngle);
    const innerStart = polarToCartesian(innerRadius, startAngle);
    const innerEnd = polarToCartesian(innerRadius, endAngle);
    const largeArcFlag = percentage > 0.5 ? 1 : 0;
    const pathData = [
      `M ${outerStart.x} ${outerStart.y}`,
      `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${outerEnd.x} ${outerEnd.y}`,
      `L ${innerEnd.x} ${innerEnd.y}`,
      `A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${innerStart.x} ${innerStart.y}`,
      `Z`,
    ].join(" ");

    startAngle = endAngle + offset;
    return <path d={pathData} fill={color} key={i || 0} />;
  };

  const arcs = total
    ? data?.map(({ value, color }, i) => drawSectorChart({ value, color }, i))
    : drawSectorChart(emptyChartParams);

  const textX = 0;
  const textY = 0;
  const textStyle = {
    fontSize: fontSize,
    fontFamily: "var(--INTER)",
    textAnchor: "middle",
    dominantBaseline: "central",
    fontWeight: 500,
    fill: isText == 0 ? "#000000" : fillColor,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      width={width}
      height={height}
      style={style}
    >
      <defs>
        <style type="text/css">
          {`
        @font-face {
          font-family: 'Inter';
           
        }
        text {
          font-family: 'Inter';
        }
      `}
        </style>
      </defs>
      <circle cx={width / 2} cy={height / 2} r={height / 2} fill="white" />
      <g transform={`translate(${width / 2}, ${height / 2})`}>
        {arcs}
        {(isText || isText == 0) && (
          <text x={textX} y={textY} style={textStyle}>
            {isText}
          </text>
        )}
      </g>
    </svg>
  );
};

export default DonutChart;
