import React from "react";
import {
  ListSubheader,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";

export const MyListSubheader = ({ options, setOptions, itemOption ,setSelectedValue,isSystemPPZ}) => {
  const { id, name, open } = itemOption || {};

  const handleClick = () => {
    const updatedOptions = options?.length && options.map((option) => ({
        ...option,
        open: !option.open && option.id === id
    }));
    console.log("updatedOptions",updatedOptions)
    setOptions(updatedOptions);
  };
  const handlesetValue = (event) => {
    event.stopPropagation(); 
    if(!isSystemPPZ) return
    console.log("isSystemPPZ",options)
    setSelectedValue(JSON.stringify({id: id, name: `${name}`,isType:true }))
  };

  return (

    <ListSubheader sx={{  width:"24px",position: "inherit",marginRight:"20px",padding:0}}>
      <ListItemButton onClick={handlesetValue}   sx={{ padding: 0,height:"24px",width:"24px" }}>
        {open ? <ExpandMoreIcon onClick={handleClick} /> : <ChevronRightIcon onClick={handleClick}/>}
     
      </ListItemButton>
    </ListSubheader>
  );
};

MyListSubheader.muiSkipListHighlight = true;

export const MyListSubheaderSearch = ({searchValue, setSearchValue}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
     setSearchValue(e.target.value)
  }

  const handleKeyDown = (e) => {
      if (e.key !== "Escape") {
          e.stopPropagation();
      }
  }

  return (
    <ListSubheader
      sx={{
        zIndex: 5,
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <TextField
          fullWidth
          autoFocus
          placeholder={t("placeholders:search_select")}
          value={searchValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
      />
    </ListSubheader>
  );
};

MyListSubheaderSearch.muiSkipListHighlight = true;
