import React from "react";
import { Bar } from "react-chartjs-2";
import { CategoryScale, Chart, LinearScale, BarElement } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTasksChartBarData } from "../helpers";
import { useMemo } from "react";

const ChartComponent = ({ jobAnalytics }) => {
  Chart.register(CategoryScale, LinearScale, BarElement);
  const { t } = useTranslation();

  const data = useMemo(() => {
    const dataCfg = getTasksChartBarData(jobAnalytics);

    return {
      labels: dataCfg?.reduce((acc, { label }) => [...acc, t(label)], []) || [],
      datasets: [
        {
          data: dataCfg?.reduce((acc, { data }) => [...acc, data], []) || [],
          barPercentage: 0.5,
          backgroundColor:
            dataCfg?.reduce(
              (acc, { backgroundColor }) => [...acc, backgroundColor],
              []
            ) || [],
        },
      ],
    };
  }, [t, jobAnalytics]);

  const options = {
    indexAxis: "y",
    maintainAspectRatio: false,
    responsive: true, 
    plugins: {
      legend: { display: false },
      datalabels: {
        display: true,
        color: "black",
        formatter: Math.round,
        anchor: "end",
        offset: -50,
        align: "start",
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        barPercentage: 0.3,
        categoryPercentage: 0.4,
        offset: 2, 
      },
    },
    elements: {
      bar: {
        borderRadius: 30, 
      },
    },
  };

  const colorValuePairs = data.labels.map((label, index) => ({
    label,
    value: data.datasets[0]?.data[index],
    color: data.datasets[0]?.backgroundColor[index],
  }));

  return (
    <Box
      style={{
        marginBottom: "30px",
        height: "200px",
        width: "75vw",
      }}
    >
      <Typography className="label-title">{t("title_page:tasks")}</Typography>
      <Bar
        style={{ marginTop: "10px" }}
        data={data}
        options={options}
        plugins={[ChartDataLabels]}
      />

      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        {colorValuePairs.map((pair, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "4px",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: pair.color,
                marginRight: "4px",
                borderRadius: "50%",
              }}
            ></span>
            <span>
              {pair.label}: {pair.value}
            </span>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default ChartComponent;
