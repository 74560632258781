import React from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

import "./styles/style.css";
import { dateFormatWithTime } from "../../../../constants";
import { useGetDebuggersListQuery } from "../../../../middleware/controllersApi";

export const ModalDebugging = ({ open, setOpen }) => {
  const { t } = useTranslation();

  const { data: debbugersList, isLoading } = useGetDebuggersListQuery();

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="modal modal_debuging"
      maxWidth="md"
    >
      <DialogTitle className="modal-title-between">
        <Typography className="modal-title">
          {t("modal_title:debugging")}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {isLoading ? (
        <DialogContent className="modal_debuging-content_empty">
          <CircularProgress color="primary" size={24} />
        </DialogContent>
      ) : (
        <DialogContent
          className={!!debbugersList?.length
            ? "modal_debuging-content"
            : "modal_debuging-content_empty"
          }
        >
          {!!debbugersList?.length ? (
            debbugersList.map(({ id, reactionDateTime, message }) => (
              <Box key={id} className="modal_debuging-content_item">

                <DialogContentText className="modal_debuging-content_item-date">
                  {dayjs(reactionDateTime).format(dateFormatWithTime)}
                </DialogContentText>

                <DialogContentText className="modal_debuging-content_item-message">
                  {message}
                </DialogContentText>

              </Box>
            ))
          ) : (
            <Box className="modal_debuging-content_empty_item">
              <DialogContentText>
                {t("modal_content_text:no_data_to_display")}
              </DialogContentText>
            </Box>
          )}
        </DialogContent>
      )}

    </Dialog>
  );
};
