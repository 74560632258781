import React from "react";
import { useTranslation } from "react-i18next";
import { ListItemText, OutlinedInput, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};

const SelectSimple = ({ options, label, onChange, error, value, setValue, ...rest }) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth error={!!error} {...rest}>
      <InputLabel>{t(label)}</InputLabel>
      <Select
        value={value || ""}
        input={<OutlinedInput sx={{ padding: 0 }} label={t(label)} />}
        onChange={onChange}
        MenuProps={MenuProps}
        sx={{
          height: "56px",
          width: "458px",
          backgroundColor: "rgba(255, 255, 255, 0.51)",
          border: "1px solid #E2E8F0",
          borderRadius: "2px",
          marginBottom: "24px",
        }}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i}
            value={option.value || option || ""}
            sx={{ height: ITEM_HEIGHT }}
          >
            <ListItemText
              primary={
                <Typography>
                  {option.name?.toString() || option || ""}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectSimple;
