import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useAddTaskMutation } from "../../../../middleware/tasksApi";
import { formFields } from "./options";
import { useAction } from "../../../../hooks/actions";
import { PATHS } from "../../../../constants";
import {
  formConfigFloorAndObject,
  formConfigPriority,
  formConfigEquipmentPPZ,
  sections,
} from "./form-config";
import {
  getConfigByRole,
  getRequestData,
  getRequestDataFilesForAdd,
} from "./helpers";
import { useAppSelector } from "../../../../hooks/redux";
import { WarningValidationForm } from "../../../components/Notifications";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";
import FormItem from "../../../components/FormItem";
import AccordionSection from "./AccordionSection";
import { DEFAULT_ROLE_ID } from "../../../../constants";
import { fields, PRIORITY_ID } from "../constants";
import {
  useGetFacilitySelectElementsQuery,
  useLazyGetFloorOptionsSelectByFacilityQuery,
} from "../../../../middleware/facilitiesApi";
import dayjs from "dayjs";

const AddTask = () => {
  const [ranOnce, setRanOnce] = useState(false);
  const [isEquipmentFormVisible, setIsEquipmentFormVisible] = useState(false);
  const [jobEquipmentsList, setJobEquipmentsList] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    setValue,
    watch,
    trigger,
    resetField,
    error,
  } = useForm({
    mode: "onSubmit",
  });

  //Get Object and Floor
  const objectId = watch(formFields.ProtectionObjectId);
  const equipmentTypeIdJSON = watch(formFields.equipmentTypeId);
  const equipmentGroupId = watch(formFields.equipmentGroupId);
  const jobEquipmentsJSON = watch(formFields.jobEquipments);
  const floorInfoId = watch(formFields.FloorInfoId);

  const { errorFace } = useGetFacilitySelectElementsQuery();
  const [
    getFloorOptions,
    { isError: isErrorGetFloorOptions, isLoading: isLoadingGetFloorOptions },
  ] = useLazyGetFloorOptionsSelectByFacilityQuery();
  const isErrorRequest = errorFace || isErrorGetFloorOptions;

  useEffect(() => {
    objectId &&
      getFloorOptions(objectId).then(
        ({ data, isSuccess }) =>
          isSuccess &&
          !!data?.length &&
          setValue(formFields.FloorInfoId, data[0].value)
      );
  }, [objectId, getFloorOptions, setValue]);

  const isActive = true; // mock data. Wait from backend
  const { info } = useAppSelector((state) => state.users);
  const { roleId } = info || {};
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [regulations, setRegulations] = useState([]);
  const [manipulateFiles, { error: errorFile, isSuccess: isSuccessFile }] =
    useManipulateFilesMutation();
  const [addTask, { error: errorAdd, isSuccess: isSuccessAdd }] =
    useAddTaskMutation();

  const configFormPriority = useMemo(
    () =>
      formConfigPriority(isActive, regulations, objectId, setValue, resetField),
    [isActive, regulations, objectId, setValue, resetField]
  );

  const configFormFloorAndObject = useMemo(
    () =>
      formConfigFloorAndObject(
        isActive,
        regulations,
        objectId,
        setValue,
        resetField
      ),
    [isActive, regulations, objectId, setValue, resetField]
  );

  const addEquipmentPPZ = useMemo(
    () =>
      formConfigEquipmentPPZ(
        objectId,
        equipmentTypeIdJSON,
        equipmentGroupId,
        floorInfoId,
        error,
        setValue
      ),
    [objectId, equipmentGroupId, equipmentTypeIdJSON, floorInfoId, setValue]
  );

  const hasUniqueObject = (newObj, stateArray) => {
    return stateArray.some(item => item.id === newObj.id);
  };
  
  useEffect(() => {
    if (jobEquipmentsJSON) {
      const jobEquipments = JSON.parse(jobEquipmentsJSON);
      const isHaveUnique = hasUniqueObject(jobEquipments, jobEquipmentsList)
      
      if(!isHaveUnique) {
        setJobEquipmentsList(prev => [...prev, jobEquipments]);
      } else return 
    }
  }, [jobEquipmentsJSON]);

  const isWarningValidation = !!Object.keys(errors).length;
  const errorSubmit = errorAdd || errorFile;
  const isSuccess = isSuccessAdd || isSuccessFile;
  const handleCancel = () => navigate(PATHS.LIST_TASKS);
  useEffect(() => {
    if (!ranOnce) {
      setRanOnce(true);
      setValue(formFields.priority, PRIORITY_ID[fields.lower]);
      setValue(formFields.deadline, dayjs().add(10, "day"));
    }
  }, []);

  useEffect(() => {
    setIsErrorSubmit(errorSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSubmit]);
  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  // Обработка события отправки формы
  const onSubmit = async (data) => {
    const filteredJobEquipmentsList = Array.from(
      new Set(jobEquipmentsList)
    ).filter(Boolean);

    const newArray = filteredJobEquipmentsList.map(item => item.id);
    data.jobEquipments = newArray;
    const { entities, jobFiles } = getRequestData(data);
    addTask(entities).then((result) => {
      if ("error" in result) {
        return;
      }
      const id = result?.data?.id;
      const requestDataFiles = getRequestDataFilesForAdd(id, jobFiles);

      manipulateFiles(requestDataFiles).then((response) => {
        if ("error" in response) {
          return;
        }
      });
      navigate(PATHS.LIST_TASKS);
    });
  };

  const chooseEquipmentHandler = () => {
    if (!isEquipmentFormVisible) {
      setValue(formFields.equipmentTypeId, "");
      setValue(formFields.equipmentGroupId, "");
      setValue(formFields.jobEquipments, "");
    }
    setIsEquipmentFormVisible((prev) => !prev);
    setJobEquipmentsList([])
  };

  return (
    <Box className="template_page employee">
      <Box className="template_page-header">
        <Typography className="title-page">
          {t("title_page:add_task")}
        </Typography>
        <Box className="template_page-header-buttons">
          <Button className="btn btn-outlined-grey" onClick={handleCancel}>
            {t("button:comeback")}
          </Button>
          <Button
            form="myform"
            className="btn btn-primary-fill"
            type="submit"
            disabled={isSubmitSuccessful && !errorSubmit}
          >
            {t("button:save")}
          </Button>
        </Box>
      </Box>

      <form
        id="myform"
        onSubmit={handleSubmit(onSubmit)}
        className="scrolled-form"
      >
        <Box
          sx={{
            paddingBottom: "16px",
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          {configFormPriority &&
            configFormPriority?.map(
              ({
                id,
                name,
                label,
                input,
                expended,
                isCanDisabled,
                CustomFormItem,
                ...rest
              }) => (
                <FormItem
                  control={control}
                  errors={errors}
                  name={name}
                  disabled={isCanDisabled ? !objectId || isErrorRequest : false}
                  label={label}
                  input={input}
                  trigger={trigger}
                  {...rest}
                />
              )
            )}

          <Box sx={{ display: "flex", gap: "20px" }}>
            {configFormFloorAndObject &&
              configFormFloorAndObject?.map(
                ({
                  id,
                  name,
                  label,
                  input,
                  expended,
                  isCanDisabled,
                  CustomFormItem,
                  ...rest
                }) => (
                  <FormItem
                    control={control}
                    errors={errors}
                    name={name}
                    disabled={
                      isCanDisabled ? !objectId || isErrorRequest : false
                    }
                    label={label}
                    input={input}
                    trigger={trigger}
                    {...rest}
                  />
                )
              )}
          </Box>

          <Box sx={{ padding: "12px 0" }}>
            <Button
              className="btn btn-primary-fill"
              onClick={chooseEquipmentHandler}
            >
              {isEquipmentFormVisible
                ? "Отменить выбор оборудования"
                : "Выбрать оборудование"}
            </Button>
          </Box>

          {isEquipmentFormVisible && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {addEquipmentPPZ &&
                addEquipmentPPZ?.map(
                  ({
                    id,
                    name,
                    label,
                    input,
                    expended,
                    isCanDisabled,
                    CustomFormItem,
                    ...rest
                  }) => (
                    <FormItem
                      control={control}
                      errors={errors}
                      name={name}
                      disabled={
                        isCanDisabled ? !objectId || isErrorRequest : false
                      }
                      label={label}
                      input={input}
                      trigger={trigger}
                      {...rest}
                    />
                  )
                )}
            </Box>
          )}

          {jobEquipmentsList.length > 0 && (
            <>
              <Typography>Список выбранного оборудования:</Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                {jobEquipmentsList.map((equipment) => (
                  <Box>{equipment.name}</Box>
                ))}
              </Box>
            </>
          )}
        </Box>
        {sections({ control }).map(
          ({ id, title, hidden, Component, config }) =>
            !hidden && (
              <AccordionSection key={id} title={title}>
                {Component && (
                  <Component
                    id={id}
                    control={control}
                    errors={errors}
                    facilityId={objectId}
                    config={getConfigByRole({
                      roleId: roleId || DEFAULT_ROLE_ID,
                      config,
                    })}
                  />
                )}
              </AccordionSection>
            )
        )}
      </form>
      {isWarningValidation && (
        <WarningValidationForm isWarning={isWarningValidation} />
      )}
    </Box>
  );
};

export default AddTask;
