import { fileSizeCheck, fileTypeCheck } from "../../../../validation";
import InputFileSingle from "../../../components/InputFile/InputFileSingle";
import React from "react";
import InputHandleEditAvailability from "../../../components/InputHandleEditAvailability/InputHandleEditAvailability";
import { getMaskPhone } from "../../../../helpers/helper";
import InputMaskPhone from "../../../components/InputMaskPhone";
import InputText from "../../../components/InputText";

export const formFields = {
  avatarLink: "avatarLink",
  email: "email",
  phone: "phone",
};

export const AvatarFormItemConfig = [
  {
    name: formFields.avatarLink,
    type: "file",
    rules: {
      validate: {
        type: (files) => {
          const availableFileTypes = ["image/jpeg", "image/png"];
          return fileTypeCheck(
            files.filesToAdd,
            formFields.avatarLink,
            availableFileTypes,
          );
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files.filesToAdd,
            formFields.avatarLink,
            maxAvailableFileSize,
          );
        },
      },
    },
    canDeleteFile: false,
    previewMode: "avatar",
    width: "auto",
    input: (props) => (<InputFileSingle {...props} accept={".png, .jpg, .jpeg"} />),
  },
];

export const profileContactConnectionFormConfig = [
  {
    name: formFields.phone,
    label: "",
    type: "text",
    flexBasis: "49%",
    sx: { minWidth: "230px" },
    rules: {
      required: true,
      validate: (value) => value.replace(/[+() ]/g, "", "")?.length === 11,
    },
    input: (props) => (
      <InputHandleEditAvailability
        {...props}
        mask={getMaskPhone}
        Component={InputMaskPhone}
      />
    ),
  },
  {
    name: formFields.email,
    label: "",
    type: "text",
    sx: { minWidth: "230px" },
    rules: {
      required: true,
      pattern:
        /[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,}$/g,
    },
    flexBasis: "49%",
    input: (props) => (
      <InputHandleEditAvailability {...props} Component={InputText} />
    ),
  },
];