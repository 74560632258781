import React from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { PATHS } from "../../../constants";
import "./style.css";

export const NotificationItem = ({ notification, markNotification }) => {
  const dateFormat = "DD.MM.YYYY HH:mm";
  const date = dayjs(notification?.createdAt).format(dateFormat);

  return (
    <div
      className="notification-item"
      onClick={markNotification.bind(null, [notification?.id])}
    >
      {!notification.isSystem ? <p className="notification-item__text">{notification.assignerName}</p> : <></>}
      <Link
        to={PATHS.LIST_TASKS + `?notif=${notification?.jobId}`}
        className="notification-item__number"
      >
        {notification?.jobName}
      </Link>
      <p className="notification-item__text">{notification?.text}</p>
      {notification.details && <p className="notification-item__text">{notification.details}</p>}
      <p className="notification-item__date">{date}</p>
    </div>
  );
};
