import React from "react";
import { Box, Typography } from "@mui/material";

import FormItem from "../../../components/FormItem";
import { useTranslation } from "react-i18next";

const FloorForm = ({ control, errors, config, setValue, data, roomAdd, floorScheme }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        columnGap: "15px",
      }}
    >
      {config.map(({ name, title, label, input, Component, flexBasis, ...rest }, i) => (
        Component
          ? <Component
            key={i}
            data={data}
            control={control}
            setValue={setValue}
            roomAdd={roomAdd}
            floorScheme={floorScheme}
          />
          : (
            <Box key={i} className="resizing_basic_forms">
              {!!title && <Typography className="label">{t(title)}</Typography>}
              <FormItem
                control={control}
                errors={errors}
                name={name}
                label={label}
                input={input}
                {...rest}
              />
            </Box>
          )
      ))}
    </Box>
  );
};

export default FloorForm;
