import React from "react";

const ArrowPrevNext = ({ isActive }) => {
  return (

    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 20.9995C5.00072 21.7692 5.92706 22.2495 6.66741 21.8641C7.01042 21.6856 7.22179 21.3561 7.22212 20.9995C7.22672 17.1358 10.7061 14.0047 14.9996 14.0006H21.203L18.655 16.2927C18.0491 16.8379 18.3263 17.7689 19.1539 17.9684C19.538 18.061 19.9478 17.9622 20.229 17.7092L24.6732 13.7098C25.1089 13.3191 25.1089 12.6841 24.6732 12.2934L20.229 8.29397C19.6231 7.74878 18.5886 7.99823 18.3669 8.74297C18.264 9.08861 18.3738 9.4574 18.655 9.71042L21.203 12.0009H14.9996C9.4795 12.0064 5.00612 16.032 5 20.9995Z" fill={isActive ? "#1876d2" :  "#d1d8e0"}/>
    </svg>
    
  );
};

export default ArrowPrevNext;
