import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const notificationActions = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
