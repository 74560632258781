import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { formFields, formConfigBank } from "./form-config";

import FormItem from "../../components/FormItem";

const BankDetails = ({ control, errors, disabled }) => {
  const { t } = useTranslation();
console.log("name",`${[formFields.organizationResponsibleBankDetails]}.`)
  return (
    <Accordion
      square={true}
      disableGutters={true}
      sx={{
        background: "var(--white)",
        padding: "20px 32px",
        borderRadius: "32px",
        "& .MuiAccordionSummary-expandIconWrapper svg": {
          color: "var(--black)",
        },
        "&::before": {
          height: "0",
        },
      }}
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          flexDirection: "row-reverse",
          padding: "0",
          minHeight: "fit-content",
          "& .MuiAccordionSummary-content": {
            transition: "margin-bottom 0.3s ease-in-out",
            margin: "12px 0",
          },
          "& .Mui-expanded": { mb: "20px" },
        }}
      >
        <Typography className="form-section-label" sx={{}}>
          {t("label:bankRequisites")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          background: "var(--white)",
          width: "50%",
          padding: "0",
          mb: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            "& .input-wrapper": { padding: "0 !important" },
          }}
        >
          {formConfigBank.map(({ name, label, input, ...rest }) => (
            <FormItem
              key={name}
              control={control}
              errors={errors}
              name={`${[formFields.organizationResponsibleBankDetails]}.${name}`}
              label={label}
              input={input}
              disabled={disabled}
              {...rest}
            />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default BankDetails;
