// libs imports
import React from 'react';
import InputPassword from "../../../components/InputPassword";

export const formFields = {
    oldPassword: "oldPassword",
    newPassword: "newPassword",
    newPasswordConfirm: "currentPasswordConfirm"
};

export const SetNewPasswordDialogFormConfig = [
    {
        name: formFields.oldPassword,
        label: "label:enterCurrentPassword",
        type: "password",
        rules: {
            required: true,
            validate: {
                lengthCheck: (value)  => value.length >= 6 || "password_length",
            }
        },
        input: (props) => <InputPassword {...props} />
    },
    {
        name: formFields.newPassword,
        label: "label:enterNewPassword",
        type: "password",
        rules: {
            required: true,
            validate: {
                lengthCheck: (value)  => value.length >= 6 || "password_length",
            }
        },
        input: (props) => <InputPassword {...props} />
    },
    {
        name: formFields.newPasswordConfirm,
        label: "label:repeatNewPassword",
        type: "password",
        rules: {
            required: true,
            validate: {
                lengthCheck: (value)  => value.length >= 6 || "password_length",
                matchPassword: (value, formValues) =>
                    value === formValues[formFields.newPassword] || "password_not_match"
            }
        },
        input: (props) => <InputPassword {...props} />
    }
];