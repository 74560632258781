import React, { useState,useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import { Box, Typography, Button } from "@mui/material";
import './styles.css'
import calendar from "../../../image/icons/calendar.png"
import close from "../../../image/icons/closeInputFile.png"
import leftArrow from "../../../image/icons/leftArrow.png"
import rightArrow from "../../../image/icons/rightArrow.png"

import { ru } from 'date-fns/locale';
const CustomHeader = ({ date, onChange }) => {
    const incrementMonth = () => {
        const newDate = new Date(date);
        newDate.setMonth(date.getMonth() + 1);
        onChange(newDate);
    };

    const decrementMonth = () => {
        const newDate = new Date(date);
        newDate.setMonth(date.getMonth() - 1);
        onChange(newDate);
    };

    const incrementYear = () => {
        const newDate = new Date(date);
        newDate.setFullYear(date.getFullYear() + 1);
        onChange(newDate);
    };

    const decrementYear = () => {
        const newDate = new Date(date);
        newDate.setFullYear(date.getFullYear() - 1);
        onChange(newDate);
    };

    return (
        <div className="custom-header">

            <Box className="year-navigation" sx={{ display: "flex", justifyContent: "space-between", padding: "0px 32px", marginBottom: "12px" }}>
                <Box
                    component="img"
                    src={leftArrow}
                    onClick={() => decrementYear()}
                    sx={{ cursor: "pointer", }}
                />
                <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>{date?.getFullYear()}</Typography>
                <Box
                    component="img"
                    src={rightArrow}
                    onClick={() => incrementYear()}
                    sx={{ cursor: "pointer", }}
                />
            </Box>
            <Box className="month-navigation" sx={{ display: "flex", justifyContent: "space-between", padding: "0px 32px", marginBottom: "12px" }}>
                <Box
                    component="img"
                    src={leftArrow}
                    onClick={() => decrementMonth()}
                    sx={{ cursor: "pointer", }}
                />
                <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>{date?.toLocaleString('ru-RU', { month: 'long' })}</Typography>
                <Box
                    component="img"
                    src={rightArrow}
                    onClick={() => incrementMonth()}
                    sx={{ cursor: "pointer", }}
                />

            </Box>
        </div>
    );
};
const DateRangePickerNew = ({ setdaterange, daterange, placeholder, }) => {
    function useDebounce(value, delay = 300) {
        const [debounced, setDebounced] = useState(value);
        useEffect(() => {
          const handler = setTimeout(() => setDebounced(value), delay);
          return () => clearTimeout(handler);
        }, [value, delay]);
        return debounced;
      }
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState(daterange);
    const debouncedSearch = useDebounce(search, 300);
    
    useEffect(() => {
        console.log('debouncedSearch',debouncedSearch)
        if(debouncedSearch[0] && debouncedSearch[1])
        setdaterange(debouncedSearch);
      }, [debouncedSearch]); // eslint-disable-line
    const clearDates = () => {
        setdaterange([null, null])
        setSearch([null, null])
        
    };
    
    return (
        <Box sx={{ display: "flex", alignItems: "center !important" }}>
            <DatePicker
                selectsRange={true}
                startDate={search[0]}
                endDate={search[1]}
                dateFormat="dd.MM.yyyy"
                open={isOpen}
                className="date-picker-input"
                placeholderText={placeholder}
                onClickOutside={() => setIsOpen(false)}
                onChange={(data) => setSearch(data)}
                locale={ru}
                disabledKeyboardNavigation
                portalId="root-portal"
                readOnly
                
                renderCustomHeader={({ date, changeYear, changeMonth }) => (
                    <CustomHeader date={date} onChange={(newDate) => {
                        changeMonth(newDate.getMonth());
                        changeYear(newDate.getFullYear());
                    }} />
                )}
                
            /* showTimeSelect={true} // Включаем выбор времени
             timeFormat="HH:mm" // Формат времени
             timeIntervals={15} // Интервал между доступными значениями времени
             timeCaption="Время"
             */
            />
            <Box sx={{
                display: "flex",
                alignItems: "center"
            }}>
                {(search[0] != null && search[1] != null) && <Box
                    component="img"
                    src={close}
                    onClick={() => clearDates()}
                    sx={{ cursor: "pointer", height: "13px", }}
                />
                }
                <Box
                    component="img"
                    src={calendar}
                    onClick={() => setIsOpen(prev => true)}
                    sx={{ cursor: "pointer", height: "24px", margin: "0 16px" }}
                />
            </Box>

        </Box>

    );
};

export default DateRangePickerNew;