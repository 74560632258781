import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const uploadFileApi = createApi({
  reducerPath: "uploadFile/api",
  tagTypes: ['Files'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, {getState}) => {
      const { token } = getState().users
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    downloadFile: build.mutation({
      query: (id) => ({
        url: `/api/file-storage?fileId=${id}`,
        responseHandler: async (response) =>
          await response.blob().then((myBlob) =>
            new File([myBlob], myBlob?.name || "image", {
              type: myBlob.type,
            })
          ),
        cache: "no-cache",
      }),
    }),
    addFile: build.mutation({
      query: (file) => ({
        url: "/api/organizations/add-ogrn-file",
        method: "POST",
        body: file,
      }),
      invalidatesTags: ['Files'],
    }),
    removeFile: build.mutation({
      query: (key) => ({
        url: "/api/file-storage",
        method: "DELETE",
        body: key,
      }),
      invalidatesTags: ['Files'],
    }),
    manipulateFiles: build.mutation({
      query: (entityFileList) => ({
        url: "/api/file-storage/manipulate-entity-files",
        method: "POST",
        body: entityFileList,
      }),
      invalidatesTags: ['Files'],
    }),
    getContractFilesSelect: build.query({
      query: (params) => ({
        url: `api/file-storage/get-contract-files-select`,
      }),
      providesTags: ['Files'],
    }),
  }),
});

export const {
  useDownloadFileMutation,
  useAddFileMutation,
  useRemoveFileMutation,
  useManipulateFilesMutation,
  useGetContractFilesSelectQuery,
} = uploadFileApi;