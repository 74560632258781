// libs imports
import React from "react";
import InputText from "../../../components/InputText";
import ReadOnlyPersonalData from "./ReadOnlyPersonalData";
import ProfileContactData from "../ProfileContactData";
import TelegramNotificationsAvailability from "./Components";
import InputHandleEditAvailability from "../../../components/InputHandleEditAvailability/InputHandleEditAvailability";
import { EmployeeProfile } from "../../EmployeeList/EmployeeProfile";
import { ReadOnlyEmployeePersonalData } from "../../EmployeeList/ReadOnlyEmployeePersonalData";
import { ReadOnlyEmployeeContactInfo } from "../../EmployeeList/ReadOnlyEmployeeContactInfo";
import Switcher from "../../../components/Switcher";
import SelectSimple from "../../../components/SelectSimple";
import { t } from "i18next";

export const formFields = {
  id: "id",
  firstName: "firstName",
  surname: "surname",
  lastName: "lastName",
  email: "email",
  phone: "phone",
  telegramNotificationsAvailability: "telegramNotificationsAvailability",
  readOnlyPersonalData: "readOnlyPersonalData",
  sendMessagesByEmail: "sendMessagesByEmail",
  messageKindToSendByEmail: "messageKindToSendByEmail",
};

export const notificationByEmailOptions = [
  { status: true, name: "" },
  { status: false, name: "" },
];

export const messageKindToSendByEmailOptions = [
  { value: -1, name: t("label:all") },
  { value: 1, name: t("label:alertNotifications") },
  { value: 2, name: t("label:mulfunctionNotifications") },
  { value: 3, name: t("label:automaticNotifications") },
];

export const telegramNotificationsAvailableRole = "Администратор системы";

export const getDefaultValues = (profileData) => {
  const {
    id,
    firstName,
    surname,
    lastName,
    email,
    phone,
    avatarLink,
    sendMessagesByEmail,
    messageKindToSendByEmail,
    telegramNotificationsAvailability = false,
  } = profileData;
  return {
    id,
    firstName,
    surname,
    lastName,
    email,
    phone,
    avatarLink: {
      filesPrev: avatarLink ? [avatarLink] : [],
      filesToAdd: [],
      filesToDelete: [],
    },
    sendMessagesByEmail,
    messageKindToSendByEmail,
    telegramNotificationsAvailability,
  };
};

export const ProfileMainDataFormFormConfig = [
  {
    id: 1,
    name: formFields.sendMessagesByEmail,
    label: "label:sendMessagesByEmail",
    type: "switch",
    options: notificationByEmailOptions,
    input: (props) => <Switcher {...props} />,
  },
  {
    id: 2,
    name: formFields.messageKindToSendByEmail,
    label: "label:messageKindToSendByEmail",
    type: "select-number",
    width: "100%",
    options: messageKindToSendByEmailOptions,
    input: (props) => <SelectSimple {...props} />,
  },
  // {
  //   id: 3,
  //   title: "label:personalData",
  //   block: true,
  //   expended: [
  //     {
  //       name: formFields.lastName,
  //       label: "label:lastName",
  //       type: "text",
  //       rules: {
  //         required: true,
  //       },
  //       flexBasis: "33%",
  //       input: (props) => (
  //         <InputHandleEditAvailability
  //           disabled={true}
  //           {...props}
  //           Component={InputText}
  //         />
  //       ),
  //     },
  //     {
  //       name: formFields.firstName,
  //       label: "label:firstName",
  //       type: "text",
  //       rules: {
  //         required: true,
  //       },
  //       flexBasis: "33%",
  //       input: (props) => (
  //         <InputHandleEditAvailability {...props} Component={InputText} />
  //       ),
  //     },
  //     {
  //       name: formFields.surname,
  //       label: "label:surname",
  //       type: "text",
  //       rules: {},
  //       flexBasis: "33%",
  //       input: (props) => (
  //         <InputHandleEditAvailability {...props} Component={InputText} />
  //       ),
  //     },
  //     {
  //       items: [],
  //       name: formFields.readOnlyPersonalData,
  //       Component: ReadOnlyPersonalData,
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   name: formFields.telegramNotificationsAvailability,
  //   label: "label:telegramNotificationsAvailability",
  //   type: "checkbox",
  //   rules: {},
  //   input: (props) => <TelegramNotificationsAvailability {...props} />,
  // },
];

export const EditProfileFormDataName = [
  {
    id: 3,
    name: formFields.lastName,
    label: "Фамилия",
    type: "text",
    width: "100%",
    rules: { required: true },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 4,
    name: formFields.firstName,
    label: "Имя",
    type: "text",
    width: "100%",
    rules: { required: true },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 5,
    name: formFields.surname,
    label: "Отчество",
    type: "text",
    width: "100%",
    rules: { required: true },
    input: (props) => <InputText {...props} />,
  },
];

export const EditProfileFormDataInfo = [
  {
    id: 6,
    name: formFields.phone,
    label: "Номер телефона",
    type: "text",
    width: "100%",
    rules: { required: true },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 7,
    name: formFields.email,
    label: "E-mail",
    type: "text",
    width: "100%",
    rules: { required: true },
    input: (props) => <InputText {...props} />,
  },
  // {
  //   id: 2,
  //   name: formFields.,
  //   label: "",
  //   type: "text",
  //   width: "100%",
  //   rules: { required: true },
  //   input: (props) => <InputText {...props} />,
  // },
];

export const ProfileEmployeeFormFormConfig = [
  {
    id: 8,
    mode: "employee",
    CustomFormItem: EmployeeProfile,
  },
  {
    id: 9,
    title: "label:personalData",
    block: true,
    expended: [
      {
        items: [],
        name: formFields.readOnlyPersonalData,
        Component: ReadOnlyEmployeePersonalData,
      },
    ],
  },
  {
    id: 10,
    title: "label:contactInformation",
    block: true,
    expended: [
      {
        items: [],
        name: formFields.readOnlyPersonalData,
        Component: ReadOnlyEmployeeContactInfo,
      },
    ],
  },
  {
    id: 11,
    name: formFields.telegramNotificationsAvailability,
    label: "label:telegramNotificationsAvailability",
    type: "checkbox",
    rules: {},
    input: (props) => <TelegramNotificationsAvailability {...props} />,
  },
];
