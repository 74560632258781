import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close"
import { t } from 'i18next'
import EditReglamentPage from '../../../ReglamentList/EditReglamentPage'
import SelectSimple from '../../../../components/SelectSimple'
import { useLazyGetRegulationQuery, useLazyGetRegulationsListQuery } from '../../../../../middleware/regulationsApi'
import SelectSimpleSearch from '../../../../components/SelectSimple/SelectSimpleSearch'


const EditReglamentModal = ({ onClose, isOpen, selectedEquipmentGroup }) => {
  const [reglamentData, setReglamentData] = useState('')
  const [selectedReglament, setSelectedReglament] = useState('');
  const [reglamentsList, setReglamentsList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [getReglamentList] = useLazyGetRegulationsListQuery();
  const [getReglament] = useLazyGetRegulationQuery();

  const handleClose = useCallback(() => {
    onClose();
    setReglamentData('');
    setSelectedReglament('');
  }, [onClose]);

  const goNext = () => {
    setReglamentData('')
    getReglament({
      Id: selectedReglament.id,
      RegulationId: selectedReglament.regulationId,
      ProtectionObjectId: selectedReglament.protectionObjectId,
      EquipmentKindId: selectedReglament.equipmentKindId,
      EquipmentTypeId: selectedReglament.equipmentTypeId,
      EquipmentGroupId: selectedReglament.equipmentGroup,
    }).then(res => {
      if(res.data) {
        setReglamentData(res.data)
      }
    })
  }
  useEffect(() => {
    if(selectedEquipmentGroup) {
      const params = `&equipmentGroupId=${selectedEquipmentGroup}&regulationName=${searchValue}`
      getReglamentList({ PageNumber: 0, filters: params }).then(res => {
        if(Array.isArray(res.data.data)) {
          setReglamentsList(selectedReglament ? [selectedReglament, ...res.data.data.filter(r => r.regulationId !== selectedReglament.regulationId)] : res.data.data)
        }
      })
    }
  }, [selectedEquipmentGroup, searchValue])


  useEffect(() => {
    if(reglamentsList?.length && isOpen) {
      setSelectedReglament(reglamentsList[0])
    }
  }, [reglamentsList, isOpen])
  useEffect(() => {
    if(selectedReglament) {
      goNext()
    }
  }, [selectedReglament])
  return (
    <Dialog className="modal select_columns" open={isOpen}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
        <Box sx={{paddingTop: '10px', width: '95%'}}>
          <SelectSimpleSearch
            onChange={(e) => {
              setSelectedReglament(e.target.value)
            }}
            labelKey={'regulationName'}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            label={t('tableHeader:equip_reglament')}
            options={reglamentsList}
            value={selectedReglament}
          />
        </Box>
        <DialogTitle className="modal-title-right">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </Box>
      <DialogContent sx={{ padding: "10px" }}>
      {
        !!reglamentData && (
          <EditReglamentPage 
            style={{maxHeight: 'auto'}}
            onClose={handleClose}
            reglamentData={reglamentData} 
          />
        ) 
      }
      </DialogContent>
    </Dialog>
  )
}

export default EditReglamentModal