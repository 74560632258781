export const getSum = (data) => {
    return data?.reduce(
        (accumulator, {data}) => accumulator + data,
        0
    );
}

export const getPercentageBonusPoints = (data) => {
    return "+10%"
}

export const getLabels = (data) => {
    return data?.reduce(
        (accumulator, {label}) => label ? [...accumulator, label] : accumulator,
        []
    );
}