import React, { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { Typography, Box } from "@mui/material";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import { dateFormat } from "../../../constants";
import { useAppSelector } from "../../../hooks/redux";
import SelectSimple from "../../components/SelectSimple";
import {
  useGetEquipmentKindsFacilityQuery,
} from "../../../middleware/facilitiesApi";

export const PeriodDateCell = ({ item }) => {
  const { periodName, startPeriod, EndPeriod } = item
  const content = `${periodName},  ${dayjs(startPeriod).format(dateFormat)} - ${dayjs(EndPeriod).format(dateFormat)}`;

  return <Typography>{content}</Typography>;
};
export const ResponsibleTextCell = ({item}) => {
  const resArr = item || []
  return <Box>
    {resArr?.length >0 && item.map((item,index) => <Typography>{`${item}${index < resArr?.length-1 ? "," : ""}`}</Typography>)}
  </Box>;
};
export const SelectPlanPeriods = (props) => {
  const { periodsSelect } = useAppSelector(
    (state) => state.facilities
  );
  console.log("equipmentKindsFacility",periodsSelect)
  return <SelectSimpleInbuilt options={[{value:"все",name:"все"},...periodsSelect]} {...props} />;
};
export const SelectSimpleEquipKinds = (props) => {
 
  const { equipmentKindsFacility } = useAppSelector(
    (state) => state.facilities
  );
  
  return <SelectSimpleInbuilt options={[{value:"все",name:"все"},...equipmentKindsFacility]} {...props} />;
};
export const ClickableEquipCell = ( {item} ) => {
  const { name, action } = item || {};
console.log("itemm",item)
  return (
    <Typography
      className="table-row-cell-link"
      onClick={action ? action : () => { }}
    >
      {name || ""}
    </Typography>);
};