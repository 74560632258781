import React from "react";

import { useFormContext } from "react-hook-form";

import { formFields } from "../form-config";
import { documentationsConfig } from "./form-config";

import FormItem from "../../../components/FormItem";
import { Box } from "@mui/material";

export const DocumentationsForm = ({readonly}) => {
  const { control, errors } = useFormContext();

  return (
    <Box style={{ display: "flex", flexWrap: "wrap", columnGap: "10px", backgroundColor: "var(--white)", padding: '20px 32px', borderRadius: '32px' }}>
      {documentationsConfig.map(({ id, name, label, input, ...rest }) => (
        <FormItem
          key={id}
          control={control}
          disabled={readonly}
          errors={errors}
          name={`${[formFields.files]}.${name}`}
          label={label}
          input={input}
          sx={{ minWidth: "350px" }}
          {...rest}
        />
      ))}
    </Box>
  );
};
