import React from "react";
import { useGetAllObjectsAnalyticsQuery } from "../../../middleware/analyticsApi";
import { useAction } from "../../../hooks/actions";
import { RATING_OBJECT_OPTIONS } from "../../../constants";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { LinearRating } from "../../components/LinearRating";
import { useTranslation } from "react-i18next";

export const LinarRatingBox = () => {
  const {
    data: allObjectsAnalytics,
    isLoading: isLoadingAllObjectsAnalytics,
    error: errorAllObjectsAnalytics,
  } = useGetAllObjectsAnalyticsQuery();

  const { t } = useTranslation();
  const { setIsErrorRequest } = useAction();

  const isLoadAllObjectsAnalytics = !isLoadingAllObjectsAnalytics;
  const error = errorAllObjectsAnalytics;

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      {isLoadAllObjectsAnalytics && (
        <Box sx={{ marginBottom: "10px", width: "60%", marginLeft: "-1px" }}>
          <LinearRating
            legend={t(`rating_legend:industrialRating`)}
            procent={Math.round(+allObjectsAnalytics?.industrialRating)}
            options={RATING_OBJECT_OPTIONS}
            width="100%"
            minWidthLegend="222px"
          />

          <LinearRating
            legend={t(`rating_legend:protectionRating`)}
            procent={Math.round(+allObjectsAnalytics?.relaiabilityRating)}
            options={RATING_OBJECT_OPTIONS}
            width="100%"
            minWidthLegend="222px"
          />

          <LinearRating
            legend={t(`rating_legend:professionalRating`)}
            procent={Math.round(+allObjectsAnalytics?.professionalRating)}
            options={RATING_OBJECT_OPTIONS}
            width="100%"
            minWidthLegend="222px"
          />
        </Box>
      )}
    </>
  );
};
