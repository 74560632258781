import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import "./styles/styles.css";
import { SetSMSCodeDialogFormConfig, formFields } from "./form-config.jsx";
import { DEFAULT_SMS_TIMER, TIME_STEP } from "../constants";
import { useVerifyPhoneNumberMutation } from "../../../../middleware/signingApi";

import FormItem from "../../../components/FormItem";
import { useAppSelector } from "../../../../hooks/redux";
import { useLazyGetProfileInfoQuery } from "../../../../middleware/usersApi";

export const SetSMSCodeDialog = ({ open, onClose, actionRequest }) => {
  const { t } = useTranslation();

  const token = useAppSelector((state) => state.users.token);
  const [getProfileInfo] = useLazyGetProfileInfoQuery();
  const [verifyPhoneNumber, { isLoading }] =
    useVerifyPhoneNumberMutation();
  const [seconds, setSeconds] = useState(DEFAULT_SMS_TIMER);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    resetField,
  } = useForm({
    mode: "onChange",
  });

  const code = watch(formFields.smsCode);

  const handleClose = () => {
    onClose();
    setIsWarning(false);
    resetField(formFields.smsCode);
    setSeconds(DEFAULT_SMS_TIMER);
  };

  const handleRequest = () => {
    if (seconds === 0) {
      setIsWarning(false);
      actionRequest().then(({ error }) => {
        !error && setSeconds(DEFAULT_SMS_TIMER);
      });
    }
  };

  const onSubmit = async ({ smsCode }) => {
    setIsDisabled(true);
    verifyPhoneNumber(smsCode).then(({ error }) => {
      if (error) {
        setIsWarning(true);
        resetField(formFields.smsCode);
      } else {
        getProfileInfo(token).then(() => handleClose())
      }
      setIsDisabled(false);
    });
  };

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setSeconds((prev) => (prev !== 0 ? prev - 1 : prev));
      }, TIME_STEP);
      return () => {
        clearTimeout(timer);
      };
    }
  });

  return (
    <Dialog className="sms_code_dialog" open={open} maxWidth="md">
      <DialogTitle className="sms_code_dialog-header">
        {t("title:enter_sms_code")}
        <IconButton
          className="sms_code_dialog-close_icon"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="sms_code_dialog-content">
        <form id="setSmsCodeForm">
          {SetSMSCodeDialogFormConfig.map(({ name, label, input, ...rest }) => (
            <FormItem
              key={name}
              control={control}
              errors={errors}
              name={name}
              label={label}
              input={input}
              {...rest}
            />
          ))}
        </form>
        <Box sx={{ height: "18px" }}>
          {isWarning && (
            <Typography
              className="sms_code_dialog-content-warning_text"
            >
              {t("notification:error_send_code")}
            </Typography>
          )}
        </Box>
        <Box sx={{ height: "24px" }}>
          <Typography
            className={`sms_code_dialog-content-text default-label-wrap ${seconds === 0 ? "label-link" : ""}`}
            onClick={handleRequest}
          >
            {`${t("label:send_message_again")} ${seconds}c.`}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className="sms_code_dialog-submit_btn_wrapper">
        <Button
          form="setSmsCodeForm"
          className="btn btn-primary-fill"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={code?.length !== 4 || isDisabled}
        >
          {isLoading ? (
            <CircularProgress color="primary" size={24} />
          ) : (
            t("button:confirm")
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
