import React, { useState } from "react";
import Box from "@mui/material/Box";
import settingsicon from "../../../image/icons/settingsButtonWhite.png";

import "./styles/style.css";
import { sections } from "./documents-form-config";

import Header from "./Header";
import AccordionSection from "../../components/AccordionSection";
import PageheaderNav from "../../components/PageheaderNav";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const ActsDocuments = () => {
  const itemsNav = ["plume_and_address", "func_tests", "documents", "planning"];
  const { t } = useTranslation();
  const [openSelectModal, setOpenSelectModal] = useState(false);

  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  return (
    <Box className="acts-container">
      <Header nameForm="documents" />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "25px" }}>
        <PageheaderNav itemsNav={itemsNav} />
        <Button
          className="btn btn-primary-fill magazine_events-select_container-btn"
          variant="outlined"
          onClick={handleOpenSelectColumnsModal}
          sx={{ height: "52px" }}
        >
          <Box
            component="img"
            src={settingsicon}
            sx={{ marginRight: "16px" }}
          />
          {t("button:customize_fields_to_display")}
        </Button>
      </Box>
      <Box className="scrolled-form scrolled-acts_panel">
        {sections.map(({ id, title, Component }) => (
          <AccordionSection key={id} title={title}>
            {Component && (
              <Component
                section={title}
                setOpenSelectModal={setOpenSelectModal}
                openSelectModal={openSelectModal}
              />
            )}
          </AccordionSection>
        ))}
      </Box>
    </Box>
  );
};

export default ActsDocuments;
