import * as React from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const WorkContractFileView = ({ img, title, open, onClose }) => {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "80vw",
          bgColor: "var(--white)",
          p: "5px 20px 15px 20px",
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          p: 0,
          mt: 2,
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>{title}</Typography>
        <IconButton aria-label="close" onClick={onClose} sx={{ p: 0 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ m: 0, p: 0 }}>
        <Card>
          <CardActionArea>
            <CardMedia
              component="iframe"
              src={img}
              sx={{ margin: "auto" }}
              title={title}
              width="100%"
              height="500"
            />
          </CardActionArea>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default WorkContractFileView;
