import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TaskStateList from "../../components/TaskStateList/TaskStateList";
import { useGetFloorInfoPopUpQuery } from "../../../middleware/fireCompartmentApi";
import DrawCanvas from "../../components/DrawCanvas/DrawCanvas";
import DetailsWithoutImage from "./DetailsWithoutImage";
import { getDataToChart } from "../../../helpers/helper";
import { useMemo } from "react";
import { SCHEMA_CANVAS } from "../../../constants";

const FloorCompartmentDetails = ({ open, setOpen, id }) => {
  const { t } = useTranslation();

  const { data: floorInfoData, isLoading: isLoadingfloorInfo } =
    useGetFloorInfoPopUpQuery(id, {
      skip: !id,
    });

  const dataToolTip = useMemo(
    () => (floorInfoData ? getDataToChart(floorInfoData) : []),
    [floorInfoData]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="details"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle
        className="details-header"
        style={{ padding: "24px 20px 35px 40px" }}
      >
        {!isLoadingfloorInfo && (
          <Typography className="details-header-title">
            {floorInfoData?.floorInfo?.floorNumber}
          </Typography>
        )}
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        className="details-body"
        style={{ padding: "0px 40px 48px 40px" }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box style={{ display: "flex" }}>
            {!isLoadingfloorInfo &&
              floorInfoData?.floorInfo?.floorSchemeFileId && (
                <>
                  <Typography
                    sx={{
                      fontFamily: "var(--INTER)",
                      fontWeight: 500,
                      fontSize: "var(--standartLg)",
                      lineHeight: "24px",
                      color: "var(--black)",
                    }}
                  >
                    Адрес помещения:
                  </Typography>
                  <Typography
                    sx={{
                      marginLeft: "16px",
                      fontFamily: "var(--INTER)",
                      fontWeight: 400,
                      fontSize: "var(--standartLg)",
                      lineHeight: "24px",
                      color: "var(--black)",
                    }}
                  >
                    Адрес помещения
                  </Typography>
                </>
              )}
          </Box>

          {/*  <Box>
            <Button className="btn btn-outlined-primary" variant="outlined">
              {t("button:go_to_floor")}
            </Button>
            <Button
              className="btn btn-outlined-primary"
              variant="outlined"
              style={{ margin: "0 15px" }}
            >
              {t("button:edit_floor")}
            </Button>
            <Button className="btn btn-primary-fill" sx={{ height: "48px" }}>
              {t("button:go_equipment_list")}
            </Button>
          </Box> */}
        </Box>
        {!isLoadingfloorInfo &&
          (floorInfoData?.floorInfo?.floorSchemeFileId ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "15px 0",
              }}
            >
              <DrawCanvas
                data={floorInfoData?.equipmentCoordinates}
                schemaImg={floorInfoData?.floorInfo?.floorSchemeFileId}
                height={SCHEMA_CANVAS.HEIGHT}
                width={SCHEMA_CANVAS.WIDTH}
              />
            </Box>
          ) : !floorInfoData?.floorInfo?.floorSchemeFileId &&
            !floorInfoData?.floorInfo?.floorSchemeFileName ? (
            <Box
              sx={{
                color: "var(--additionalGrey)",
                fontFamily: "var(--INTER)",
                fontWeight: 400,
                fontSize: "var(--standart)",
                lineHeight: "24px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "40px",
                marginBottom: "16px",
              }}
            >
              {t("label:no_data_to_display")}
            </Box>
          ) : (
            <DetailsWithoutImage data={floorInfoData?.equipmentKinds} />
          ))}
        <TaskStateList data={dataToolTip} height="12px" width="12px" />
      </DialogContent>
    </Dialog>
  );
};

export default FloorCompartmentDetails;
