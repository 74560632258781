import React, { useState } from "react";
import { Box, Card, CardContent, CardMedia, Typography, Button } from "@mui/material";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import InputFileBtn from "../InputFileBtn";
import { Link } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';

const InputFileWithPreview = ({ file, handleChange, handleDownload, uploadBtnText = "button:uploadSingle", accept = "", disabled }) => {

  const [showDownloadButton, setShowDownloadButton] = useState(false);
  return (
    <Card
      sx={{
        display: "grid",
        border: "1px solid var(--bgTableHeader)",
        borderRadius: 0,
        gridTemplateAreas: `'item1 item2'
                       'item1 item3'`,
        gridTemplateRows: "repeat(2, 1fr)",
        gridTemplateColumns: "1fr 5fr",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          borderRight: "1px solid var(--bgTableHeader)",
          padding: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gridArea: "item1",
        }}
      >
        {file ? (
          <Box
            onMouseEnter={() => setShowDownloadButton(true)}
            onMouseLeave={() => setShowDownloadButton(false)}
            className="image-container"
          >
            <CardMedia
              component="img"
              image={file.fileLink || URL.createObjectURL(file)}

              alt="Live from space album cover"
              sx={{ filter: showDownloadButton? 'blur(5px)' : 'none', width: "80px", height: "80px" }}
            />
            {/*{showDownloadButton && (*/}
              <Link
                to={file.fileLink || URL.createObjectURL(file)}
                download={file.name || file.fileName}
                target="_blank"
                className="download-button"
                rel="noreferrer"
              >
                
                  <DownloadIcon sx={{position: "absolute",left: "11px",top: "11px",color:"var(--main)",fontSize:"30px"}}/>
               
              </Link>
           
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              backgroundColor: "var(--bgTableHeader)",
              height: "80px",
              width: "80px",
            }}
          >
            <CropOriginalIcon />
          </Box>
        )}
      </Box>
      <CardContent
        sx={{
          borderBottom: "1px solid var(--bgTableHeader)",
          padding: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gridArea: "item2",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }} >
          {file && <Typography>{file.name || file.fileName}</Typography>}
          {accept && (
            <Typography className="input_block_label-helper" >
              {accept ? `Формат( ${accept.split(",").join("")} )` : ""}
            </Typography>
          )}
        </Box>
      </CardContent>
      <Box
        sx={{
          padding: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gridArea: "item3",
          ".btn-upload": {
            border: "none",
            "&:hover": {
              border: "none",
            },
          },
        }}
      >
        <InputFileBtn
          uploadMode="single"
          onChange={handleChange}
          disabled={disabled}
          label={uploadBtnText}
        />
      </Box>
    </Card>
  );
};

export default InputFileWithPreview;
