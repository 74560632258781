import React from "react";

import InputText from "../../components/InputText";
import ForgotPasswordFormItem from "./AuthSteps/ForgotPasswordFormItem";
import InputMaskPhone from "../../components/InputMaskPhone";
import InputPassword from "../../components/InputPassword";

export const formFields = {
  loginOrMail: "loginOrMail",
  login: "login",
  password: "password",
  newPassword: "newPassword",
  newPasswordConfirm: "newPasswordConfirm",
  phone: "phone",
};

export const formConfigFirstStep = [
  {
    name: formFields.login,
    label: "label:loginOrMail",
    type: "text",
    rules: {
      required: true,
    },
    input: (props) => <InputText {...props} />,
  },
];

export const formConfigSecondStep = [
  {
    name: formFields.password,
    label: "label:password",
    type: "password",
    rules: {
      required: true,
    },
    input: (props) => <ForgotPasswordFormItem {...props} />,
  },
];

export const formConfigResetPassword = [
  {
    name: formFields.login,
    label: "label:loginOrMail",
    type: "text",
    rules: {
      required: true,
    },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.phone,
    label: "label:phone",
    rules: {
      required: true,
      validate: (value, formValues) =>
        value.replace(/[+() ]/g, "", "")?.length === 11,
    },
    input: (props) => <InputMaskPhone {...props} />,
  },
];

export const formConfigSetPassword = [
  {
    name: formFields.newPassword,
    label: "label:newPassword",
    type: "password",
    rules: {
      required: true,
      validate: {
        lengthCheck: (value) => value.length >= 6 || "password_length",
      },
    },
    input: (props) => <InputPassword {...props} />,
  },
  {
    name: formFields.newPasswordConfirm,
    label: "label:newPasswordConfirm",
    type: "password",
    rules: {
      required: true,
      validate: {
        matchPassword: (value, formValues) =>
          value === formValues[formFields.newPassword] || "password_not_match",
      },
    },
    input: (props) => <InputPassword {...props} />,
  },
];
