export const DEFAULT_VALUE = {
  availableOrganizations: [],
  organizationWorkingFor: "",
  protectionObjectsIds: [],
  equipmentKindsIds: [],
  roleId: "",
  isContactPerson: true,
  firstName: "",
  surname: "",
  lastName: "",
  jobTitle: "",
  phoneNumber: "",
  email: "",
  login: "",
  password: "",
  permissionsIds: [],
};
