import { createSlice } from "@reduxjs/toolkit";
// import { settingsApi } from "../../middleware/api";

const initialState = {
  searchValue: "",
  pageNumber: 0,
  rowsPerPage: 15,
  titlePage: "",
  pathForModalClosePage: "",
  isErrorSubmit: false,
  isErrorRequest: false,
  isSuccessSubmit: false,
  isNoPermission: false,
  isWarning:false,
  errorNotificationParams: {
    isOpen: false,
    title: "",
    content: "",
  },
  SCHEMA_CANVAS: {
    WIDTH: 0,
    HEIGHT: 0,
  },
  SCHEMA_CANVAS1: {
    WIDTH: 0,
    HEIGHT: 0,
  },
  openSidebar: true,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    changeSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    changePageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    changeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    changeTitlePage(state, action) {
      state.titlePage = action.payload;
    },
    setPathForModalClosePage(state, action) {
      state.pathForModalClosePage = action.payload;
    },
    setIsErrorSubmit(state, action) {
      state.isErrorSubmit = action.payload;
    },
    setIsSuccessSubmit(state, action) {
      state.isSuccessSubmit = action.payload;
    },
    setIsErrorRequest(state, action) {
      state.isErrorRequest = action.payload;
    },
    setIsWarningRequest(state, action) {
      state.isWarning = action.payload;
    },
    setIsNoPermissionRequest(state, action) {
      state.isNoPermission = action.payload;
    },
    setErrorNotificationParams(state, action) {
      state.errorNotificationParams = action.payload;
    },
    setCanvasSize(state, action) {
      state.SCHEMA_CANVAS = action.payload;
    },
    setCanvasSize1(state, action) {
      state.SCHEMA_CANVAS1 = action.payload;
    },
    setOpenSidebar(state, action) {
      state.openSidebar = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const settingsActions = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
