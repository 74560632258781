export const fileSizeCheck = (files, field, maxAvailableFileSize) => {
    if(!files?.length) {
        return
    }
    for (const file of files) {
        if((file instanceof File) && file.size > maxAvailableFileSize * 1024 * 1024) {
            return `file_size_max:${field}`
        }
    }
}

export const fileTypeCheck = (files, field, availableFileTypes) => {
    if(!files?.length) {
        return
    }
    for (const file of files) {
        if((file instanceof File) && !availableFileTypes.includes(file.type)) {
            return `file_type:${field}`
        }
    }
}

const checkApprox = (width, height, availableDimension, inaccuracy = 0.01) => {
    return Math.abs( width / height - availableDimension) < inaccuracy;
}

const loadImage = (imageSrc, availableDimension) => new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
        const height = image.height;
        const width = image.width;
        resolve(checkApprox(width, height, availableDimension))
    };
    image.src = imageSrc;
});

export const fileDimensionCheck = async (files, field, availableDimension) => {
    if(!files?.length) {
        return;
    }
    for (const file of files) {
        if(file instanceof File) {
            const imageSrc = URL.createObjectURL(file);
            const isValidDimension = await loadImage(imageSrc, availableDimension);
            if (!isValidDimension) {
                return `file_dimension:${field}`;
            }
        }
    }
}

