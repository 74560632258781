import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormItem from "../../../components/FormItem";
import { formConfigResetPassword } from "../form-config";
import { useTranslation } from "react-i18next";
import { Box, Button, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { PATHS } from '../../../../constants';
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import { useSendRecoveryMailMutation } from "../../../../middleware/usersApi";

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);
  const [isFailedSubmit, setIsFailedSubmit] = useState(false);
  const [sendRecoveryMail] = useSendRecoveryMailMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    data.phone = data.phone.replace(/[+() ]/g, "");
    const response = await sendRecoveryMail(data);
    if (response.error) {
      setIsFailedSubmit(true);
      return;
    }
    setIsSuccessSubmit(true);
  };

  if (isSuccessSubmit) {
    return <ResetPasswordSuccess />;
  }
  return (
    <>
      <Typography className="login-title" sx={{ mb: "16px" }} variant={"h4"}>
        {t("label:resetPassword")}
      </Typography>
      <Box>
        <IconButton
          sx={{ mb: "15px", p: 0 }}
          onClick={() => navigate(PATHS.LOGIN_ROUTE)}
          size="small"
        >
          <ArrowBackIcon fontSize="small" />
        </IconButton>
        <form
          id="myform"
          className="object-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {formConfigResetPassword.map(({ name, label, input, ...rest }) => (
            <FormItem
              key={name}
              control={control}
              errors={errors}
              name={name}
              label={label}
              input={input}
              {...rest}
            />
          ))}
          <Button
            sx={{ mt: "30px" }}
            fullWidth
            className="btn btn-primary-fill"
            variant="contained"
            type="submit"
          >
            {t("button:resetPassword")}
          </Button>
        </form>
        {isFailedSubmit && (
          <Typography
            className="default-label-text"
            sx={{ pt: "5px", color: "var(--red)" }}
          >
            {t("error_message:resetPasswordSubmitFailed")}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default ResetPassword;
