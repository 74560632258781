import React from "react";
import { sections } from "./form-config";
import AccordionSection from "../../../components/AccordionSection";

export const AdditionalInformationForm = ({ expandedAccordions,handleAccordionChange,readonly}) => {
  return (
    sections.map(({ id, title, Component, config }) => (
      <AccordionSection  handleAccordionChange={handleAccordionChange} expandedAccordions={expandedAccordions} key={id} idd={id} title={title}>
        {Component && (
          <Component config={config} readonly={readonly} section={title} />
        )}
      </AccordionSection>
    ))
  );
};