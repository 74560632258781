import FormItem from "../../../../components/FormItem";

import {
    Box,CircularProgress, InputAdornment
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { RABFields, voltageFileds } from "./FormConfigs/equipments"
import { formFields } from "../form-config";
import { useWatch } from "react-hook-form";
const EquipColumn = ({ indexCirc, CircuitName, formConfig, plumeequip, isActExist, ...rest }) => {
    const { control, setValue } = rest;
    const { [formFields.protectionObjectId]: selectedFacilityId } = useWatch({
        control
    });
    let PlumesName = plumeequip?.find(obj => obj.name === CircuitName);
    const countItemperpage = 10
    const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [EquipCircuitArr, setEquipCircuitArr] = useState([])

    const equipfetchData = () => {
        setLoading(true);

        setTimeout(() => {
            setPage(prev => prev + 1)
            setLoading(false);
        }, 1500);

    }

    useEffect(() => {

        if (PlumesName?.equipmentSelectElements) {
            if (PlumesName?.equipmentSelectElements?.length > page * countItemperpage) {
                const newpageContent = PlumesName?.equipmentSelectElements?.slice(page * countItemperpage, countItemperpage * (page + 1))
                
                setEquipCircuitArr(prev => [...prev, ...newpageContent])
            }

        }
    }, [page, plumeequip])

    if (selectedFacilityId) {

        const handleScroll = (e) => {
            const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
            if (bottom >= 1 && bottom <= 50 && !loading && EquipCircuitArr?.length < PlumesName?.equipmentSelectElements?.length) {
                equipfetchData()
            }
        };


        return <Box sx={{ maxHeight: "400px", overflowY: 'auto' }} onScroll={handleScroll}>
            {EquipCircuitArr?.map(({ id, name, plume, integrationNumbe, workingCorrectly }, index) => {
                const equipName = `${plume ? "шлейф 0" + plume + "," : ""} ${name}`
                return <Box sx={{
                    display: "flex",
                    alignSelf: "center",
                    gap: "16px",
                }}>
                    {formConfig.map((item) => {
                        let adorment = ""
                        if (CircuitName == "cableInsulationResistanceFormConfig")
                            adorment = "мОм"
                        else if (CircuitName == "RABCapacityFormConfig") {
                            adorment = "А\ч"
                        } else if (CircuitName == "voltageCurrentPowerSupplyFormConfig") {
                            adorment = "В,А"
                        }
                        if (item.name == "equipmentId") rest.equiptyp = equipName
                        if (item.name == "workingCorrectly") item.defaultValue = workingCorrectly.toString()
                        if (item.name == "equipmentId")
                            return (
                                <FormItem
                                    {...item}
                                    {...rest}
                                    key={`${[rest.name]}.${index}.${item.name}`}

                                    sx={{ alignSelf: "center", width: "250px" }}
                                    value={id}

                                    name={`${[rest.name]}.${index}.${item.name}`}
                                />
                            );
                        else if (item.name == "workingCorrectly")
                            return (
                                <Box>
                                    <FormItem
                                        key={`${[rest.name]}.${index}.${item.name}`}
                                        {...item}
                                        sx={{ width: item.name == "corresponds" ? "200px" : "auto" }}
                                        {...rest}
                                        name={`${[rest.name]}.${index}.${item.name}`}
                                    />
                                    {(CircuitName == "voltageCurrentPowerSupplyFormConfig" || CircuitName == "RABCapacityFormConfig" || CircuitName == "cableInsulationResistanceFormConfig") &&
                                        RABFields.map((item) => {
                                            return (
                                                <FormItem
                                                    key={`${[rest.name]}.${index}.${item.name}`}
                                                    {...item}
                                                    InputProps={{ endAdornment: <InputAdornment position="end">{adorment}</InputAdornment>, type: "number" }}
                                                    {...rest}
                                                    name={`${[rest.name]}.${index}.${item.name}`}
                                                />
                                            );


                                        })
                                    }
                                </Box>
                            )
                        else
                            return (
                                <FormItem
                                    key={`${[rest.name]}.${index}.${item.name}`}
                                    {...item}
                                    sx={{ width: item.name == "corresponds" ? "200px" : "auto" }}
                                    {...rest}
                                    name={`${[rest.name]}.${index}.${item.name}`}
                                />
                            );
                    })}
                    {(CircuitName == "RABCapacityFormConfig") &&
                        RABFields.map((item) => {
                            return (
                                <FormItem
                                    key={`${[rest.name]}.${index}.${item.name}`}
                                    {...item}

                                    {...rest}
                                    name={`${[rest.name]}.${index}.${item.name}`}
                                />
                            );


                        })
                    }
                    {(CircuitName == "voltageCurrentPowerSupplyFormConfig") &&
                        voltageFileds.map((item) => {
                            return (
                                <FormItem
                                    key={`${[rest.name]}.${index}.${item.name}`}
                                    {...item}

                                    {...rest}
                                    name={`${[rest.name]}.${index}.${item.name}`}
                                />
                            );


                        })
                    }
                </Box>
            }
            )}
            {loading && (

                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            )}
        </Box>


    }


};


export default EquipColumn;