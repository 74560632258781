import React, { useMemo } from "react";
import { Box, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InputFileBtn from "../InputFileBtn";
import ClearIcon from "@mui/icons-material/Clear";
import { PreviewFileBlock } from "../PreviewFileBlock";
import { Link } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';

export const InputFileMultiple = ({
  onChange,
  error,
  value,
  canDeleteFile,
  uploadBtnText = "button:uploadMultiple",
  accept = "",
  isPrewImageColumn = false,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const { filesPrev, filesToAdd, filesToDelete } = useMemo(() => {
    return {
      filesPrev: value.filesPrev || [],
      filesToAdd: value.filesToAdd || [],
      filesToDelete: value.filesToDelete || []
    }
  }, [value])

  const handleChange = (event) => {
    let newFiles = Array.from(event.target.files)

    if (!newFiles?.length) {
      return;
    }

    newFiles = newFiles.filter(newFile => {
      const fileToAddWithSameName = filesToAdd.find(file => file.name === newFile.name)
      const filePrevWithSameName = filesPrev.find(file => file.fileName === newFile.name)
        && !filesToDelete.find(file => file.fileName === newFile.name)
      return !fileToAddWithSameName && !filePrevWithSameName
    })

    onChange({
      filesPrev,
      filesToAdd: filesToAdd?.length ? [...filesToAdd, ...newFiles] : [...newFiles],
      filesToDelete
    });
  };

  const handleRemoveFile = (fileName) => {
    const filesToAddList = filesToAdd.filter(file => file.name !== fileName);
    const filesToDeleteList = filesToDelete;
    const fileToRemove = filesPrev.find(file => file.fileName === fileName);
    if (fileToRemove) {
      filesToDeleteList.push(fileToRemove)
    }
    onChange({ filesPrev, filesToAdd: filesToAddList, filesToDelete: filesToDeleteList });
  };

  const filterPrevFilesToShow = () => {
    return filesPrev.filter(filePrev => !filesToDelete.find(fileToDelete => fileToDelete.id === filePrev.id)) || []
  }

  const stylesContainer = isPrewImageColumn
    ? { display: "flex", alignItems: "flex-start", flexDirection: "column", gap: "22px" }
    : { display: "flex", alignItems: "flex-start", columnGap: 1 };

  const stylesImagesContainer = isPrewImageColumn
    ? { display: "flex", alignItems: "flex-start", flexDirection: "column", gap: "10px" }
    : { display: "flex", gap: 1, flexWrap: "wrap" };

  return (
    <Box>
      <Box
        className="input_file_block"
        sx={stylesContainer}
      >
        <InputFileBtn
          uploadMode="multiple"
          onChange={handleChange}
          label={uploadBtnText}
          accept={accept}
          isError={error}
          disabled={disabled}
        />
        <Box
          className="input_file_block-preview_field"
          sx={stylesImagesContainer}
        >
          {filterPrevFilesToShow().map((file, i) => (
            file && (
              <Box
                key={i}
                className="input_file_block-preview_field-item"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <PreviewFileBlock file={file} />

                <Chip
                  key={file.id}
                  sx={{ p: 1, bgColor: "var(--bgTableHeader)", maxWidth: isPrewImageColumn ? "250px" : "100%" }}
                  label={<Link
                    to={file.fileLink}
                    download={file.name}
                    target="_blank"
                    rel="noreferrer noopener"
                  ><Box sx={{ display: "flex" }}><Typography sx={{ fontSize: "14px" }}>{file.fileName}</Typography><DownloadIcon sx={{ fontSize: "20px", marginTop: "2px", color: "var(--main)", }} /></Box></Link>}
                  onDelete={canDeleteFile ? () => handleRemoveFile(file.fileName) : null}
                  deleteIcon={<ClearIcon />}
                />

              </Box>
            )
          ))}
          {filesToAdd?.map((file, i) => (
            file && (
              <Box
                key={i}
                className="input_file_block-preview_field-item"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <PreviewFileBlock file={file} />

                <Chip
                  key={file.name}
                  className="input_file_block-preview_field-item_chip"
                  sx={{ p: 1, bgColor: "var(--bgTableHeader)", maxWidth: isPrewImageColumn ? "250px" : "100%" }}
                  label={<Link
                    to={file.fileLink}
                    download={file.name}
                    target="_blank"
                    rel="noreferrer noopener"
                  ><Box sx={{ display: "flex" }}><Typography sx={{ fontSize: "14px" }}>{file.name}</Typography><DownloadIcon sx={{ fontSize: "20px", marginTop: "2px", color: "var(--main)", }} /></Box></Link>}
                  onDelete={canDeleteFile ? () => handleRemoveFile(file.name) : null}
                  deleteIcon={<ClearIcon />}
                />

              </Box>
            )
          ))}
        </Box>
      </Box>
      {error && (
        <Typography className="error-text">
          {t(`error_message:${error.message}`)}
        </Typography>
      )}
    </Box>
  );
};