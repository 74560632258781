export const fields = {
  starts: "Пуски",
  fires: "Пожары",
  alarms: "Тревоги",
  stops: "Остановы",
  malfunctions: "Неисправности",
  blocking: "Блокировка",
  automatic_shutdown: "Автоматика отключена",
  normal: "Норма",
  shutdowns: "Отключения",
  not_affecting_status: "Не влияют на состояния",

  input: "вход",
  output: "выход",
  reader: "считыватель",
  device: "прибор",
  output_input: "выход-вход",
  subscriber: "абонент",
  zone: "зона",
};

export const ELEMENT = {
  [fields.starts]: 1,
  [fields.fires]: 2,
  [fields.alarms]: 3,
  [fields.stops]: 4,
  [fields.malfunctions]: 5,
  [fields.blocking]: 6,
  [fields.automatic_shutdown]: 7,
  [fields.normal]: 8,
  [fields.shutdowns]: 9,
  [fields.not_affecting_status]: 10,
};

export const MESSAGE_CODE_TYPE = {
  [fields.input]: 1,
  [fields.output]: 2,
  [fields.reader]: 3,
  [fields.device]: 4,
  [fields.output_input]: 5,
  [fields.subscriber]: 6,
  [fields.zone]: 7,
};

export const elementOptions = [
  {
    value: MESSAGE_CODE_TYPE[fields.input],
    name: fields.input,
  },
  {
    value: MESSAGE_CODE_TYPE[fields.output],
    name: fields.output,
  },
  {
    value: MESSAGE_CODE_TYPE[fields.reader],
    name: fields.reader,
  },
  {
    value: MESSAGE_CODE_TYPE[fields.device],
    name: fields.device,
  },
  {
    value: MESSAGE_CODE_TYPE[fields.output_input],
    name: fields.output_input,
  },
  {
    value: MESSAGE_CODE_TYPE[fields.subscriber],
    name: fields.subscriber,
  },
  {
    value: MESSAGE_CODE_TYPE[fields.zone],
    name: fields.zone,
  },
];

export const messageCodeTypeOptions = [
  {
    value: ELEMENT[fields.starts],
    name: fields.starts,
  },
  {
    value: ELEMENT[fields.fires],
    name: fields.fires,
  },
  {
    value: ELEMENT[fields.alarms],
    name: fields.alarms,
  },
  {
    value: ELEMENT[fields.stops],
    name: fields.stops,
  },
  {
    value: ELEMENT[fields.malfunctions],
    name: fields.malfunctions,
  },
  {
    value: ELEMENT[fields.blocking],
    name: fields.blocking,
  },
  {
    value: ELEMENT[fields.automatic_shutdown],
    name: fields.automatic_shutdown,
  },
  {
    value: ELEMENT[fields.normal],
    name: fields.normal,
  },
  {
    value: ELEMENT[fields.shutdowns],
    name: fields.shutdowns,
  },
  {
    value: ELEMENT[fields.not_affecting_status],
    name: fields.not_affecting_status,
  },
];
