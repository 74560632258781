import extinguisher from "../../components/icons/extinguisher.svg";
import fireAlarm from "../../components/icons/fireAlarm.svg";
import avacuationSystem from "../../components/icons/avacuationSystem.svg";
import { t } from "i18next";

export const workingCorrectlyOptions = [
  { status: true, name: t("label:yes") },
  { status: false, name: t("label:no") },
];

export const floorOptions = [1,2,3];

export const iconSchema = {
  extinguisher: extinguisher,
  fireAlarm: fireAlarm,
  avacuationSystem: avacuationSystem,
};