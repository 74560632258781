import React, {useRef} from "react";
import {Avatar, Box, IconButton, Typography} from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {useTranslation} from "react-i18next";

const InputFileAvatarPreview = ({file, onChange, uploadMode, label, disabled, error, size = "default"}) => {

    const {t} = useTranslation();
    const filePicker = useRef(null);

    const handlePick = () => {
        filePicker.current.value = ''
        filePicker.current.click();
    };

    const STYLE = {
      small: {
        height: "82px",
        width: "82px",
        "& .icon_button" : {
          height: "27px",
          width: "27px",
          top: "-5px",
          right: "-3px",
          "& svg": {
            width: "16px",
            height: "16px",
          }
        }
      },
      default: {
        height: "152px",
        width: "152px",
        "& .icon_button" : {
          height: "40px",
          width: "40px",
          top: "-5px",
          right: "5px",
        }
      }
    };

    return (
        <>
            <Box sx={{
                position: "relative",
                ...STYLE[size],
            }}>
                <Avatar
                    alt={label}
                    src={file instanceof File ? URL.createObjectURL(file) : file}
                    sx={{width: "100%", height: "100%"}}
                />
                <input
                    type="file"
                    className="hidden"
                    ref={filePicker}
                    multiple={uploadMode}
                    onChange={onChange}
                    disabled={disabled}
                />
                <IconButton
                    className="icon_button"
                    sx={{
                        borderRadius: "50%",
                        backgroundColor: "var(--white)",
                        filter: "drop-shadow(0px 0px 8px rgba(183, 203, 216, 0.4))",
                        "&:hover": {
                            backgroundColor: "var(--white-transparent)",
                        },
                        position: "absolute",
                    }}
                    onClick={handlePick}
                    disabled={disabled}
                >
                    <EditOutlinedIcon/>
                </IconButton>
                {error && (
                    <Typography
                        sx={{position: "absolute", left:0, right: 0, top: "160px"}}
                        className="error-text"
                    >
                        {t(`error_message:${error.message}`)}
                    </Typography>
                )}
            </Box>
        </>
    );
}

export default InputFileAvatarPreview;