import React from "react";
import { SelectSimpleObject, SelectSimpleFloor,SelectIntNumbers, PlumesList } from "./Components";
import InputText from "../../components/InputText";
import SelectSimple from "../../components/SelectSimple";
import { Box, Button, Typography } from '@mui/material'
import { t } from 'i18next'

export const formFields = {
  object: "object",
  floor: "floor",

  floorSchemeFileLink: "floorSchemeFileLink",
  equipmentsCoordinates: "equipmentsCoordinates",
  roomAddresses: "roomAddresses",
  roomAddress: "roomAddress",
  name: "name",
  plume: "plume",
  plumes: "plumes",
  protectionObjectIntegrNumberAltId:"protectionObjectIntegrNumberAltId",
};

export const config = [
  {
    id: 1,
    name: formFields.object,
    label: `label:${formFields.object}`,
    type: "select-text",
    rules: {},
    width: "320px",
    sx: { padding: "0px" },
    isCanDisabled: true,
    input: (props) => <SelectSimpleObject {...props} />,
  },
  
  {
    id: 2,
    name: formFields.floor,
    label: `label:${formFields.floor}`,
    type: "select-number",
    rules: {},
    width: "250px",
    sx: { padding: "0px" },
    isCanDisabled: true,
    input: (props) => <SelectSimpleFloor {...props} />,
  },
];

export const modalAddressConfig = [
  {
    id: 1,
    name: formFields.name,
    label: "label:roomAddress",
    type: "text",
    rules: {
      required: true,
    },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 2,
    name: formFields.protectionObjectIntegrNumberAltId,
    label: "tableHeader:integrationNumber",

    input: (props) => <SelectIntNumbers {...props} />,
  },
  {
    id: 3,
    name: formFields.plumes,
    label: "label:number_plume",    
    rules: {
      validate: val => val?.length && !val.find(el => !String(el).trim()),
    },
    onKeyDown:(e) => {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "." || e.key === ",") {
        e.preventDefault()
      }
    },
    InputProps:{ type:"number" },

    CustomFormItem: PlumesList
  },
];

export const modalAddressConfigSelect = (options) => ([
  {
    id: 1,
    name: formFields.name,
    label: "label:roomAddress",
    type: "select-text",
    rules: {
      required: true,
    },
    input: (props) => <SelectSimple options={options} {...props} />,
  },
  {
    id: 2,
    name: formFields.plume,
    label: "label:number_plume",
    type: "number",
    disabled: true,
    rules: {
      required: true,
    },
    input: (props) => <InputText {...props} />
  },
]);