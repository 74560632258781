import { Box, Typography } from "@mui/material";
import React from "react";

const Responsible = ({ data }) => {
  if (!data || data?.length === 0) {
    return <Typography>Нет контактов</Typography>;
  }

  return data?.map(({ fullName, position, phone, email }, i) => (
    <Box key={i} className="responsible">
      <Box className="responsible_text">{fullName}</Box>
      <Box className="responsible_text">{position}</Box>
      <Box className="responsible_text">{phone}</Box>
      <Box className="responsible_text">{email}</Box>
    </Box>
  ));
};
export default Responsible;
