import { useState, useEffect } from 'react';

const useScreenSize = (scale = 1, sizeFormat = null) => {
  const [screenSize, setScreenSize] = useState({
    width: sizeFormat ? window.innerHeight * sizeFormat * scale : window.innerWidth * scale,
    height: window.innerHeight * scale,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: sizeFormat ? window.innerHeight * sizeFormat * scale  : window.innerWidth * scale,
        height: window.innerHeight * scale,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [scale, sizeFormat]);

  return screenSize;
};

export default useScreenSize;
