import React, { useEffect } from "react";
import { createSnmeActFormConfig } from "./form-config.jsx";
import Form from "../Form";


const CreateSnmeAct = () => {


  return (
    
      <Form
        formId="createSNMEActForm"
        formTitle="create_snme_act"
       
        actType={1}
        formConfig={createSnmeActFormConfig}
      />
      
  );
};

export default CreateSnmeAct;
