import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const ModalConfirmPage = ({
    open,
    setOpen,
    handleSubmit,
    onSubmit
}) => {

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmation = () => {
        setOpen(false);
    };

    return (

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Подтверждение"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Не все работы были выполнены. Вы уверены,что хотите закрыть задачу?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Нет
                </Button>
                <Button onClick={() => { handleSubmit(onSubmit)(); setOpen(false); }} color="primary" autoFocus>
                    Да
                </Button>
            </DialogActions>
        </Dialog>
    );
}
