import React, { useState, useMemo, useEffect } from "react";
import { useAppSelector } from "../../../../hooks/redux";
import SelectGrouping from "../../../components/SelectGrouping";
import SelectSimple from "../../../components/SelectSimple";
import SelectSimpleSearch from "../../../components/SelectSimple/SelectSimpleSearch";
import {
  useGetRegulationsforSelectionQuery,
} from "../../../../middleware/regulationsApi";

export const RegulationList = ({ facilityId, ...props }) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: regulationsSelect = [] } =
    useGetRegulationsforSelectionQuery({ facilityId: facilityId, regulationName: searchValue, equipmentKindId: "" });

  const options = useMemo(() => {
    if (!regulationsSelect) {
      return [];
    }
    return regulationsSelect;

  }, [regulationsSelect]);
  return (
    <SelectSimpleSearch
      {...props}
      sx={{ ".MuiTypography-root": { padding: 0 } }}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      options={options}
    />
  );
};

export const SelectSimpleObject = (props) => {
  const { facilitySelectElements } = useAppSelector(
    (state) => state.facilities
  );
  const { searchObject, setsearchObject } = props
  //console.log("options",options)
  return (
    <SelectGrouping

      setSearchValue={setsearchObject}
      searchValue={searchObject}
      options={[...facilitySelectElements]}
      //bgcolor={bgcolor}
      {...props}
    />
  );

};
export const SelectSimpleFloor = (props) => {
  const { floorOptionsSelectByFacility } = useAppSelector(
    (state) => state.facilities
  );

  return <SelectSimple options={floorOptionsSelectByFacility} {...props} />;
};

