import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Box, Chip, Typography } from "@mui/material";
import DateRangePickerNew from "../../components/DateRangePickerNew/DateRangePickerNew";
import { PRIORITY, STATUS, DEADLINE_STATUS_COLOR } from "./form-config";
import { dateFormat, dateFormatWithTimeWithoutA } from "../../../constants";

import { PATHS } from "../../../constants";

export const DateRangePicker = ({ placeholder, setValue, value }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (endDate != null) {
      if (startDate != null) setValue((prev) => [startDate, startDate]);
    }
  }, [endDate]);
  return (
    <DateRangePickerNew
      placeholder={placeholder}
      setValue={setValue}
      value={value}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
    />
  );
};

export const PriorityCell = ({ item }) => {
  const { bgColor, textColor } = PRIORITY[item] || {};

  return (
    <Box className="point_and_text_cell">
      <Typography
        className="point_and_text_cell-text"
        sx={{ color: textColor, backgroundColor: bgColor }}
      >
        {item}
      </Typography>
    </Box>
  );
};

export const StatusCell = ({ item }) => {
  const { bgColor, textColor } = STATUS[item] || {};

  return (
    <Chip
      className="chip"
      sx={{ backgroundColor: bgColor, color: `${textColor} !important` }}
      label={item}
    />
  );
};

export const SimpleTextCell = ({ item, type }) => {
  const content =
    type === "date"
      ? dayjs(item).format(dateFormat)
      : type === "dateTime"
        ? dayjs(item).format(dateFormatWithTimeWithoutA)
        : item;

  return <Typography>{content}</Typography>;
};

export const DeadlineStatusTextCell = ({ item }) => (
  <Box className="point_and_text_cell">
    <Box
      className="point_and_text_cell-point"
      sx={{ backgroundColor: DEADLINE_STATUS_COLOR[item] }}
    ></Box>
    <Typography className="point_and_text_cell-text">{item}</Typography>
  </Box>
);
export const EquipmentsCell = ({ item, navigate }) => {
  console.log("item", item);
  return item?.map(({ id, name }) => (
    <Typography
      key={id}
      className="table-row-cell-link"
      onClick={() => navigate(PATHS.GET_EQUIPMENT_DETAILS(id))}
    >
      {`${name}`}
    </Typography>
  ));
};

export const JobEquipmentsCell = ({ item, navigate }) => {
  return item?.map(({ id, integrationNumber, name }) => (
    <Typography
      key={id}
      className="table-row-cell-link"
      onClick={() => navigate(PATHS.GET_EQUIPMENT_DETAILS(id))}
    >
      {`${integrationNumber ? `${integrationNumber},` : ""} ${name}`}
    </Typography>
  ));
};

export const ClickableReplacementJobsCell = ({ item }) => {
  return item?.map(({ id, name, action }) => (
    <Typography
      key={id}
      className="table-row-cell-link"
      onClick={action ? action : () => {}}
    >
      {`${name}`}
    </Typography>
  ));
};
export const ClickableEquipmentCell = ({ item }) => {
  console.log("itemm", item);

  return item.length > 0 ? (
    item.map(({ id, name, action }, index) => (
      <Typography
        className="table-row-cell-link"
        onClick={action ? action : () => {}}
      >
        {index == item.length - 1
          ? `${index + 1}.${name}`
          : `${index + 1}. ${name},` || ""}
      </Typography>
    ))
  ) : (
    <></>
  );
};

export const ClickableJobNameCell = ({ item }) => {
  const { taskName, action } = item || {};

  return (
    <Typography
      className="table-row-cell-link"
      onClick={action ? action : () => {}}
    >
      {taskName || ""}
    </Typography>
  );
};

export const SimpleHHeader = ({ name }) => (
  <Box sx={{ paddingTop: "2px" }} className="table-head-up_level">
    <Typography className="standart">{name || ""}</Typography>
  </Box>
);
export const SimpleHeader = ({ name }) => (
  <Box className="table-head-up_level">
    <Typography className="standart">{name || ""}</Typography>
  </Box>
);
