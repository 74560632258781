import * as React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import "./styles/style.css";

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 24,
    left: "calc(-50% + 35px)",
    right: "calc(50% + 35px)",
  },
  // [`& .${stepConnectorClasses.line}`]: {
  //   borderColor: "var(--lightGrey)",
  //   borderTopWidth: 2,
  // },
}));

const StepperCustom = ({ steps, activeStep, setActiveStep, orientation }) => {
  const { t } = useTranslation();
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep - 1}
        alternativeLabel
        className="stepper"
        connector={<QontoConnector />}
        orientation={orientation}
      >
        {steps.map(({ step, label }) => (
          <Step key={label}>
            <StepButton
              color="inherit"
              onClick={handleStep(step)}
              sx={{display: "flex"}}
            >
              {t(`stepper_label:${label}`)}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperCustom;
