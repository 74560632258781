import { ROLES } from "../../../../constants";
import { getIsDisabledField } from "./options";

export const getConfigByRole = ({ roleId, config, isCompletelyStatus }) => {
  const roleUser = ROLES[roleId];
  let arr = config.map((item) => ({
    ...item,
    disabled: isCompletelyStatus || getIsDisabledField({ role: roleUser, name: item.name }),
    role: roleUser,
  }));
  console.log("arr",arr)
  return config.map((item) => ({
    ...item,
    disabled: isCompletelyStatus || getIsDisabledField({ role: roleUser, name: item.name }),
    role: roleUser,
  }));
};

export const getRequestData = (data) => {
  const { jobFiles, priority, status, equipmentMessages, jobChecklist } = data || {};
  const entities = {
    ...data, 
    priorityId: priority,
    statusId: status,
    jobChecklist: jobChecklist?.map(({ id, isCompleted }) => ({ id, isCompleted })) || [],
    equipmentMessages:
    equipmentMessages?.map(({ messageId, equipmentId, triggerCause }) => ({
        messageId,
        equipmentId,
        triggerCause,
      })) || [],
  };
  delete entities.jobFiles;

  return {entities, jobFiles};
};

export const getRequestDataFilesForAdd = (id, files) => {
  const formData = new FormData();

  if(!files) {
      return formData;
  }

  const { filesToAdd, filesToDelete } = files || {};
  let fileConfigIndex = 0;

  if(filesToAdd?.length) {
    filesToAdd.forEach((file, index) => {
        fileConfigIndex += index
        formData.append(`entityFileList[${fileConfigIndex}].EntityId`, id);
        formData.append(`entityFileList[${fileConfigIndex}].EntityField`, "Job");
        formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "Job");
        formData.append(`entityFileList[${fileConfigIndex}].File`, file);
        formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Add");
    })
    fileConfigIndex++
  }
  if(filesToDelete?.length) {
    filesToDelete.forEach((file, index) => {
        if (file.id) {
            fileConfigIndex += index
            formData.append(`entityFileList[${fileConfigIndex}].EntityId`, id);
            formData.append(`entityFileList[${fileConfigIndex}].EntityField`, "Job");
            formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "Job");
            formData.append(`entityFileList[${fileConfigIndex}].id`, file.id);
            formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Delete");
        }
    })
    fileConfigIndex++;
  }

  return formData;
};