import MyOrganizations from "./pages/MyOrganizations";
import AddOrganizationPage from "./pages/MyOrganizations/AddOrganizationPage";
import EditOrganizationPage from "./pages/MyOrganizations/EditOrganizationPage";
import ObjectsList from "./pages/ObjectsList";
import ObjectsOnMap from "./pages/ObjectsOnMap";
import { AddObjectTabsPage } from "./pages/ObjectsList/AddObjectTabsPage";
import { AddShortFireCompartmentPage } from "./pages/FireCompartments/AddShortFireCompartmentPage";
import EditFireCompartmentPage from "./pages/FireCompartments/EditFireCompartmentPage";
import AuthSteps from "./pages/LogIn/AuthSteps";
import React from "react";
import ResetPassword from "./pages/LogIn/ResetPassword";
import SetPassword from "./pages/LogIn/SetPassword";
import LogIn from "./pages/LogIn";
import EditObjectPage from "./pages/ObjectsList/EditObjectPage";
import AddEquipmentPage from "./pages/Equipments/AddEquipmentPage";
import AddEquipmentPageLite from "./pages/Equipments/AddEquipmentPage/AddequipmentPageLite";
import EquipmentList from "./pages/EquipmentList";
import ReglamentList from "./pages/ReglamentList";
import AddReglament from "./pages/ReglamentList/AddReglament";
import EditReglamentPage from "./pages/ReglamentList/EditReglamentPage";
import Equipments from "./pages/Equipments";
import ListFireCompartments from "./pages/FireCompartments/ListFireCompartments";
import TasksList from "./pages/TasksList";
import GroupTasks from "./pages/GroupTasks";
import Profile from "./pages/Profile";
import EquipmentDetails from "./pages/EquipmentDetails";
import {MagazineFinishedTasks} from "./pages/MagazineFinishedTasks";
import MainAnalytics from "./pages/Analytics/MainAnalytics";
import FacilityAnalytics from "./pages/Analytics/FacilityAnalytics";
import { EmployeeList } from "./pages/EmployeeList";

import { AddEmployee } from "./pages/EmployeeList/AddEmployee";
import { EditEmployee } from "./pages/EmployeeList/EditEmployee";
import { MagazineEvents } from "./pages/MagazineEvents";

import { PATHS } from "../constants";
import { TimeTable } from "./pages/TimeTable/TimeTable";
import { Plume } from "./pages/Plume";
import { MessageCodePage } from "./pages/MessageTemplatePage/MessageCodePage";
import { Registration } from "./pages/Registration/Registration";
import Acts from "./pages/Acts";
import ActsDocuments from "./pages/Acts/acts_documents";
import ActsPlanning from "./pages/ActsPlanning/PlanningTree";
import AddTask from "./pages/TasksList/AddTask";
import CreateAfaAct from "./pages/Acts/CreateAfaAct";
import CreateSnmeAct from "./pages/Acts/CreateSnmeAct";
import Controllers from "./pages/Controllers";
import AddController from "./pages/Controllers/AddController";
export const TASKS = "/tasks";
export const ORGANIZATIONS = "/organizations";
export const RESPONSIBLE = "/responsible";
export const OBJECTS = "/objects";
export const MAGAZINES = "/magazines";
export const ANALYTICS = "/analytics";
export const FIRE_COMPARTMENTS = "/fire_compartments";
export const EQUIPMENT = "/equipment";
export const TIMETABLE = "time_table";
export const DIGITIZATION = "/digitization";

export const excludeInBreadcrumb = [
  "/edit_organization",
  "/edit_object",
  "/edit_employee",
  "/edit_r_organization",
  "/completed_jobs_amount",
  "/needs_attention_jobs_amount",
  "/overdued_jobs_amount",
  "/all_completed",
  
];

export const rootPaths = [
  ORGANIZATIONS,
  OBJECTS,
  MAGAZINES,
  RESPONSIBLE,
  EQUIPMENT,
  DIGITIZATION,
  TASKS,
  FIRE_COMPARTMENTS,
];

export const AppRoutes = [
  {
    path: PATHS.MY_ORGANIZATIONS,
    component: <MyOrganizations />,
    exact: true,
    name: "legal_entities",
    requiresAuth: true,
    permission: "organizations",
  },
  {
    path: PATHS.ADD_MY_ORGANIZATION,
    component: <AddOrganizationPage />,
    exact: true,
    name: "add_organization",
    requiresAuth: true,
    permission: "organizationsadd-edit",
  },
  {
    path: PATHS.EDIT_MY_ORGANIZATION,
    component: <EditOrganizationPage />,
    exact: true,
    name: "add_organization",
    requiresAuth: true,
    permission: "organizations",
  },
  {
    path: PATHS.LIST_OBJECTS,
    component: <ObjectsList />,
    exact: true,
    name: "list_objects",
    requiresAuth: true,
    permission: "facilities",
  },
  {
    path: PATHS.LIST_EMPLOYEES,
    component: <EmployeeList />,
    exact: true,
    name: "employees",
    requiresAuth: true,
    permission: "users",
  },
  {
    path: PATHS.EDIT_EMPLOYEE,
    component: <EditEmployee />,
    exact: true,
    name: "edit_employee",
    requiresAuth: true,
    permission: "users",
  },
  {
    path: PATHS.ADD_EMPLOYEE,
    component: <AddEmployee />,
    exact: true,
    name: "add_employees",
    requiresAuth: true,
    permission: "users",
  },
  {
    path: PATHS.OBJECTS_MAP,
    component: <ObjectsOnMap />,
    exact: true,
    name: "objects_on_map",
    requiresAuth: true,
    permission: "facilities",
  },
  {
    path: PATHS.ADD_OBJECT,
    component: <AddObjectTabsPage />,
    exact: true,
    name: "add_object",
    requiresAuth: true,
    permission: "facilities",
  },
  {
    path: PATHS.EDIT_OBJECT,
    component: <EditObjectPage />,
    exact: true,
    name: "edit_object",
    requiresAuth: true,
    permission: "facilities",
  },
  {
    path: PATHS.ADD_FIRE_COMPARTMENT,
    // component: <AddFireCompartmentPage />,
    component: <AddShortFireCompartmentPage />,
    exact: true,
    name: "add_fire_compartment",
    requiresAuth: true,
    permission: "facilities",
  },
  {
    path: PATHS.LIST_FIRE_COMPARTMENTS,
    component: <ListFireCompartments />,
    exact: true,
    name: "list_fire_compartments",
    requiresAuth: true,
    permission: "facilities",
  },
  {
    path: PATHS.EDIT_FIRE_COMPARTMENT,
    component: <EditFireCompartmentPage />,
    exact: true,
    name: "edit_fire_compartment",
    requiresAuth: true,
    permission: "facilities",
  },
  {
    path: EQUIPMENT,
    component: <Equipments />,
    exact: true,
    name: "equipment",
    requiresAuth: true,
    permission: "equipments",
  },
  {
    path: PATHS.ADD_EQUIPMENT_LITE,
    component: <AddEquipmentPageLite />,
    exact: true,
    name: "add_equipment_lite",
    requiresAuth: true,
    permission: "equipmentsadd-edit",
  },
 /* {
    path: PATHS.ADD_EQUIPMENT,
    component: <AddEquipmentPage />,
    exact: true,
    name: "add_equipment",
    requiresAuth: true,
    permission: "equipmentsadd-edit",
  },
  */
  {
    path: PATHS.LIST_EQUIPMENT,
    component: <EquipmentList />,
    exact: true,
    name: "add_equipment",
    requiresAuth: true,
    permission: "equipments",
  },
  {
    path: PATHS.LIST_EQUIPMENT,
    component: <EquipmentList />,
    exact: true,
    name: "add_equipment",
    requiresAuth: true,
    permission: "equipments",
  },
  {
    path: PATHS.EDIT_EQUIPMENT_REGLAMENTS,
    component: <EditReglamentPage />,
    exact: true,
    name: "add_equipment",
    requiresAuth: true,
    permission: "equipments",
  },
  {
    path: PATHS.EQUIPMENT_REGLAMENTS,
    component: <ReglamentList />,
    exact: true,
    name: "add_equipment",
    requiresAuth: true,
    permission: "equipments",
  },
  {
    path: PATHS.ADD_EQUIPMENT_REGLAMENTS,
    component: <AddReglament />,
    exact: true,
    name: "add_equipment",
    requiresAuth: true,
    permission: "equipmentsadd-edit",
  },
  {
    path: PATHS.LIST_EQUIPMENT,
    component: <EquipmentList />,
    exact: true,
    name: "add_equipment",
    requiresAuth: true,
    permission: "equipments",
  },
  {
    path: PATHS.MESSAGE_TEMPLATE,
    component: <MessageCodePage />,
    exact: true,
    name: "message_template",
    requiresAuth: true,
    permission: "equipments",
  },
  
  {
    path: PATHS.EQUIPMENT_DETAILS,
    component: <EquipmentDetails />,
    exact: true,
    name: "equipment_details",
    requiresAuth: true,
    permission: "equipments",
  },
  {
    
    path: PATHS.LIST_TASKS,
    component: <TasksList />,
    exact: true,
    name: "tasks",
    requiresAuth: true,
    permission: "tasks",
  },
  {
    
    path: PATHS.GROUP_TASKS,
    component: <GroupTasks />,
    exact: true,
    name: "tasks",
    requiresAuth: true,
    permission: "tasks",
  },
  {
    path: PATHS.ADD_TASK,
    component: <AddTask />,
    exact: true,
    name: "add_task",
    requiresAuth: true,
    permission: "tasks",
  },

  {
    path: PATHS.TASKS_STATUS,
    component: <TasksList />,
    exact: true,
    name: "tasks",
    requiresAuth: true,
    permission: "tasks",
  },
  {
    path: PATHS.TASKS_EQUIP,
    component: <TasksList />,
    exact: true,
    name: "tasks",
    requiresAuth: true,
    permission: "tasks",
  },
  {
    path: PATHS.TASKS_NOTIF_TASK,
    component: <TasksList />,
    exact: true,
    name: "tasks",
    requiresAuth: true,
    permission: "tasks",
  },
  {
    path: PATHS.PROFILE_ROUTE,
    component: <Profile />,
    exact: true,
    name: "profile",
    requiresAuth: true,
    permission: "profile",
  },
  {
    path: PATHS.MAGAZINES_FINISHED_TASKS,
    component: <MagazineFinishedTasks />,
    exact: true,
    name: "magazines_finished_tasks",
    requiresAuth: true,
    permission: "logs",
  },
  {
    path: PATHS.MAGAZINES_EVENTS,
    component: <MagazineEvents />,
    exact: true,
    name: "magazines_events",
    requiresAuth: true,
    permission: "logs",
  },
  {
    path: PATHS.ANALYTICS,
    component: <MainAnalytics />,
    exact: true,
    name: "analytics",
    requiresAuth: true,
    permission: "analytics",
  },
  // {
  //   path: PATHS.TIMETABLE,
  //   component: <TimeTable />,
  //   exact: true,
  //   name: "time_table",
  //   requiresAuth: true,
  //   permission: "calendar-plan",
  // },
  {
    path: PATHS.FACILITY_ANALYTICS,
    component: <FacilityAnalytics />,
    exact: true,
    name: "facility_analytics",
    requiresAuth: true,
    permission: "analytics",
  },
  {
    path: PATHS.ACTS,
    component: <Acts />,
    exact: true,
    name: "act",
    requiresAuth: true,
    permission: "documentation",
  },
  {
    path: PATHS.PLUME_AND_ADDRESS,
    component: <Plume />,
    exact: true,
    name: "plume_and_address",
    requiresAuth: true,
    permission: "documentation",
  },
  {
    path: PATHS.FUNC_TESTS,
    component: <Acts />,
    exact: true,
    name: "func_tests",
    requiresAuth: true,
    permission: "documentation",
  },
  {
    path: PATHS.DOCUMENTS,
    component: <ActsDocuments />,
    exact: true,
    name: "documents",
    requiresAuth: true,
    permission: "documentation",
  },
  {
    path: PATHS.PLANNING,
    component: <ActsPlanning />,
    exact: true,
    name: "planning",
    requiresAuth: true,
    permission: "documentation",
  },
  {
    path: PATHS.CREATE_AFA_ACT,
    component: <CreateAfaAct />,
    exact: true,
    name: "create_afa_act",
    requiresAuth: true,
    permission: "documentation",
  },
  {
    path: PATHS.CREATE_SNME_ACT,
    component: <CreateSnmeAct />,
    exact: true,
    name: "create_snme_act",
    requiresAuth: true,
    permission: "documentation",
  },
  {
    path: PATHS.CONTROLLERS,
    component: <Controllers />,
    exact: true,
    name: "controllers",
    requiresAuth: true,
    permission: "control-console",
  },
  {
    path: PATHS.ADD_CONTROLLER,
    component: <AddController />,
    exact: true,
    name: "add_controller",
    requiresAuth: true,
    permission: "control-console",
  },
  {
    path: PATHS.CONTROLLER_MESSAGE_TEMPLATE,
    component: <MessageCodePage />,
    exact: true,
    name: "controller_message_template",
    requiresAuth: true,
    permission: "control-console",
  },
];

export const NonAuthRoutes = [
  {
    path: PATHS.LOGIN_ROUTE,
    component: (
      <LogIn>
        <AuthSteps />
      </LogIn>
    ),
    exact: true,
    name: "login",
    requiresAuth: false,
  },
  {
    path: PATHS.REGISTRATION_ROUTE,
    component: <Registration />,
    exact: true,
    name: "registration",
    requiresAuth: false,
  },
  {
    path: PATHS.RESET_PASSWORD_ROUTE,
    component: (
      <LogIn>
        <ResetPassword />
      </LogIn>
    ),
    exact: true,
    name: "reset_password",
    requiresAuth: false,
  },
  {
    path: PATHS.SET_PASSWORD_ROUTE,
    component: (
      <LogIn>
        <SetPassword />
      </LogIn>
    ),
    exact: true,
    name: "set_password",
    requiresAuth: false,
  },
];
