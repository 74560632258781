
export const formFields = {
  fireCompartmentId: "fireCompartmentId",
  object: "object",
  floor: "floor",
  name: "name",
  objectMain: "objectMain",
  floorMain:"floorMain",
  plume: "plume",
  number: "number",
  startNumber: "startNumber",
  endNumber: "endNumber",
  hasEquipmentGroup: "hasEquipmentGroup",
  equipmentsCount: "equipmentsCount",
  equipmentTypeId: "equipmentTypeId",
  integrationNumber: "integrationNumber",
  workingCorrectly: "workingCorrectly",
  manufactureDate: "manufactureDate",
  installationDate: "installationDate",
  contractDate: "contractDate",
  contractNumber: "contractNumber",
  shelfLife: "shelfLife",
  lastServiceDate: "lastServiceDate",
  performedServicePersonName: "performedServicePersonName",
  equipmentPassportFile: "equipmentPassportFile",
  years: "years",
  months: "months",
  selfService: "selfService",
  termOfContract: "termOfContract",
  equipmentContractFileIdAvailability: "equipmentContractFileIdAvailability",
  equipmentContractFileId: "equipmentContractFileId",
  responsibleOrganizationId: "responsibleOrganizationId",
  // for step 4
  floor: "floor",
  havingServiceContract: "havingServiceContract",
  regulationId: "regulationId",
  date: "date",
  period: "period",

  generalInformation: "generalInformation",
  regulations: "regulations",
  regulations_equip:"regulations_equip",
  select: "select",
  schema: "schema",
  ready: "ready",
  placement: "placement",
  floorScheme: "floorScheme",
  roomAddress: "roomAddress",
  equipmentsCoordinates: "equipmentsCoordinates",
  roomAddresses: "roomAddresses",
};
