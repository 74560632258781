export const getEmptyValue = (type) => {
    let emptyValue;

    switch (type) {
        case "checkbox":
            emptyValue = false;
            break;
        case "switch":
          emptyValue = true;
          break; 
        case "number":
        case "select-number":
        case "date":
        case "address":
            emptyValue = null;
            break;
        case "select-multi":
            emptyValue = [];
            break;
        case "select-text":
            emptyValue = "";
            break;
        default:
            emptyValue = "";
    }

    return emptyValue;
};