import { ObjectsSelect } from '../EmployeeList/form-config';

export const formFields = {
  facilitiesIds: "facilitiesIds",
};
export const modalConfig = [
  {
    id: 1,
    name: formFields.facilitiesIds,
    type: "select-multi",
    label: "label:objects",
  
    input: (props) => <ObjectsSelect {...props} />,
  },
];
