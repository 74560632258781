import React, { useState } from "react";
import { IconButton, InputAdornment, TextField,InputLabel,Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const InputPassword = ({ label, disabled = false, onChange, error, value, setValue, placeholder, ...rest }) => {

    const { t } = useTranslation();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const handleChangePassword = (e) => {
        onChange(e.target.value.replace(/\s/g, ""));
    };

    return (
        <Box sx={{ position: "relative" }} className="input_text">
            <InputLabel sx={{ mb: "8px" }}>
                {t(label)}
            </InputLabel>
           
            <TextField
                onChange={handleChangePassword}
                variant="outlined"
                error={!!error}
                placeholder={t(placeholder) || ""}
                helperText={error?.message ? t(`error_message:${error?.message}`) : ""}
                fullWidth
                value={value}
                InputLabelProps={{ shrink: true, }}
                disabled={disabled}
                sx={{
                    input: {
                        "&::-ms-reveal": { display: "none" },
                        "&::-ms-clear": { display: "none" }
                    },
                }}
                InputProps={{
                    type: showPassword ? "text" : "password",
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleClickShowPassword}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                {...rest}
            />
        </Box>
    );
};

export default InputPassword;
