// buildingPassport options
export const functionFireSecurityClasses = [
  {
    value: "Ф1.1",
    hint: " Ф1.1 - здания дошкольных образовательных организаций, специализированных домов престарелых и инвалидов (неквартирные), больницы, спальные корпуса образовательных организаций с наличием интерната и детских организаций;",
    name: "Ф1.1",
  },
  {
    value: "Ф1.2",
    hint: "Ф1.2 - гостиницы, общежития, спальные корпуса санаториев и домов отдыха общего типа, кемпингов, мотелей и пансионатов;",
    name: "Ф1.2",
  },
  { value: "Ф1.3", hint: "Ф1.3 - многоквартирные жилые дома;", name: "Ф1.3" },
  {
    value: "Ф1.4",
    hint: "Ф1.4 - одноквартирные жилые дома, в том числе блокированные;",
    name: "Ф1.4",
  },
  {
    value: "Ф2.1",
    hint: "Ф2.1 - театры, кинотеатры, концертные залы, клубы, цирки, спортивные сооружения с трибунами, библиотеки и другие учреждения с расчетным числом посадочных мест для посетителей в закрытых помещениях;",
    name: "Ф2.1",
  },
  {
    value: "Ф2.2",
    hint: "Ф2.2 - музеи, выставки, танцевальные залы и другие подобные учреждения в закрытых помещениях;",
    name: "Ф2.2",
  },
  {
    value: "Ф2.3",
    hint: "Ф2.3 - театры, кинотеатры, концертные залы, клубы, цирки, спортивные сооружения с трибунами, библиотеки и другие учреждения с расчетным числом посадочных мест для посетителей на открытом воздухе;",
    name: "Ф2.3",
  },
  {
    value: "Ф2.4",
    hint: "Ф2.4 - музеи, выставки, танцевальные залы и другие подобные учреждения на открытом воздухе;",
    name: "Ф2.4",
  },
  { value: "Ф3.1", hint: " Ф3.1 - здания организаций торговли;", name: "Ф3.1" },
  {
    value: "Ф3.2",
    hint: "Ф3.2 - здания организаций общественного питания;",
    name: "Ф3.2",
  },
  { value: "Ф3.3", hint: "Ф3.3 - вокзалы;", name: "Ф3.3" },
  { value: "Ф3.4", hint: "Ф3.4 - поликлиники и амбулатории;", name: "Ф3.4" },
  {
    value: "Ф3.5",
    hint: "Ф3.5 - помещения для посетителей организаций бытового и коммунального обслуживания с нерасчетным числом посадочных мест для посетителей;",
    name: "Ф3.5",
  },
  {
    value: "Ф3.6",
    hint: "Ф3.6 - физкультурно-оздоровительные комплексы и спортивно-тренировочные учреждения с помещениями без трибун для зрителей, бытовые помещения, бани;",
    name: "Ф3.6",
  },
  {
    value: "Ф4.1",
    hint: "Ф4.1 - здания общеобразовательных организаций, организаций дополнительного образования детей, профессиональных образовательных организаций;",
    name: "Ф4.1",
  },
  {
    value: "Ф4.2",
    hint: "Ф4.2 - здания образовательных организаций высшего образования, организаций дополнительного профессионального образования;",
    name: "Ф4.2",
  },
  {
    value: "Ф4.3",
    hint: "Ф4.3 - здания органов управления учреждений, проектно-конструкторских организаций, информационных и редакционно-издательских организаций, научных организаций, банков, контор, офисов;",
    name: "Ф4.3",
  },
  { value: "Ф4.4", hint: "Ф4.4 - здания пожарных депо;", name: "Ф4.4" },
  {
    value: "Ф5",
    hint: "1.5. Ф5 - пожарные отсеки производственного или складского назначения с категорией помещений по взрывопожарной и пожарной опасности В1 - В4, Г, Д, входящие в состав зданий с функциональной пожарной опасностью Ф1, Ф2, Ф3, Ф4, в том числе Ф5.2 - стоянки для автомобилей без технического обслуживания и ремонта.",
    name: "Ф5",
  },
];

export const timezones = [
  {
    value: 2,
    name: `GMT +2 (Калининград)`,
  },
  {
    value: 3,
    name: `GMT +3 (Москва)`,
  },
  {
    value: 4,
    name: `GMT +4 (Самара)`,
  },
  {
    value: 5,
    name: `GMT +5 (Екатеринбург)`,
  },
  {
    value: 6,
    name: `GMT +6 (Омск)`,
  },
  {
    value: 7,
    name: `GMT +7 (Красноярск, Новосибирск)`,
  },
  {
    value: 8,
    name: `GMT +8 (Иркутск)`,
  },
  {
    value: 9,
    name: `GMT +9 (Якутск)`,
  },
  {
    value: 10,
    name: `GMT +10 (Владвосток)`,
  },
  {
    value: 11,
    name: `GMT +11 (Магадан, Сахалин, Среднеколымск)`,
  },
  {
    value: 12,
    name: `GMT +12 (Анадырь, Камчатка)`,
  },
];
export const riskCategory = [
  {
    value: "Чрезвычайно-высокий",
    tooltip: "Объект чрезвычайно-высокого риска",
    className: "extremely-high",
  },
  {
    value: "Высокий",
    tooltip: "Объект высокого риска",
    className: "high",
  },
  {
    value: "Значительный",
    tooltip: "Объект значительного риска",
    className: "significant",
  },
  {
    value: "Средний",
    tooltip: "Объект среднего риска",
    className: "medium",
  },
  {
    value: "Умеренный",
    tooltip: "Объект умеренного риска",
    className: "moderate",
  },
  {
    value: "Низкий",
    tooltip: "Объект низкой категории риска",
    className: "low",
  },
];

// buildingCharacteristics options
export const potentialRadiationHazardOptions = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "Отсутствует",
];
export const fireResistanceDegreeOptions = ["I", "II", "III", "IV", "V"];
export const structuralFireHazardClassOptions = ["C0", "C1", "C2", "C3"];
export const fireAndExplosionHazard = [
  "АН",
  "БН",
  "ВН",
  "ГР",
  "ДН",
  "Отсутствует",
];
export const ventilationTypeOptions = [
  "Естественная",
  "Принудительная",
  "Противодымная вентиляция",
];

// facilitySystems options
export const SNCEOptions = ["1", "2", "3", "4", "5"];
export const AFASOptions = ["Адресная", "Безадресная"];
export const typesAlarmOptions = [
  { id: "Дымовые", name: "Дымовые" },
  { id: "Тепловые", name: "Тепловые" },
  { id: "Пламени", name: "Пламени" },
];

export const newTypesAlarmOptions = [
  { id: "ИП дымовой", name: "ИП дымовой" },
  { id: "ИП тепловой", name: "ИП тепловой" },
  { id: "ИП пламени", name: "ИП пламени" },
  { id: "ИП мультикритериальный", name: "ИП мультикритериальный" },
  { id: "ИП комбинированный", name: "ИП комбинированный" },
  { id: "ИП газовый", name: "ИП газовый" },
  { id: "ИП электроиндукционный", name: "ИП электроиндукционный" },
  { id: "ИП с видеоканалом", name: "ИП с видеоканалом" },
  { id: "ИП аспирационный", name: "ИП аспирационный" },
  { id: "ИПР - ручной", name: "ИПР - ручной" },
  { id: "ИП линейный дымовой", name: "ИП линейный дымовой" },
  {
    id: "ИП линейный тепловой невосстанавливаемый",
    name: "ИП линейный тепловой невосстанавливаемый",
  },
  {
    id: "ИП линейный тепловой восстанавливаемый",
    name: "ИП линейный тепловой восстанавливаемый",
  },
];
export const AFSSTOptions = [
  "Порошковая",
  "Водяная",
  "Тонкораспыленная вода ТРВ",
  "Аэрозольная",
  "Газовая",
];
export const voltageOptions = [220, 380];
export const typeWiringOptions = ["Скрытая", "Открытая"];
