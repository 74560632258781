import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const billingApi = createApi({
  reducerPath: "billing/api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getBillingInfo: build.query({
      query: ({ id, typeId }) => ({
        url: `api/billing/get-billing-info?organizationId=${id}&organizationTypeId=${typeId}`,
      }),
    }),
    addPayment: build.mutation({
      query: (data) => ({
        url: "/api/billing/add-payment",
        method: "POST",
        body: data,
      }),
    }),
    editBillingInfo: build.mutation({
      query: (data) => ({
        url: "/api/billing/edit-billing-info",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLazyGetBillingInfoQuery,
  useAddPaymentMutation,
  useGetBillingInfoQuery,
  useEditBillingInfoMutation,
} = billingApi;
