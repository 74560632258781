import React, { useEffect } from "react";
import FormItem from "../index";
import { useWatch } from "react-hook-form";
import { formFields } from "../constants";
import { getEmptyValue } from "../helpers";

const FormItemCheckboxControlled = ({
  input,
  name,
  label,
  control,
  errors,
  unregister,
  setValue,
  expended,
  sectionName,
  controlExpandedCheck,
  rules,
  type,
  formFieldss,
  disabled = false,
  trigger,
  ...rest
}) => {
  const checkbox = useWatch({
    control,
    name: sectionName ? `${sectionName}.${name}` : name,
  });
  const controlexpandedTwo = useWatch({
    control,
    name: `${sectionName}.${controlExpandedCheck}`,
  });

  useEffect(() => {
    if (!checkbox) {
      expended?.forEach(({ name: expendedName, type }) => {
        let expendedNameWithSection = expendedName;
        if (type === "file") {
          expendedNameWithSection = `${[formFields.files]}.${expendedName}`;
        } else if (sectionName) {
          expendedNameWithSection = `${sectionName}.${expendedName}`;
        }
        setValue(expendedNameWithSection, getEmptyValue(type));
      });
    }
  }, [unregister, checkbox, expended, setValue, sectionName]);

  return (
    <FormItem
      key={name}
      control={control}
      errors={errors}
      name={sectionName ? `${sectionName}.${name}` : name}
      label={label}
      input={input}
      rules={rules}
      type={type}
      setValue={setValue}
      disabled={disabled}
      trigger={trigger}
      {...rest}
    >
      {checkbox &&
        expended.map(
          ({ name: expendedName, label, input, expended, ...rest }) => (
            <FormItem
              key={expendedName}
              control={control}
              errors={errors}
              setValue={setValue}

              name={
                rest?.type === "file"
                  ? `${[formFields.files]}.${expendedName}`
                  : sectionName
                    ? `${sectionName}.${expendedName}`
                    : expendedName
              }
              label={label}
              input={input}
              rules={rules}
              type={type}
              disabled={disabled}
              trigger={trigger}
              {...rest}
            >
              {controlexpandedTwo && expended?.map(
                ({ name: expendedNName, label, input, expended, ...rest }) => (
                  <FormItem
                    key={expendedNName}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    name={
                      rest?.type === "file"
                        ? `${[formFields.files]}.${expendedNName}`
                        : sectionName
                          ? `${sectionName}.${expendedNName}`
                          : expendedNName
                    }
                    label={label}
                    input={input}
                    rules={{required: controlexpandedTwo ? true : false }}
                    type={type}
                    disabled={disabled}
                    trigger={trigger}
                    {...rest}
                  />
                 ))}
                  </FormItem>
                )
              )}
            </FormItem>
          );
};

      export default FormItemCheckboxControlled;
