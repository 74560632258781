import React from "react";
import { useDraggable } from "@dnd-kit/core";

const Draggable = (props) => {
  const { id, children, className, withOverlay } = props;
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <div
      ref={setNodeRef}
      style={!withOverlay && style || {}}
      {...listeners}
      {...attributes}
      className={className}
    >
      {children}
    </div>
  );
};

export default Draggable;
