import React from "react";
import {
  SimpleTextCell,
  DateFormatCell,SimpleTextWithTooltipCell,
  ExecutiveUserNameSelectSimple,
  InformationIcontWithTooltipCell,
  ReplacementTasksList,
  TechCardCell,
  AboutTaskTyp
} from "./Components";

import AutomationMessagesForm from "./AutomationMessagesForm";
import TaskDetailsForm from "./TaskDetailsForm";
import { FilesForm } from "./FilesForm";
import ChecklistForm from "./ChecklistForm";
import AboutTaskForm from "./AboutTaskForm";
import CommentsForm from "./CommentsForm";
import { t } from "i18next";
import { Box } from "@mui/material";
import CheckboxSimple from "../../../components/CheckboxSimple";

import { fileTypeCheck, fileSizeCheck } from "../../../../validation";

import EquipmentsForm from "./EquipmentsForm";
import SelectSimple from "../../../components/SelectSimple";
import { priorityOptions, statusOptions, triggerCauseOptions } from "./options";
import SelectMultipleChipDeletable from "../../../components/SelectMultipleChipDeletable";
import SelectSimpleColored from "../../../components/SelectSimpleColored/SelectSimpleColored";
import DatePickerField from "../../../components/DatePickerField";
import FormItem from "../../../components/FormItem";
import SelectSimpleInbuilt from "../../../components/SelectSimpleInbuilt";
import { InputFileMultiple } from "../../../components/InputFile/InputFileMultiple";
import { formFields } from "./options";
import { SignaturesTable } from "./SignaturesTable";
import { SignatureCell } from "../../../components/SignatureCell";
import InputTextarea from "../../../components/InputTextarea";
import { FloorSchemaImage } from "./FloorSchemaImage";
import PhotoIcon from '@mui/icons-material/Photo';

export const formConfigSection = [
  {
    name: formFields.status,
    label: "label:status",
    type: "text",
    height: "56px",
    rules: {},
    disabled: true,
    input: (props) => (
      <SelectSimpleColored {...props} options={statusOptions({ ...props })} />
    ),
  },
  {
    name: formFields.priority,
    label: "tableHeader:priority",
    type: "text",
    height: "56px",
    rules: {},
    disabled: true,
    input: (props) => <SelectSimple {...props} options={priorityOptions} />,
  },
];

export const statusConfig = [
  {
    name: formFields.status,
    label: "label:status",
    type: "text",
    height: "56px",
    rules: {},
    disabled: false,
    input: (props) => (
      <Box>
        <SelectSimple {...props} options={priorityOptions} />
      </Box>
    ),
  },
];

const EquipmentsSelect = (props) => {
  const options = [];
  console.log("equipmentsConfig",props)
  return <SelectMultipleChipDeletable isLink {...props} options={options} />;
};

export const automationMessagesColumns = ({ control }) => [
  {
    id: formFields.equipmentNumber,
    numeric: false,
    label: t("tableHeader:equipmentNumber"),
    enableSorting: false,
    clickable: true,
    RowCell: (props) => <SimpleTextCell {...props} />,
  },
  {
    id: formFields.messageDescription,
    numeric: false,
    label: t("tableHeader:event_class"),
    enableSorting: false,
    clickable: true,
    RowCell: (props) => <SimpleTextCell {...props} />,
  },
  {
    id: formFields.triggerCause,
    numeric: false,
    label: t("tableHeader:triggerCause"),
    enableSorting: false,
    clickable: true,
    RowCell: (props) => (
      <FormItem
        control={control}
        name={`${formFields.equipmentMessages}[${props.index}].${formFields.triggerCause}`}
        label={t("tableHeader:triggerCause")}
        options={triggerCauseOptions}
        sx={{ padding: "0px" }}
        width="235px"
        input={(props) => (
          <SelectSimpleInbuilt
            withEmptyField={true}
            {...props}
            className="select_in_built standart-light"
          />
        )}
      />
    ),
  },
  {
    id: formFields.reactionDateTime,
    numeric: false,
    label: t("tableHeader:reactionDateTime"),
    enableSorting: false,
    clickable: true,
    RowCell: (props) => <DateFormatCell {...props} />,
  },
];

export const equipmentsConfig = ({ control }) => ([
  {
    config: automationMessagesColumns({ control }),
    Component: AutomationMessagesForm,
  },
  {
    name: formFields.jobEquipments,
    label: "label:added_equipments",
    type: "select-multi",
    onClickEquip: true,
    input: (props) => <EquipmentsSelect {...props} />,
  },
  {
    name: formFields.replacementTasks,
    label: "label:added_equipments",
    input: (props) => <ReplacementTasksList {...props} />,
  },
  {
    Component: FloorSchemaImage,
  },
]);

export const taskDetailsConfig = [
  // {
  //   name: formFields.jobStandards,
  //   label: t("label:GOST"),
  //   type: "select-multi",
  //   disabled: true,
  //   input: (props) => <JobStandardsSelect {...props} />,
  // },
  {
    name: formFields.executiveUserId,
    label: t("label:executiveUserName"),
    type: "select-text",
    disabled: true,
    rules: {},
    input: (props) => <ExecutiveUserNameSelectSimple {...props} />,
  },
  {
    name: formFields.deadline,
    label: t("label:deadline"),
    type: "date",
    rules: {},
    disabled: true,
    input: (props) => (
      <Box className="medium_container">
        <DatePickerField {...props} />
      </Box>
    ),
  },

  {
    name: formFields.description,
    label: t("label:description"),
    type: "text",
    variant: "standard",
    rules: {},
    disabled: true,
    input: (props) => <InputTextarea {...props} />,
  },
];
export const CommentsConfig = [

  {
    name: formFields.comments,
    label: t("label:comment"),
    type: "text",
    disabled: false,
    input: (props) => <InputTextarea {...props} />,
  },

];

export const filesConfig = [
  {
    id: 1,
    name: formFields.jobFiles,
    type: "file",
    rules: {
      validate: {
        type: (files) => {
          const availableFileTypes = [
            "image/jpeg",
            "image/png",
            "application/pdf",
          ];
          return fileTypeCheck(
            files.filesToAdd,
            formFields.jobFiles,
            availableFileTypes
          );
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files.filesToAdd,
            formFields.jobFiles,
            maxAvailableFileSize
          );
        },
      },
    },
    canDeleteFile: false,
    input: (props) => (
      <InputFileMultiple {...props} accept={".png, .jpg, .jpeg, .pdf"} />
    ),
  },
];

export const checkListColumns = ({ control, isCompletelyStatus }) => [
  {
    id: formFields.isCompleted,
    type: "checkbox",
    rules: {},
    clickable: true,
    RowCell: (props) => (
      <FormItem
        name={`${formFields.jobChecklist}[${props.index}].${formFields.isCompleted}`}
        control={control}
        disabled={isCompletelyStatus}
        input={(props) => <CheckboxSimple {...props} />}
      />
    ),
  },
  {
    id: formFields.name,
    numeric: false,
    label: "Вид работ",
    enableSorting: false,
    clickable: true,
    RowCell: (props) => <SimpleTextCell {...props} />,
  },
  {
    id: formFields.description,
    numeric: false,
    label: "Состав работ",
    enableSorting: false,
    clickable: true,
    RowCell: (props) => <SimpleTextWithTooltipCell {...props} />,
  },
 /* {
    id: formFields.regulationName,
    numeric: false,
    label: "Регламенты",
    enableSorting: false,
    clickable: true,
    RowCell: (props) => <SimpleTextCell {...props} />,
  },
  /*{
    id: formFields.gost,
    numeric: false,
   
    enableSorting: false,
    clickable: true,
    RowCell: (props) => <InformationIcontWithTooltipCell {...props} />,
  },*/
  {
    id: formFields.techcard,
    numeric: false,
    label: "ТК",
    value:"ТК",
    enableSorting: false,
    clickable: true,
    RowCell: (props) => <TechCardCell {...props} />,
  },
  {
    id: formFields.files,
    numeric: false,
    label: "Фото",
    enableSorting: false,
    clickable: true,
    RowCell: (props) => (
      <PhotoIcon sx={{    marginTop: "5px",color:"var(--main)"}}/>
    ),
  },
  
];

export const signaturesColumns = [
  {
    id: formFields.signatureDateTime,
    numeric: false,
    label: t("tableHeader:signatureDateTime"),
    enableSorting: false,
    clickable: false,
    size: "250px",
    RowCell: (props) => <DateFormatCell {...props} />,
  },
  {
    id: formFields.signatureImage,
    numeric: false,
    label: t("tableHeader:signatureImage"),
    enableSorting: false,
    clickable: false,
    RowCell: (props) => <SignatureCell {...props} />,
  },
];
export const aboutTask = [
  {
    name: formFields.createdAt,
    label: t("label:createdAt"),
    input: (props) => (
        <AboutTaskTyp {...props} />
    ),
  },
  {
    name: formFields.deadlineDate,
    label: t("label:deadlineDate"),
    input: (props) => (
        <AboutTaskTyp {...props} />
    ),
  },
  {
    name: formFields.startedAt,
    label: t("label:startDate"),
    input: (props) => (
      
        <AboutTaskTyp {...props} />
      
    ),
  },
  {
    name: formFields.completedAt,
    label: t("label:endDate"),
    input: (props) => (
    
        <AboutTaskTyp {...props} />
     
    ),
  },
];

export const sections = ({ control, isHidden, isCompletelyStatus }) => [
  {
    id: 0,
    title: "signatures",
    hidden: isHidden,
    Component: SignaturesTable,
    config: signaturesColumns,
  },
  {
    id: 1,
    title: "equipments",
    Component: EquipmentsForm,
    config: equipmentsConfig({ control }),
  },
  {
    id: 2,
    title: "tasks_details",
    hidden: isHidden,
    Component: TaskDetailsForm,
    config: taskDetailsConfig,
  },
  {
    id: 3,
    title: "comments",
    Component: CommentsForm,
    config: CommentsConfig ,
  },
  {
    id: 4,
    title: "files",
    Component: FilesForm,
    config: filesConfig,
  },
  {
    id: 5,
    title: "check_list",
    Component: ChecklistForm,
    config: checkListColumns({ control, isCompletelyStatus }),
  },
  {
    id: 6,
    title: "about_task",
    Component: AboutTaskForm,
    config: aboutTask,
  },
];
