import React from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AccordionSection = ({ title, children }) => {
  const { t } = useTranslation();

  return (
    <Accordion
      square={true}
      disableGutters={true}
      sx={{
        background: "var(--transparent)",
        border: "none",
        boxShadow: "none",
        borderTop: "2px solid #E2E8F0",
      }}
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          flexDirection: "row-reverse",
          padding: '0px',
          svg: { color: "var(--black)" },
        }}
      >
        <Typography className="form-section-label">
          {t(`title:${title}`)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "16px 0", background: "var(--transparent)" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionSection;
