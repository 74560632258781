import { t } from "i18next";
import CheckboxSimple from "../../components/CheckboxSimple";

export const formFields = {
  taskname: "taskname",
  executiveUsers:"executiveUsers",
  responsibleCustomerUsers:"responsibleCustomerUsers",
  ResponsionCustomerUserName:"ResponsionCustomerUserName",
  PlanPeriodId:"PlanPeriodId",
  ExecutiveUserName:"ExecutiveUserName",
  TaskName:"TaskName",
  equipmentKindName:"equipmentKindName",
  EquipmentKindId:"EquipmentKindId",
  taskName:"taskName",
  searchParams: "searchParams",
  act: "act",
  system: "system",
  protectionObjectName: "protectionObjectName",
  protectionObjectId:"protectionObjectId",
  periodDate: "periodDate",
  status: "status",
  resUser: "resUser",
  resCreator: "resCreator",
}

export const allColumns = [
  { name: formFields.taskname, showName: "group_task", value: true },
  { name: formFields.act, value: true, showName: "act" },
  { name: formFields.system, showName: "system", value: true },
  { name: formFields.protectionObjectName, showName: "protectionObjectName", value: true },
  { name: formFields.periodDate, showName: "periodDate", value: true },
  { name: formFields.status, showName: "status", value: true },
  { name: formFields.resUser, showName: "resUser", value: true },
  { name: formFields.resCreator, showName: "resCreator", value: true },
];

export const getConfigFormModalSelect = (columns) =>
columns.map(({ name }) => ({
    name,
    label: `tableHeader:${name}`,
    type: "checkbox",
    rules: {},
    flexBasis: "50%",
    input: (props) => <CheckboxSimple {...props} />,
  }));



