// libs imports
import React from 'react';
import {fileSizeCheck, fileTypeCheck} from "../../../../../validation";
import {Box, Typography} from "@mui/material";
import {t} from "i18next";
import InputFileSingle from "../../../../components/InputFile/InputFileSingle";

export const formFields = {
    floorSchema: "floorSchema",
};

export const MissingFloorSchemaFormFormConfig = [
    {
        name: formFields.floorSchema,
        type: "file",
        rules: {
            validate: {
                type: (files) => {
                    const availableFileTypes = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
                    return fileTypeCheck(
                        files.filesToAdd,
                        formFields.floorSchema,
                        availableFileTypes
                    );
                },
                size: (files) => {
                    const maxAvailableFileSize = 10; // MBs
                    return fileSizeCheck(
                        files.filesToAdd,
                        formFields.floorSchema,
                        maxAvailableFileSize
                    );
                },
            },
        },
        canDeleteFile: false,
        preview: false,
        input: (props) => (
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Typography className="label">
                    {t("label:image_missing")}
                </Typography>
                <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} direction="column" />
            </Box>
        ),
    }
];