import { Main } from "./Main";
import { t } from "i18next";
import Switcher from "../../components/Switcher";
import DatePickerField from "../../components/DatePickerField";
import SelectSimple from "../../components/SelectSimple";
import { DateFormatCell } from "../EquipmentDetails/Components";
import { SimpleTextCell } from "../EquipmentDetails/Components";
import { PaymentHistory } from "./PaymentHistory";
import InputText from "../../components/InputText";
import { Payment } from "./Payment";

export const formFields = {
  isBlocked: "isBlocked",
  balance: "balance",
  billingPeriod: "billingPeriod",
  billingRateId: "billingRateId",
  operation: "operation",
  operationDate: "operationDate",
  paymentReuest: "paymentReuest",
  amount: "amount",
  storageCapacity: "storageCapacity",
};

export const timezones = [
  {
    value: 1,
    name: `Тариф простой`,
  },
  {
    value: 2,
    name: `Тариф расширенный`,
  },
  {
    value: 3,
    name: `Тариф Про`,
  },
];

export const storageCapacity = [
  {
    value: 100,
    name: `100Мб`,
  },
  {
    value: 250,
    name: `250Мб`,
  },
  {
    value: 500,
    name: `500Мб`,
  },
];

export const blockingOptions = [
  { status: true, name: t("label:yes") },
  { status: false, name: t("label:no") },
];

export const mainConfig = [
  {
    id: 1,
    name: formFields.isBlocked,
    label: "label:blocking",
    type: "switch",
    options: blockingOptions,
    rules: {},
    input: (props) => <Switcher {...props} />,
  },
  {
    id: 2,
    name: formFields.billingRateId,
    label: "label:fare",
    type: "select-number",
    options: timezones,
    displayEmpty:true,
    placeholder: "placeholder:tarif",
    rules: {},
    flexBasis: "40%",
    sx: { paddingTop: "18px" },
    input: (props) => <SelectSimple {...props} />,
  },
  {
    id: 3,
    name: formFields.billingPeriod,
    label: "label:calculationPeriod",
    type: "date",
    rules: {
      required: true,
    },
    flexBasis: "40%",
    input: (props) => <DatePickerField {...props} />,
  },
  {
    id: 4,
    name: formFields.storageCapacity,
    label: "Объем хранения данных",
    type: "select-number",
    displayEmpty:true,
    placeholder: "placeholder:storage_capacity",
    options: storageCapacity,
    rules: {},
    flexBasis: "40%",
    sx: { paddingTop: "18px" },
    input: (props) => <SelectSimple {...props} />,
  },
];

export const paymentHistoryColumns = [
  {
    id: formFields.operationDate,
    numeric: false,
    label: t("tableHeader:paymentDate"),
    enableSorting: false,
    clickable: true,
    textCenter: false,
    size: "350px",
    RowCell: (props) => <DateFormatCell {...props} />,
  },
  {
    id: formFields.operation,
    numeric: false,
    label: t("tableHeader:payment"),
    enableSorting: false,
    clickable: true,
    textCenter: false,
    size: "350px",
    RowCell: (props) => <SimpleTextCell {...props} />,
  },
];

export const paymentConfig = [
  {
    id: 1,
    name: formFields.amount,
    label: t("label:sum_payment"),
    placeholder: "placeholder:amount",
    type: "number",
    rules: {
      required: true,
      customValidate: (v) => /^-?(\d)*(?:\.\d{0,2})?$/.test(v), // negative or positive fractional numbers
    },
    flexBasis: "100%",
    input: (props) => <InputText {...props} />,
  },
];

export const sections = [
  {
    id: 0,
    Component: Main,
    config: mainConfig,
  },
];

export const historySections = [
  {
    id: 2,
    title: "payment_history",
    Component: PaymentHistory,
    config: paymentHistoryColumns,
  },
];

export const paymentSection = [
  {
    id: 1,
    Component: Payment,
    config: paymentConfig,
  },
];
