import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Button } from "@mui/material";
import {
  List,
  ListItem,
  ListItemButton,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import FormItem from "../../../components/FormItem";
import { LimitedTypography } from "../../../components/LimitedTypography";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  useAddTaskCommentMutation,
  useLazyGetTaskCommentsQuery,
  useGetTaskCommentsQuery,
} from "../../../../middleware/tasksApi";
import { useAction } from "../../../../hooks/actions";

const CommentsForm = ({
  control,
  taskId,
  getValues,
  setValue,
  errors,
  data,
  config,
  params,
}) => {
  const { t } = useTranslation();
  const [pageNumber, changePageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const dateFormat = "DD.MM.YYYY HH:mm:ss";
  const [commentsList, setcommentsList] = useState({});
  const { setIsErrorSubmit, setIsSuccessSubmit, setIsErrorRequest } =
    useAction();
  const [
    getTaskComments,
    {
      error: errorgetTaskComments,
      isSuccess: isSuccessgetTaskComments,
      isLoading: isLoadinggetTaskComments,
    },
  ] = useLazyGetTaskCommentsQuery();
  const [
    addTaskComment,
    { error: errorAddComment, isSuccess: isSuccessAddComment },
  ] = useAddTaskCommentMutation();
 
  const fetchData = async () => {
    try {
      const response = await getTaskComments({
        jobId: taskId,
        pageNumber: 0,
      });
      setcommentsList({
        ...response?.data,
        hasMore:
          response?.data?.data.length < response?.data.recordsCount
            ? true
            : false,
      });
    } catch (error) {
      console.log("error");
    }
  };
  const morefetchData = () => {
    setLoading(true);
    setTimeout(() => {
      getTaskComments({
        jobId: taskId,
        pageNumber: pageNumber + 1,
      }).then(({ data, isSuccess }) => {
        changePageNumber((prev) => prev + 1);
        console.log("error", data.data, {
          ...commentsList,
          hasMore:
            data?.data.length + commentsList?.data.length <
            data.data.recordsCount
              ? true
              : false,
          data: { ...commentsList?.data, ...data?.data },
        });
        setcommentsList({
          ...commentsList,
          hasMore:
            data?.data.length + commentsList?.data.length <
            data.data.recordsCount
              ? true
              : false,
          data: [...commentsList?.data, ...data.data],
        });
        setLoading(false);
      });
    }, 1000);
  };
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control]);
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
    if (bottom >= 1 && bottom <= 50 && !loading && commentsList?.hasMore) {
      morefetchData();
    }
  };

  const addComment = () => {
    addTaskComment({ comment: getValues("comments"), jobId: taskId }).then(
      (data) => {
        setValue("comments", "");
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },
      []
    );
  };
  useEffect(() => {
    setIsErrorSubmit(errorAddComment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAddComment]);
  useEffect(() => {
    setIsErrorRequest(errorAddComment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAddComment]);
  useEffect(() => {
    setIsSuccessSubmit(isSuccessAddComment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAddComment]);
  console.log("commentsList", commentsList);
  return (
    <Box className="task_details_form">
      {commentsList != {} && commentsList?.data ? (
        <List
          onScroll={handleScroll}
          sx={{ maxHeight: "250px", overflowY: "auto", marginBottom: "15px" }}
        >
          {commentsList?.data.map(
            ({ id, createdAt, comment, userFullName }, index) => (
              <ListItem sx={{ paddingLeft: "2px !important" }} key={id}>
                <Card sx={{ width: "100%" }}>
                  <ListItemButton>
                    <CardContent sx={{ width: "100%", padding: "10px" }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "var(--INTER)",
                            color: "var(--main)",
                            fontWeight: "bold",
                          }}
                          component="div"
                        >
                          {userFullName}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "var(--INTER)",
                            color: "gray",
                            fontWeight: "bold",
                            fontSize: "12px",
                            alignSelf: "center",
                          }}
                          component="div"
                        >
                          {dayjs(createdAt).format(dateFormat)}
                        </Typography>
                      </Box>

                      <LimitedTypography key={index} fullText={comment} />
                    </CardContent>
                  </ListItemButton>
                </Card>
              </ListItem>
            )
          )}
          {loading && (
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              key={"loading"}
            >
              <CircularProgress />
            </ListItem>
          )}
        </List>
      ) : (
        <Typography mt={"10px"} sx={{ marginBottom: "10px" }} variant="body2">
          Комментариев пока нет
        </Typography>
      )}
      {config.map(({ name, label, input, ...rest }) => (
        <FormItem
          key={name}
          control={control}
          errors={errors}
          name={name}
          label={label}
          input={input}
          data={data}
          params={params}
          {...rest}
        />
      ))}
      <Button
        className="btn btn-outlined-primary"
        variant="outlined"
        onClick={addComment}
      >
        {t("button:add_comment")}
      </Button>
    </Box>
  );
};

//   useEffect(() => {
//     fetchData();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);
//   useEffect(() => {
//     setIsErrorSubmit(errorAddComment);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [errorAddComment]);
//   useEffect(() => {
//     setIsErrorRequest(errorAddComment);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [errorAddComment]);
//   useEffect(() => {
//     setIsSuccessSubmit(isSuccessAddComment);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [isSuccessAddComment]);
//   console.log("commentsList", commentsList);
//   return (
//     <Box className="task_details_form">
//       {commentsList != {} && commentsList?.data ? (
//         <List
//           onScroll={handleScroll}
//           sx={{ maxHeight: "250px", overflowY: "auto", marginBottom: "15px" }}
//         >
//           {commentsList?.data.map(
//             ({ id, createdAt, comment, userFullName }, index) => (
//               <ListItem sx={{ paddingLeft: "2px !important" }} key={id}>
//                 <Card sx={{ width: "100%" }}>
//                   <ListItemButton>
//                     <CardContent sx={{ width: "100%", padding: "10px" }}>
//                       <Box
//                         sx={{
//                           width: "100%",
//                           display: "flex",
//                           justifyContent: "space-between",
//                         }}
//                       >
//                         <Typography
//                           sx={{
//                             fontFamily: "var(--INTER)",
//                             color: "var(--main)",
//                             fontWeight: "bold",
//                           }}
//                           component="div"
//                         >
//                           {userFullName}
//                         </Typography>
//                         <Typography
//                           sx={{
//                             fontFamily: "var(--INTER)",
//                             color: "gray",
//                             fontWeight: "bold",
//                             fontSize: "12px",
//                             alignSelf: "center",
//                           }}
//                           component="div"
//                         >
//                           {dayjs(createdAt).format(dateFormat)}
//                         </Typography>
//                       </Box>

//                       <LimitedTypography key={index} fullText={comment} />
//                     </CardContent>
//                   </ListItemButton>
//                 </Card>
//               </ListItem>
//             )
//           )}
//           {loading && (
//             <ListItem
//               sx={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//               }}
//               key={"loading"}
//             >
//               <CircularProgress />
//             </ListItem>
//           )}
//         </List>
//       ) : (
//         <Typography mt={"10px"} sx={{ marginBottom: "10px" }} variant="body2">
//           Комментариев пока нет
//         </Typography>
//       )}
//       {config.map(({ name, label, input, ...rest }) => (
//         <FormItem
//           key={name}
//           control={control}
//           errors={errors}
//           name={name}
//           label={label}
//           input={input}
//           data={data}
//           params={params}
//           {...rest}
//         />
//       ))}
//       <Button
//         className="btn btn-outlined-primary"
//         variant="outlined"
//         onClick={addComment}
//       >
//         {t("button:add_comment")}
//       </Button>
//     </Box>
//   );
// };

export default CommentsForm;
