import React, { useEffect, useState } from "react";
import {Paper, IconButton, InputBase} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from '@mui/icons-material/Search';

const Search = ({ changeSearchValue, searchValue, placeholder }) => {

  function useDebounce(value, delay = 300) {
    const [debounced, setDebounced] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => setDebounced(value), delay);
      return () => clearTimeout(handler);
    }, [value, delay]);
    return debounced;
  }

  const [search, setSearch] = useState(searchValue);
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    changeSearchValue(debouncedSearch);
  }, [debouncedSearch]); // eslint-disable-line

  return (
    <Paper
      component="form"
      className="table_search_form"
      elevation={0}
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 398,
        height: 40,
        margin: "12px 0",
        border: "1px solid #E0E0E0",
        borderRadius: "4px",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1, fontSize: "14px" }}
        placeholder={placeholder}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            ev.preventDefault();
          }
        }}
      />
      {search ? (
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="clean search"
          onClick={(event) => setSearch("")}
        >
          <ClearIcon sx={{ fontSize: 16 }} />
        </IconButton>
      ) : (
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      )}
    </Paper>
  );
};

export default Search;