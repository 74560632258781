import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { PATHS } from '../../../constants';
import { LAST_VALUE_TAB, INIT_VALUE_TAB } from "./constants";

const ButtonsField = ({
  isEmptyPage = false,
  handleCancel,
  handleSubmit,
  isSubmitDisabled,
  value,
  readonly,
  handleStep,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isFirstTab = value === INIT_VALUE_TAB;
  const isLastTab = value === LAST_VALUE_TAB;

  return (
    <Box className="object-header-buttons">
      {!isEmptyPage ? (
        <>
          {!readonly && (isFirstTab || isLastTab) && (
            <Button
              className="btn btn-outlined-grey"
              onClick={handleCancel}
            >
              {t("button:cancel")}
            </Button>
          )}

          {!readonly && !isFirstTab && (
            <Button
              className="btn btn-outlined-primary"
              onClick={() => handleStep(value - 1)}
            >
              {t("button:to_back")}
            </Button>
          )}

          {!readonly && !isLastTab && (
            <Button
              className="btn btn-outlined-primary"
              onClick={() => handleStep(value + 1)}
            >
              {t("button:next")}
            </Button>
          )}
          {!readonly &&
            <Button
              name="submit"
              form="myform"
              className="btn btn-primary-fill"
              disabled={isSubmitDisabled}
              onClick={handleSubmit}
            >
              {t("button:save")}
            </Button>
          }
        </>
      ) : (
        <Button
          className="btn btn-outlined-primary"
          variant="outlined"
          onClick={() => navigate(PATHS.ADD_MY_ORGANIZATION)}
        >
          {t("button:add_account_entity")}
        </Button>
      )}
    </Box>
  );
};

export default ButtonsField;
