import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import { PATHS } from "../../../../constants";
import { useAppSelector } from "../../../../hooks/redux";
import { useAction } from "../../../../hooks/actions";

import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";

const stepForms = [FirstStep, SecondStep];

const AuthSteps = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { authStep } = useAppSelector((state) => state.logs);
  const { setAuthStep } = useAction();

  const CurrentStepForm = useMemo(() => stepForms[authStep], [authStep]);

  return (
    <>
      <Box>
        <Typography
          className="login-title-auth"
          sx={{ mb: "16px" }}
          variant={"h4"}
        >
          {t("label:authorization")}
        </Typography>
        <Typography className="default-label-text">
          {t("label:logIntoAccount")}
        </Typography>
      </Box>
      <Box>
        <CurrentStepForm setAuthStep={setAuthStep} />
        <Typography sx={{ mt: "15px", fontWeight: "500" }} className="default-label-text">
          {`${t("label:havingAccountQuestion")} `}
          <Button
            className="btn btn-link"
            sx={{ verticalAlign: "initial"}}
            onClick={() => navigate(PATHS.REGISTRATION_ROUTE)}
          >
            {t("button:registrationRequest")}
          </Button>
        </Typography>
      </Box>
    </>
  );
};

export default AuthSteps;
