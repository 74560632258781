import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ru from "./ru.json";

i18n.use(initReactI18next).init({
  fallbackLng: "ru",
  lng: "ru",
  debug: false,
  resources: { ru },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export { i18n };
