import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const analyticsApi = createApi({
  reducerPath: "analytics/api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getSystemsUnderControlCharData: build.query({
      query: () => ({
        url: "api/analytics/get-systems-under-control-chart-data",
      }),
    }),
    getJobAnalytics: build.query({
      query: ({ startDate, endDate }) => ({
        url: `api/analytics/get-job-analytics?startDate=${startDate}&endDate=${endDate}`,
      }),
    }),
    getJobStatistics: build.query({
      query: ({ startDate, endDate }) => ({
        url: `api/analytics/get-job-statistics-by-date-chart-data?startDate=${startDate}&endDate=${endDate}`,
      }),
    }),
    getEmployeesStatistics: build.query({
      query: ({ startDate, endDate }) => ({
        url: `api/analytics/get-exec-users-analytics?startDate=${startDate}&endDate=${endDate}`,
      }),
    }),
    getPerformanceStatistics: build.query({
      query: ({ startDate, endDate }) => ({
        url: `api/analytics/get-systems-performance-statistics?startDate=${startDate}&endDate=${endDate}`,
      }),
    }),
    getObjectRating: build.query({
      query: (objectId) => ({
        url: `api/analytics/get-protection-object-analytics?protectionObjectId=${objectId}`,
      }),
    }),
    getSystemOverallAnalytics: build.query({
      query: () => ({
        url: `api/analytics/get-system-overall-analytics`,
      }),
    }),
    getAllObjectsAnalytics: build.query({
      query: () => ({
        url: `api/analytics/get-all-objects-analytics`,
      }),
    }),
  }),
});

export const {
  useGetSystemsUnderControlCharDataQuery,
  useGetJobAnalyticsQuery,
  useGetAllObjectsAnalyticsQuery,
  useGetSystemOverallAnalyticsQuery,
  useGetJobStatisticsQuery,
  useGetEmployeesStatisticsQuery,
  useGetPerformanceStatisticsQuery,
  useGetObjectRatingQuery,
} = analyticsApi;
