import {alarmLoopBreakandShortCircuit} from "./alarmLoopBreakandShortCircuit";
//import {alarmLoopShortCircuitFormConfig} from "./alarmLoopShortCircuit";
import {smokeDetectorsFormConfig} from "./smokeDetectors";
import {heatDetectorsFormConfig} from "./heatDetectors";
import {linearDetectorsFormConfig} from "./linearDetectors";
import {manualDetectorsFormConfig} from "./manualDetectors";
import {flameDetectorsFormConfig} from "./flameDetectors";
import {cableInsulationResistanceFormConfig} from "./cableInsulationResistance";
//import {systemInertiaFormConfig} from "./systemInertia";
import {RABWithoutMainsSupplyFormConfig} from "./RABWithoutMainsSupply";
import {RABCapacityFormConfig} from "./RABCapacity";
import {voltageCurrentPowerSupplyFormConfig} from "./voltageCurrentPowerSupply";

export const formConfig = [
  //alarmLoopBreakandShortCircuit,
  //alarmLoopShortCircuitFormConfig,
  alarmLoopBreakandShortCircuit,
  smokeDetectorsFormConfig,
  heatDetectorsFormConfig,
  linearDetectorsFormConfig,
  manualDetectorsFormConfig,
  flameDetectorsFormConfig,
  cableInsulationResistanceFormConfig,
 // systemInertiaFormConfig,
  RABWithoutMainsSupplyFormConfig,
  RABCapacityFormConfig,
  voltageCurrentPowerSupplyFormConfig
];