
import React, { useState } from "react";
import { useGetRegionsQuery } from "../../../middleware/usersApi";
import SelectSimpleSearch from "../../components/SelectSimple/SelectSimpleSearch";

export const SelectSimpleGetRegoinsSearch = (props) => {
  const [searchValue, setSearchValue] = useState("");

  const { data = [] } = useGetRegionsQuery({ name:searchValue });
  const options = data.map(({ name, id }) => ({
    name,
    value: id,
  }));

  return (
     <SelectSimpleSearch sx={{ ".MuiTypography-root": { padding:0 } }} {...props}  searchValue={searchValue}
      setSearchValue={setSearchValue} options={options} />
  );
};
