import { createSlice } from "@reduxjs/toolkit";
import { responsibleOrganizationsApi } from "../../middleware/responsibleOrganizationsApi";

const initialState = {
    allResponsibleOrganizations: [],
};

export const responsibleOrganizationsSlice = createSlice({
    name: "responsibleOrganizations",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            responsibleOrganizationsApi.endpoints.getResponsibleOrganizationsTable.matchFulfilled,
            (state, { payload }) => {
                state.allResponsibleOrganizations = payload;
            }
        );
    },
});

export const responsibleOrganizationsActions = responsibleOrganizationsSlice.actions;
export const responsibleOrganizationsReducer = responsibleOrganizationsSlice.reducer;
