import React, { useMemo } from "react";
import { Box } from "@mui/material";

import { useAppSelector } from "../../../../hooks/redux";
import { useAction } from "../../../../hooks/actions";
import { formFields } from "../form-config";

import TableBasic from "../../../components/TableBasic";

const ExecutiveUserNameForm = ({ config, data }) => {
  const { pageNumber } = useAppSelector((state) => state.settings);
  const { changePageNumber } = useAction();

  const rows = useMemo(() => {
    const { jobs } = data || {};

    return jobs?.length
      ? jobs.map(
          ({ id, executor, executorPosition, executorOrganization, name, description, checklists,lastComment }) => ({
            [formFields.executiveUserName]: {
              executor,
              executorPosition,
              executorOrganization,
            },
            [formFields.kindWorksAndComments]: {
              lastComment,
              description,
              checklists,
            },
            [formFields.task]: { id, name },
          })
        )
      : [];
    // eslint-disable-next-line
  }, [data]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "flex-end",
          width: "100%",
        }}
      >
        <TableBasic
          columns={config}
          rows={rows}
          handleClickCell={() => {}}
          withSearchField={false}
          isLoading={false}
          isPagination={false}
          pageNumber={pageNumber}
          changePageNumber={changePageNumber}
          isNotBigTable={true}
        />
      </Box>
    </Box>
  );
};

export default ExecutiveUserNameForm;
