import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { PATHS } from "../../../constants";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      align: "start",
      padding: 0,
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        paddingTop: 5,
        paddingLeft: 0,
      },
    },
    datalabels: {
      color: "#212121",
      align: "end",
      anchor: "end",
      zIndex: 999,
      offset: -6,
      font: {
        size: 10,
      },
    },
  },
  elements: {},
};

const VerticalBar = ({ data, labels }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dataCfg = useMemo(
    () => ({
      labels: labels?.length ? labels.map((label) => t(label)) : [""],
      datasets: data.map((item) => ({
        ...item,
        label: t(item.label),
        barPercentage: 0.3,
        data: [item.data],
      })),
    }),
    [t, data, labels]
  );
  const globalFields = {
    all_completed: "all_completed",
    overdued_jobs_amount: "overdued_jobs_amount",
  };

  const getPath = (name) => {
    const newName = name.replace("label:", "");
    if (newName === "completedInTimeJobsAmount") {
      return globalFields.all_completed;
    }
    if (newName === "overduedJobsAmount") {
      return globalFields.overdued_jobs_amount;
    }
    return "";
  };
  const handleClick = (event, elements) => {
    if (elements.length > 0) {
      const selectedDatasetIndex = elements[0].datasetIndex;
      const selectedDataset = data[selectedDatasetIndex];
      navigate(PATHS.GET_TASKS_STATUS(getPath(selectedDataset.label)));
    }
  };

  const chartOptions = {
    ...options,
    onClick: handleClick,
  };

  return (
    <div>
      <Bar plugins={[ChartDataLabels]} options={chartOptions} data={dataCfg} />
    </div>
  );
};

export default VerticalBar;
