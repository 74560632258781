import React, { useEffect, useState } from "react";
import { Box, Backdrop, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/redux";
import { useGetProfileInfoQuery } from "../../../middleware/usersApi";
import { useAction } from "../../../hooks/actions";
import ProfileMainDataForm from "./ProfileMainDataForm";
import ButtonsField from "./ButtonsField";
import EditProfileMainDataForm from "./EditProfileMainDataForm";
import "./styles/styles.css";

const Profile = () => {
  const { t } = useTranslation();
  const { setIsErrorRequest } = useAction();
  const [isEditedProfileData, setIsEditedProfileData] = useState(false);
  const { isSuccessSubmit } = useAppSelector((state) => state.settings);

  const token = useAppSelector((state) => state.users.token);

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    error: errorGetProfileData,
    refetch: refetchProfileData,
  } = useGetProfileInfoQuery(token, { skip: !token });

  useEffect(() => {
    setIsErrorRequest(errorGetProfileData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGetProfileData]);

  useEffect(() => {
    if (isSuccessSubmit) {
      refetchProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSubmit]);

  if (isLoadingProfileData) {
    return (
      <Backdrop
        sx={{ color: "var(--white)", zIndex: 9999 }}
        open={isLoadingProfileData}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <Box className="profile_container">
      <Box className="profile_container-header">
        <Typography className="title-page">
          {isEditedProfileData ? t("title_page:edit-profile") : t("title_page:profile") }
        </Typography>
        <ButtonsField
          isEditedProfileData={isEditedProfileData}
          setIsEditedProfileData={setIsEditedProfileData}
          token={token}
        />
      </Box>
      <Box className="scrolled-form">
        {!isEditedProfileData ? (
          <ProfileMainDataForm profileData={profileData} token={token} />
        ) : (
          <EditProfileMainDataForm profileData={profileData} token={token} setIsEditedProfileData={setIsEditedProfileData}/>
        )}
      </Box>
    </Box>
  );
};

export default Profile;
