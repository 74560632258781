import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const groupJobsApi = createApi({
  reducerPath: "groupjobs/api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ['GroupJob'],
  endpoints: (build) => ({
    getGroupTasksList: build.query({
      query: ({ PageNumber = 0, filters }) => ({
        url: `api/groupjobs/get-list?pageNumber=${PageNumber}${filters}`,
      }),
      providesTags: ['GroupJob'],
    }),
    getGroupTask: build.query({
      query: ({ id,PageNumber = 0, filters }) => ({
        url: `api/groupjobs/get-details?id=${id}&pageNumber=${PageNumber}${filters}`,
      }),
      providesTags: ['GroupJob'],
    }),
  }),
});

export const {
  useLazyGetGroupTasksListQuery,
  useLazyGetGroupTaskQuery
} = groupJobsApi;
