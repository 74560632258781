import React,{useEffect,useState} from "react";
import { useWatch } from "react-hook-form";
import { Box, Modal, Button, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import {
    useGetEquipmentQuery,
  } from "../../../middleware/equipmentsApi";
import FormItem from "../FormItem";
import FloorSchema from "../../pages/EquipmentList/FloorSchema";
export const ModalEquipSchema = ({
    open, setOpen, equipId,refetch,control,setValue
}) => {
  const { t } = useTranslation();
  const [equipmentsCoordinates, setequipmentsCoordinates] = useState([]);
  const {
    data: equipment,
    isLoading: isLoadingEquipment,
  } = useGetEquipmentQuery(equipId);
  useEffect(() => {
    if (equipment) {
      const {
        id,
        equipmentCoordinateX,
        equipmentCoordinateY,
        workingCorrectly,
        jobChart,
        typeId,
        facilityName,
        fireCompartmentName,
      } = equipment || {};
      const facilityAndFireCompartment = `${
        facilityName ? facilityName + ", " : ""
      }${fireCompartmentName || ""}`;
      const equipmentsCoordinates = [
        {
          id,
          floorSchemeCoordinateX: equipmentCoordinateX,
          floorSchemeCoordinateY: equipmentCoordinateY,
          workingCorrectly,
          jobChart,
          typeId,
        },
      ];
      setequipmentsCoordinates(equipmentsCoordinates)
    }
  }, [equipment,setequipmentsCoordinates]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      className="modal_address"
    >
      <Box className="modal_schema-body">
        <Box>
          <Box className="modal_address-header_right">
            <Typography className="modal_address-body-title">
              {t("title:schema_floor")}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

        </Box>
        <FloorSchema control={control} equipmentsCoordinates={equipmentsCoordinates} setValue={setValue}  data={equipment} />
      </Box>
    </Modal>
  );
};