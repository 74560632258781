import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  useGetEmployeesStatisticsQuery,
  useGetJobAnalyticsQuery,
  useGetJobStatisticsQuery,
  useGetPerformanceStatisticsQuery,
} from "../../../middleware/analyticsApi";
import { getDefaultSatrtDate, getDefaultEndDate } from "./helpers";

import ChartComponent from "./ChartComponent/ChartComponent";
import AreaChart from "./AreaChart/AreaChart";
import AvailabilityStatistics from "./AvailabilityStatistics/AvailabilityStatistics";
import TableWithCharts from "./TableWithCharts/TableWithCharts";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import { useAction } from "../../../hooks/actions";
import { LinarRatingBox } from "../ObjectsOnMap/LinarRatingBox";

const MainAnalytics = () => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(getDefaultSatrtDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());

  const {
    data: jobAnalytics,
    isLoading: isLoadingJobAnalytics,
    error: isErrorJobAnalytics,
  } = useGetJobAnalyticsQuery({ startDate, endDate });
  const {
    data: employeesStatistics,
    isLoading: isLoadingEmployeesStatistics,
    error: isErrorEmployeesStatistics,
  } = useGetEmployeesStatisticsQuery({ startDate, endDate });

  const {
    data: jobStatistics,
    isLoading: isLoadingJobStatistics,
    error: isErrorJobStatistics,
  } = useGetJobStatisticsQuery({ startDate, endDate });

  const {
    data: perfomanceStatistics,
    isLoading: isLoadingPerfomanceStatistics,
    error: isErrorPerfomanceStatistics,
  } = useGetPerformanceStatisticsQuery({ startDate, endDate });

  const { setIsErrorRequest } = useAction();

  const isLoadTasks = !isLoadingJobAnalytics;
  const isLoadStatistics = !isLoadingEmployeesStatistics;
  const isLoadJobStatistics = !isLoadingJobStatistics;
  const isLoadPerfomanceStatistics = !isLoadingPerfomanceStatistics;
  const isError =
    isErrorJobAnalytics ||
    isErrorEmployeesStatistics ||
    isErrorJobStatistics ||
    isErrorPerfomanceStatistics;

  useEffect(() => {
    setIsErrorRequest(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
          alignItems: "center",
        }}
      >
        <Typography className="title-page">
          {t("title_page:analytics")}
        </Typography>
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </Box>
      <LinarRatingBox />
      <Box>
        {isLoadTasks && isLoadJobStatistics && (
          <div
            style={{
              display: "flex",
              margin: "20px 0",
            }}
          >
            <ChartComponent jobAnalytics={jobAnalytics} />
          </div>
        )}
        {isLoadStatistics &&
          isLoadPerfomanceStatistics &&
          isLoadTasks &&
          isLoadJobStatistics && (
            <Box>
              <AvailabilityStatistics
                perfomanceStatistics={perfomanceStatistics}
              />
              <AreaChart data={jobStatistics} />
            </Box>
          )}
        {isLoadStatistics && isLoadPerfomanceStatistics && (
          <TableWithCharts employeesStatistics={employeesStatistics} />
        )}
      </Box>
    </>
  );
};

export default MainAnalytics;
