import { getEquipmentsCoordinatesWithRoomId } from "../../../helpers/helper";
import { floorSchemeInfo } from "./form-config";
import {fileEntityFields} from "./constants";

export const getRequestEditData = (data) => {
  const { id, workingCorrectly, equipmentsCoordinates,floor, roomAddresses=[],roomAddressId, isActive,name,plume,placement,integrationNumber,manufactureDate,equipmentPassportFile,installationDate,number } = data || {};
  const { floorSchemeCoordinateX, floorSchemeCoordinateY, typeId } =
    equipmentsCoordinates[0] || {};
    console.log("equipmentsCoordinates",data)
  const placementEquip = placement == "Запотолочное пространство" ? 0 : placement == "Подпольное пространство" ? 1 : 2
  const entities = {
    name,
    plume,
    integrationNumber:integrationNumber,
    manufactureDate:manufactureDate,
    installationDate:installationDate,
    id,
    floorInfoId:floor !== "" ? floor : null,
    placement:placementEquip,
    workingCorrectly,
    equipmentCoordinateX: floorSchemeCoordinateX,
    equipmentCoordinateY: floorSchemeCoordinateY,
    roomAddressId: roomAddressId !== "" ? roomAddressId : null,
    typeId,
    isActive,
    number
  };
  
  return { entities, files: {...equipmentPassportFile } };
};



export const getRequestDataFilesForAdd = (entityId, entityType, files) => {
  const formData = new FormData();

  if(!files) {
      return formData;
  }

  const { filesToAdd, filesToDelete } = files || {};
  let fileConfigIndex = 0;
  
  if(filesToAdd?.length) {
    filesToAdd.forEach((file, index) => {
        fileConfigIndex += index
        formData.append(`entityFileList[${fileConfigIndex}].EntityId`, entityId);
        formData.append(`entityFileList[${fileConfigIndex}].EntityField`, fileEntityFields["equipmentPassportFile"]);
        formData.append(`entityFileList[${fileConfigIndex}].EntityType`, entityType);
        formData.append(`entityFileList[${fileConfigIndex}].File`, file);
        formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Add");
    })
    fileConfigIndex++
  }
  console.log("filesToAdd",formData)
  if(filesToDelete?.length) {
    filesToDelete.forEach((file, index) => {
        if (file.id) {
            fileConfigIndex += index
            formData.append(`entityFileList[${fileConfigIndex}].EntityId`, entityId);
            formData.append(`entityFileList[${fileConfigIndex}].EntityField`, fileEntityFields["equipmentPassportFile"]);
            formData.append(`entityFileList[${fileConfigIndex}].EntityType`, entityType);
            formData.append(`entityFileList[${fileConfigIndex}].id`, file.id);
            formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Delete");
        }
    })
    fileConfigIndex++;
  }

  return formData;
};