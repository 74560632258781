import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FormItem from "../../components/FormItem";
import { useLinkFacilitiesWithConsoleMutation } from "../../../middleware/facilitiesApi";
import { useAction } from "../../../hooks/actions";
import { formFields } from "./form-config";

export const ModalAddObject = ({ open, setOpen, config, selectedId, refetch }) => {
  const { t } = useTranslation();

  const { control, handleSubmit, resetField } = useForm();

  const [
    linkFacilitiesWithConsole,
    { isError: isErrorAdd, isSuccess: isSuccessAdd },
  ] = useLinkFacilitiesWithConsoleMutation();

  const onSubmit = async (data) => {
    linkFacilitiesWithConsole({
      facilitiesIds: data?.facilitiesIds,
      consoleId: selectedId,
    }).then(({ error }) => {
      !error && refetch({ pageNumber: 0 });
      handleClose();
    });
  };

  const handleClose = () => {
    resetField(formFields.facilitiesIds);
    setOpen(false);
  };

  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();

  useEffect(() => {
    setIsErrorSubmit(isErrorAdd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorAdd]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccessAdd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAdd]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="modal select_columns"
        maxWidth="md"
      >
        <DialogTitle className="modal-title-between border_bottom">
          <Typography className="modal-title">
            {t("button:addObject")}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0 36px" }}>
          <form
            id="formModalSelect"
            className="modal select_columns-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            {config.map(({ name, label, input, ...rest }) => (
              <FormItem
                key={name}
                control={control}
                name={name}
                label={label}
                input={input}
                {...rest}
              />
            ))}
          </form>
        </DialogContent>
        <DialogActions className="select_columns-btns modal-btn-field modal-btn-field-right">
          <Button className="btn btn-outlined-grey" onClick={handleClose}>
            {t("button:comeback")}
          </Button>
          <Button
            form="formModalSelect"
            className="btn btn-primary-fill"
            type="submit"
            autoFocus
          >
            {t("button:addSelectedObjets")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
