
import CheckboxSimple from "../../components/CheckboxSimple";

export const formFields = {
  name: "name",
  searchParams: "searchParams",
  integrationNumber: "integrationNumber",
  number: "number",
  plume: "plume",
  protectionObjectName: "protectionObjectName",
  protectionObjectId: "protectionObjectId",
  workingProperly: "workingProperly",
  isReplaced: "isReplaced",
  edit: "edit",
  lastServiceDate: "lastServiceDate",
  lifeTimeDate: "lifeTimeDate",
  floorNumber: "floorNumber",
  floorId: "floorId",
  equipmentGroupId: "equipmentGroupId",
  explotationExceeded: "explotationExceeded",
  equipmentKindName: "equipmentKindName",
  equipmentKindId: "equipmentKindId",
  workingCorrectly: "workingCorrectly",
  searchName: "searchName",
  searchReplacingEquipmentName: "searchReplacingEquipmentName",
  searchPlume: "searchPlume",
  searchIntegrationNumber: "searchIntegrationNumber",
  searchNumber: "searchNumber",
  tasks: "tasks",
  roomAddressName: "roomAddressName",
  objectIntegrationNumber: "objectIntegrationNumber",
  searchRoomAddressName: "searchRoomAddressName",
  searchObjectIntegrationNumber: "searchObjectIntegrationNumber",
  replacementDate: "replacementDate",
  replacingEquipment: "replacingEquipment"
}

export const allColumns = [
  { name: formFields.name, showName: "equip_name", value: true },
  { name: formFields.number, value: true, showName: "number" },
  { name: formFields.integrationNumber, value: true, showName: "intNumber" },
  { name: formFields.plume, value: true, showName: "plume" },
  { name: formFields.protectionObjectName, value: true, showName: "protectionObjectName" },
  { name: formFields.workingProperly, showName: "workingCorrectly", value: true },
  { name: formFields.isActive, showName: "isActive", value: true },
  { name: formFields.lastServiceDate, value: true, showName: "lastServiceDate" },
  { name: formFields.lifeTimeDate, showName: "lifeTimeDate", value: true },
  { name: formFields.floorNumber, showName: "floorNumber", value: true },
  { name: formFields.roomAddressName, showName: "roomAddressName", value: true },
  { name: formFields.objectIntegrationNumber, showName: "objectIntegrationNumber", value: true },
  { name: formFields.equipmentKindName, showName: "system", value: true },
  { name: formFields.replacementDate, showName: "replacementDate", value: true },
  { name: formFields.replacingEquipment, showName: "replacingEquipment", value: true },
  { name: formFields.tasks, showName: "tasks", value: true }
];

export const getConfigFormModalSelect = (columns) =>
  columns?.map(({ name }) => ({
    name,
    label: `tableHeader:${name}`,
    type: "checkbox",
    rules: {},
    flexBasis: "50%",
    input: (props) => <CheckboxSimple {...props} />,
  }));
