import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const ModalNoPermission = ({ open, handleClose }) => {
  const { t } = useTranslation();
 
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="modal"
      maxWidth="md"
    >
      <DialogTitle className="modal-title-between">
        <Typography className="modal-title">
            {t("modal_permission:title")}
          </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "0 24px 40px" }}>
        <Typography className="modal-text">
          {t("modal_permission:text")}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};