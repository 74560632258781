import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useWatch} from "react-hook-form";
import dayjs from "dayjs";
import {Box, Typography} from "@mui/material";

import {ShelfLifeItemFormConfig} from "./form-config";
import { dateFormat } from "../../../../constants";

import FormItem from "../../../components/FormItem";
import DateItems from "./DateItems";

const ShelfLifeItem = ({
    name,
    label,
    control,
    errors,
    setValue,
    trigger
}) => {

    const { t } = useTranslation();
    const { shelfLife, manufactureDate, installationDate } = useWatch({
        control,
    });

    const style = useMemo(() => {
        return {
            ".block-footer-text, .block-title, .adornment" : {
                color: errors?.shelfLife ? "#d32f2f!important" : "inherit"
            }
        }
    }, [errors?.shelfLife])

    const date = useMemo(() => {
        const date = installationDate || manufactureDate
        return date && dayjs(installationDate || manufactureDate)
    }, [manufactureDate, installationDate])

    return (
        <Box sx={style} className="block">
            <Box>
                <Typography className="block-title">{t(label)}</Typography>
                <DateItems
                    itemName={name}
                    error={errors["shelfLife"]}
                    setValue={setValue}
                    itemValue={shelfLife}
                    trigger={trigger}
                />
                <Box className="block-footer">
                    <Box sx={{display: "none"}}>
                        {ShelfLifeItemFormConfig.map(({name, label, input, ...rest}) => (
                            <FormItem
                                key={name}
                                control={control}
                                errors={errors}
                                name={name}
                                label={label}
                                input={input}
                                trigger={trigger}
                                {...rest}
                            />
                        ))}
                    </Box>
                    <Box className="block-footer-wrapper-text">
                      <Typography className="block-footer-text">
                        {`${t("label:validUntil")}:`}
                      </Typography>
                      <Typography className="block-footer-text">
                          {date?.$D ? date.add(shelfLife, 'M').format(dateFormat) : ""}
                      </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ShelfLifeItem;