import { useEffect, useMemo, useState } from "react";
import { useLazyGetOrgInnQuery, useLazyGetBankBICQuery } from "../../../middleware/addressDataApi";
import { useLazyGetOrganizationByInnQuery } from "../../../middleware/apiOrganizations";
import { useWatch } from "react-hook-form";
import { formFields } from "./form-config";

export const useWatchOrganizationInnNumber = ({ control, errors, reset, setValue, resetField }) => {
  const [getOrganizationByInn] = useLazyGetOrgInnQuery();
  const [getAlreadyOrganizationByInn] = useLazyGetOrganizationByInnQuery();
  const [okvedCode, setokvedcode] = useState("");
  const [addressFromDadata, setaddressFromDadata] = useState([null, null]);
  const [indCheck, setindCheck] = useState(false);
  const data = useWatch({ control })

  const innNumberValue = useWatch({
    control,
    name: `organizationRegistrationInfo.${formFields.innNumber}`,
  });
  const nameValue = useWatch({
    control,
    name: `organizationRegistrationInfo.${formFields.name}`,
  });

  console.log("innNumberValue", innNumberValue)
  const shouldDisableAllFields = useMemo(
    () => !innNumberValue || errors.innNumber,
    [errors.innNumber, innNumberValue]
  );

  useEffect(() => {
    if (!innNumberValue || innNumberValue?.length < 10 || innNumberValue?.length > 13 || !reset) {
      setokvedcode("")
      reset({"organizationRegistrationInfo.innNumber":innNumberValue})
      //setaddressFromDadata([null, null])
      return;
    }
    getOrganizationByInn(innNumberValue)
      .then((res) => {
        const organization = res?.data?.suggestions && res?.data?.suggestions?.length > 0 ? { ...res?.data?.suggestions[0] } : null;


        if (!organization?.data) {
          setokvedcode("")
      reset({"organizationRegistrationInfo.innNumber":innNumberValue})
      //setaddressFromDadata([null, null])
          return;
        }
        const organizationData = organization?.data

        setokvedcode(organizationData.okved)
        setValue("organizationRegistrationInfo.name", organizationData?.name?.short_with_opf)
        setValue("organizationRegistrationInfo.ogrnNumber", organizationData.ogrn)
        setValue("organizationRegistrationInfo.kppCode", organizationData.kpp)
        setValue("organizationRegistrationInfo.okpoCode", organizationData.okpo)
        setValue("organizationRegistrationInfo.okvedCode", organizationData.okved)
        setindCheck(organizationData?.type == "INDIVIDUAL" ? true : false)
        setValue("organizationRegistrationInfo.indCheck", organizationData?.type == "INDIVIDUAL" ? true : false)
        setaddressFromDadata([organizationData?.address, organizationData?.address])//( { city: organizationData?.address.data.city, street: organizationData?.address.data.street, building: organizationData?.address.data.house 
      
      })
      .catch((err) => {
        console.log(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innNumberValue, errors.innNumber, resetField, reset, setValue, getOrganizationByInn, getAlreadyOrganizationByInn]);

  return { innNumberValue, shouldDisableAllFields, okvedCode: okvedCode, indCheck, addressFromDadata, setaddressFromDadata};
};

export const useWatchBicNumber = ({ control, errors, setValue }) => {
  const [getBankByBIC] = useLazyGetBankBICQuery();
  const data = useWatch({ control })

  const BankInfo = useWatch({
    control,
    name: `${[formFields.organizationRegistrationInfo]}.${[
      formFields.bankDetails,
    ]}.bankBIC`,
  });

  useEffect(() => {

    if (!BankInfo || BankInfo?.length < 9 || !setValue) {
      return;
    }

    getBankByBIC(BankInfo)
      .then((res) => {
        const bank = res?.data?.suggestions && res?.data?.suggestions?.length > 0 ? { ...res?.data?.suggestions[0] } : null;

        if (!bank?.data) {
          return;
        }
        const bankData = bank?.data
        setValue("organizationRegistrationInfo.bankDetails." + formFields.bankBIC, bankData.bic)
        setValue("organizationRegistrationInfo.bankDetails." + formFields.bankName, bankData.name.short == null ? bank.value : bankData.name.short)
        setValue("organizationRegistrationInfo.bankDetails." + formFields.correspondentAccount, bankData.correspondent_account)
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BankInfo, errors.bankBIC, setValue, getBankByBIC]);

  return { BankInfo };
};

