import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AccordionSection = ({
  expandedAccordions,
  handleAccordionChange,
  idd,
  title,
  children,
  withoutPadding
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const handleExpandAll = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      square={true}
      disableGutters={true}
      expanded={
        expandedAccordions ? expandedAccordions.includes(idd) : expanded
      }
      onChange={
        handleAccordionChange ? handleAccordionChange(idd) : handleExpandAll
      }
      sx={{
        "& .MuiAccordionSummary-expandIconWrapper svg": {
          color: "var(--black)",
        },

        padding: `${withoutPadding ? '0 10px 0 0' : "20px 32px 20px 32px"}`,
        background: "var(--white)",
        borderRadius: "32px",
        "&::before": { height: '0 !important'}
      }}
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          flexDirection: "row-reverse",
          padding: "0",
          minHeight: "fit-content",
          "& .MuiAccordionSummary-content": {
            transition: "margin-bottom 0.3s ease-in-out",
          },
          "& .Mui-expanded": { mb: "32px" },
        }}
      >
        <Typography className="form-section-label">
          {t(`title:${title}`)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          mb: '12px',
          "& .input-wrapper": { padding: "0px" },
          "& .input-block-wrapper": { padding: "0px", border: 'none' },
          "& .input-block-title": { mb: '0' },
          "& .input-block-field": { mb: '0' },
          "& .checkbox": { height: 'fit-content' },
          
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionSection;
