import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const calendarPlanApi = createApi({
  reducerPath: "calendar-plan/api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getCalendarPlan: build.query({
      query: ({ startDate, endDate }) => ({
        url: `api/calendar-plan/get-calendar-plan?startDate=${startDate}&endDate=${endDate}`,
      }),
    }),
  }),
});

export const { useGetCalendarPlanQuery } = calendarPlanApi;
