import { t } from "i18next";
import { optionFields,nameFields } from "./form-config";

export const eventsOptions = [
  {
    value: optionFields.allEvents,
    name: t(`options_name:${nameFields.allEvents}`),
  },
  {
    value: optionFields.alerts,
    name: t(`options_name:${nameFields.alerts}`),
  },
  {
    value: optionFields.malfunctions,
    name: t(`options_name:${nameFields.malfunctions}`),
  },
  {
    value: optionFields.automation,
    name: t(`options_name:${nameFields.automation}`),
  },
  {
    value: optionFields.maintenance,
    name: t(`options_name:${nameFields.maintenance}`),
  },
];

// export const bgColors = [
//   { value: 1, color: "#FDE8E9" },
//   { value: 2, color: "#F9F2E2" },
//   { value: 3, color: "#d3f1e8" },
// ];

export const bgColors = [
  { value: 2, color: "#FDE8E9" },
  { value: 3, color: "#FDE8E9" },
];