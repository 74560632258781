import InputText from "../../../../../components/InputText";
import React from "react";

import SelectSimple from "../../../../../components/SelectSimple";

import { workingCorrectlyOptions, correspondsOptions } from "./constants";
import { FacilityEquipmentSelectGrouping } from "../../Components";

export const equipmentFormFields = {
  equipments:"equipments",
  equipmentId: "equipmentId",
  corresponds: "corresponds",
  workingCorrectly: "workingCorrectly",
  factValue:"factValue",
  factValueUnit:"factValueUnit"
}

const commonFields = [
    {
        name: equipmentFormFields.equipmentId,
    
        rules: { required:true},
        sx:{width:200},
        input: (props) => <FacilityEquipmentSelectGrouping {...props} />,
      },
      {
        name: equipmentFormFields.corresponds,
        type: "select-boolean",
        rules: {},
        defaultValue:"true",
        
        options: correspondsOptions,
        input: (props) => <SelectSimple sx={{width:"200px !important"}} {...props} />,
      },
      {
        name: equipmentFormFields.workingCorrectly,
        type: "select-boolean",
        rules: {},
        
        options: workingCorrectlyOptions,
        input: (props) => <SelectSimple sx={{width:"200px !important"}} {...props} />,
      },
    
  ]
export  const RABFields = [
  {
    name: equipmentFormFields.factValue,
    label: "label:deviceReading",
    type: "number",
    onKeyDown:(e) => {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "," || e.Key == ".") {
        e.preventDefault()
      }
    },
    InputProps:{ type:"number" },
    rules: {},
    input: (props) => <InputText sx={{width:"200px !important"}} {...props}/>,
  }
] 
export const voltageFileds = [
  {
    name: equipmentFormFields.factValueUnit,
    label: "label:deviceReading",
    type: "number",
    onKeyDown:(e) => {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "," || e.Key == ".") {
        e.preventDefault()
      }
    },
    InputProps:{ type:"number" },
    rules: {},
    input: (props) => <InputText {...props}/>,
  }
]
  export const getEquipmentFormConfig = pointName => {
    
    let fields = commonFields;
    
    return fields;
  }