import React from "react";
import { useWatch } from "react-hook-form";
import { Box } from "@mui/material";

import FormItem from "../../../../components/FormItem";
import { formFields } from "../../form-config";

export const BasicFrom = ({ control, errors, config, data }) => {
  const isContactPerson = useWatch({
    control,
    name: formFields.isContactPerson,
  });

  return (
    <Box>
      <Box className="basic_form" >
        {config.map(({ id, ...rest }) => {
          return (
          <FormItem
            key={id}
            control={control}
            errors={errors}
            editData={data}
            {...rest}
           /* display={
              !isContactPerson && name === formFields.organizationWorkingFor
                ? "none"
                : "block"
            }*/
             // {required: isContactPerson }
             /* name === formFields.organizationWorkingFor
                ? { required: isContactPerson }
                : rules*/
          />
        )})}
      </Box>
    </Box>
  );
};
