import InputText from "../../../components/InputText";
import { FloorShortFields } from "../FloorShortFields";
import { templateFloor, templateNumber } from "../FloorFields/form-config";
import IntNumbersFields from "../IntShortFields";
import { Box } from "@mui/material";

export const formFields = {
  name: "name",
  floors: "floors",
  fireCompartments: "fireCompartments",
  protectionObjectIntegrNumbersAlts: "protectionObjectIntegrNumbersAlts",
};

export const templateCompartment = {
  [formFields.name]: "",
  [formFields.floors]: [templateFloor],
};

export const templateIntnumber = {
  [formFields.name]: "",
  [formFields.protectionObjectIntegrNumbersAlts]: [templateNumber],
};
export const fireCompartmentShortConfig = [
  {
    name: formFields.name,
    label: "label:fireCompartmentName",
    placeholder: "placeholder:name_fire_place",
    type: "text",
    rules: {
      required: true,
    },
    input: (props) => (
      <Box sx={{width: '65%'}}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.floors,
    rules: {
      required: true,
    },
    
    flexBasis: "49%",
    CustomFormItem: FloorShortFields,
  },
];
export const intNumbersConfig = [
  {
    name: formFields.name,
    label: "label:fireCompartmentName",
    type: "text",
    rules: {
      required: true,
    },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.protectionObjectIntegrNumbersAlts,
    rules: {
      required: true,
    },
    flexBasis: "49%",
    CustomFormItem: IntNumbersFields,
  },
];
