import React from "react";
import { useGetFacilitySelectElementsQuery } from "../../../../middleware/facilitiesApi";
import { CircularProgress } from "@mui/material";
import SelectSimple from "../../../components/SelectSimple";

const FacilitySelectSimple = (props) => {
  const { data = [], isLoading } = useGetFacilitySelectElementsQuery();

  if (isLoading) {
    return <CircularProgress />;
  }
  return (<SelectSimple {...props} options={data} />);
};

const formFields = {
  protectionObjectId: "protectionObjectId",
};

export const formConfig = [
  {
    name: formFields.protectionObjectId,
    label: "label:object",
    type: "select-text",
    rules: {
      required: true,
    },
    input: (props) => (<FacilitySelectSimple {...props} />),
  },
];