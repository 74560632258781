import {
  Avatar,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetEquipmentRegistryQuery } from "../../../middleware/equipmentsApi";

const EquipmentRegistry = ({ open, onClose, objectId }) => {
  const { t } = useTranslation();

  const { data: equipmentList, isLoading } =
    useGetEquipmentRegistryQuery(objectId);

  if (isLoading || !equipmentList) {
    return (
      <Backdrop sx={{ color: "var(--white)", zIndex: 9999 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="modal equipment-registry_dialog"
    >
      
      <DialogTitle
        style={{ marginTop: "35px" }}
        className="modal-title-between"
      >
        <Typography className="modal-title">
          {t("modal_title:equipmentRegistry")}
        </Typography>
        <IconButton
        style={{ position: "absolute", top: "10px", right: "20px" }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      </DialogTitle>
      <DialogContent>
        <List>
          {equipmentList.map(
            ({ equipmentRegistryKind, equipmentRegistryKindElements }) => (
              <ListItem sx={{ display: "block", paddingLeft: 0 }}>
                <ListItemText
                  sx={{
                    "& .MuiTypography-root": {
                      fontFamily: "var(--INTER)",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "var(--popupTitle)",
                      color: "var(--black)",
                      padding: 0,
                    },
                  }}
                >
                  {equipmentRegistryKind}
                </ListItemText>
                <Divider component="li" />
                <List>
                  {equipmentRegistryKindElements.map(
                    ({ equipmentName, equipmentAmount, equipmentType }) => (
                      <ListItem sx={{ paddingLeft: 0 }}>
                        <ListItemAvatar>
                          <Tooltip
                            title={t("label:amount")}
                            placement="bottom-end"
                          >
                            <Avatar>{equipmentAmount}</Avatar>
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            "& .MuiTypography-root": {
                              fontFamily: "var(--INTER)",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "var(--standart)",
                              padding: 0,
                              color: "var(--black)",
                              "&.MuiListItemText-primary": {
                                fontSize: "var(--titleBlock)",
                              },
                            },
                          }}
                          primary={equipmentName}
                          secondary={
                            <Typography
                              sx={{
                                display: "inline",
                                fontSize: "var(--underInput)",
                              }}
                            >
                              {equipmentType}
                            </Typography>
                          }
                        />
                      </ListItem>
                    )
                  )}
                </List>
              </ListItem>
            )
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default EquipmentRegistry;
