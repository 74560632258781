import React, { useEffect } from 'react';
import { useFieldArray } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { formFields } from "./form-config";
import { worksConfig, FloorsConfig, templatejob } from "./form-config.jsx";

import FormItem from "../../../components/FormItem";
import { useTranslation } from 'react-i18next';

const WorkShortFields = ({ control, errors, rules, setValue }) => {
  const { t } = useTranslation();

  const { fields: WorkShortFields, append, remove } = useFieldArray({
    control,
    name: `workRequirements`,
  });


  return (
    <Box sx={{ alignSelf: "start",marginTop: "20px",flexBasis: "100%" }}>
      {WorkShortFields.length > 0 ?
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ flexBasis: "35.5%", fontSize: "20px" }}>Вид работ</Typography>
          <Typography sx={{ flexBasis: "35%", fontSize: "20px" }}>Состав работ</Typography>
          <Typography sx={{ flexBasis: "20%", fontSize: "20px" }}>Активный</Typography>
        </Box>
        :
        <Typography sx={{ flexBasis: "45%",marginTop: "10px",marginBottom: "20px", fontWeight: "bold", fontSize: "20px" }}>Работы</Typography>
    }
    <Box sx={{overflowY:"auto",maxHeight:"310px",}}>
      {WorkShortFields.map((item, index) => (
        <Box
          key={item.id}
          sx={{
            
            display: "grid",
            gridTemplateColumns: "9fr 1fr",
            gridColumnGap: "20px",
            alignItems: "flex-start",
            borderBottom: "1px solid var(--borderLightGrey)",
            ".error-text": {
              whiteSpace: "normal"
            }
          }}
        >
          <Box sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}>
            {worksConfig.map(({ name, label, input, type, ...rest }) => (
              <FormItem
                key={`workRequirements.${index}.${name}`}
                control={control}
                errors={errors}
                name={`workRequirements.${index}.${name}`}
                label={label}
                input={input}
                rules={rules}
                type={type}
                setValue={setValue}
                {...rest}
              />
            ))}
          </Box>

          <Box sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>

            <Button
              className="btn btn-remove btn-basket-small"
              onClick={() => remove(index)}
            >
              <DeleteForeverIcon />
            </Button>

          </Box>

        </Box>
      ))}
</Box>
      <Box sx={{ alignSelf: "center", mt: '12px' }}>
        <Button
          className="btn btn-outlined-primary"
          variant="outlined"
          onClick={() => append(templatejob)}
        >
          {t("button:add")}
        </Button>
      </Box>
    </Box>
  );
};

export default WorkShortFields;